import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
import * as Yup from "yup";
import { countries, continents } from "countries-list";
import { toPng } from 'html-to-image';
import { Link, useLocation, useNavigate } from "react-router-dom";
// material ui
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
  Button,
  Divider,
  IconButton
} from "@mui/material";
// components
import Layout from "../../layout/Layout";
import {
  MuiDataGrid,
  MuiAutocomplete,
  geographyArr,
  GridCellExpand,
  ModalPopUp,
  FormSubmitButton,
  FormTextField as MyTextInput,
  Notifier,
  ErrorNotifier,
  ModalCloseBtn,
  ConfirmDialog,
  MuiTooltip,
  CommonCurdPopUp
} from "../../components";
// redux
import {
  getMapAssessorsByTrackingId,

} from "../../features/config_and_publish/mapAssessorSlice";
import PreviewIcon from '@mui/icons-material/Preview';
import {
  publishAssessment,
  getPublishPreviewReminderById,

  resetMessage,
  resetErrMessage,
} from '../../features/config_and_publish/publishAssessmentSlice'
import { CoPresentOutlined } from "@mui/icons-material";

// -------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ------------------------------------------------------------------------

const PublishAssessment = (props) => {
  const [date, setDate] = useState("")
  const [previewEmail, setPreviewEmail] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const htmlRef = useRef(null);
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate()

  const { mapAssessorsData, selectedData } =
    useSelector((state) => ({ ...state.mapAssessors }));

  const { message, errMessage, previewRimnderData } = useSelector((state) => ({ ...state.publishAssessment }))

  function getClient(params) {
    return `${params.row.assessmentCustomerUser?.assessmentCustomerUserDetail?.firstName || ""} ${params.row.assessmentCustomerUser?.assessmentCustomerUserDetail?.lastName || ""}`;
  }

  function getClientEmail(params) {
    return params?.row?.assessmentCustomerUser?.email
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
  }

  const data = {
    rows: mapAssessorsData,
    columns: [
      { field: "client", headerName: "Assessor", width: 250, valueGetter: getClient },
      { field: 'email', headerName: 'E-Mail', width: 250, valueGetter: getClientEmail },
      { field: "relationshipType", headerName: "Relationship", width: 150 },
      // {
      //   field: 'Preview',

      //   type: 'preview',
      //   width: 70,
      //   renderCell: (params) => {
      //     if (params?.assessmentStatus !== "Published" && !params?.assessmentStatus !== "Completed") {
      //       return (
      //         // location?.state?.permissions && location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "forceDelCustUsr") && 
      //         <MuiTooltip title="Assessor Email Template" placement="right">
      //           <IconButton onClick={() => handlePreviewClick({
      //             trackingId: location?.state?.trackingSelectedData?.id, customerId: params?.row?.assessmentCustomerUser?.id
      //           })} color="primary" >
      //             <PreviewIcon />
      //           </IconButton>
      //         </MuiTooltip>
      //       );
      //     }

      //   }
      // },
      {
        field: "comments",
        headerName: "Comments",
        width: 350,
        renderCell: renderCellExpand
      },
    ],
  };

  useEffect(() => {
    dispatch(getMapAssessorsByTrackingId({ assessmentTrackingId: location?.state?.trackingSelectedData?.id }));
    if (location?.state?.trackingSelectedData?.cutOffDate) {

      let date = location?.state?.trackingSelectedData?.cutOffDate
      date = date.substring(0, 10).split('-')
      date = date[1] + '-' + date[2] + '-' + date[0]
      setDate(date)
    }
  }, []);

  const handlePublish = () => {
    dispatch(
      publishAssessment({
        id: location?.state?.trackingSelectedData?.id
      })
    ).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          navigate("/exco/publish", { state: { permissions: location?.state?.permissions } })
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
          navigate("/exco/publish", { state: { permissions: location?.state?.permissions } })
        }, 3000);
      }
    });
  }


  const handlePreviewClick = async (data) => {

    setPreviewEmail(true)
    dispatch(getPublishPreviewReminderById({ id: data?.trackingId, }))
    if (htmlRef.current) {
      // Convert HTML content to image
      await toPng(htmlRef.current);
    }
  }

  const showPublishDialog = () =>
    setConfirmDialog({
      isOpen: true,
      title: "Attention!",
      subTitle: "Clicking this button will send an email to the designated recipients. Please Preview the information before proceeding. Once sent, emails cannot be retrieved.",
      onConfirm : ()=> handlePublish()
    });

    const handleSetConfirmDialog = (obj) => {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: obj.isOpen,
      });
    };
  
return (
    <Layout>
      <>
        <PageHeader variant="pageHeader">
          Preview Assessment {location?.state?.trackingSelectedData?.assessmentTemplate?.assessmentTemplateName} for {location?.state?.trackingSelectedData?.assessmentCustomerUser?.assessmentCustomerUserDetail?.firstName} {location?.state?.trackingSelectedData?.assessmentCustomerUser?.assessmentCustomerUserDetail?.lastName}
        </PageHeader>
        <Grid container >
          <div style={{ margin: "auto" }}>
            <p >
              <b >Cut Off Date</b> : {date && date}
            </p>
          </div>
          {location?.state?.trackingSelectedData?.assessmentStatus !== "Completed" && 
          location?.state?.trackingSelectedData?.assessmentStatus !== "Published" && <label
            htmlFor="icon-button-file"
            style={{ position: "absolute", right: 100, zIndex: 1 }}
          >

            <MuiTooltip title="Email Preview" placement="bottom">
              <IconButton
                onClick={() => handlePreviewClick({
                  trackingId: location?.state?.trackingSelectedData?.id, customerId: location?.state?.trackingSelectedData?.
                    assessmentCustomerUser?.id
                })}
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <PreviewIcon />
              </IconButton>
            </MuiTooltip>

          </label>}

          {/* &nbsp;
          <Divider orientation="vertical" variant="middle" flexItem={true} /> &nbsp;
           <p>
          <b>Reminder Frequency</b> - {location?.state?.trackingSelectedData?.reminderFrequency && `${location?.state?.trackingSelectedData?.reminderFrequency} days`} 
           </p> */}
        </Grid>
        <MuiDataGrid
          height={300}
          data={data}
        />

        {previewEmail && <CommonCurdPopUp

          mediumScreen={true}
          close={
            () => setPreviewEmail(false)
          }
        >
          {

            previewRimnderData?.data?.tempalteArray?.length > 0 ?

              previewRimnderData?.data?.tempalteArray?.map((data, index) => {
                if (index === 0) {
                  return (
                    <div ref={htmlRef} key={index}>
                      <h5 className="center bold pt-2 header-font-size">Client Email Preview</h5>
                      <div dangerouslySetInnerHTML={{ __html: data }} />
                    </div>
                  )
                }
                else {
                  return (
                    <div ref={htmlRef} key={index}>
                      <h5 className="center bold pt-2 header-font-size">Assessor Email Preview</h5>
                      <div dangerouslySetInnerHTML={{ __html: data }} />
                    </div>

                  )

                }


              })

              : `${previewRimnderData?.message}`



          }




        </CommonCurdPopUp>}
        <Box textAlign='center'>
          <Button variant="contained" sx={{ margin: '1rem' }} onClick={showPublishDialog}>Publish</Button>
          <Button variant="contained" component={Link} to="/exco/publish" state={{ permissions: location?.state?.permissions }} sx={{
            margin: '1rem', background: '#e50000', '&:hover': {
              background: '#ff0000'
            }
          }}>
            Cancel
          </Button>
        </Box>

      </>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />
      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default PublishAssessment;
