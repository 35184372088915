import { configureStore } from '@reduxjs/toolkit'
// reducers(slices)
import authReducer from "../features/auth/authSlice";
import forgotPasswordReducer from '../features/auth/forgotPasswordSlice'
// notification
import notificationReducer from '../features/notifications/notificationSlice'
//dashboard
import dashboardReducer from "../features/dashboard/dashboardSlice"
// iam reducers
import l1MenuReducer from '../features/iam/application_management/menu_management/l1MenuSlice'
import l2MenuReducer from '../features/iam/application_management/menu_management/l2MenuSlice'
import l3MenuReducer from '../features/iam/application_management/menu_management/l3MenuSlice'
import apiManagementReducer from "../features/iam/application_management/apiManagementSlice";
import userGroupsReducer from "../features/iam/role_management/userGroupsSlice";
import rolesReducer from "../features/iam/role_management/rolesSlice";
import l1AccessPermissionReducer from "../features/iam/role_management/access_permissions/l1AccessPermissionSlice";
import l2AccessPermissionReducer from '../features/iam/role_management/access_permissions/l2AccessPermissionSlice';
import l3AccessPermissionReducer from '../features/iam/role_management/access_permissions/l3AccessPermissionSlice';
// customer management reducers
import customerReducer from "../features/customer/customerSlice";
import custUserReducer from "../features/customer/custUserSlice";
import accountReducer from "../features/customer/accountSlice";
// platform --> assessment configuration 
import assessmentTemplateConfigReducer from "../features/config/assessmentTemplateConfigSlice";
import assessmentTemplateSectionConfigSlice from "../features/config/assessmentTemplateSectionConfigSlice";
import assessmentTemplateDimensionConfigSlice from "../features/config/assessmentTemplateDimensionConfigSlice";
import questionTypeReducer from '../features/config/questionTypeSlice'
// platform -->Assessment framework
import assessmentFrameworkConfigReducer from "../features/config/assessmentFrameworkConfigSlice";
import assessmentFrameworkSectionConfigReducer from "../features/config/assessmentFrameworkSectionConfigSlice";
import assessmentFrameworkDimensionConfigReducer from "../features/config/assessmentFrameworkDimensionConfigSlice";


import questionRatingValueReducer from '../features/config/questionRatingValueSlice'
import assessmentProgressReducer from '../features/config/assessmentProgressSlice'
import assessmentStatusReducer from '../features/config/assessmentStatusSlice'
import assessmentTemplateStatusReducer from '../features/config/platformStatusSlice'
import responseStatusReducer from '../features/config/responseStatusSlice'
import individualResponseStatusReducer from '../features/config/individualResponseStatusSlice'
import relationshipTypeReducer from '../features/config/relationshipTypeSlice'
// prepare assessment reducer
import prepareAssessmentReducer from '../features/prepare_assessments/prepareAssessmentSlice';
import assessmentTemplateSectionReducer from '../features/prepare_assessments/assessmentTemplateSectionSlice';
import assessmentTemplateDimensionReducer from '../features/prepare_assessments/assessmentTemplateDimensionSlice'
import prepareQuestionReducer from '../features/prepare_assessments/prepareQuestionSlice'
// publish assessment reducer
import publishAssessmentReducer from '../features/config_and_publish/publishAssessmentSlice'
import mapAssessorReducer from '../features/config_and_publish/mapAssessorSlice'
// assessment response reducer
import assessmentResponseReducer from '../features/analyze_and_report/assessmentResponseSlice'

import sectionConfigQuestionReducer from "../features/config/sectionConfigQuestionSlice";
import dimensionConfigQuestionReducer from "../features/config/diemensionConfigQuestionSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard : dashboardReducer,
    forgotPass: forgotPasswordReducer,
    notification: notificationReducer,
    l1Menus: l1MenuReducer,
    l2Menus: l2MenuReducer,
    l3Menus: l3MenuReducer,
    apiManagement: apiManagementReducer,
    userGroups: userGroupsReducer,
    roles: rolesReducer,
    l1AccessPermission: l1AccessPermissionReducer,
    l2AccessPermission: l2AccessPermissionReducer,
    l3AccessPermission: l3AccessPermissionReducer,
    customer: customerReducer,
    custUser: custUserReducer,
    account: accountReducer,
    questionType: questionTypeReducer,
    questionRatingValue: questionRatingValueReducer,
    assessmentProgress: assessmentProgressReducer,
    assessmentStatus: assessmentStatusReducer,
    assessmentTemplateStatus : assessmentTemplateStatusReducer,
    responseStatus: responseStatusReducer,
    individualResponseStatus: individualResponseStatusReducer,
    relationshipType: relationshipTypeReducer,
    prepareAssessment: prepareAssessmentReducer,
    assessmentTemplateConfig: assessmentTemplateConfigReducer,
    assessmentTemplateSectionConfig: assessmentTemplateSectionConfigSlice,
    assessmentTemplateDimensionConfig: assessmentTemplateDimensionConfigSlice,

    assessmentFrameworkConfig: assessmentFrameworkConfigReducer,
    assessmentFrameworkDimensionConfig: assessmentFrameworkDimensionConfigReducer,
    assessmentFrameworkSectionConfig: assessmentFrameworkSectionConfigReducer,

    sectionConfigQuestion: sectionConfigQuestionReducer,
    dimensionConfigQuestion: dimensionConfigQuestionReducer,

    assessmentTemplateSection: assessmentTemplateSectionReducer,
    assessmentTemplateDimension: assessmentTemplateDimensionReducer,
    assessmentTemplateQuestion: prepareQuestionReducer,
    publishAssessment: publishAssessmentReducer,
    mapAssessors: mapAssessorReducer,
    assessmentResponse: assessmentResponseReducer,
  },
})