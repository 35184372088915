import * as React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
// material ui
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { makeStyles } from "@mui/styles";
// icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SupportIcon from "@mui/icons-material/Support";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import SchemaIcon from "@mui/icons-material/Schema";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import AssistantIcon from "@mui/icons-material/Assistant";
import BoyIcon from "@mui/icons-material/Boy";
import AttributionIcon from "@mui/icons-material/Attribution";
import EngineeringIcon from "@mui/icons-material/Engineering";
import EmergencyRecordingIcon from "@mui/icons-material/EmergencyRecording";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import AddTaskIcon from "@mui/icons-material/AddTask";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import AddModeratorIcon from "@mui/icons-material/AddModerator";

// components
import { MuiTooltip } from "../../components";
import { ASSESSMENT_BASE_URL, STATIC_IMAGE } from "../../utils/config";

// --------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  listButton: {
    "& .MuiListItemText-root": {
      color: "white",
    },
    "& .drawerIcon": {
      color: "white",
    },
    "&:hover .drawerIcon": {
      color: theme.palette.primary.light,
    },
    "&.Mui-selected": {
      borderLeft: "0.25rem solid #00527d",
      paddingLeft: "0.75rem",
      backgroundColor: "#ffffff !important",
      minWidth: "20rem !important",
    },
    "&.Mui-selected .MuiListItemText-root": {
      color: "#000000 !important",
    },
    "&.Mui-selected .drawerIcon": {
      color: theme.palette.primary.main,
    },
    "&.Mui-selected .MuiSvgIcon-root": {
      color: theme.palette.black.main,
    },
  },
  collapseListButton: {
    paddingLeft: "2rem !important",
    "& .MuiListItemText-root": {
      color: "white",
    },
    "& .drawerIcon": {
      color: "white",
    },
    "&:hover .drawerIcon": {
      color: theme.palette.primary.light,
    },
    "&.Mui-selected": {
      borderLeft: "0.25rem solid #00527d",
      paddingLeft: "1.75rem !important",
      backgroundColor: "#ffffff !important",
      width: "20rem !important",
    },
    "&.Mui-selected .MuiListItemText-root": {
      color: theme.palette.black.main,
    },
    "&.Mui-selected .drawerIcon": {
      color: theme.palette.primary.main,
    },
  },
  MuiLinks: {
    textDecoration: "none",
    color: "inherit",
  },
  listText: {
    "& span": {
      fontFamily: "Arial",
      fontSize: "1.25rem",
      fontWeight: 500,
      lineHeight: "normal",
      letterSpacing: "0em",
    },
  },
}));

// -------------------------------------------------------------------------------------

export const AdminMainListItems = ({ show, clickSideMenu }) => {
  const [selectedIndex, setSelectedIndex] = React.useState( 0);

  const [custOpen, setCustOpen] = React.useState(false);
  const [excoOpen, setExcoOpen] = React.useState(false);
  const [mentorOpen, setMentorOpen] = React.useState(false);
  const [configOpen, setConfigOpen] = React.useState(false);
  const [reportOpen, setReportOpen] = React.useState(false);
  const [listOpen, setListOpen] = React.useState("");

  const classes = useStyles(custOpen);

  // handleListItemClick function to set the selected value
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    localStorage.setItem("selectedIndex",index)
  };

  // handleListClick function to expand the customer menu
  const handleListClick = (list) => {
    setListOpen(list);
  };

  const { loginData } = useSelector((state) => ({
    ...state.auth,
  }));
  console.log(selectedIndex)

  // const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <React.Fragment>
      {loginData?.data?.getAssessmentUserRoles?.userRoleL1MenuPermission.map(
        (module, index) => {
          return (
            <>
              <MuiTooltip
                title={show ? "" : module?.level1Menu?.l1MenuName}
                placement="right"
              >
                {module?.userRoleL2MenuPermission.length === 0 ? (
                  <Link
                    to={module.level1Menu?.l1PageLink}
                    state={{
                      permissions:
                        module?.level1Menu?.userRoleL1ServicePermission,
                    }}
                    className={classes.MuiLinks}
                  >
                    <ListItemButton
                      className={classes.listButton}
                      selected={selectedIndex === index}
                      onClick={(event) => {
                        handleListItemClick(event, index);
                        clickSideMenu();
                      }}
                    >
                      <ListItemIcon>
                        <img
                          src={`${STATIC_IMAGE}/${module?.level1Menu?.l1MenuIcon}`}
                          className={`${
                            selectedIndex !== index && "white-icon"
                          }`}
                          height={30}
                          width={30}
                        />
                      </ListItemIcon>
                      {show && (
                        <ListItemText
                          primary={module.level1Menu?.l1MenuName}
                          className={classes.listText}
                        />
                      )}
                    </ListItemButton>
                  </Link>
                ) : (
                  <ListItemButton
                    className={classes.listButton}
                    selected={selectedIndex === index}
                    onClick={(event) => {
                      handleListItemClick(event, index);
                      handleListClick(module.level1Menu?.l1MenuName);
                    }}
                  >
                    <ListItemIcon
                      className="drawerIcon"
                      sx={{ ...(!show && { minWidth: "2rem" }) }}
                    >
                      <img
                        src={`${STATIC_IMAGE}/${module?.level1Menu?.l1MenuIcon}`}
                        className={`${selectedIndex !== index && "white-icon"}`}
                        height={30}
                        width={30}
                      />
                    </ListItemIcon>
                    {show && (
                      <ListItemText
                        primary={module.level1Menu?.l1MenuName}
                        className={classes.listText}
                      />
                    )}
                    {module.level1Menu?.l1MenuName === listOpen ? (
                      <ExpandLess color="white" />
                    ) : (
                      <ExpandMore color="white" />
                    )}
                  </ListItemButton>
                )}
              </MuiTooltip>
              <Collapse
                in={listOpen === module.level1Menu?.l1MenuName}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {module?.userRoleL2MenuPermission?.length > 0 &&
                    module?.userRoleL2MenuPermission.map(
                      (subModule, index2) => {
                        return (
                          <>
                            <MuiTooltip
                              title={
                                show ? "" : subModule.level2Menu?.l2MenuName
                              }
                              placement="right"
                            >
                              <Link
                                to={subModule?.level2Menu?.l2PageLink}
                                state={{
                                  l3Menu: subModule?.userRoleL3MenuPermission,
                                  permissions:
                                    subModule?.level2Menu
                                      ?.userRoleL2ServicePermission,
                                }}
                                className={classes.MuiLinks}
                              >
                                <ListItemButton
                                  className={classes.collapseListButton}
                                  selected={
                                    selectedIndex ===
                                    loginData?.data?.getAssessmentUserRoles
                                      ?.userRoleL1MenuPermission.length +
                                      index2
                                  }
                                  onClick={(event) => {
                                    clickSideMenu();
                                    handleListItemClick(
                                      event,
                                      loginData?.data?.getAssessmentUserRoles
                                        ?.userRoleL1MenuPermission.length +
                                        index2
                                    );
                                  }}
                                >
                                  <ListItemIcon className="drawerIcon">
                                    <img
                                      src={`${STATIC_IMAGE}/${subModule?.level2Menu?.l2MenuIcon}`}
                                      className={`${
                                        selectedIndex !==
                                          loginData?.data
                                            ?.getAssessmentUserRoles
                                            ?.userRoleL1MenuPermission.length +
                                            index2 && "white-icon"
                                      }`}
                                      height={30}
                                      width={30}
                                    />
                                  </ListItemIcon>
                                  {show && (
                                    <ListItemText
                                      primary={subModule.level2Menu?.l2MenuName}
                                      className={classes.listText}
                                    />
                                  )}
                                </ListItemButton>
                              </Link>
                            </MuiTooltip>
                          </>
                        );
                      }
                    )}
                </List>
              </Collapse>
            </>
          );
        }
      )}
    </React.Fragment>
  );
};

// ---------------------------------------------------------------------------------

export const ExecMainListItems = ({ show }) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [reportOpen, setReportOpen] = React.useState(false);

  const classes = useStyles();

  // handleListItemClick function to set the selected value
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  // handleReportListClick function to expand the config menu
  const handleReportListClick = () => {
    setReportOpen(!reportOpen);
  };

  return (
    <React.Fragment>
      <MuiTooltip title={show ? "" : "Dashboard"} placement="right">
        <Link to="dashboard" className={classes.MuiLinks}>
          <ListItemButton
            className={classes.listButton}
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}
          >
            <ListItemIcon>
              <DashboardIcon className="drawerIcon" />
            </ListItemIcon>
            {show && (
              <ListItemText primary="Dashboard" className={classes.listText} />
            )}
          </ListItemButton>
        </Link>
      </MuiTooltip>

      <MuiTooltip title={show ? "" : "Customer"} placement="right">
        <ListItemButton
          className={classes.listButton}
          selected={selectedIndex === 6}
          onClick={(event) => {
            handleListItemClick(event, 6);
            handleReportListClick();
          }}
        >
          <ListItemIcon
            className="drawerIcon"
            sx={{ ...(!show && { minWidth: "2rem" }) }}
          >
            <AssessmentIcon />
          </ListItemIcon>
          {show && (
            <ListItemText primary="Customers" className={classes.listText} />
          )}
          {reportOpen ? (
            <ExpandLess color="white" />
          ) : (
            <ExpandMore color="white" />
          )}
        </ListItemButton>
      </MuiTooltip>
      <Collapse in={reportOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {/* <MuiTooltip
            title={show ? "" : "Customers"}
            placement="right"
          >
            <Link
              to="/exco/business/customers"
              className={classes.MuiLinks}
            >
              <ListItemButton
                className={classes.collapseListButton}
                selected={selectedIndex === 7}
                onClick={(event) => handleListItemClick(event, 7)}
              >
                <ListItemIcon className="drawerIcon">
                  <EngineeringIcon />
                </ListItemIcon>
                {show && (
                  <ListItemText
                    primary="Customers"
                    className={classes.listText}
                  />
                )}
              </ListItemButton>
            </Link>
          </MuiTooltip>  */}
          <MuiTooltip title={show ? "" : "Accounts"} placement="right">
            <Link to="/exco/customers/accounts" className={classes.MuiLinks}>
              <ListItemButton
                className={classes.collapseListButton}
                selected={selectedIndex === 7}
                onClick={(event) => handleListItemClick(event, 7)}
              >
                <ListItemIcon className="drawerIcon">
                  <EmergencyRecordingIcon />
                </ListItemIcon>
                {show && (
                  <ListItemText
                    primary="Accounts"
                    className={classes.listText}
                  />
                )}
              </ListItemButton>
            </Link>
          </MuiTooltip>
          <MuiTooltip title={show ? "" : "Users"} placement="right">
            <Link to="/exco/customers/users" className={classes.MuiLinks}>
              <ListItemButton
                className={classes.collapseListButton}
                selected={selectedIndex === 8}
                onClick={(event) => handleListItemClick(event, 8)}
              >
                <ListItemIcon className="drawerIcon">
                  <EmergencyRecordingIcon />
                </ListItemIcon>
                {show && (
                  <ListItemText primary="Users" className={classes.listText} />
                )}
              </ListItemButton>
            </Link>
          </MuiTooltip>
        </List>
      </Collapse>
      <MuiTooltip title={show ? "" : "Prepare"} placement="right">
        <Link to="/exco/prepare" className={classes.MuiLinks}>
          <ListItemButton
            className={classes.listButton}
            selected={selectedIndex === 9}
            onClick={(event) => handleListItemClick(event, 9)}
          >
            <ListItemIcon>
              <AssistantIcon className="drawerIcon" />
            </ListItemIcon>
            {show && (
              <ListItemText primary="Prepare" className={classes.listText} />
            )}
          </ListItemButton>
        </Link>
      </MuiTooltip>
      <MuiTooltip title={show ? "" : "Publish"} placement="right">
        <Link to="/exco/publish" className={classes.MuiLinks}>
          <ListItemButton
            className={classes.listButton}
            selected={selectedIndex === 10}
            onClick={(event) => handleListItemClick(event, 10)}
          >
            <ListItemIcon>
              <AllInboxIcon className="drawerIcon" />
            </ListItemIcon>
            {show && (
              <ListItemText primary="Publish" className={classes.listText} />
            )}
          </ListItemButton>
        </Link>
      </MuiTooltip>
      <MuiTooltip title={show ? "" : "Report"} placement="right">
        <Link to="/exco/report" className={classes.MuiLinks}>
          <ListItemButton
            className={classes.listButton}
            selected={selectedIndex === 11}
            onClick={(event) => handleListItemClick(event, 11)}
          >
            <ListItemIcon>
              <AssignmentIcon className="drawerIcon" />
            </ListItemIcon>
            {show && (
              <ListItemText primary="Report" className={classes.listText} />
            )}
          </ListItemButton>
        </Link>
      </MuiTooltip>
      <MuiTooltip title={show ? "" : "IAM"} placement="right">
        <ListItemButton
          className={classes.listButton}
          selected={selectedIndex === 12}
          onClick={(event) => {
            handleListItemClick(event, 12);
            handleReportListClick();
          }}
        >
          <ListItemIcon
            className="drawerIcon"
            sx={{ ...(!show && { minWidth: "2rem" }) }}
          >
            <AssessmentIcon />
          </ListItemIcon>
          {show && <ListItemText primary="IAM" className={classes.listText} />}
          {reportOpen ? (
            <ExpandLess color="white" />
          ) : (
            <ExpandMore color="white" />
          )}
        </ListItemButton>
      </MuiTooltip>
      <Collapse in={reportOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <MuiTooltip title={show ? "" : "App Services"} placement="right">
            <Link to="/exco/iam/app-services" className={classes.MuiLinks}>
              <ListItemButton
                className={classes.collapseListButton}
                selected={selectedIndex === 13}
                onClick={(event) => handleListItemClick(event, 13)}
              >
                <ListItemIcon className="drawerIcon">
                  <EngineeringIcon />
                </ListItemIcon>
                {show && (
                  <ListItemText
                    primary="App Services"
                    className={classes.listText}
                  />
                )}
              </ListItemButton>
            </Link>
          </MuiTooltip>
          <MuiTooltip title={show ? "" : "Roles"} placement="right">
            <Link to="/exco/iam/roles" className={classes.MuiLinks}>
              <ListItemButton
                className={classes.collapseListButton}
                selected={selectedIndex === 14}
                onClick={(event) => handleListItemClick(event, 14)}
              >
                <ListItemIcon className="drawerIcon">
                  <EmergencyRecordingIcon />
                </ListItemIcon>
                {show && (
                  <ListItemText primary="Roles" className={classes.listText} />
                )}
              </ListItemButton>
            </Link>
          </MuiTooltip>
          <MuiTooltip title={show ? "" : "Users"} placement="right">
            <Link to="/exco/iam/users" className={classes.MuiLinks}>
              <ListItemButton
                className={classes.collapseListButton}
                selected={selectedIndex === 15}
                onClick={(event) => handleListItemClick(event, 15)}
              >
                <ListItemIcon className="drawerIcon">
                  <EmergencyRecordingIcon />
                </ListItemIcon>
                {show && (
                  <ListItemText primary="Users" className={classes.listText} />
                )}
              </ListItemButton>
            </Link>
          </MuiTooltip>
        </List>
      </Collapse>
      <MuiTooltip title={show ? "" : "Platform"} placement="right">
        <ListItemButton
          className={classes.listButton}
          selected={selectedIndex === 16}
          onClick={(event) => {
            handleListItemClick(event, 16);
            handleReportListClick();
          }}
        >
          <ListItemIcon
            className="drawerIcon"
            sx={{ ...(!show && { minWidth: "2rem" }) }}
          >
            <AssessmentIcon />
          </ListItemIcon>
          {show && (
            <ListItemText primary="Platform" className={classes.listText} />
          )}
          {reportOpen ? (
            <ExpandLess color="white" />
          ) : (
            <ExpandMore color="white" />
          )}
        </ListItemButton>
      </MuiTooltip>
      <Collapse in={reportOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <MuiTooltip title={show ? "" : "Config"} placement="right">
            <Link to="/exco/platform/config" className={classes.MuiLinks}>
              <ListItemButton
                className={classes.collapseListButton}
                selected={selectedIndex === 17}
                onClick={(event) => handleListItemClick(event, 17)}
              >
                <ListItemIcon className="drawerIcon">
                  <EngineeringIcon />
                </ListItemIcon>
                {show && (
                  <ListItemText primary="Config" className={classes.listText} />
                )}
              </ListItemButton>
            </Link>
          </MuiTooltip>
        </List>
      </Collapse>
    </React.Fragment>
  );
};
