import React from "react";
import { PageHeader, MyTextInput } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
// material ui
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Box,
  CardContent,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import { FormSubmitButton,CommonCurdPopUp } from "../../../../components";
import Notifier from "../../../../components/common/Notifier";
import ErrorNotifier from "../../../../components/common/ErrorNotifier";
// redux
import {
  updatePlatformStatus,
  getPlatformStatus,
  resetMessage,
  resetErrMessage,
} from "../../../../features/config/platformStatusSlice";

// --------------------------------------------------------------

const validationSchema = Yup.object({
  status: Yup.string().required("Status is required"),
  colorCode: Yup.string().required("Color Code is required"),
  comments: Yup.string(),
});

//   ------------------------------------------------------------------------------------------

const UpdateAssessmentStatus = (props) => {
  const {  message, errMessage, selectedData } = useSelector(
    (state) => ({ ...state.assessmentTemplateStatus })
  );

  const dispatch = useDispatch();

  console.log(selectedData)

  const formik = useFormik({
    initialValues: {
      status: selectedData?.assessmentTemplateStatus      ,
      colorCode: selectedData?.colorCode,
      comments: selectedData?.comments,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        updatePlatformStatus({
          id: selectedData.id,
          assessmentTemplateStatus: values.status,
          colorCode: values.colorCode,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.close();
            dispatch(getPlatformStatus());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  return (
    <>

{props?.showEdit && <CommonCurdPopUp
        heading={`Update Platform Status`}
        mediumScreen={true}
        close={props.close}
      >
       
       <Grid container >
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid spacing={1} container item xs={12} sm={12} md={11} >
            <Grid item xs={12} sm={12} md={8}>
              <MyTextInput
                label="Status"
                type="text"
                name="status"
                required={true}
                fullWidth={true}
                margin="normal"
                id="status"
                value={formik.values.status}
                onChange={formik.handleChange}
                error={
                  formik.touched.status &&
                  Boolean(formik.errors.status)
                }
                helperText={
                  formik.touched.status &&
                  formik.errors.status
                }
              />
               </Grid>
                <Grid item xs={12} sm={12} md={4}>
                <MyTextInput
                label="Color Code"
                type="color"
                name="colorCode"
                required={true}
                fullWidth={true}
                margin="normal"
                id="colorCode"
                value={formik.values.colorCode}
                onChange={formik.handleChange}
                error={
                  formik.touched.colorCode &&
                  Boolean(formik.errors.colorCode)
                }
                helperText={
                  formik.touched.colorCode &&
                  formik.errors.colorCode
                }
              />
                </Grid>
           
              <MyTextInput
                label="Comments"
                type="text"
                name="comments"
                fullWidth={true}
                margin="normal"
                id="comments"
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={
                  formik.touched.comments && Boolean(formik.errors.comments)
                }
                helperText={formik.touched.comments && formik.errors.comments}
              />
              <Box style={{ width: "100%", textAlign: "center" }} >

<FormSubmitButton onClick={formik?.handleSubmit} type="submit" variant="contained">
  Submit
</FormSubmitButton>
</Box>
</Grid>
</Grid>

</CommonCurdPopUp>}

      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
   
    </>
  );
};

export default UpdateAssessmentStatus;
