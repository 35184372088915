import React from "react";
import { Link, useLocation } from "react-router-dom";
// material ui
import { Card, CardContent, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
// components
import Layout from "../../layout/Layout";

// ---------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  MuiLinks: {
    textDecoration: "none",
    color: "inherit",
  },
  MuiPaper: {
    padding: "1rem",
    textAlign: "center",
    backgroundColor: "#F5F5F5 !important",
    "&:hover": {
      backgroundColor: `${theme.palette.primary.light} !important`,
      TransitionEvent: "easing 0.2sec",
    },
  },
}));

// --------------------------------------------------------------------------------

const Configuration = () => {
  const classes = useStyles();
  const location = useLocation()
  return (
    <Layout>
       <Card sx={{ width: "40rem", margin: "auto", padding: "1rem" }}>
         <CardContent>
          <Grid container spacing={2}>
            {location?.state?.l3Menu?.length > 0 &&
              location?.state?.l3Menu.map((feature) => {
                return (
                  <Grid item xs={12} sm={12} md={6}>
                    <Link
                      to={feature.level3Menu?.l3PageLink}
                      state={{
                        l3Menu: location?.state?.l3Menu,
                        permissions: feature?.userRoleL3ServicePermission,
                      }}
                      className={classes.MuiLinks}
                    >
                      <Paper elevation={2} className={classes.MuiPaper}>
                        <Typography variant="pageSecHeader">
                          {feature.level3Menu?.l3MenuName}
                        </Typography>
                      </Paper>
                    </Link>
                  </Grid>
                );
              })}
          </Grid>
        </CardContent> 
      </Card>
    </Layout>
  );
};

export default Configuration;
