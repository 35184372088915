// import { customUseEffect, useLocation } from "../../../common";
import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { PDFExport } from "@progress/kendo-react-pdf";
// material ui
import { Box, Button, Grid, Slider, Stack, Switch } from "@mui/material";
import { makeStyles } from "@mui/styles";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
// import Layout from "../../../../layout/Layout";
// redux
import {
  getReport,
  getOptionalCommentsReport,
  getShortResponseReport,
  getLongResponseReport,
} from "../../../../features/analyze_and_report/assessmentResponseSlice";

import {

  viewAssessmentFrameworkRador,

} from "../../../../features/config/assessmentFrameworkConfigSlice";

import { toPng } from "html-to-image";
import Layout from "../../../../layout/Layout";
// ----------------------------------------------------------
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  LabelList,
  PieChart,
  Pie,
  Sector,
  Tooltip,
  Cell,
  Label,
} from "recharts";
import Graph from "./Graph";
import { AppPageHeader } from "../../../../components";

import GraphDimension from "./GraphDimension";

const data = [
  {
    subject: "Shapes Strategy 1",
    A: 0,
    B: 3,
    fullMark: 100,
  },
  {
    subject: "Builds Culture 2",
    A: 3,
    B: 5,
    fullMark: 100,
  },
  {
    subject: "Drives Execution 3",
    A: 2,
    B: 4,
    fullMark: 100,
  },
  {
    subject: "Scale Leadership 4",
    A: 1,
    B: 2,
    fullMark: 100,
  },
  {
    subject: "Fosters Inclusion 5",
    A: 5,
    B: 2,
    fullMark: 100,
  },
  {
    subject: "Role 6",
    A: 2,
    B: 3,
    fullMark: 100,
  },
  {
    subject: "Embraces -7",
    A: 4,
    B: 2,
    fullMark: 100,
  },
  {
    subject: "Prioryize-8",
    A: 1,
    B: 3,
    fullMark: 100,
  },
  {
    subject: "Clarity - 9",
    A: 4,
    B: 5,
    fullMark: 100,
  },
  // {
  //   subject: "Growth - 10",
  //   A: 54,
  //   B: 76,
  //   fullMark: 100,
  // },
  // {
  //   subject: "Delivers Result - 11",
  //   A: 67,
  //   B: 39,
  //   fullMark: 100,
  // },
  // {
  //   subject: "Enables Team - 12",
  //   A: 23,
  //   B: 78,
  //   fullMark: 100,
  // },

  // {
  //   subject: "Builds Culture Test 13",
  //   A: 80,
  //   B: 78,
  //   fullMark: 100,
  // },
  // {
  //   subject: "Drives Execution Test 14",
  //   A: 89,
  //   B: 34,
  //   fullMark: 100,
  // },
  // {
  //   subject: "Shapes Strategy Test 15",
  //   A: 23,
  //   B: 89,
  //   fullMark: 100,
  // },
  // {
  //   subject: "Scale Leadership Test 16",
  //   A: 34,
  //   B: 78,
  //   fullMark: 100,
  // },
  // {
  //   subject: "Fosters Inclusion Test - 17",
  //   A: 23,
  //   B: 34,
  //   fullMark: 100,
  // },
  // {
  //   subject: "RoleTest - 18",
  //   A: 89,
  //   B: 89,
  //   fullMark: 100,
  // },
  // {
  //   subject: "Embracestest -19",
  //   A: 23,
  //   B: 89,
  //   fullMark: 100,
  // },
  // {
  //   subject: "PrioryizeTest-20",
  //   A: 23,
  //   B: 78,
  //   fullMark: 100,
  // },
  // {
  //   subject: "ClarityTest - 21",
  //   A: 34,
  //   B: 78,
  //   fullMark: 100,
  // },
  // {
  //   subject: "GrowthTest - 22",
  //   A: 54,
  //   B: 76,
  //   fullMark: 100,
  // },
  // {
  //   subject: " Results - 23",
  //   A: 67,
  //   B: 39,
  //   fullMark: 100,
  // },
  // {
  //   subject: "Clarity - 24",
  //   A: 23,
  //   B: 78,
  //   fullMark: 100,
  // },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100% !important",
    "& :nth-child(14)": {
      width: "0.2rem !important",
      height: "2rem !important",
      borderRadius: "0px !important",
      backgroundColor: "#000080 !important",
    },
    "& :nth-child(15)": {
      width: "0.2rem !important",
      height: "2rem !important",
      borderRadius: "0px !important",
      backgroundColor: "#fff !important",
      border: "1px solid #000080",
    },
  },
  mark: {
    width: "8px !important",
    height: "30px !important",
    backgroundColor: "#000",
    marginTop: "-3px",
  },
  label: {
    color: "red",
  },
  rail: {
    background: "linear-gradient(to right, red, orange, yellow, green);",
  },
  thumb: {
    width: "0px !important",
    border: "none !important",
  },
  track: {
    backgroundColor: "transparent !important",
    height: "0.7rem !important",
  },
  [theme.breakpoints.down("md")]: {
    responsive_slide: {
      width: "62.5rem",
    },
  },
}));

// --------------------------------------------------------------------------------------------

function Report(props) {
  const [custReportsData, setCustReportsData] = useState({});

  const [sectionStore, setSectionStore] = useState([]);
  const [dimensionStore, setDimensionStore] = useState([]);
  const [questionStore, setQuestionStore] = useState([]);
  const [showTooltipOutside, setShowTooltipOutside] = useState(false);
  const [widthValue, setWidthValue] = useState(
    (window.innerWidth / window.screen.width) * 100
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const pdfExportComponent = React.useRef(null);

  // const { reportsData, shortResponseData, longResponseData } = useSelector(
  //   (state) => ({
  //     ...state.assessmentResponse,
  //   })
  // );
  const { reportsData } =
    useSelector((state) => ({ ...state.assessmentFrameworkConfig }));

  const prepareAssessment = useSelector((state) => state?.prepareAssessment);
  console.log(location?.state?.frameworkSelectedData?.id)


  useEffect(() => {

    dispatch(viewAssessmentFrameworkRador({ id: location?.state?.frameworkSelectedData?.id }));
    // dispatch(
    //   getOptionalCommentsReport({ id: location?.state?.clientSelectedData?.id })
    // );
    // dispatch(getShortResponseReport({ id: location?.state?.clientSelectedData?.id }));
    // dispatch(getLongResponseReport({ id: location?.state?.clientSelectedData?.id }));
  }, []);




  //   const reportsData = [
  //     {
  //         "questionNo": 1,
  //         "questionName": "Does the test scenarios capture the business aspects of the requirements",
  //         "standardValue": 5,
  //         "sectionNo": 1,
  //         "sectionName": "Strategy",
  //         "dimensionNo": 1,
  //         "dimensionName": "Business Coordination",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.33",
  //         "min_column2": 2,
  //         "max_column2": 4
  //     },
  //     {
  //         "questionNo": 2,
  //         "questionName": "Does the testing and the business team have a common understanding of business and its outcome",
  //         "standardValue": 4,
  //         "sectionNo": 1,
  //         "sectionName": "Strategy",
  //         "dimensionNo": 1,
  //         "dimensionName": "Business Coordination",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.66",
  //         "min_column2": 2,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 3,
  //         "questionName": "Does the business team coorelates the business requirements with testing aspects",
  //         "standardValue": 5,
  //         "sectionNo": 1,
  //         "sectionName": "Strategy",
  //         "dimensionNo": 1,
  //         "dimensionName": "Business Coordination",
  //         "self_rating": null,
  //         "avg_stakeholder": "4.00",
  //         "min_column2": 3,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 1,
  //         "questionName": "Is risk based testing followed",
  //         "standardValue": 4,
  //         "sectionNo": 1,
  //         "sectionName": "Strategy",
  //         "dimensionNo": 2,
  //         "dimensionName": "Prevention Activities",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.66",
  //         "min_column2": 2,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 2,
  //         "questionName": "Are critical risks identified to prioritize the Defect prevention activities",
  //         "standardValue": 5,
  //         "sectionNo": 1,
  //         "sectionName": "Strategy",
  //         "dimensionNo": 2,
  //         "dimensionName": "Prevention Activities",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.66",
  //         "min_column2": 3,
  //         "max_column2": 4
  //     },
  //     {
  //         "questionNo": 3,
  //         "questionName": "Are relevant checklists developed and used to introduce checks and prevent defects",
  //         "standardValue": 5,
  //         "sectionNo": 1,
  //         "sectionName": "Strategy",
  //         "dimensionNo": 2,
  //         "dimensionName": "Prevention Activities",
  //         "self_rating": null,
  //         "avg_stakeholder": "4.33",
  //         "min_column2": 4,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 1,
  //         "questionName": "Are the root cause of failing user scenarios identified",
  //         "standardValue": 5,
  //         "sectionNo": 1,
  //         "sectionName": "Strategy",
  //         "dimensionNo": 3,
  //         "dimensionName": "RCA",
  //         "self_rating": null,
  //         "avg_stakeholder": "2.66",
  //         "min_column2": 2,
  //         "max_column2": 3
  //     },
  //     {
  //         "questionNo": 2,
  //         "questionName": "Is the root cause analysis of frequently occuring defects across all phases done",
  //         "standardValue": 4,
  //         "sectionNo": 1,
  //         "sectionName": "Strategy",
  //         "dimensionNo": 3,
  //         "dimensionName": "RCA",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.33",
  //         "min_column2": 3,
  //         "max_column2": 4
  //     },
  //     {
  //         "questionNo": 3,
  //         "questionName": "Does the RCA result in process change or additional training to eliminate the root causes of the defects",
  //         "standardValue": 5,
  //         "sectionNo": 1,
  //         "sectionName": "Strategy",
  //         "dimensionNo": 3,
  //         "dimensionName": "RCA",
  //         "self_rating": null,
  //         "avg_stakeholder": "4.00",
  //         "min_column2": 3,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 1,
  //         "questionName": "Is testing regarded by personnel involved team members as necessary and important",
  //         "standardValue": 5,
  //         "sectionNo": 1,
  //         "sectionName": "Strategy",
  //         "dimensionNo": 4,
  //         "dimensionName": "Commitment",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.00",
  //         "min_column2": 2,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 2,
  //         "questionName": "Does management control testing based on time and budget",
  //         "standardValue": 5,
  //         "sectionNo": 1,
  //         "sectionName": "Strategy",
  //         "dimensionNo": 4,
  //         "dimensionName": "Commitment",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.66",
  //         "min_column2": 3,
  //         "max_column2": 4
  //     },
  //     {
  //         "questionNo": 3,
  //         "questionName": "Is the testing team matured enough to ptovide suggesstions for improving the quality of the product or project",
  //         "standardValue": 5,
  //         "sectionNo": 1,
  //         "sectionName": "Strategy",
  //         "dimensionNo": 4,
  //         "dimensionName": "Commitment",
  //         "self_rating": null,
  //         "avg_stakeholder": "4.00",
  //         "min_column2": 3,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 1,
  //         "questionName": "Are the Risks & Legal aspects documented by business and shared with testing",
  //         "standardValue": 4,
  //         "sectionNo": 2,
  //         "sectionName": "Process",
  //         "dimensionNo": 1,
  //         "dimensionName": "Regulatory Validation",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.00",
  //         "min_column2": 1,
  //         "max_column2": 4
  //     },
  //     {
  //         "questionNo": 2,
  //         "questionName": "Are the fraud scenarios documented and reviewed by business",
  //         "standardValue": 4,
  //         "sectionNo": 2,
  //         "sectionName": "Process",
  //         "dimensionNo": 1,
  //         "dimensionName": "Regulatory Validation",
  //         "self_rating": null,
  //         "avg_stakeholder": "4.00",
  //         "min_column2": 3,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 3,
  //         "questionName": "is the regulatory planning & tracking done",
  //         "standardValue": 4,
  //         "sectionNo": 2,
  //         "sectionName": "Process",
  //         "dimensionNo": 1,
  //         "dimensionName": "Regulatory Validation",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.66",
  //         "min_column2": 3,
  //         "max_column2": 4
  //     },
  //     {
  //         "questionNo": 1,
  //         "questionName": "Does testing develops reusable business process assets",
  //         "standardValue": 5,
  //         "sectionNo": 2,
  //         "sectionName": "Process",
  //         "dimensionNo": 2,
  //         "dimensionName": "Process Validation",
  //         "self_rating": null,
  //         "avg_stakeholder": "4.00",
  //         "min_column2": 2,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 2,
  //         "questionName": "Are exception handling business scenarios tested",
  //         "standardValue": 5,
  //         "sectionNo": 2,
  //         "sectionName": "Process",
  //         "dimensionNo": 2,
  //         "dimensionName": "Process Validation",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.00",
  //         "min_column2": 1,
  //         "max_column2": 4
  //     },
  //     {
  //         "questionNo": 3,
  //         "questionName": "Are the business processes quality reporting done at regular intervals",
  //         "standardValue": 4,
  //         "sectionNo": 2,
  //         "sectionName": "Process",
  //         "dimensionNo": 2,
  //         "dimensionName": "Process Validation",
  //         "self_rating": null,
  //         "avg_stakeholder": "4.33",
  //         "min_column2": 3,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 1,
  //         "questionName": "Are there defined master test strategy exists and updated",
  //         "standardValue": 5,
  //         "sectionNo": 2,
  //         "sectionName": "Process",
  //         "dimensionNo": 3,
  //         "dimensionName": "Test Process",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.33",
  //         "min_column2": 1,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 2,
  //         "questionName": "Are there defect management process established and published",
  //         "standardValue": 5,
  //         "sectionNo": 2,
  //         "sectionName": "Process",
  //         "dimensionNo": 3,
  //         "dimensionName": "Test Process",
  //         "self_rating": null,
  //         "avg_stakeholder": "4.00",
  //         "min_column2": 3,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 3,
  //         "questionName": "Is there a metrics tracking program in place with focus on definition and tracking of Cost of Quality",
  //         "standardValue": 5,
  //         "sectionNo": 2,
  //         "sectionName": "Process",
  //         "dimensionNo": 3,
  //         "dimensionName": "Test Process",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.66",
  //         "min_column2": 2,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 1,
  //         "questionName": "Are the requirements for test environment defined & documented",
  //         "standardValue": 5,
  //         "sectionNo": 2,
  //         "sectionName": "Process",
  //         "dimensionNo": 4,
  //         "dimensionName": "Environmnet Coordination",
  //         "self_rating": null,
  //         "avg_stakeholder": "4.00",
  //         "min_column2": 2,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 2,
  //         "questionName": "is the Test Environment shared between multiple projects",
  //         "standardValue": 5,
  //         "sectionNo": 2,
  //         "sectionName": "Process",
  //         "dimensionNo": 4,
  //         "dimensionName": "Environmnet Coordination",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.00",
  //         "min_column2": 2,
  //         "max_column2": 4
  //     },
  //     {
  //         "questionNo": 3,
  //         "questionName": "Are the control processes, co-ordination and communication defined for effective Environment coordination",
  //         "standardValue": 4,
  //         "sectionNo": 2,
  //         "sectionName": "Process",
  //         "dimensionNo": 4,
  //         "dimensionName": "Environmnet Coordination",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.33",
  //         "min_column2": 2,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 1,
  //         "questionName": "Does testing include testing of application performance, infrastructure scalability & elasticity as stated in SLAs",
  //         "standardValue": 5,
  //         "sectionNo": 3,
  //         "sectionName": "Types of Testing",
  //         "dimensionNo": 1,
  //         "dimensionName": "Functional Testing",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.66",
  //         "min_column2": 3,
  //         "max_column2": 4
  //     },
  //     {
  //         "questionNo": 2,
  //         "questionName": "Are cloud testing requirements, goals, SLAs established and defined in test strategy",
  //         "standardValue": 5,
  //         "sectionNo": 3,
  //         "sectionName": "Types of Testing",
  //         "dimensionNo": 1,
  //         "dimensionName": "Functional Testing",
  //         "self_rating": null,
  //         "avg_stakeholder": "4.33",
  //         "min_column2": 4,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 3,
  //         "questionName": "Does testing cover SaaS, integration with internal, external dependencies and other legacy systems over cloud",
  //         "standardValue": 5,
  //         "sectionNo": 3,
  //         "sectionName": "Types of Testing",
  //         "dimensionNo": 1,
  //         "dimensionName": "Functional Testing",
  //         "self_rating": null,
  //         "avg_stakeholder": "4.00",
  //         "min_column2": 3,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 1,
  //         "questionName": "Are various NFRs identified and documented in requirements stage",
  //         "standardValue": 4,
  //         "sectionNo": 3,
  //         "sectionName": "Types of Testing",
  //         "dimensionNo": 2,
  //         "dimensionName": "Non-Functional Testing",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.33",
  //         "min_column2": 2,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 2,
  //         "questionName": "Does testing team review NFRs, ambiguous ones identified by close of planning phase",
  //         "standardValue": 4,
  //         "sectionNo": 3,
  //         "sectionName": "Types of Testing",
  //         "dimensionNo": 2,
  //         "dimensionName": "Non-Functional Testing",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.00",
  //         "min_column2": 2,
  //         "max_column2": 4
  //     },
  //     {
  //         "questionNo": 3,
  //         "questionName": "Is there a NF test process and assets established processes, scriptin standards, templates, checklists, guidelines etc",
  //         "standardValue": 5,
  //         "sectionNo": 3,
  //         "sectionName": "Types of Testing",
  //         "dimensionNo": 2,
  //         "dimensionName": "Non-Functional Testing",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.33",
  //         "min_column2": 1,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 1,
  //         "questionName": "Is the test strategy prepared with a view of all the channel and iser interaction patterns",
  //         "standardValue": 5,
  //         "sectionNo": 3,
  //         "sectionName": "Types of Testing",
  //         "dimensionNo": 3,
  //         "dimensionName": "Omnichannel Testing",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.00",
  //         "min_column2": 1,
  //         "max_column2": 4
  //     },
  //     {
  //         "questionNo": 2,
  //         "questionName": "Does the test strategy cover testing of a process that spans across multiple devices including social, mobile, analytics, cloud etc",
  //         "standardValue": 5,
  //         "sectionNo": 3,
  //         "sectionName": "Types of Testing",
  //         "dimensionNo": 3,
  //         "dimensionName": "Omnichannel Testing",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.33",
  //         "min_column2": 2,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 3,
  //         "questionName": "Is there a dedicated environment available to validate end to end business scenarios covering web, mobile and other omni channels",
  //         "standardValue": 5,
  //         "sectionNo": 3,
  //         "sectionName": "Types of Testing",
  //         "dimensionNo": 3,
  //         "dimensionName": "Omnichannel Testing",
  //         "self_rating": null,
  //         "avg_stakeholder": "2.66",
  //         "min_column2": 2,
  //         "max_column2": 3
  //     },
  //     {
  //         "questionNo": 1,
  //         "questionName": "Is the data testing strategy, testing release cycles, testing types, volume of data processed, 3rd party tool details documented",
  //         "standardValue": 5,
  //         "sectionNo": 3,
  //         "sectionName": "Types of Testing",
  //         "dimensionNo": 4,
  //         "dimensionName": "Testing Types",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.66",
  //         "min_column2": 1,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 2,
  //         "questionName": "is the data security plan available with the testing team and is it reviewed",
  //         "standardValue": 5,
  //         "sectionNo": 3,
  //         "sectionName": "Types of Testing",
  //         "dimensionNo": 4,
  //         "dimensionName": "Testing Types",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.33",
  //         "min_column2": 2,
  //         "max_column2": 4
  //     },
  //     {
  //         "questionNo": 3,
  //         "questionName": "Is the test scope clearly defined and test process in place for Data Analytics, ETL, Data Migration, Reports testing",
  //         "standardValue": 5,
  //         "sectionNo": 3,
  //         "sectionName": "Types of Testing",
  //         "dimensionNo": 4,
  //         "dimensionName": "Testing Types",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.00",
  //         "min_column2": 3,
  //         "max_column2": 3
  //     },
  //     {
  //         "questionNo": 2,
  //         "questionName": "Is an automation feasibility done to prove ROI over present situation",
  //         "standardValue": 5,
  //         "sectionNo": 4,
  //         "sectionName": "Automation",
  //         "dimensionNo": 1,
  //         "dimensionName": "Test Automation",
  //         "self_rating": null,
  //         "avg_stakeholder": "4.33",
  //         "min_column2": 4,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 3,
  //         "questionName": "Does the test strategy clearly mentions the automation framework and the tools to be used",
  //         "standardValue": 5,
  //         "sectionNo": 4,
  //         "sectionName": "Automation",
  //         "dimensionNo": 1,
  //         "dimensionName": "Test Automation",
  //         "self_rating": null,
  //         "avg_stakeholder": "4.00",
  //         "min_column2": 3,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 1,
  //         "questionName": "Is there a process defined for continuous development, deployment, build, package, version control, environmnet configuration, continuous testing and monitoring",
  //         "standardValue": 4,
  //         "sectionNo": 4,
  //         "sectionName": "Automation",
  //         "dimensionNo": 2,
  //         "dimensionName": "Continuous Testing",
  //         "self_rating": null,
  //         "avg_stakeholder": "4.33",
  //         "min_column2": 4,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 2,
  //         "questionName": "Is there a process defined and followed for common understanding of issues, prioriies and Definition of done across teams",
  //         "standardValue": 4,
  //         "sectionNo": 4,
  //         "sectionName": "Automation",
  //         "dimensionNo": 2,
  //         "dimensionName": "Continuous Testing",
  //         "self_rating": null,
  //         "avg_stakeholder": "4.33",
  //         "min_column2": 3,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 3,
  //         "questionName": "Is auto triggering of non-functional testing established, tracked and monitored from continuous testing pipeline",
  //         "standardValue": 5,
  //         "sectionNo": 4,
  //         "sectionName": "Automation",
  //         "dimensionNo": 2,
  //         "dimensionName": "Continuous Testing",
  //         "self_rating": null,
  //         "avg_stakeholder": "4.33",
  //         "min_column2": 4,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 1,
  //         "questionName": "Is test data generation and management a key part of test strategy",
  //         "standardValue": 5,
  //         "sectionNo": 4,
  //         "sectionName": "Automation",
  //         "dimensionNo": 3,
  //         "dimensionName": "Test Data Management",
  //         "self_rating": null,
  //         "avg_stakeholder": "5.00",
  //         "min_column2": 5,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 2,
  //         "questionName": "Are there standard test data defined updated in sync and version controlled for each release",
  //         "standardValue": 5,
  //         "sectionNo": 4,
  //         "sectionName": "Automation",
  //         "dimensionNo": 3,
  //         "dimensionName": "Test Data Management",
  //         "self_rating": null,
  //         "avg_stakeholder": "4.00",
  //         "min_column2": 4,
  //         "max_column2": 4
  //     },
  //     {
  //         "questionNo": 3,
  //         "questionName": "Is the Data loading in the test environments  done through automated scripts that follow the data cleansing rules  removing personal identifiers and load the data in test environments",
  //         "standardValue": 5,
  //         "sectionNo": 4,
  //         "sectionName": "Automation",
  //         "dimensionNo": 3,
  //         "dimensionName": "Test Data Management",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.33",
  //         "min_column2": 3,
  //         "max_column2": 4
  //     },
  //     {
  //         "questionNo": 1,
  //         "questionName": "Is there a comprehensive risk based middleware test strategy defined",
  //         "standardValue": 5,
  //         "sectionNo": 4,
  //         "sectionName": "Automation",
  //         "dimensionNo": 4,
  //         "dimensionName": "Middleware Testing",
  //         "self_rating": null,
  //         "avg_stakeholder": "3.66",
  //         "min_column2": 3,
  //         "max_column2": 5
  //     },
  //     {
  //         "questionNo": 2,
  //         "questionName": "Does testing include servcie component, service transactions, contract testing etc",
  //         "standardValue": 5,
  //         "sectionNo": 4,
  //         "sectionName": "Automation",
  //         "dimensionNo": 4,
  //         "dimensionName": "Middleware Testing",
  //         "self_rating": null,
  //         "avg_stakeholder": "4.00",
  //         "min_column2": 4,
  //         "max_column2": 4
  //     },
  //     {
  //         "questionNo": 3,
  //         "questionName": "Are automation tools for web services or APIs and middleware identified and used",
  //         "standardValue": 4,
  //         "sectionNo": 4,
  //         "sectionName": "Automation",
  //         "dimensionNo": 4,
  //         "dimensionName": "Middleware Testing",
  //         "self_rating": null,
  //         "avg_stakeholder": "4.00",
  //         "min_column2": 3,
  //         "max_column2": 5
  //     }
  // ]

  console.log(reportsData)


  useEffect(() => {
    // Initialize fresh arrays
    let newSectionArr = [];
    let newDimensionArr = [];
    let newQuestionsArr = [];
  
    // Calculate latest sectionArr
    newSectionArr = reportsData?.reduce((acc, item) => {
      if (
        !acc.some((dataItem) => dataItem?.sectionNo === item?.sectionNo) &&
        item?.sectionName !== null
      ) {
        acc.push({
          name: item?.sectionName,
          value: 100,
          sectionNo: item?.sectionNo,
        });
      }
      return acc;
    }, []);
  
    for (let key of newSectionArr) {
      let obj = {};
      obj.data = [];
      
      for (let keyTwo of reportsData) {
        if (key?.sectionNo === keyTwo?.sectionNo) {
          obj.data.push(keyTwo);
        }
      }
  
      let filterData = obj.data?.reduce((acc, item) => {
        if (
          !acc.some((data) => data?.dimensionNo === item?.dimensionNo) &&
          item?.dimensionName !== null
        ) {
          acc.push({
            name: item?.dimensionName,
            value: 100,
            dimensionNo: item?.dimensionNo,
          });
        }
        return acc;
      }, []);
  
      newDimensionArr.push(...filterData);
    }
  
    // Set the latest arrays to state
    setSectionStore(newSectionArr);
    setDimensionStore(newDimensionArr);
  
    // Prepare questionsArr based on latest assessment type
    for (let key of reportsData) {
      if (key?.sectionNo != null) {
        if (
          prepareAssessment?.selectedData?.assessmentType &&
          (prepareAssessment?.selectedData?.assessmentType).toLowerCase() === "talent"
        ) {
          newQuestionsArr.push({
            field: key?.questionName,
            A: key?.avg_stakeholder ?? 0,
            C: key?.self_rating ?? 0,
          });
        } else if (
          prepareAssessment?.selectedData?.assessmentType &&
          (prepareAssessment?.selectedData?.assessmentType).toLowerCase() === "maturity"
        ) {
          newQuestionsArr.push({
            field: key?.questionName,
            A: key?.avg_stakeholder ?? 0,
            C: key?.standardValue ?? 0,
          });
        }
      }
    }
  
    // Set the latest questions array to state
    setQuestionStore(newQuestionsArr);
  }, [reportsData]);
  

  const COLORS = ["#314F40"];
  const COLORSTWO = ["#233958"];

  // let customizedReportData = [
  //   {
  //     sectionName: "SHAPES STRATEGY",
  //     dimension: [
  //       {
  //         dimensionName: "Creates Clarity",
  //         questions: reportsData.filter(section => section.sectionName === "Strategys" && section.dimensionName === "Creates Clarity")
  //       },
  //       {
  //         dimensionName: "Balances Short & Long-Term Thinking",
  //         questions: reportsData.filter(section => section.sectionName === "Strategys" && section.dimensionName === "Balances Short & Long-Term Thinking")
  //       },
  //       {
  //         dimensionName: "Balances Risk & Innovation",
  //         questions: reportsData.filter(section => section.sectionName === "Strategys" && section.dimensionName === "Balances Risk & Innovation")
  //       },
  //     ],
  //   },
  // ];

  // Reorder the data

  const renderCustomTick = (props) => {
    const { payload, x, y } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          display="none"
          textAnchor="middle"
          fill="#666" // Customize the text color here
          fontSize={12} // Customize the font size here
          fontWeight="bold" // Customize the font weight here
        >
          {/* {payload.value} */}
        </text>
      </g>
    );
  };

  function updatePercentage() {
    const widthPercentage = (window.innerWidth / window.screen.width) * 100;
    const heightPercentage = (window.innerHeight / window.screen.height) * 100;
    setWidthValue(widthPercentage);
  }

  useEffect(() => {
    window.addEventListener("resize", updatePercentage);

    return () => {
      window.addEventListener("resize", updatePercentage);
    };
  }, []);

  // Event listener for window resize

  const renderCustomTick1 = (tickProps) => {
    const { x, y, payload } = tickProps;

    return (
      <text x={x} y={y} fill="black" fontSize={16} textAnchor="middle" dy={5}>
        {payload.value}
      </text>
    );
  };

  const divRef = useRef(null);
  const handleDownload = useCallback(() => {
    if (divRef.current === null) {
      return;
    }

    // Set styles to allow content to expand
    divRef.current.style.width = "1000";
    divRef.current.style.height = "100%";
    divRef.current.style.marginTop = "30%";
    divRef.current.style.backgroundColor = "white";
    const width = 1000; // Example width in pixels
    const height = 900; // Example height in pixels
    toPng(divRef.current, { cacheBust: true, width, height })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "Graphical-view.png";
        link.href = dataUrl;
        link.click();

        divRef.current.style.width = "auto"; // Set width to auto to allow content to expand
        divRef.current.style.height = "auto"; // Set height to auto to allow content to expand
      })
      .catch((err) => {
        console.log(err);
      });
  }, [divRef]);

  let shrink = props.sidebar ? "scale" : "no-scale";

  console.log(sectionStore);
  console.log(dimensionStore)

  return (

    <Layout>
      {location?.state?.componentName === "FRAMEWORK" ? (
        <AppPageHeader
          variant="pageHeader"
          // pathname="/piedap/assessment360/report"
          pathname="/exco/assessment-framework"
          serviceAccessData={location?.state?.serviceAccessData}
          trackingSelectedData={location?.state?.template}
        >
          Graphical View for {location?.state?.frameworkSelectedData.frameworkName}
        </AppPageHeader>
      ) : (
        <AppPageHeader
          variant="pageHeader"
          // pathname="/piedap/assessment360/report"
          pathname="/exco/prepare"
          serviceAccessData={location?.state?.serviceAccessData}
          trackingSelectedData={location?.state?.template}
        >
          Graphical View for {location?.state?.frameworkSelectedData.frameworkName}
        </AppPageHeader>
      )}



      <div style={{ display: "flex", flexDirection: "row",justifyContent:"center" }}>

        {/* <div style={{ background: "red"}} >


          <h5 >
            Show Value Outside
            {showTooltipOutside ? (
              <Switch
                onClick={() => setShowTooltipOutside(!showTooltipOutside)}
                defaultChecked
              />
            ) : (
              <Switch
                onClick={() => setShowTooltipOutside(!showTooltipOutside)}
              />
            )}
          </h5>
        </div> */}



        <div
          ref={divRef}
          
          style={{
            position: "relative",
            // marginLeft: "6rem",
            width: "100%",
            height: "100%",
          }}
        // className="res_spider_graph"
        >
          {/* <Grid container>

  <Grid xs={12} sm={12}> */}

          <div style={{ zIndex: 1 }}>
            <Graph
              sectionStore={sectionStore}
              dimensionStore={dimensionStore}
            />
          </div>
          <div></div>
          <div
            style={{
              zIndex: 1,
              // marginTop: "-31.9rem",
              marginLeft: "4.05rem",
              position:'absolute',
              bottom:163
            }}
          >
            <GraphDimension
              sectionStore={sectionStore}
              dimensionStore={dimensionStore}
            />
          </div>




        </div>

        <div >
          <button onClick={handleDownload}>Download</button>
        </div>

       



        {/* <PieChart width={900} height={900}>
            <Pie
              data={sectionStore}
              innerRadius={350}
              outerRadius={400}
              fill="#8884d8"
              paddingAngle={2}
              dataKey="value"
              startAngle={90}
              endAngle={-450}
            >
              {sectionStore.map((entry, index) => (
                <Cell
                  key={`cell-outer-${index}`}
                  fill={COLORSTWO[index % COLORSTWO.length]}
                />
              ))}
              <LabelList
                dataKey="name"
                position="inside"
                fill="black"
                fontSize={20}
                fontWeight="bold"
              />
              

            </Pie>
          </PieChart>

          <PieChart
            width={900}
            height={900}
            style={{ position: "absolute", left: 0, top: 0 }}
          >
            <Pie
              data={dimensionStore}
              innerRadius={250}
              outerRadius={330}
              fill="#82ca9d"
              paddingAngle={2}
              dataKey="value"
              startAngle={90}
              endAngle={-450}
            >
              {dimensionStore.map((entry, index) => (
                <Cell
                  key={`cell-inner-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
              <LabelList
                dataKey="name"
                position="inside"
                // fill="black"
                fontSize={13}
                // fontWeight="bold"
              />
            </Pie>
            <Tooltip formatter={(value, name) => [name]} />
          </PieChart>*/}


        {/* Tooltip outside the RadarChart */}
        {/* {questionStore.map((data, index) => (
          <div
            key={index}
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Tooltip
              position={{ x: 225, y: 224 }}
              content={<CustomTooltip active payload={[{ payload: data }]} label={data.field} />}
            />
          </div>
        ))} */}

        {/* </Grid>

</Grid> */}
      </div>
    </Layout>

  );
}

export default Report;

const extraCode = () => {
  return (
    <RadarChart
      outerRadius={200}
      width={450}
      height={450}
    // width={450}
    // height={430}
    // data={questionStore}

    // startAngle={100}
    // endAngle={460}
    // startAngle={0}
    // endAngle={360}

    // startAngle={82.5}
    // endAngle={-277.5}
    >
      <defs>
        <radialGradient id="radial" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
          {/* <stop offset="0%" stopColor="rgba(255, 255, 0, 0.5)" /> Light yellow */}
          {/* <stop offset="100%" stopColor="rgba(0, 255, 0, 0.5)" /> Light green */}
          <stop offset="0%" stopColor="rgba(255, 255, 0, 0.3)" />{" "}
          {/* Very light yellow */}
          <stop offset="100%" stopColor="rgba(0, 255, 0, 0.3)" />{" "}
          {/* Very light green */}
        </radialGradient>
      </defs>

      <g>
        <circle cx={225} cy={224} r={200} fill="url(#radial)" />
      </g>
      <PolarGrid
        gradientTransform=""
        gridType="circle"
        enableBackground={true}
        tickCount={5}
      // fill="green"
      // fillOpacity={0.5}
      />
      <PolarAngleAxis
        name="Question"
        dataKey="field"
      // fill="red"
      // tick={renderCustomTick}
      />

      <PolarRadiusAxis domain={[2, 4, 6, 8, 10]} />
      <Radar
        dot={true}
        // name={
        //   location?.state?.template?.templateTypeName === "360"
        //     ? "Client"
        //     : "Standard"
        // }
        dataKey="C"
        stroke="blue"
        // fill="red"
        // fillOpacity={0.5}
        strokeWidth={4}
        fillOpacity={0}
      />
      <Radar
        width="100%"
        dot={true}
        name="Assessor"
        dataKey="A"
        stroke="red"
        // fill="green"
        // fillOpacity={0.5}
        strokeWidth={4}
        fillOpacity={0}
      />
      <Tooltip />
    </RadarChart>
  );
};
// -------------------------------------------------------------------------

const CustomTooltip = ({ active, payload }) => {
  const prepareAssessment = useSelector((state) => state?.prepareAssessment);
  if (active && payload && payload.length) {
    const data = payload[0].payload; // Assuming the first payload contains the necessary data
    // Customize the Tooltip content based on your data
    return (
      <div
        style={{
          minWidth: "25rem",
          maxWidth: "80rem",
          position: "absolute",
          marginTop: "-15rem",
          marginLeft: "10rem",
          backgroundColor: "whiteSmoke",
          padding: 10,
          borderRadius: 10,
        }}
      >
        <p style={{ width: "100%" }}>{data.field}</p>
        <p style={{ width: "100%" }}>
          {prepareAssessment?.selectedData?.assessmentType &&
            (prepareAssessment?.selectedData?.assessmentType).toLowerCase() ===
            "talent"
            ? "Client "
            : "Standard "}
          : {data.C}
        </p>
        <p style={{ width: "100%" }}>Assessor : {data.A}</p>
      </div>
    );
  }

  return null;
};

{
  /* <Tooltip content={<CustomTooltip />} /> */
}
