import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { countries, continents } from "countries-list";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useLocation } from "react-router-dom";

// material ui
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
  Autocomplete,
  TextField,
  Chip,
  Input,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toPng } from 'html-to-image';
// icon
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from '@mui/icons-material/Delete';
import PreviewIcon from '@mui/icons-material/Preview';
import html2canvas from 'html2canvas';
// components
import Layout from "../../../layout/Layout";
import {
  MuiAutocomplete,
  geographyArr,
  MuiDataGrid,
  GridCellExpand,
  ModalPopUp,
  FormSubmitButton,
  FormTextField as MyTextInput,
  Notifier,
  ErrorNotifier,
  ModalCloseBtn,
  ConfirmDialog,
  MuiTooltip,
  CommonCurdPopUp
} from "../../../components";
import MicrositeConfirmDialog from "../microsites/MicrositeConfirmDialog";
import { ASSESSMENT_BASE_URL } from "../../../utils/config";
// redux
import {
  resendEmail,
  createCustUser,
  getCustUser,
  getCustHrByAcc,
  getCustManagerByAcc,
  updateCustUser,
  deleteCustUser,
  forceDeleteCustUser,
  batchUploadCustomerUser,
  getCustUserByAccountId,
  getCustUserByCustomerId,
  getCustUserRoles,
  previewCustomerEmailTemplate,
  rowSelect,
  resetMessage,
  resetErrMessage,
} from "../../../features/customer/custUserSlice";
import { getAccount, getAccountById } from "../../../features/customer/accountSlice";
import { getCustomer } from "../../../features/customer/customerSlice";
// --------------------------------------------------------------------------------------

const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string("Enter your email")
    .required("Email is required")
    // .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "email is not valid"),
    .email("Email is not valid"),
  comments: Yup.string().nullable(),
  account: Yup.object().required("Account is required"),
  custName: Yup.object().required("Customer is required")
});

const validationEditSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string("Enter your email")
    .required("Email is required")
    // .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "email is not valid"),
    .email("Email is not valid"),
  comments: Yup.string().nullable(),
  account: Yup.string().required("Account is required"),
  custName: Yup.string().required("Customer is required")
});

// ---------------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ------------------------------------------------------------------------

const CustUserOnboarding = (props) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [forceDelConfirmDialog, setForceDelConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [selectedCustValue, setSelectedCustValue] = useState(null)
  const [selectedAccountValue, setSelectedAccountValue] = useState(null)
  const [previewEmail, setPreviewEmail] = useState(false)
  const iframeRef = useRef(null);
  const htmlRef = useRef(null);

  const location = useLocation()
  const dispatch = useDispatch();
  const {
    message,
    errMessage,
    custUserData,
    previewCustomerEmailTemplateData,
    custHrData,
    custManagerData,
    custUserRolesData,
    selectedData,
  } = useSelector((state) => ({ ...state.custUser }));

  const { accountData, accountIdData } = useSelector((state) => ({
    ...state.account,
  }));

  const { customersData } =
    useSelector((state) => ({ ...state.customer }));

  const showAddModel = () => {
    setShowAdd(!showAdd);
  };

  const closeModel = () => {
    setShowAdd(false);
    const { account, custName } = formik?.values
    formik.resetForm({
      values: {
        account,
        custName,
        firstName: "",
        lastName: "",
        email: "",
        comments: "",
      },
    });
  };

  const showEditModal = () => {
    setShowEdit(true);
  };

  const closeEditModal = () => {
    setShowEdit(false);
  };

  const showDelete = () =>
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => handleDeleteCustUser(),
    });



  const handleDeleteCustUser = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deleteCustUser(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          selectedCustValue && !selectedAccountValue ?
            dispatch(getCustUserByCustomerId({ assessmentCustomerId: selectedCustValue?.id }))
            : selectedAccountValue
              ? dispatch(getCustUserByAccountId({ assessmentCustomerAccountId: selectedAccountValue?.id }))
              : dispatch(getCustUser());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const showForceDelete = (dataId) =>
    setForceDelConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: (pass) => handleForceDeleteCustomerUser(pass, dataId),
    })

  const showCustomerActivateModel = (dataId) =>
    setConfirmDialog({
      isOpen: true,
      title: "Attention!",
      subTitle: "Clicking this button will send an email to the designated recipients. Please Preview the information before proceeding. Once sent, emails cannot be retrieved.",
      onConfirm: () => dispatch(resendEmail({ id: dataId })).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            handleSetUserActivateConfirmDialog()
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
            handleSetUserActivateConfirmDialog()
          }, 3000);
        }
      })
    })

  const handleForceDeleteCustomerUser = (pass, dataId) => {
    let data = {
      id: dataId?.id,
      password: pass
    };
    dispatch(forceDeleteCustUser(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setForceDelConfirmDialog({
            ...forceDelConfirmDialog,
            isOpen: false,
          });
          selectedCustValue && !selectedAccountValue ?
            dispatch(getCustUserByCustomerId({ assessmentCustomerId: selectedCustValue?.id }))
            : selectedAccountValue
              ? dispatch(getCustUserByAccountId({ assessmentCustomerAccountId: selectedAccountValue?.id }))
              : dispatch(getCustUser());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 2000);
      }
    });
  };

  const handleSetForceDelConfirmDialog = (obj) => {
    setForceDelConfirmDialog({
      ...forceDelConfirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleSetUserActivateConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };



  function getCustomerName(params) {
    return params?.row?.assessmentCustomer?.customerName;
  }

  function getAccountName(params) {
    return params?.row?.assessmentCustomer?.accountName;
  }

  function getComments(params) {
    return params?.row?.assessmentCustomerUserDetail?.comments;
  }

  function getClientName(params) {
    return (
      <GridCellExpand
        value={
          `${params.row.assessmentCustomerUserDetail?.firstName || ""} ${params.row.assessmentCustomerUserDetail?.lastName || ""
          }` || ""
        }
        width={params.colDef.computedWidth}
      />
    );
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }









  const handlePreviewClick = async (id) => {

    setPreviewEmail(true)
    dispatch(previewCustomerEmailTemplate({ id: id }))
    if (htmlRef.current) {
      // Convert HTML content to image
      await toPng(htmlRef.current);
    }
  }





  const data = {
    rows: custUserData,
    columns: [
      {
        field: "clientName",
        headerName: "User Name",
        width: 200,
        renderCell: getClientName,
        valueGetter: (params) =>
          `${params.row.assessmentCustomerUserDetail?.firstName || ""} ${params.row.assessmentCustomerUserDetail?.lastName || ""
          }`,
      },
      {
        field: "email",
        headerName: "Email",
        width: 200,
        renderCell: renderCellExpand,
      },
      {
        field: "customer",
        headerName: "Customer",
        width: 150,
        valueGetter: (params) => params?.row?.assessmentCustomerAccount?.assessmentCustomer?.
          customerName,
      },
      {
        field: "account",
        headerName: "Account",
        width: 150,
        valueGetter: (params) => params?.row?.assessmentCustomerAccount?.accountName,
      },
      {
        field: 'Preview',
        // headerName: 'Clone',
        type: 'preview',
        width: 70,
        renderCell: (params) => {
          return (
            // location?.state?.permissions && location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "forceDelCustUsr") && 
            !params?.row?.assessmentCustomerUserActivity?.isPasswordChanged && (<MuiTooltip title="Preview Email Template" placement="right">
              <IconButton onClick={() => handlePreviewClick(params?.row?.id)} sx={{ p: 0 }} >
                <PreviewIcon color="primary" />
              </IconButton>
            </MuiTooltip>)
          );
        }
      },

      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 150,
        renderCell: (params) => {
          return (
            !params?.row?.assessmentCustomerUserActivity?.isPasswordChanged && (
              <FormSubmitButton
                type="submit"
                variant="contained"
                sx={{ textTransform: "none" }}
                onClick={() => showCustomerActivateModel(params?.row?.id)}
              // onClick={() => {
              //   let data = { id: params?.row?.id };
              //   dispatch(resendEmail(data)).then((res) => {
              //     if (res?.payload?.data?.ok) {
              //       setTimeout(() => {
              //         dispatch(resetMessage());
              //       }, 2000);
              //     }
              //     if (res?.payload?.error) {
              //       setTimeout(() => {
              //         dispatch(resetErrMessage());
              //       }, 3000);
              //     }
              //   });
              // }}
              >
                Activate User
              </FormSubmitButton>
            )
          );
        },
      },

      {
        field: 'forceCustDelete',
        // headerName: 'Clone',
        type: 'actions',
        width: 70,
        renderCell: (params) => {
          return (
            location?.state?.permissions && location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "forceDelCustUsr") && <MuiTooltip title="Force Delete" placement="right">
              <IconButton sx={{ p: 0 }} onClick={() => showForceDelete(params?.row)}>
                <DeleteIcon style={{ color: '#FF0000' }} />
              </IconButton>
            </MuiTooltip>
          );
        }
      },
      {
        field: "comments",
        headerName: "Comments",
        width: 250,
        renderCell: renderCellExpand,
        valueGetter: getComments,
      },
    ],
  };

  const handleFile = (e) => {
    let formdata = new FormData();

    formdata.append("file", e.target.files[0]);

    dispatch(batchUploadCustomerUser(formdata)).then((res) => {
      // if (res?.payload?.data?.ok) {
      setTimeout(() => {
        dispatch(resetMessage());
        dispatch(getCustUser())
      }, 2000);
      // }
      // if (res?.payload?.error) {
      //   setTimeout(() => {
      //     dispatch(resetErrMessage());
      //   }, 3000);
      // }
    }).catch((err) => {
      setTimeout(() => {
        dispatch(resetErrMessage())
      })
    });
    e.target.value = null;
  
  };

  const formik = useFormik({
    initialValues: {
      custName: "",
      customerSelectedData: "",
      accountSelectedData: "",
      account: "",
      firstName: "",
      lastName: "",
      email: "",
      comments: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values, onSubmitProps) => {
      let roles = [];
      roles.push();
      dispatch(
        createCustUser({
          assessmentCustomerAccountId: values.account?.id,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          comments: values?.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeModel();
            selectedCustValue && !selectedAccountValue ?
              dispatch(getCustUserByCustomerId({ assessmentCustomerId: selectedCustValue?.id }))
              : selectedAccountValue
                ? dispatch(getCustUserByAccountId({ assessmentCustomerAccountId: selectedAccountValue?.id }))
                : dispatch(getCustUser());
            onSubmitProps.resetForm();
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  const formikEdit = useFormik({
    enableReinitialize: true,

    initialValues: {

      custName: selectedData?.assessmentCustomerAccount?.assessmentCustomer?.id,
      customerSelectedData: "",
      accountSelectedData: "",
      account: selectedData?.assessmentCustomerAccount?.id,
      firstName: selectedData?.assessmentCustomerUserDetail?.firstName,
      lastName: selectedData?.assessmentCustomerUserDetail?.lastName,
      email: selectedData?.email,
      comments: selectedData?.assessmentCustomerUserDetail?.comments,
    },

    validationSchema: validationEditSchema,
    onSubmit: (values) => {
      let roles = [];
      roles.push(values.roles);
      dispatch(
        updateCustUser({
          id: selectedData?.id,
          assessmentCustomerAccountId: values.account,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          comments: values?.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeEditModal();
            selectedCustValue && !selectedAccountValue ?
              dispatch(getCustUserByCustomerId({ assessmentCustomerId: selectedCustValue?.id }))
              : selectedAccountValue
                ? dispatch(getCustUserByAccountId({ assessmentCustomerAccountId: selectedAccountValue?.id }))
                : dispatch(getCustUser());
            // dispatch(getCustUser());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  let options = Object.values(countries);

  useEffect(() => {
    dispatch(getCustomer())
    dispatch(getCustUser());

    // dispatch(getCustUserRoles());
  }, []);

  const useStyles = makeStyles((theme) => ({
    rootbox: {
      position: "absolute !important",
      top: "50% !important",
      left: "60% !important",
      transform: "translate(-50%, -50%) !important",
      width: '56.25rem !important',
      [theme.breakpoints.down('sm')]: {
        width: '20rem !important',
        height: '60% !important',
        display: 'block !important',
        overflow: 'scroll !important',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        width: '33.375rem !important',
        height: '49% !important',
        display: 'block !important',
        overflow: 'scroll !important',
        // margin: '-3.188rem',
        marginTop: '-3.75rem  !important',
        marginLeft: '-3.563rem',
        padding: '2.125rem !important',
      },
      background: "#fff !important",
      borderRadius: "0.313rem !important",
      boxShadow: '1.5rem !important',
      paddingTop: '1rem !important',
      paddingLeft: '2rem !important',
      paddingRight: '2rem !important',
      paddingBottom: '1.5rem',
      display: "flex !important",
      flexDirection: "column !important",
      justifyContent: "center !important",
      alignItems: "center !important",
    },

    iconResponsive: {
      [theme.breakpoints.down('md')]: {
        marginRight: '-7rem',

      },
    },
    text_responsive: {
      width: '40rem',
      [theme.breakpoints.down('md')]: {
        width: '17rem',

      },
    }
  }))

  useEffect(() => {
    if (formik.values?.custName?.id) {
      dispatch(getAccountById({ assessmentCustomerId: formik.values?.custName?.id }))
    }


  }, [formik.values?.custName])

  useEffect(() => {

    dispatch(getAccountById({ assessmentCustomerId: formikEdit.values?.custName?.id }))
    // if (!formikEdit.values?.custName) {
    //   formikEdit.setFieldValue("accountName", "")
    //   formikEdit.setFieldTouched("accountName", true)
    // }
  }, [formikEdit.values?.custName])


  useEffect(() => {
    if (selectedData?.assessmentCustomerAccount?.assessmentCustomer?.id) {
      dispatch(getAccountById({ assessmentCustomerId: selectedData?.assessmentCustomerAccount?.assessmentCustomer?.id }))
    }
    // let resultOne = customersData?.find((dataTwo) => dataTwo?.id === selectedData?.assessmentCustomerAccount?.assessmentCustomer?.id)
    // formikEdit.setFieldValue("custName", resultOne)

  }, [selectedData])

  const getCustomerSelectedValue = (data) => {
    formik.setFieldValue("custName", data)
    setSelectedCustValue(data)
  }

  const getAccountSelectedValue = (data) => {
    formik.setFieldValue("account", data)
    setSelectedAccountValue(data)
  }






  const classes = useStyles();
  return (
    <Layout>
      <>

        <label
          htmlFor="icon-button-file"
          style={{ position: "absolute", right: 100, zIndex: 1 }}
        >
          <Input
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            id="icon-button-file"
            type="file"
            sx={{ display: "none" }}
            name="l1MenuIcon"
            onChange={handleFile}
            required={true}
          />
          <MuiTooltip title="Upload Clients" placement="bottom">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <CloudUploadIcon className={classes.iconResponsive} />
            </IconButton>
          </MuiTooltip>

        </label>
        <label

          style={{ position: "absolute", right: 60, zIndex: 3 }}
        >
          <MuiTooltip title="Download Template" placement="bottom">
            <a href={`${ASSESSMENT_BASE_URL}/v1/api/excel-parser/template/batch-upload-assessment-customer-user`} target="_blank">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <FileDownloadIcon className={classes.iconResponsive} />
              </IconButton>
            </a>
          </MuiTooltip>
        </label>

        <PageHeader variant="pageHeader">Users Management</PageHeader>
        <MuiDataGrid
          setShowAdd={
            location?.state?.permissions?.find(
              (e) =>
                e.applicationService?.name?.split(".")[
                e?.applicationService?.name?.split(".")?.length - 1
                ] === "create"
            ) && showAddModel
          }
          setShowEdit={
            location?.state?.permissions?.find(
              (e) =>
                e.applicationService?.name?.split(".")[
                e?.applicationService?.name?.split(".")?.length - 1
                ] === "update"
            ) && showEditModal
          }
          setShowDelete={
            location?.state?.permissions?.find(
              (e) =>
                e.applicationService?.name?.split(".")[
                e?.applicationService?.name?.split(".")?.length - 1
                ] === "delete"
            ) && showDelete
          }
          getAccountSelectedValue={(data) => getAccountSelectedValue(data)}
          getCustomerSelectedValue={(data) => getCustomerSelectedValue(data)}
          getCallBackCustomer={(data) => getCustUserByCustomerId({ assessmentCustomerId: data })}
          getCallBackOne={getCustUser}
          getCallBackTwo={(data) => getCustUserByAccountId({ assessmentCustomerAccountId: data })}
          //  setShowAdd={showAddModel}
          //  setShowEdit={showEditModal}
          // setShowDelete={showDelete
          // }
          isAccount={true}
          isCustomer={true}
          toolbarColumn={true}
          // toolbarFilter={true}
          toolbarDensity={true}
          toolbarExport={true}
          data={data}
          select={(data) => dispatch(rowSelect(data))}
        />
      </>

      {previewEmail && <CommonCurdPopUp
        heading="Email Preview"
        mediumScreen={true}
        close={
          () => setPreviewEmail(false)
        }
      >

        <div ref={htmlRef} dangerouslySetInnerHTML={{ __html: previewCustomerEmailTemplateData }} style={{}} />


      </CommonCurdPopUp>}



      {showAdd && <CommonCurdPopUp
        heading={`Create User`}
        mediumScreen={true}
        close={
          closeModel
        }
      >
      <Grid container >
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11} >


                <MuiAutocomplete

                  name="custName"
                  options={customersData}
                  setValue={(name, newValue) => {

                    formik.setFieldValue(name, newValue)
                    formik.setFieldValue("account", "")

                  }
                  }
                  value={formik.values.custName}
                  optionValue="customerName"
                  label="Customer"
                  required={true}
                  error={formik.touched.custName && Boolean(formik.errors.custName)}
                  helperText={formik.touched.custName && formik.errors.custName}
                />

                <MuiAutocomplete

                  name="account"
                  options={accountIdData}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.account}
                  optionValue="accountName"
                  // optionSecValue="accountName"
                  label="Account Name"
                  required={true}
                  error={
                    formik.touched.account && Boolean(formik.errors.account)
                  }
                  helperText={formik.touched.account && formik.errors.account}
                />
                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%", paddingRight: "0.5rem" }}>
                    <MyTextInput
                      label="First Name"
                      type="text"
                      name="firstName"
                      required={true}
                      margin="normal"
                      id="firstName"
                      value={formik.values.firstName}
                      fullWidth={true}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                  </div>

                  <div style={{ width: "50%" }}>
                    <MyTextInput
                      label="Last Name"
                      type="text"
                      name="lastName"
                      required={true}
                      margin="normal"
                      fullWidth={true}
                      id="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                  </div>
                </div>
                <MyTextInput
                  label="Email"
                  type="email"
                  name="email"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <MyTextInput
                  label="Comments"
                  type="text"
                  name="comments"
                  fullWidth={true}
                  margin="normal"
                  id="comments"
                  value={formik.values.comments}
                  onChange={formik.handleChange}
                  tabIndex={8}
                  error={
                    formik.touched.comments && Boolean(formik.errors.comments)
                  }
                  helperText={formik.touched.comments && formik.errors.comments}
                />
                <Box style={{ width: "100%", textAlign: "center" }} mb={10} >
              <FormSubmitButton onClick={formik?.handleSubmit} type="submit" variant="contained">
            Submit
          </FormSubmitButton>
          </Box>
              </Grid>
              
            </Grid>


        </CommonCurdPopUp>}

        
      {showEdit && <CommonCurdPopUp
        heading={`Update User`}
        mediumScreen={true}
        close={
          closeEditModal
        }
      >
             <Grid container >
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11} >
                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Customer *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="custName"
                    defaultValue={formikEdit.values.custName}
                    value={formikEdit.values.custName}
                    label="Customer"
                    disabled={true}
                    onChange={formikEdit.handleChange}
                    error={
                      formikEdit.touched.custName &&
                      Boolean(formikEdit.errors.custName)
                    }

                  >
                    {customersData.map((option) => {
                      return (
                        <MenuItem
                          key={option.id}
                          value={option.id}
                        >
                          {option.customerName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {formikEdit.touched.custName && formikEdit.errors.custName}
                  </FormHelperText>
                </FormControl>

                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Account Name *
                  </InputLabel>
                  <Select

                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="account"
                    defaultValue={formikEdit?.values?.account}
                    value={formikEdit.values.account}
                    error={
                      formikEdit.touched.account &&
                      Boolean(formikEdit.errors.account)
                    }
                    label="Account Name *"
                    onChange={formikEdit.handleChange}
                    disabled={true}
                  >
                    {accountIdData.length > 0 &&
                      accountIdData.map((item) => (
                        <MenuItem key={item.id} value={item.id}>

                          {item.accountName}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {formikEdit.touched.account && formikEdit.errors.account}
                  </FormHelperText>
                </FormControl>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%", paddingRight: "0.5rem" }}>
                    <MyTextInput
                      label="First Name"
                      type="text"
                      name="firstName"
                      required={true}
                      margin="normal"
                      id="firstName"
                      value={formikEdit.values.firstName}
                      onChange={formikEdit.handleChange}
                      fullWidth={true}
                      error={
                        formikEdit.touched.firstName &&
                        Boolean(formikEdit.errors.firstName)
                      }
                      helperText={
                        formikEdit.touched.firstName &&
                        formikEdit.errors.firstName
                      }
                    />
                  </div>

                  <div style={{ width: "50%",}}>
                    <MyTextInput
                      label="Last Name"
                      type="text"
                      name="lastName"
                      fullWidth={true}
                      required={true}
                      margin="normal"
                      id="lastName"
                      value={formikEdit.values.lastName}
                      onChange={formikEdit.handleChange}
                      error={
                        formikEdit.touched.lastName &&
                        Boolean(formikEdit.errors.lastName)
                      }
                      helperText={
                        formikEdit.touched.lastName &&
                        formikEdit.errors.lastName
                      }
                    />
                  </div>
                </div>
                <MyTextInput
                  label="Email"
                  type="email"
                  name="email"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="email"
                  value={formikEdit.values.email}
                  onChange={formikEdit.handleChange}
                  disabled={true}
                  error={
                    formikEdit.touched.email && Boolean(formikEdit.errors.email)
                  }
                  helperText={
                    formikEdit.touched.email && formikEdit.errors.email
                  }
                />
                <MyTextInput
                  label="Comments"
                  type="text"
                  name="comments"
                  fullWidth={true}
                  margin="normal"
                  id="comments"
                  value={formikEdit.values.comments}
                  onChange={formikEdit.handleChange}
                  tabIndex={8}
                  error={
                    formikEdit.touched.comments &&
                    Boolean(formikEdit.errors.comments)
                  }
                  helperText={
                    formikEdit.touched.comments && formikEdit.errors.comments
                  }
                />
                <Box style={{ width: "100%", textAlign: "center", }} mb={10} >
              <FormSubmitButton onClick={formikEdit?.handleSubmit} type="submit" variant="contained">
            Submit
          </FormSubmitButton>
          </Box>
              </Grid>
            </Grid>

        </CommonCurdPopUp>}




      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />

      <MicrositeConfirmDialog
        confirmDialog={forceDelConfirmDialog}
        setConfirmDialog={(obj) => handleSetForceDelConfirmDialog(obj)}
      />
      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default CustUserOnboarding;
