import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CRUD_SECTION_CONFIG_QUESTIONS, GET_DIMENSION_CONFIG_QUESTIONS, GET_SECTION_CONFIG_QUESTIONS } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createDimensionConfigQuestion = createAsyncThunk(
  "dimensionConfigQuestion/createDimensionConfigQuestion",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CRUD_SECTION_CONFIG_QUESTIONS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getDimensionConfigQuestion = createAsyncThunk(
  "sectionConfigQuestion/getDimensionConfigQuestion",
  async (data, { rejectWithValue }) => {
    try {
    //   const response = await axios.post(GET_SECTION_CONFIG_QUESTIONS, data, {
      const response = await axios.post(GET_DIMENSION_CONFIG_QUESTIONS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const updateDimensionConfigQuestion = createAsyncThunk(
  "dimensionConfigQuestion/updateDimensionConfigQuestion",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CRUD_SECTION_CONFIG_QUESTIONS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteDimensionConfigQuestion = createAsyncThunk(
  "dimensionConfigQuestion/deleteDimensionConfigQuestion",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CRUD_SECTION_CONFIG_QUESTIONS, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const dimensionConfigQuestionSlice = createSlice({
  name: "dimensionConfigQuestion",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    dimensionConfigQuestionData: [],
    selectedData: {},
    selectedSectionData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
    sectionRowSelect: (state, action) => {
      state.selectedSectionData =
        action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createDimensionConfigQuestion.pending]: (state, action) => {
      state.loading = true;
    },
    [createDimensionConfigQuestion.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createDimensionConfigQuestion.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getDimensionConfigQuestion.pending]: (state, action) => {
      state.loading = true;
    },
    [getDimensionConfigQuestion.fulfilled]: (state, action) => {
      state.loading = false;
      state.dimensionConfigQuestionData = action?.payload?.data;
    },
    [getDimensionConfigQuestion.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateDimensionConfigQuestion.pending]: (state, action) => {
      state.loading = true;
    },
    [updateDimensionConfigQuestion.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateDimensionConfigQuestion.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteDimensionConfigQuestion.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteDimensionConfigQuestion.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteDimensionConfigQuestion.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect, sectionRowSelect } =
  dimensionConfigQuestionSlice.actions;
export default dimensionConfigQuestionSlice.reducer;
