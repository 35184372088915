import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_ROLES } from "../../../utils/routes";

// ---------------------------------------------------------------------

export const createRoles = createAsyncThunk(
  "roles/createRoles",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_ROLES, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getRoles = createAsyncThunk("roles/getRoles", async (obj, {rejectWithValue}) => {
  try {
    const response = await axios.get(CURD_ROLES, { withCredentials: true });
    return response;
  } catch (err) {
    return rejectWithValue(err.response)
  }
});

export const updateRoles = createAsyncThunk(
  "roles/updateRoles",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_ROLES, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteRoles = createAsyncThunk(
  "roles/deleteRoles",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_ROLES, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const rolesSlice = createSlice({
  name: "userGroups",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    rolesData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createRoles.pending]: (state, action) => {
      state.loading = true;
    },
    [createRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createRoles.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getRoles.pending]: (state, action) => {
      state.loading = true;
    },
    [getRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.rolesData = action?.payload?.data;
    },
    [getRoles.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateRoles.pending]: (state, action) => {
      state.loading = true;
    },
    [updateRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateRoles.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteRoles.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteRoles.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } = rolesSlice.actions;
export default rolesSlice.reducer;
