import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useLocation } from "react-router-dom";
// material ui
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import Layout from "../../../../layout/Layout";
import {
  MuiDataGrid,
  GridCellExpand,
  ModalPopUp,
  FormSubmitButton,
  FormTextField as MyTextInput,
  Notifier,
  ErrorNotifier,
  ModalCloseBtn,
  ConfirmDialog,
  MuiAutocomplete,
  PageHeader,
  AppPageHeader,
} from "../../../../components";
// redux
import {
  createSectionConfigQuestion,
  getSectionConfigQuestion,
  updateSectionConfigQuestion,
  deleteSectionConfigQuestion,
  rowSelect,
  resetMessage,
  resetErrMessage,
} from "../../../../features/config/sectionConfigQuestionSlice";
import { getQuestionType } from "../../../../features/config/questionTypeSlice";

// ------------------------------------------------------------------------------

const validationSchema = Yup.object({
  questionNo: Yup.number().required("Question Number is required"),
  questionType: Yup.object().required("Question Type is required"),
  question: Yup.string().required("Question is required"),
  // clientQuestion: Yup.string().when([], {
  //   is: () =>
  //     location?.templateSelectedData?.assessmentType?.toLowerCase() ===
  //     "talent",
  //   then: Yup.string().required("Client Question is required"),
  // }),
  // standardValue: Yup.number().when("questionType", {
  //   is: (value) =>
  //     location?.templateSelectedData?.assessmentType?.toLowerCase() ===
  //       "maturity" && value?.toLowerCase() === "rating",
  //   then: Yup.number().required("Standard Value is required"),
  // }),
  // targetValue: Yup.number().when("questionType", {
  //   is: (value) =>
  //     location?.templateSelectedData?.assessmentType?.toLowerCase() ===
  //       "maturity" && value?.toLowerCase() === "rating",
  //   then: Yup.number(),
  // }),
});

const editValidationSchema = Yup.object({
  questionNo: Yup.number().required("Question Number is required"),
  questionType: Yup.string().required("Question Type is required"),
  question: Yup.string().required("Question is required"),
  // clientQuestion: Yup.string()
  //   .when([], {
  //     is: () =>
  //       location?.templateSelectedData?.assessmentType?.toLowerCase() ===
  //       "talent",
  //     then: Yup.string().required("Client Question is required"),
  //   })
  //   .nullable(),
  // standardValue: Yup.number()
  //   .when("questionType", {
  //     is: (value) =>
  //       location?.templateSelectedData?.assessmentType?.toLowerCase() ===
  //         "maturity" && value?.toLowerCase() === "rating",
  //     then: Yup.number().required("Standard Value is required"),
  //   })
  //   .nullable(),
  // targetValue: Yup.number()
  //   .when("questionType", {
  //     is: (value) =>
  //       location?.templateSelectedData?.assessmentType?.toLowerCase() ===
  //         "maturity" && value?.toLowerCase() === "rating",
  //     then: Yup.number(),
  //   })
  //   .nullable(),
});

// -------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ------------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  rootbox: {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: "56.25rem !important",
    [theme.breakpoints.down("sm")]: {
      width: "20rem !important",
      height: "60% !important",
      display: "block !important",
      overflow: "scroll !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "33.375rem !important",
      height: "49% !important",
      display: "block !important",
      overflow: "scroll !important",
      // margin: '-3.188rem',
      marginTop: "-3.75rem  !important",
      marginLeft: "-3.563rem",
      padding: "2.125rem !important",
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: "1.5rem !important",
    paddingTop: "1rem !important",
    paddingLeft: "2rem !important",
    paddingRight: "2rem !important",
    paddingBottom: "1.5rem",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },

  iconResponsive: {
    [theme.breakpoints.down("md")]: {
      marginRight: "-4.5rem",
    },
  },
  text_responsive: {
    width: "40rem",
    [theme.breakpoints.down("md")]: {
      width: "17rem",
    },
  },
  responsive_close: {
    [theme.breakpoints.down("md")]: {
      marginTop: "2.063rem",
      marginRight: "-3.688rem",
    },
  },
}));

const SectionConfigQuestions = (props) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const dispatch = useDispatch();

  const location = useLocation();

  const { message, errMessage, sectionConfigQuestionData, selectedData } =
    useSelector((state) => ({ ...state.sectionConfigQuestion }));

  const { assessmentTemplateSectionData } = useSelector((state) => ({
    ...state.assessmentTemplateSection,
  }));
  const { assessmentTemplateDimensionData } = useSelector((state) => ({
    ...state.assessmentTemplateDimension,
  }));
  const { questionTypeData } = useSelector((state) => ({
    ...state.questionType,
  }));
  const { permissionsData } = useSelector((state) => ({
    ...state.permissions,
  }));
  const showAddModel = () => {
    setShowAdd(!showAdd);
  };

  const closeModel = () => {
    setShowAdd(false);
    formik.resetForm({
      values: {
        questionNo: "",
        questionType: "",
        question: "",
        clientQuestion: "",
        isRequiredForClientOrNot: true,
      },
    });
  };

  const showEditModal = () => {
    setShowEdit(true);
  };

  const closeEditModal = () => {
    setShowEdit(false);
  };

  const showDelete = () =>
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => handleDeleteCustomer(),
    });

  const handleDeleteCustomer = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deleteSectionConfigQuestion(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(
            getSectionConfigQuestion({
              assessmentSectionConfigId: location?.state?.sectionSelectedData?.id,
            })
          );
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  function getSectionName(params) {
    return params?.row?.assessmentSection?.sectionName;
  }

  function getDimensionName(params) {
    return params?.row?.assessmentDimension?.dimensionName;
  }

  function getQuestionNo(params) {
    if (
      params?.row?.assessmentSectionConfig?.sectionNo &&
      params?.row?.AssessmentDimensionConfig?.dimensionNo
    ) {
      return `${params?.row?.assessmentSectionConfig?.sectionNo}.${params?.row?.AssessmentDimensionConfig?.dimensionNo}.${params?.row?.questionNo}`;
    } else if (params?.row?.assessmentSectionConfig?.sectionNo) {
      return `${params?.row?.assessmentSectionConfig?.sectionNo}.${params?.row?.questionNo}`;
    } else if (params?.row?.AssessmentDimensionConfig?.dimensionNo) {
      return `${params?.row?.AssessmentDimensionConfig?.dimensionNo}.${params?.row?.questionNo}`;
    } else {
      return params?.row?.questionNo;
    }
  }

  function getIsRequiredForClientOrNot(params) {
    return params.row.isRequiredForClientOrNot ? "Yes" : "No";
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const [sortModel, setSortModel] = useState([
    { field: "questionNo", sort: "asc" },
  ]);

  const data = {
    rows: sectionConfigQuestionData?.length > 0 ? sectionConfigQuestionData : [],
    columns: [
      {
        field: "questionNo",
        headerName: "Question No",
        width: 90,
        sortable: true,
        valueGetter: getQuestionNo,
      },
      {
        field: "questionName",
        headerName: "Assessor Question",
        width: 350,
        renderCell: renderCellExpand,
      },
      {
        field: "clientQuestion",
        headerName: "Client Question",
        width: 350,
        renderCell: renderCellExpand,
      },

      // {
      //   field:
      //     location?.templateSelectedData?.assessmentType?.toLowerCase() ===
      //       "talent"
      //       ? "clientQuestion"
      //       : "standardValue",
      //   headerName:
      //     location?.templateSelectedData?.assessmentType?.toLowerCase() ===
      //       "talent"
      //       ? "Client Question"
      //       : location?.templateSelectedData?.assessmentType?.toLowerCase() ===
      //         "maturity"
      //         ? "Standard Value"
      //         : "",
      //   width:
      //     location?.templateSelectedData?.assessmentType?.toLowerCase() ===
      //       "talent"
      //       ? 450
      //       : location?.templateSelectedData?.assessmentType?.toLowerCase() ===
      //         "maturity"
      //         ? 120
      //         : "",
      //   hide:
      //     location?.templateSelectedData?.assessmentType?.toLowerCase() ===
      //       "talent"
      //       ? false
      //       : location?.templateSelectedData?.assessmentType?.toLowerCase() ===
      //         "maturity"
      //         ? false
      //         : true,
      //   renderCell: renderCellExpand,
      // },

      {
        field: "questionType",
        headerName: "Question Type",
        width: 120,
        renderCell: renderCellExpand,
      },
      {
        field: "isRequiredForClientOrNot",
        headerName: "Required For Client",
        width: 140,
        // hide:
        //   location?.state?.templateSelectedData?.assessmentType?.toLowerCase() ===
        //     "talent"
        //     ? false
        //     : true,
        valueGetter: getIsRequiredForClientOrNot,
      },
    ],
  };



  const formik = useFormik({
    reset: true,
    initialValues: {
      questionNo: "",
      questionType: "",
      question: "",
      clientQuestion: "",
      standardValue: "",
      targetValue: "",
      isRequiredForClientOrNot: true,
    },

    validationSchema: validationSchema,
    onSubmit: (values, onSubmitProps) => {
      dispatch(
        createSectionConfigQuestion({
          assessmentSectionConfigId: location?.state?.sectionSelectedData?.id,
          assessmentTemplateConfigId: location?.state?.templateSelectedData?.id,
          questionNo: values.questionNo,
          questionType: values?.questionType?.questionTypeName,
          questionName: values.question,
          clientQuestion: values?.clientQuestion,




          isRequiredForClientOrNot:values.isRequiredForClientOrNot
              
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeModel();
            dispatch(
              getSectionConfigQuestion({
                // assessmentSectionId: location?.state?.sectionSelectedData?.id,
                assessmentSectionConfigId: location?.state?.sectionSelectedData?.id,
              })
            );
            // onSubmitProps.resetForm();
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      questionNo: selectedData?.questionNo,
      questionType: selectedData?.questionType,
      question: selectedData?.questionName,
      clientQuestion: selectedData?.clientQuestion,
      standardValue: selectedData?.standardValue,
      targetValue: selectedData?.targetValue,
      isRequiredForClientOrNot: selectedData?.isRequiredForClientOrNot,
    },

    validationSchema: editValidationSchema,
    onSubmit: (values) => {
      dispatch(
        updateSectionConfigQuestion({
          id: selectedData?.id,
          assessmentSectionConfigId: location?.state?.sectionSelectedData?.id,
          assessmentTemplateConfigId: location?.state?.templateSelectedData?.id,
          questionNo: values?.questionNo,
          questionType: values?.questionType,
          questionName: values?.question,
          clientQuestion: values?.clientQuestion,

          isRequiredForClientOrNot: values?.isRequiredForClientOrNot
             
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeEditModal();
            dispatch(
              getSectionConfigQuestion({
                assessmentSectionConfigId: location?.state?.sectionSelectedData?.id,
              })
            );
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    let data = {
      assessmentSectionConfigId: location?.state?.sectionSelectedData?.id,
      //   assessmentTemplateConfigId: location?.state?.templateSelectedData?.id,
      // assessmentTemplateConfigId: location?.state?.sectionSelectedData?.id,
    };
    // dispatch(getAssessmentTemplateSection(data));
    // dispatch(getAssessmentTemplateDimension(data));
    dispatch(getSectionConfigQuestion(data));
    dispatch(getQuestionType());
  }, []);

  //   useEffect(() => {
  //     if(!formik?.values?.section) {
  //       formik.setFieldValue("dimension", "")
  //     }
  //   }, [formik?.values?.section])

  //   useEffect(() => {
  //     if (selectedData?.assessmentSection?.id !== formikEdit?.values?.section) {
  //       formikEdit.setFieldValue("dimension", null);
  //     }
  //   }, [formikEdit?.values?.section]);

  const classes = useStyles();

  let shrink = props.sidebar ? "scale" : "no-scale";

  console.log(location?.state)

  return (
    <Layout>
      <>
        <AppPageHeader
          variant="pageHeader"
          pathname="/exco/config/assessment-template/section-dimension"
          data={location?.data}
          permissions={location?.state?.permissions}
          l3Menu={location?.state?.l3Menu}
          templateSelectedData={location?.templateSelectedData}
        >
          Section Questions For {location?.state?.sectionSelectedData?.sectionName}
        </AppPageHeader>
        <MuiDataGrid
           setShowAdd={
            location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "createAssessQn") &&
            showAddModel
          }
          setShowEdit={
            location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "updateAssessQn") &&
            showEditModal
          }
          setShowDelete={
            location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "deleteAssessQn") &&
            showDelete
          }
          // createAssessQn
          // setShowAdd={
          //   location?.serviceAccessData?.userRoleL3ServicePermission?.find(
          //     (e) =>
          //       e.applicationService?.name?.split(".")[
          //         e?.applicationService?.name?.split(".")?.length - 1
          //       ] === "createAssesQn"
          //   ) && showAddModel
          // }
          // setShowEdit={
          //   location?.serviceAccessData?.userRoleL3ServicePermission?.find(
          //     (e) =>
          //       e.applicationService?.name?.split(".")[
          //         e?.applicationService?.name?.split(".")?.length - 1
          //       ] === "updateAssesQn"
          //   ) && showEditModal
          // }
          // setShowDelete={
          //   location?.serviceAccessData?.userRoleL3ServicePermission?.find(
          //     (e) =>
          //       e.applicationService?.name?.split(".")[
          //         e?.applicationService?.name?.split(".")?.length - 1
          //       ] === "deleteAssesQn"
          //   ) && showDelete
          // }
          // setShowAdd={showAddModel}
          // setShowEdit={showEditModal}
          // setShowDelete={showDelete
          // }

          toolbarColumn={true}
          toolbarFilter={true}
          toolbarDensity={true}
          toolbarExport={true}
          toolbarUpload={true}
          // handleUpload={handleFile}
          toolbarPageObjectDownload={true}
          // handlePageObjectDownload={}
          data={data}
          select={(data) => dispatch(rowSelect(data))}
          // For Sorting
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        />
      </>
      <ModalPopUp
        open={showAdd}
        handleClose={closeModel}
        closeModel={closeModel}
      >
        <Box
          // sx={{ ...style, width: 900 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={closeModel} />
          <h5 className="center bold pt-2 res_margin_top">
            Add Question For {location?.state?.sectionSelectedData?.sectionName}
          </h5>
          <div className={classes.responsive_text}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Question No."
                  type="number"
                  name="questionNo"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="questionNo"
                  value={formik.values.questionNo}
                  // onChange={formik.handleChange}
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    // Use a regular expression to check if the input is a number
                    if (/^\d*$/.test(inputValue)) {
                      // If it's a number, update the field value
                      formik.handleChange(e);
                    }
                    // You can also handle invalid input here if needed
                  }}
                  error={
                    formik.touched.questionNo &&
                    Boolean(formik.errors.questionNo)
                  }
                  helperText={
                    formik.touched.questionNo && formik.errors.questionNo
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MuiAutocomplete
                  name="questionType"
                  options={questionTypeData}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.questionType}
                  optionValue="questionTypeName"
                  optionSecValue="comments"
                  label="Question Type"
                  required={true}
                  error={
                    formik.touched.questionType &&
                    Boolean(formik.errors.questionType)
                  }
                  helperText={
                    formik.touched.questionType && formik.errors.questionType
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <MyTextInput
                  label="Assessor Question"
                  type="text"
                  name="question"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="question"
                  value={formik.values.question}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.question && Boolean(formik.errors.question)
                  }
                  helperText={formik.touched.question && formik.errors.question}
                />
              </Grid>
              {/* {location?.templateSelectedData?.assessmentType?.toLowerCase() ===
                "maturity" &&
                formik?.values?.questionType?.questionTypeName?.toLowerCase() ===
                  "rating" && (
                  <>
                    <Grid item xs={12} sm={12} md={6}>
                      <MyTextInput
                        label="Standard Value"
                        type="number"
                        name="standardValue"
                        required={true}
                        fullWidth={true}
                        margin="normal"
                        id="standardValue"
                        value={formik.values.standardValue}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.standardValue &&
                          Boolean(formik.errors.standardValue)
                        }
                        helperText={
                          formik.touched.standardValue &&
                          formik.errors.standardValue
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MyTextInput
                        label="Target Value"
                        type="number"
                        name="targetValue"
                        required={false}
                        fullWidth={true}
                        margin="normal"
                        id="targetValue"
                        value={formik.values.targetValue}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.targetValue &&
                          Boolean(formik.errors.targetValue)
                        }
                        helperText={
                          formik.touched.targetValue &&
                          formik.errors.targetValue
                        }
                      />
                    </Grid>
                  </>
                )} */}
              {/* {location?.templateSelectedData?.assessmentType?.toLowerCase() ===
                "talent" && ( */}
              <Grid item xs={12} container spacing={1}>
                <Grid item xs={12} sm={12} md={9}>
                  <MyTextInput
                    label="Client Question"
                    type="text"
                    name="clientQuestion"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="clientQuestion"
                    value={formik.values.clientQuestion}
                    onChange={formik.handleChange}
                    onFocus={() =>
                      formik.setFieldValue(
                        "clientQuestion",
                        formik?.values?.question
                      )
                    }
                    error={
                      formik.touched.clientQuestion &&
                      Boolean(formik.errors.clientQuestion)
                    }
                    helperText={
                      formik.touched.clientQuestion &&
                      formik.errors.clientQuestion
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Required For Client
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="isRequiredForClientOrNot"
                      value={formik.values.isRequiredForClientOrNot}
                      label="Required For Client"
                      onChange={formik.handleChange}
                      error={
                        formik.touched.isRequiredForClientOrNot &&
                        Boolean(formik.errors.isRequiredForClientOrNot)
                      }
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                    <FormHelperText error={true} sx={{ color: "red" }}>
                      {formik.touched.isRequiredForClientOrNot &&
                        formik.errors.isRequiredForClientOrNot}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              {/* )} */}
            </Grid>
          </div>

          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>
      <ModalPopUp
        open={showEdit}
        handleClose={() => setShowEdit(false)}
        closeModel={() => setShowEdit(false)}
      >
        <Box
          // sx={{ ...style, width: 900 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formikEdit.handleSubmit}
        >
          <ModalCloseBtn close={() => setShowEdit(false)} />
          <h5 className="center bold pt-2 res_margin_top">
            Update Question For {location?.state?.sectionSelectedData?.sectionName}
          </h5>
          <div className={classes.responsive_text}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Question No *"
                  type="number"
                  name="questionNo"
                  fullWidth={true}
                  margin="normal"
                  id="questionNo"
                  value={formikEdit.values.questionNo}
                  // onChange={formikEdit.handleChange}
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    // Use a regular expression to check if the input is a number
                    if (/^\d*$/.test(inputValue)) {
                      // If it's a number, update the field value
                      formikEdit.handleChange(e);
                    }
                    // You can also handle invalid input here if needed
                  }}
                  disabled={
                    location?.templateSelectedData?.assessmentTracking &&
                    location?.templateSelectedData?.assessmentTracking
                      ?.assessmentStatus === "Published"
                  }
                  error={
                    formikEdit.touched.questionNo &&
                    Boolean(formikEdit.errors.questionNo)
                  }
                  helperText={
                    formikEdit.touched.questionNo &&
                    formikEdit.errors.questionNo
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Question Type *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="questionType"
                    defaultValue={formikEdit.values.questionType}
                    value={formikEdit.values.questionType}
                    label="Question Type"
                    onChange={formikEdit.handleChange}
                    disabled={
                      location?.templateSelectedData?.assessmentTracking &&
                      location?.templateSelectedData?.assessmentTracking
                        ?.assessmentStatus === "Published"
                    }
                    error={
                      formikEdit.touched.questionType &&
                      Boolean(formikEdit.errors.questionType)
                    }
                  >
                    {questionTypeData.map((option) => {
                      return (
                        <MenuItem
                          key={option.id}
                          value={option.questionTypeName}
                        >
                          {option.questionTypeName}-{option.comments}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {formikEdit.touched.questionType &&
                      formikEdit.errors.questionType}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <MyTextInput
                  label="Assessor Question *"
                  type="text"
                  name="question"
                  fullWidth={true}
                  margin="normal"
                  id="question"
                  value={formikEdit.values.question}
                  onChange={formikEdit.handleChange}
                  error={
                    formikEdit.touched.question &&
                    Boolean(formikEdit.errors.question)
                  }
                  helperText={
                    formikEdit.touched.question && formikEdit.errors.question
                  }
                />
              </Grid>
              {location?.templateSelectedData?.assessmentType?.toLowerCase() ===
                "maturity" &&
                formikEdit?.values?.questionType?.toLowerCase() ===
                "rating" && (
                  <>
                    <Grid item xs={12} sm={12} md={6}>
                      <MyTextInput
                        label="Standard Value"
                        type="text"
                        name="standardValue"
                        required={true}
                        fullWidth={true}
                        margin="normal"
                        id="standardValue"
                        value={formik.values.standardValue}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.standardValue &&
                          Boolean(formik.errors.standardValue)
                        }
                        helperText={
                          formik.touched.standardValue &&
                          formik.errors.standardValue
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MyTextInput
                        label="Target Value"
                        type="text"
                        name="targetValue"
                        required={false}
                        fullWidth={true}
                        margin="normal"
                        id="targetValue"
                        value={formik.values.targetValue}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.targetValue &&
                          Boolean(formik.errors.targetValue)
                        }
                        helperText={
                          formik.touched.targetValue &&
                          formik.errors.targetValue
                        }
                      />
                    </Grid>
                  </>
                )}
            
                  <Grid item xs={12} container spacing={1}>
                    <Grid item xs={12} sm={12} md={9}>
                      <MyTextInput
                        label="Client Question"
                        type="text"
                        name="clientQuestion"
                        required={true}
                        fullWidth={true}
                        margin="normal"
                        id="clientQuestion"
                        value={formikEdit.values.clientQuestion}
                        onChange={formikEdit.handleChange}
                        error={Boolean(formikEdit.errors.clientQuestion)}
                        helperText={formikEdit.errors.clientQuestion}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Required For Client
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="isRequiredForClientOrNot"
                          defaultValue={
                            formikEdit.values.isRequiredForClientOrNot
                          }
                          value={formikEdit.values.isRequiredForClientOrNot}
                          label="Required For Client"
                          onChange={formikEdit.handleChange}
                          error={
                            formikEdit.touched.isRequiredForClientOrNot &&
                            Boolean(formikEdit.errors.isRequiredForClientOrNot)
                          }
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </Select>
                        <FormHelperText error={true} sx={{ color: "red" }}>
                          {formikEdit.touched.isRequiredForClientOrNot &&
                            formikEdit.errors.isRequiredForClientOrNot}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
              
            </Grid>
          </div>

          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />

      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default SectionConfigQuestions;
