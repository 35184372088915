import React from "react";
import { PageHeader, MyTextInput } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
// material ui
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Box,
  CardContent,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import { FormSubmitButton, MuiAutocomplete,CommonCurdPopUp } from "../../../../components";
import Notifier from "../../../../components/common/Notifier";
import ErrorNotifier from "../../../../components/common/ErrorNotifier";
// redux
import {
  createAssessmentProgress,
  getAssessmentProgress,
  resetMessage,
  resetErrMessage,
} from "../../../../features/config/assessmentProgressSlice";

// --------------------------------------------------------------

const validationSchema = Yup.object({
  progress: Yup.string().required("Progress is required"),
  comments: Yup.string(),
});

//   ------------------------------------------------------------------------------------------

const AddAssessmentProgress = (props) => {
  const { message, errMessage } = useSelector((state) => ({
    ...state.assessmentProgress,
  }));

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      progress: "",
      comments: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        createAssessmentProgress({
          assessmentProgress: values.progress,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.close();
            dispatch(getAssessmentProgress());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  return (
    <>


      {props?.showAdd && <CommonCurdPopUp
        heading={`Create Role`}
        mediumScreen={true}
        close={props.close}
      >
        <Grid container >
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11} >

            <MuiAutocomplete
              name="assessmentUser"
              options={[]}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              // value={formik.values.userGroup}
              optionValue="userName"
              label="Assessment User"
              required={true}
            // error={formik.touched.userGroup && Boolean(formik.errors.userGroup)}
            // helperText={formik.touched.userGroup && formik.errors.userGroup}
            />
            <MyTextInput
              label="Progress"
              type="text"
              name="progress"
              required={true}
              fullWidth={true}
              margin="normal"
              id="progress"
              value={formik.values.progress}
              onChange={formik.handleChange}
              error={
                formik.touched.progress &&
                Boolean(formik.errors.progress)
              }
              helperText={
                formik.touched.progress &&
                formik.errors.progress
              }
            />
            <MyTextInput
              label="Comments"
              type="text"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formik.values.comments}
              onChange={formik.handleChange}
              error={
                formik.touched.comments && Boolean(formik.errors.comments)
              }
              helperText={formik.touched.comments && formik.errors.comments}
            />
          </Grid>
          <Box style={{ width: "100%", textAlign: "center" }} mb={10}>

            <FormSubmitButton onClick={formik?.handleSubmit} type="submit" variant="contained">
              Submit
            </FormSubmitButton>
          </Box>
        </Grid>

      </CommonCurdPopUp>}
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}


    </>
  );
};

export default AddAssessmentProgress;
