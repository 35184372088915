import React, { useEffect, useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { Formik} from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { MyTextInput, PageHeader } from "@excoleadershipui/pionweblib";
// material ui
import {
  Typography,
  Container,
  Link,
  Box,
  Grid,
  Paper,
  IconButton,
  Stack,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CircularProgress from '@mui/material/CircularProgress';
// icons
import logo from "../../assets/images/exco-nav-logo.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";

import { BsTwitterX } from "react-icons/bs";
// components
import { FormSubmitButton } from "../../components";
import Notifier from "../../components/common/Notifier";
import ErrorNotifier from "../../components/common/ErrorNotifier";
import { ADMIN_VERSION } from "../../utils/config";
// redux
import {
  verifyLogin,
  verifyEmail,
  login,
  verifyToken,
  getCurrentUser,
  resetMessage,
  resetErrMessage,
} from "../../features/auth/authSlice";

// -----------------------------------------------------

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.excoleadership.com/">
      The ExCo Group LLC
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

//   -----------------------------------------------------------------------------------------

function SocialMedia(props) {
  return (
    <Box textAlign="center" sx={{ marginTop: 2 }}>
      <Typography variant="body2">Stay Social</Typography>
      <Stack
        direction="row"
        spacing={1}
        divider={<Divider orientation="vertical" flexItem />}
        justifyContent="center"
      >
        <Link href="https://www.linkedin.com/company/excoleadership/" target="_blank">

        <LinkedInIcon textAlign="center" />
        </Link>
        {/* <Link href="https://twitter.com/ExCoLeadership" target="_blank">

        <TwitterIcon textAlign="center" />
        </Link> */}
      <a
          style={{ textDecoration: "none", color: "#00527d" }}
          href="https://twitter.com/ExCoLeadership"
        >
          <h3
            // data-toggle="tooltip"
            // data-placement="top"
            // onClick={this.handleAssignedView}
            style={{
               marginTop: "-0.01rem",
              cursor: "pointer",
              fontSize: "1.2rem",
            }}
          >
            {" "}
            <BsTwitterX  />{" "}
          </h3>
        </a>
      </Stack>
    </Box>
  );
}

// -------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  btnSubmit: {
    marginTop: "1rem !important",
  },
  errorDiv: {
    color: theme.palette.red.main,
  },
}));

// ---------------------------------------------------------------------------------------------

const EmailValidationSchema = Yup.object({
  email: Yup.string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});
const PassValidationSchema = Yup.object({
  password: Yup.string("Enter your password")
    .required("Password is required"),
});
const TokenValidationSchema = Yup.object({
  token: Yup.string("Enter your token")
    .required("Token is required"),
});

// ---------------------------------------------------------------------------------------------

const SignIn = (props) => {
  const [showEmail, setShowEmail] = useState(true);
  const [showPass, setShowPass] = useState(false);
  const [showToken, setShowToken] = useState(false)
  const [email, setEmail] = useState("mohan@123")

  let navigate = useNavigate();
  const classes = useStyles();

  const dispatch = useDispatch();

  const { loading, loginToken, passwordToken, isLoggedIn, baseString, message, errMessage } = useSelector(
    (state) => ({
      ...state.auth,
    })
  );

  const handleToken = () => {
    setShowPass(false);
    setShowEmail(false);
    setShowToken(true)
  };

  const handlePass = () => {
    setShowPass(true);
    setShowEmail(false);
    setShowToken(false)
  };
  
  useEffect(() => {
    dispatch(verifyLogin())
    dispatch(getCurrentUser());
  }, [])

  useEffect(() => {
    if(!isLoggedIn) {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', function(event) {
      window.history.pushState(null, document.title, window.location.href);
      window.location.reload(true)
  });
    }
    if(isLoggedIn) {
      navigate("/exco/dashboard")
    }
  }, [isLoggedIn])
  
    return (

      !isLoggedIn && <Container component="main" maxWidth="xs">
        <Box sx={{ marginTop: 4 }}>
          <PageHeader sx={{ marginTop: 4 }}>ExCo Sign In </PageHeader>
        </Box>
  
        <Paper
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "2rem",
            position: "relative",
            border: "1px solid",
          }}
          elevation={2}
        >
          {showPass && (
            <IconButton
              sx={{ position: "absolute", left: "0.5rem", top: "0.5rem" }}
              onClick={() => {
                setShowEmail(true);
                setShowPass(false);
                setShowToken(false)
              }}
            >
              <KeyboardBackspaceIcon />
            </IconButton>
          )}
          {showToken && (
            <IconButton
              sx={{ position: "absolute", left: "0.5rem", top: "0.5rem" }}
              onClick={() => {
                setShowEmail(false);
                setShowPass(true);
                setShowToken(false)
              }}
            >
              <KeyboardBackspaceIcon />
            </IconButton>
          )}
          <img alt="logo" src={logo} width="80rem" height="40rem" />
          <Typography variant="pageHeader">Sign in</Typography>
          <Formik
            initialValues={{
              email: "",
              password: "",
              token: '',
            }}
            validationSchema={
              showEmail ? EmailValidationSchema : showPass ? PassValidationSchema : TokenValidationSchema
            }
            onSubmit={ (values, { setSubmitting }) => {
              if (showEmail) {
                values.email && setEmail(values.email)
                return dispatch(verifyEmail({ email: values.email })).then(
                  (res) => {
                    if (res?.payload?.data?.ok) {
                      setTimeout(() => {
                        dispatch(resetMessage());
                        handlePass();
                      }, 200);
                    }
                    if (res?.payload?.error) {
                      setTimeout(() => {
                        dispatch(resetErrMessage());
                      }, 200);
                    }
                    if (res?.payload?.warn) {
                      setTimeout(() => {
                        dispatch(resetErrMessage());
                      }, 200);
                    }
                  }
                );
              }
              if (showPass) {
                return dispatch(
                  login({
                    // email: values.email,
                    password: values.password,
                    token: loginToken,
                    // remember: true,
                    // rq: rq,
                  })
                ).then((res) => {
                  if (res?.payload?.data?.ok) {
                    setTimeout(() => {
                      // navigate("/exco/dashboard");
                      dispatch(resetMessage());
                      handleToken()
                    }, 100);
                  }
                  if (res?.payload?.error) {
                    setTimeout(() => {
                      dispatch(resetErrMessage());
                    }, 300);
                  }
                  if (res?.payload?.warn) {
                    setTimeout(() => {
                      dispatch(resetErrMessage());
                    }, 300);
                  }
                });
              }
              if (showToken) {
                return dispatch(
                  verifyToken({
                    // email: values.email,
                    token: values.token,
                    passToken: passwordToken,
                    // remember: true,
                    // rq: rq,
                  })
                ).then((res) => {
                  if (res?.payload?.data?.ok) {
                    setTimeout(() => {
                      navigate("/exco/dashboard");
                      dispatch(resetMessage());
                    }, 100);
                  }
                  if (res?.payload?.error) {
                    setTimeout(() => {
                      dispatch(resetErrMessage());
                    }, 300);
                  }
                  if (res?.payload?.warn) {
                    setTimeout(() => {
                      dispatch(resetErrMessage());
                    }, 300);
                  }
                });
              }

            }}
          >
            {({ errors, touched, handleChange, handleSubmit, values }) => (
               loading ? <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box> : 
              <form
                noValidate
                style={{ mt: 1 }}
                
                onSubmit={(e) => {
                  e.preventDefault()
                   handleSubmit()
                  }}
              >
                {showEmail &&  (
                  <MyTextInput
                    label="Email Address"
                    type="email"
                    name="email"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="email"
                    value={values?.email}
                    onChange={handleChange("email")}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    autoFocus
                  />
                )}
                {showPass &&  (
                  <MyTextInput
                    label="Password"
                    type="password"
                    name="password"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="password"
                    onChange={handleChange("password")}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    autoFocus
                  />
                )}
                {showToken &&  (
                  <div style={{ display: "flex" }}>
                  <div style={{ width: "25%", paddingRight: "0.5rem" }}>
                    <MyTextInput
                      // label="Dialing Code"
                      type="string"
                      name="baseString"
                      fullWidth={true}
                      margin="normal"
                      id="baseString"
                      value={baseString}
                      disabled
                    />
                  </div>
                  <div style={{ width: "75%" }}>
                  <MyTextInput
                    label="One Time Pin Found in Your Email"
                    type="text"
                    name="token"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="token"
                    onChange={handleChange("token")}
                    error={touched.token && Boolean(errors.token)}
                    helperText={touched.token && errors.token}
                    autoFocus
                  />
                  </div>
                </div>
                  
                )}
                {showEmail && (
                  <FormSubmitButton
                    type="submit"
                    fullWidth={true}
                    variant="contained"
                    className={classes.btnSubmit}
                  >
                    Next
                  </FormSubmitButton>
                )}
                {showPass && (
                  <FormSubmitButton
                    type="submit"
                    fullWidth={true}
                    variant="contained"
                    className={classes.btnSubmit}
                  >
                    Next
                  </FormSubmitButton>
                )}
                {showToken && (
                  <FormSubmitButton
                    type="submit"
                    fullWidth={true}
                    variant="contained"
                    className={classes.btnSubmit}
                  >
                    Sign In
                  </FormSubmitButton>
                )}
                {showPass && (
                  <Grid container>
                    <Grid item xs>
                      <Link href="/forgot-password" variant="body2">
                        Forgot password?
                      </Link>
                    </Grid>
                  </Grid>
                )}
              </form>
            )}
          </Formik>
        </Paper>
        <SocialMedia />
        <Copyright sx={{ mt: 8, mb: 4 }} />
        <Outlet />
        {errMessage && <ErrorNotifier message={errMessage} />}
        {message && <Notifier message={message} />}
      </Container>
    );
  
};

export default SignIn;
