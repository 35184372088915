import React from "react";
import { PageHeader, MyTextInput } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
// material ui
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Box,
  CardContent,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import { FormSubmitButton,CommonCurdPopUp } from "../../../../components";
import Notifier from "../../../../components/common/Notifier";
import ErrorNotifier from "../../../../components/common/ErrorNotifier";
// redux
import {
  createQuestionType,
  getQuestionType,
  resetMessage,
  resetErrMessage,
} from "../../../../features/config/questionTypeSlice";

// --------------------------------------------------------------

const validationSchema = Yup.object({
  questionType: Yup.string().required("Question Type is required"),
  responseType: Yup.string().required("Response Type is required"),
  comments: Yup.string(),
});

//   ------------------------------------------------------------------------------------------

const AddQuestionType = (props) => {
  const {  message, errMessage } = useSelector((state) => ({
    ...state.questionType,
  }));

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      questionType: "",
      responseType: "",
      comments: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        createQuestionType({
          questionTypeName: values.questionType,
          responseType: values.responseType,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.close();
            dispatch(getQuestionType());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  return (
    <>
   
   {props?.showAdd && <CommonCurdPopUp
        heading={`Create Question Type`}
        mediumScreen={true}
        close={props.close}
      >
        <Grid container >
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11} >

              <MyTextInput
                label="Question Type"
                type="text"
                name="questionType"
                required={true}
                fullWidth={true}
                margin="normal"
                id="questionType"
                value={formik.values.questionType}
                onChange={formik.handleChange}
                error={
                  formik.touched.questionType &&
                  Boolean(formik.errors.questionType)
                }
                helperText={
                  formik.touched.questionType &&
                  formik.errors.questionType
                }
              />
              <MyTextInput
                label="Response Type"
                type="text"
                name="responseType"
                required={true}
                fullWidth={true}
                margin="normal"
                id="responseType"
                value={formik.values.responseType}
                onChange={formik.handleChange}
                error={
                  formik.touched.responseType &&
                  Boolean(formik.errors.responseType)
                }
                helperText={
                  formik.touched.responseType &&
                  formik.errors.responseType
                }
              />
              <MyTextInput
                label="Comments"
                type="text"
                name="comments"
                fullWidth={true}
                margin="normal"
                id="comments"
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={
                  formik.touched.comments && Boolean(formik.errors.comments)
                }
                helperText={formik.touched.comments && formik.errors.comments}
              />
               </Grid>
          <Box style={{ width: "100%", textAlign: "center" }} >

            <FormSubmitButton onClick={formik?.handleSubmit} type="submit" variant="contained">
              Submit
            </FormSubmitButton>
          </Box>
        </Grid>

      </CommonCurdPopUp>}
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
  
    </>
  );
};

export default AddQuestionType;
