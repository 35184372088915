import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useLocation } from "react-router-dom";
// material ui
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
// icons
import SettingsIcon from "@mui/icons-material/Settings";
import VisibilityIcon from '@mui/icons-material/Visibility';
import HelpIcon from "@mui/icons-material/Help";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import ViewCozyIcon from '@mui/icons-material/ViewCozy';
import SummarizeIcon from "@mui/icons-material/Summarize";
import DeleteIcon from "@mui/icons-material/Delete";
// components
import Layout from "../../layout/Layout";
import {
  MuiDataGrid,
  GridCellExpand,
  ModalPopUp,
  FormSubmitButton,
  FormTextField as MyTextInput,
  Notifier,
  ErrorNotifier,
  ModalCloseBtn,
  ConfirmDialog,
  MuiTooltip,
  MuiAutocomplete,
  CommonCurdPopUp
} from "../../components";
import MicrositeConfirmDialog from "../customer_management/microsites/MicrositeConfirmDialog";
// redux
import {
  createAssessmentTemplate,
  createAssessmentTemplateByPreDefined,
  getAssessmentTemplate,
  updateAssessmentTemplate,
  deleteAssessmentTemplate,
  forceDeleteAssessmentTemplate,
  copyAssessmentTemplate,
  updateArchivedAssessmentTemplate,
  getAssessmentTemplateBycustomerId,
  getAssessmentTemplateByAccountId,
  getArchivedAssessmentTemplate,
  rowSelect,
  resetMessage,
  resetErrMessage,
  rowSelectEmpty
} from "../../features/prepare_assessments/prepareAssessmentSlice";
import { getPlatformStatus } from "../../features/config/platformStatusSlice";

import { getAssessmentTemplateConfig } from "../../features/config/assessmentTemplateConfigSlice";
import { getAssessmentCompletedFramework } from "../../features/config/assessmentFrameworkConfigSlice";

import { getCustomer } from "../../features/customer/customerSlice";
import { getAccount, getAccountById } from "../../features/customer/accountSlice";
import RadorGraph from "./radorGraph"

// ------------------------------------------------------------------------------

const validationSchema = Yup.object({
  templateDisplayName: Yup.string().required(
    "Template Display Name is required"
  ),
  templateName: Yup.string().required("Template Name is required"),
  description: Yup.string().required("Description is required"),
  comments: Yup.string(),

});

const validationSchemaPreDefine = Yup.object({
  custName: Yup.object().nullable().required("Customer Name is required"),
  accountName: Yup.object().nullable().required("Account Name is required"),
  preTemplateName: Yup.object()
    .nullable()
    .required("Assessment Framework is required"),
});

const editValidationSchema = Yup.object({
  templateDisplayName: Yup.string().required(
    "Template Display Name is required"
  ),
  custName: Yup.string().nullable().required("Customer Name is required"),
  accountName: Yup.string().nullable().required("Account Name is required"),
  templateName: Yup.string().required("Template Name is required"),
  status: Yup.string().required("Status is required"),
  description: Yup.string().required("Description is required"),
  comments: Yup.string().nullable(),
});

// -------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const useStyles = makeStyles((theme) => ({
  rootbox: {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: "56.25rem !important",
    [theme.breakpoints.down("sm")]: {
      width: "17.75rem !important",
      height: "44% !important",
      // display:'block !important',
      // overflow:'scroll !important',
      marginTop: "-8.25rem",
      marginLeft: "-0.938rem",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "24.375rem  !important",
      height: "34% !important",
      display: "block !important",
      overflow: "scroll !important",
      // margin: '-3.188rem',
      marginTop: "-3.75rem  !important",
      marginLeft: "-3.563rem",
      padding: "2.125rem !important",
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: "1.5rem !important",
    paddingTop: "1rem !important",
    paddingLeft: "2rem !important",
    paddingRight: "2rem !important",
    paddingBottom: "1.5rem",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },

  responsive_text: {
    width: "50rem",
    [theme.breakpoints.down("md")]: {
      width: "13rem",
    },
  },
}));
// ------------------------------------------------------------------------

const PrepareAssessments = (props) => {

  const [showAdd, setShowAdd] = useState(false);
  const [showGraph, setShowGraph] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [viewArchived, setViewArchived] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [forceDelConfirmDialog, setForceDelConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [copyLoading, setCopyLoading] = useState(false);
  const [templateCreation, setTemplateCreation] = useState("pre-defined");
  const [selectedCustValue, setSelectedCustValue] = useState(null)
  const [selectedAccountValue, setSelectedAccountValue] = useState(null)

  const dispatch = useDispatch();

  const location = useLocation();
  const { customersData } =
    useSelector((state) => ({ ...state.customer }));

  const { accountIdData, } = useSelector(
    (state) => ({
      ...state.account,
    })
  );



  const { message, errMessage, assessmentTemplateData, selectedData, } =
    useSelector((state) => ({ ...state.prepareAssessment }));
  const [selectStateData, setSelectStateData] = useState(selectedData)

  const { assessmentTemplateStatusData } = useSelector((state) => ({
    ...state.assessmentTemplateStatus,
  }));

  const { assessmentFrameworkCompletedData } =
    useSelector((state) => ({ ...state.assessmentFrameworkConfig }));


  const showAddModel = () => {
    setShowAdd(!showAdd);
  };

  const closeModel = () => {
    setShowAdd(false);
    const { accountName, custName } = formik?.values
    formik.resetForm({
      values: {
        accountName,
        custName,
        templateName: "",
        description: "",
        comments: "",
      },
    });
    setTemplateCreation("new");
  };

  const showEditModal = () => {
    setShowEdit(true);
    // if (selectedData?.assessmentCustomerAccount?.assessmentCustomer?.id) {
    //   dispatch(getAccountById({ assessmentCustomerId: selectedData?.assessmentCustomerAccount?.assessmentCustomer?.id }))
    // }
    // let resultOne = customersData?.find((dataTwo) => dataTwo?.id === selectedData?.assessmentCustomerAccount?.assessmentCustomer?.id)
    // formikEdit.setFieldValue("custName", resultOne)
  };

  const closeEditModal = () => {
    setShowEdit(false);

    formikEdit.resetForm({
      values: {

        templateName: "",
        description: "",
        comments: "",
        custName: "",
        accountName: "",
      },
    });

  };

  const showDelete = () =>
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => handleDeleteCustomer(),
    });

  const handleDeleteCustomer = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deleteAssessmentTemplate(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          selectedCustValue && !selectedAccountValue ?
            dispatch(getAssessmentTemplateBycustomerId({ assessmentCustomerId: selectedCustValue?.id }))
            : selectedAccountValue
              ? dispatch(getAssessmentTemplateByAccountId({ assessmentCustomerAccountId: selectedAccountValue?.id }))
              : dispatch(getAssessmentTemplate());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const showForceDelete = (dataId) =>
    setForceDelConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: (pass) => handleForceDeleteAssessmentTemplate(pass, dataId),
    });

  const handleForceDeleteAssessmentTemplate = (pass, dataId) => {
    let data = {
      id: dataId?.id,
      password: pass,
    };
    dispatch(forceDeleteAssessmentTemplate(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setForceDelConfirmDialog({
            ...forceDelConfirmDialog,
            isOpen: false,
          });
          selectedCustValue && !selectedAccountValue ?
            dispatch(getAssessmentTemplateBycustomerId({ assessmentCustomerId: selectedCustValue?.id }))
            : selectedAccountValue
              ? dispatch(getAssessmentTemplateByAccountId({ assessmentCustomerAccountId: selectedAccountValue?.id }))
              : dispatch(getAssessmentTemplate());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 2000);
      }
    });
  };

  const handleSetForceDelConfirmDialog = (obj) => {
    setForceDelConfirmDialog({
      ...forceDelConfirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const classes = useStyles();

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const data = {
    rows: assessmentTemplateData,
    columns: [

      {
        field: "assessmentTemplateName",
        headerName: "Assessment Template Name",
        width: 250,
        renderCell: renderCellExpand,
      },
      {
        field: "assessmentTemplateDisplayName",
        headerName: "Template Display Name",
        width: 250,
        renderCell: renderCellExpand,
      },
      {
        field: "status",
        headerName: "Template Status",
        width: 150,
        renderCell: renderCellExpand,
      },



      {
        field: "config",
        hide: viewArchived ? true : false,

        // headerName: 'Actions',
        type: "actions",
        width: 50,
        renderCell: (params) => {
          return (
            <MuiTooltip title="Sections & Dimensions" placement="right">
              {!params?.row?.isArchived && <Link
                to="/exco/prepare/section-dimension"
                state={{
                  templateSelectedData: params?.row,
                  permissions: location?.state?.permissions,
                }}
              >
                <IconButton sx={{ p: 0 }}>
                  <SettingsIcon style={{ color: "#808080" }} />
                </IconButton>
              </Link>}
            </MuiTooltip>
          );
        },
      },
      {
        field: "prepareQuestion",
        hide: viewArchived ? true : false,

        // headerName: 'Actions',
        type: "actions",
        width: 50,
        renderCell: (params) => {
          return (
            <MuiTooltip title="Questions" placement="right">
              {!params?.row?.isArchived && <Link
                to="/exco/prepare/prepare-questions"
                state={{
                  templateSelectedData: params?.row,
                  permissions: location?.state?.permissions,
                }}
              >
                <IconButton sx={{ p: 0 }}>
                  <HelpIcon style={{ color: "#808080" }} />
                </IconButton>
              </Link>}
            </MuiTooltip>
          );
        },
      },

      //  ...( (location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "cloneAssessTemp")) &&
      {
        field: "copyAssessment",
        hide: viewArchived ? true : false,
        headerName: location?.state?.permissions?.find(
          (e) =>
            e.applicationService?.name?.split(".")[
            e?.applicationService?.name?.split(".")?.length - 1
            ] === "cloneAssessTemp"
        ) ? "Clone" : "",
        type: "actions",

        width: 70,
        renderCell: (params) => {
          return (
            <MuiTooltip title="Clone Template" placement="right">
              <Link
                to="/exco/prepare"
                state={{
                  templateSelectedData: params?.row,
                  permissions: location?.state?.permissions,
                }}
              >
                {
                  location?.state?.permissions?.find(
                    (e) =>
                      e.applicationService?.name?.split(".")[
                      e?.applicationService?.name?.split(".")?.length - 1
                      ] === "cloneAssessTemp"
                  ) && !params?.row?.isArchived && (
                    <IconButton
                      sx={{ p: 0 }}
                      onClick={() =>
                        dispatch(
                          copyAssessmentTemplate({

                            id: params?.row?.id,
                            assessmentCustomerAccountId: params?.row?.
                              assessmentCustomerAccount?.id

                          })
                        ).then((res) => {
                          if (res?.payload?.data?.ok) {
                            setCopyLoading(true);
                            setTimeout(() => {
                              dispatch(resetMessage());
                              dispatch(getAssessmentTemplate());
                              setCopyLoading(false);
                            }, 2000);
                          }
                          if (res?.payload?.error) {
                            setTimeout(() => {
                              dispatch(resetErrMessage());
                            }, 3000);
                          }
                        })
                      }
                      disabled={copyLoading}
                    >
                      <FileCopyIcon style={{ color: "#808080" }} />
                    </IconButton>
                  )}
              </Link>
            </MuiTooltip>
          );
        },
      },
      {
        field: "archived",

        // headerName: 'Actions',
        type: "actions",
        width: 50,
        renderCell: (params) => {
          return (
            <MuiTooltip title={!params?.row?.isArchived ? "Archive" : "UnArchive"} placement="right">
              {!params?.row?.isArchived ? <IconButton
                onClick={() => dispatch(
                  updateArchivedAssessmentTemplate({

                    id: params?.row?.id,
                    isArchived: true

                  })
                ).then((res) => {
                  if (res?.payload?.data?.ok) {
                    // setCopyLoading(true);
                    setTimeout(() => {
                      dispatch(resetMessage());
                      dispatch(getAssessmentTemplate());
                      // setCopyLoading(false);
                    }, 2000);
                  }
                  if (res?.payload?.error) {
                    setTimeout(() => {
                      dispatch(resetErrMessage());
                    }, 3000);
                  }
                })}
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <ArchiveIcon className={classes.iconResponsive} />
              </IconButton> :
                <IconButton
                  onClick={() => dispatch(
                    updateArchivedAssessmentTemplate({

                      id: params?.row?.id,
                      isArchived: false

                    })
                  ).then((res) => {
                    if (res?.payload?.data?.ok) {
                      // setCopyLoading(true);
                      setTimeout(() => {
                        dispatch(resetMessage());
                        dispatch(getArchivedAssessmentTemplate());
                        // setCopyLoading(false);
                      }, 2000);
                    }
                    if (res?.payload?.error) {
                      setTimeout(() => {
                        dispatch(resetErrMessage());
                      }, 3000);
                    }
                  })}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <UnarchiveIcon className={classes.iconResponsive} />
                </IconButton>


              }
            </MuiTooltip>
          );
        },
      },
      // )

      {
        field: "forceDelete",
        // headerName: 'Clone',
        type: "actions",
        hide: viewArchived ? true : false,

        width: 70,
        renderCell: (params) => {
          return (
            <MuiTooltip title="Force Delete" placement="right">
              <Link
                to="/exco/prepare"
                state={{
                  templateSelectedData: params?.row,
                  permissions: location?.state?.permissions,
                }}
              >
                {location?.state?.permissions?.find(
                  (e) =>
                    e.applicationService?.name?.split(".")[
                    e?.applicationService?.name?.split(".")?.length - 1
                    ] === "forceDelAssessTemp"
                ) && !params?.row?.isArchived && (
                    <IconButton
                      sx={{ p: 0 }}
                      onClick={() => showForceDelete(params?.row)}
                    >
                      <DeleteIcon style={{ color: "#FF0000" }} />
                    </IconButton>
                  )}
              </Link>
            </MuiTooltip>
          );
        },
      },

      {
        field: "detailedDescription",
        headerName: "Description",
        width: 450,
        renderCell: renderCellExpand,
      },
      {
        field: "customer",
        headerName: "Customer Name",
        width: 200,
        valueGetter: (params) => params?.row?.assessmentCustomerAccount?.assessmentCustomer?.
          customerName,

        renderCell: renderCellExpand,
      },
      {
        field: "account",
        headerName: "Account Name",
        width: 200,
        valueGetter: (params) => params?.row?.assessmentCustomerAccount?.accountName,

        renderCell: renderCellExpand,
      },
    ],
  };

  const formik = useFormik({
    reset: true,
    initialValues: {
      customerSelectedData: "",
      accountSelectedData: "",
      templateName: "",
      templateDisplayName: "",
      description: "",
      comments: "",
      custName: "",
      accountName: "",
      preTemplateName: "",
    },

    validationSchema: validationSchemaPreDefine,
    onSubmit: (values, onSubmitProps) => {
      templateCreation === "new"
        ? dispatch(
          createAssessmentTemplate({
            assessmentCustomerAccountId: values?.accountName?.id,
            assessmentTemplateName: values.templateName,
            assessmentTemplateDisplayName: values.templateDisplayName,
            assessmentType: values?.templateType?.assessmentType,
            detailedDescription: values.description,
            status: "New",
            comments: values.comments,
          })
        ).then((res) => {
          if (res?.payload?.data?.ok) {
            setTimeout(() => {
              dispatch(resetMessage());
              closeModel();
              values?.customerSelectedData && !values?.accountSelectedData ?
                dispatch(getAssessmentTemplateBycustomerId({ assessmentCustomerId: values?.customerSelectedData?.id }))
                : values?.accountSelectedData
                  ? dispatch(getAssessmentTemplateByAccountId({ assessmentCustomerAccountId: values?.accountSelectedData?.id }))
                  : dispatch(getAssessmentTemplate());

              // dispatch(getAssessmentTemplate());
              onSubmitProps.resetForm();
            }, 2000);
          }
          if (res?.payload?.error) {
            setTimeout(() => {
              dispatch(resetErrMessage());
            }, 3000);
          }
        })
        : dispatch(
          createAssessmentTemplateByPreDefined({
            assessmentCustomerAccountId: values?.accountName?.id,
            id: values?.preTemplateName?.id,
          })
        ).then((res) => {
          if (res?.payload?.data?.ok) {
            setTimeout(() => {
              dispatch(resetMessage());
              closeModel();
              selectedCustValue && !selectedAccountValue ?
                dispatch(getAssessmentTemplateBycustomerId({ assessmentCustomerId: selectedCustValue?.id }))
                : selectedAccountValue
                  ? dispatch(getAssessmentTemplateByAccountId({ assessmentCustomerAccountId: selectedAccountValue?.id }))
                  : dispatch(getAssessmentTemplate());

              onSubmitProps.resetForm();
            }, 2000);
          }
          if (res?.payload?.error) {
            setTimeout(() => {
              dispatch(resetErrMessage());
            }, 3000);
          }
        });

    },
  });



  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      customerSelectedData: "",
      accountSelectedData: "",
      templateName: selectedData?.assessmentTemplateName,
      templateDisplayName: selectedData?.assessmentTemplateDisplayName,
      custName: selectedData?.assessmentCustomerAccount?.assessmentCustomer?.id,
      accountName: selectedData?.assessmentCustomerAccount?.id,
      description: selectedData?.detailedDescription,
      status: selectedData?.status,
      comments: selectedData?.comments,
    },

    validationSchema: editValidationSchema,
    onSubmit: (values) => {

      dispatch(
        updateAssessmentTemplate({
          id: selectedData?.id,
          assessmentTemplateName: values.templateName,
          assessmentCustomerAccountId: values?.accountName,
          assessmentTemplateDisplayName: values.templateDisplayName,
          detailedDescription: values.description,
          status: values?.status,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeEditModal();
            selectedCustValue && !selectedAccountValue ?
              dispatch(getAssessmentTemplateBycustomerId({ assessmentCustomerId: selectedCustValue?.id }))
              : selectedAccountValue
                ? dispatch(getAssessmentTemplateByAccountId({ assessmentCustomerAccountId: selectedAccountValue?.id }))
                : dispatch(getAssessmentTemplate());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });



  useEffect(() => {
    dispatch(getAssessmentCompletedFramework())
    dispatch(getAssessmentTemplate());
    dispatch(getPlatformStatus());
    dispatch(getCustomer())
    dispatch(getAssessmentTemplateConfig())
  }, []);



  useEffect(() => {
    if (formik.values?.custName?.id) {
      console.log("customer")
      dispatch(getAccountById({ assessmentCustomerId: formik.values?.custName?.id }))
    }
  }, [formik.values?.custName])

  useEffect(() => {
    console.log("customer one")
    dispatch(getAccountById({ assessmentCustomerId: formikEdit.values?.custName?.id }))
    // if (!formikEdit.values?.custName) {
    //   formikEdit.setFieldValue("accountName", "")
    //   formikEdit.setFieldTouched("accountName", true)
    // }
  }, [selectedData && formikEdit.values?.custName])




  useEffect(() => {
    if (selectedData?.assessmentCustomerAccount?.assessmentCustomer?.id) {
      dispatch(getAccountById({ assessmentCustomerId: selectedData?.assessmentCustomerAccount?.assessmentCustomer?.id }))
    }
  }, [selectedData])



  // useEffect(() => {
  //   if (!formikEdit?.values?.accountName) {
  //     let resultTwo = accountIdData?.find((dataThree) => dataThree?.id === selectedData?.assessmentCustomerAccount?.id)
  //     formikEdit.setFieldValue("accountName", resultTwo)
  //     formikEdit.setFieldTouched("accountName", true)
  //   }

  // }, [accountIdData])

  const getCustomerSelectedValue = (data) => {
    formik.setFieldValue("custName", data)
    setSelectedCustValue(data)
  }

  const getAccountSelectedValue = (data) => {
    formik.setFieldValue("accountName", data)
    setSelectedAccountValue(data)
  }

  const handleArchiveClick = () => {


    setViewArchived(!viewArchived);
    if (viewArchived === false) {
      dispatch(getArchivedAssessmentTemplate({ assessmentCustomerId: formik.values.custName?.id, assessmentCustomerAccountId: formik?.values?.accountName?.id }));
    }
 
    else {
      if (formik?.values?.custName && !formik?.values?.accountName) {
        dispatch(getAssessmentTemplateBycustomerId({ assessmentCustomerId: formik?.values?.custName?.id }))
      }
      if (formik?.values?.custName && formik?.values?.accountName) {
        dispatch(getAssessmentTemplateByAccountId({ assessmentCustomerAccountId: formik?.values?.accountName?.id }))
      }
      else {
        dispatch(getAssessmentTemplate())
      }

    }

  }



  return (
    <Layout>
      <>
        <label style={{ position: "absolute", right: 60, zIndex: 3 }}>
          <MuiTooltip title={!viewArchived ? "View Archived Records" : "View All"} placement="bottom">

            {!viewArchived ? <IconButton
              onClick={handleArchiveClick}
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <ArchiveIcon className={classes.iconResponsive} />
            </IconButton> :
              <IconButton
                onClick={handleArchiveClick}
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <ViewCozyIcon className={classes.iconResponsive} />
              </IconButton>


            }

          </MuiTooltip>
        </label>
        <PageHeader variant="pageHeader">
          Manage Assessment Templates
        </PageHeader>
        <MuiDataGrid

          setShowAdd={
            // location?.state?.permissions?.find(
            //   (e) =>
            //     e.applicationService?.name?.split(".")[
            //     e?.applicationService?.name?.split(".")?.length - 1
            //     ] === "createAssessTemp"
            // ) && 

            !viewArchived && showAddModel
          }
          setShowEdit={
            // location?.state?.permissions?.find(
            //   (e) =>
            //     e.applicationService?.name?.split(".")[
            //     e?.applicationService?.name?.split(".")?.length - 1
            //     ] === "updateAssessTemp"
            // ) &&

            !viewArchived && showEditModal
          }
          setShowDelete={
            // location?.state?.permissions?.find(
            //   (e) =>
            //     e.applicationService?.name?.split(".")[
            //     e?.applicationService?.name?.split(".")?.length - 1
            //     ] === "deleteAssessTemp"
            // ) && 
            !viewArchived && showDelete
          }
          getAccountSelectedValue={(data) => getAccountSelectedValue(data)}
          getCustomerSelectedValue={(data) => getCustomerSelectedValue(data)}
          getCallBackCustomer={(data) => viewArchived ?
            getArchivedAssessmentTemplate({
              assessmentCustomerId: data,
              assessmentCustomerAccountId: formik?.values?.accountName?.id
            })
            : getAssessmentTemplateBycustomerId({ assessmentCustomerId: data })}
          getCallBackOne={ !viewArchived ?  getAssessmentTemplate :  getArchivedAssessmentTemplate }
          getCallBackTwo={(data) => viewArchived ?
            getArchivedAssessmentTemplate({
              assessmentCustomerId: formik.values.custName?.id,
              assessmentCustomerAccountId: data
            })
            : getAssessmentTemplateByAccountId({ assessmentCustomerAccountId: data })}

          isAccount={true}
          isCustomer={true}
          // setShowAdd={showAddModel}
          // setShowEdit={showEditModal}
          // setShowDelete={showDelete
          // }

          toolbarColumn={true}
          // toolbarFilter={true}
          toolbarDensity={true}
          toolbarExport={true}
          data={data}
          select={(data) => dispatch(rowSelect(data))}
        />
      </>

      {showGraph && <CommonCurdPopUp
        heading="Graphical View"
        mediumScreen={true}
        close={
          () => setShowGraph(false)
        }
      >

        <RadorGraph frameworkSelectedData={formik.values.preTemplateName} />

      </CommonCurdPopUp>}






      {showAdd && <CommonCurdPopUp
        heading="Create Assessment Template "
        mediumScreen={true}
        close={
          closeModel
        }
      >


        {/* <ModalPopUp
        open={showAdd}
        handleClose={closeModel}
        closeModel={closeModel}
      >
        <Box
        
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={closeModel} />
          <div className={classes.responsive_text}> */}
        <Grid container >

          {/* <Grid item xs={12} sm={12} md={11} >
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={(e, value) => {
                setTemplateCreation(value)
               
              }}
              row
              defaultValue="new"
              tabIndex={9}
              className="res_radioMargin"
            >
             
              <FormControlLabel
                checked={templateCreation === "pre-defined"}
                sx={{ width: "18rem" }}
                value="pre-defined"
                control={<Radio />}
                label="Assign Framework "
              />
            </RadioGroup>
          </Grid> */}

          {/* {templateCreation === "new" ? (
            <Grid item xs={12} sm={12} md={8} margin="auto">

              <MuiAutocomplete

                name="custName"
                options={customersData}
                setValue={(name, newValue) => {

                  formik.setFieldValue(name, newValue)
                  formik.setFieldValue("accountName", "")
                  formik.setFieldTouched("accountName", true)
                }
                }
                value={formik.values.custName}
                optionValue="customerName"
                label="Customer"
                required={true}
                error={formik.touched.custName && Boolean(formik.errors.custName)}
                helperText={formik.touched.custName && formik.errors.custName}
              />

              <MuiAutocomplete

                name="accountName"
                options={accountIdData}
                setValue={(name, newValue) => {
                  setSelectStateData({})
                  formik.setFieldValue(name, newValue)


                }
                }
                value={formik.values.accountName}
                optionValue="accountName"
                label="Accounts"
                required={true}
                error={formik.touched.accountName && Boolean(formik.errors.accountName)}
                helperText={formik.touched.accountName && formik.errors.accountName}
              />
              <MyTextInput
                label="Template Name"
                type="text"
                name="templateName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="templateName"
                value={formik.values.templateName}
                onChange={formik.handleChange}
                tabIndex={1}
                error={
                  formik.touched.templateName &&
                  Boolean(formik.errors.templateName)
                }
                helperText={
                  formik.touched.templateName && formik.errors.templateName
                }
              />
              <MyTextInput
                label="Template Display Name"
                type="text"
                name="templateDisplayName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="templateDisplayName"
                value={formik.values.templateDisplayName}
                onChange={formik.handleChange}
                tabIndex={1}
                error={
                  formik.touched.templateDisplayName &&
                  Boolean(formik.errors.templateDisplayName)
                }
                helperText={
                  formik.touched.templateDisplayName &&
                  formik.errors.templateDisplayName
                }
              />
              <MyTextInput
                label="Description"
                type="comments"
                name="description"
                required={true}
                fullWidth={true}
                margin="normal"
                id="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />

            </Grid>) : ( */}

          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={11}
          // margin="auto"
          // sx={{ transition: "easing", transitionDuration: "60" }}
          >

            <MuiAutocomplete
              name="custName"
              options={customersData}
              setValue={(name, newValue) =>{
                formik.setFieldValue(name, newValue)
                formik.setFieldValue("accountName", "")
              }
               
              }
              value={formik.values.custName}
              optionValue="customerName"
              label="Customer"
              required={true}
              error={formik.touched.custName && Boolean(formik.errors.custName)}
              helperText={formik.touched.custName && formik.errors.custName}
            />

            <MuiAutocomplete
              name="accountName"
              options={accountIdData}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.accountName}
              optionValue="accountName"
              label="Accounts"
              required={true}
              error={formik.touched.accountName && Boolean(formik.errors.accountName)}
              helperText={formik.touched.accountName && formik.errors.accountName}
            />

            <Grid container>

              <Grid item sm={9} md={formik.values.preTemplateName ? 11 : 12}>


                <MuiAutocomplete
                  name="preTemplateName"
                  options={
                    assessmentFrameworkCompletedData?.length > 0 ?
                      assessmentFrameworkCompletedData : []

                  }
                  setValue={(name, newValue) => {
                    formik.setFieldValue(name, newValue);
                  }}
                  value={formik.values.preTemplateName}
                  optionValue="frameworkName"
                  label="Assessment Framework"
                  required={true}
                  error={
                    formik.touched.preTemplateName &&
                    Boolean(formik.errors.preTemplateName)
                  }
                  helperText={
                    formik.touched.preTemplateName &&
                    formik.errors.preTemplateName
                  }
                />

              </Grid>
              {formik.values.preTemplateName && (<Grid container item sm={3} md={1} alignContent="center" justifyContent="end"  >
                <Grid item>

                  <MuiTooltip title="View Framework" placement="bottom">
                    {/* <Link
                      to="/exco/config/assessment-framework/spider-report"
                      state={{
                        frameworkSelectedData: formik.values.preTemplateName,
                        permissions: location?.state?.permissions,
                        componentName: "PREPARE"
                      }}
                    > */}
                    <IconButton onClick={() => setShowGraph(true)} sx={{ p: 0 }}>
                      <VisibilityIcon style={{ color: "#00527d", fontSize: "35", marginTop: "1rem" }} />

                    </IconButton>

                    {/* </Link> */}
                  </MuiTooltip>

                </Grid>

              </Grid>)}

            </Grid>
            <Box style={{ width: "100%", textAlign: "center" }} mt={2}>

              <FormSubmitButton onClick={formik?.handleSubmit} type="submit" variant="contained">
                Submit
              </FormSubmitButton>
            </Box>
          </Grid>
          {/* )} */}
        </Grid>
      </CommonCurdPopUp>}
      {/* </div> */}

      {/* <FormSubmitButton type="submit" variant="contained">
        Submit
      </FormSubmitButton> */}
      {/* </Box>
      </ModalPopUp> */}
      {/* <ModalPopUp
        open={showEdit}
        handleClose={() => setShowEdit(false)}
        closeModel={() => setShowEdit(false)}
      >
        <Box
         
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formikEdit.handleSubmit}
        >
          <ModalCloseBtn close={() => setShowEdit(false)} />
          <div className={classes.responsive_text}> */}
      {showEdit && <CommonCurdPopUp
        heading="Update Assessment Template "
        mediumScreen={true}
        close={
          closeEditModal
        }
      >


        <Grid container>
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11} >
            <FormControl sx={{ marginTop: "1rem" }} fullWidth>
              <InputLabel id="demo-simple-select-label">
                Customer *
              </InputLabel>
              <Select
                disabled
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="custName"
                defaultValue={formikEdit.values.custName}
                value={formikEdit.values.custName}
                label="Customer"
                onChange={formikEdit.handleChange}
                error={
                  formikEdit.touched.custName &&
                  Boolean(formikEdit.errors.custName)
                }
              >
                {customersData.map((option) => {
                  return (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                    >
                      {option.customerName}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error={true} sx={{ color: "red" }}>
                {formikEdit.touched.custName && formikEdit.errors.custName}
              </FormHelperText>
            </FormControl>

            <FormControl sx={{ marginTop: "1rem" }} fullWidth>
              <InputLabel id="demo-simple-select-label">
                Accounts *
              </InputLabel>
              <Select
                disabled
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="accountName"
                defaultValue={formikEdit.values.accountName}
                value={formikEdit.values.accountName}
                label="Accounts"
                onChange={formikEdit.handleChange}
                error={
                  formikEdit.touched.accountName &&
                  Boolean(formikEdit.errors.accountName)
                }
              >
                {accountIdData?.map((option) => {
                  return (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                    >
                      {option.accountName}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error={true} sx={{ color: "red" }}>
                {formikEdit.touched.accountName && formikEdit.errors.accountName}
              </FormHelperText>
            </FormControl>

            {/* 
                <MuiAutocomplete
                  name="custName"
                  options={customersData}
                  setValue={(name, newValue) => {
                    formikEdit.setFieldValue(name, newValue)
                    formikEdit.setFieldValue("accountName", "")
                    
                  }

                  }
                  value={formikEdit.values.custName}
                  optionValue="customerName"
                  label="Customer"
                  required={true}
                  error={formikEdit.touched.custName && Boolean(formikEdit.errors.custName)}
                  helperText={formikEdit.touched.custName && formikEdit.errors.custName}
                />

                <MuiAutocomplete
                  name="accountName"
                  options={accountIdData}
                  setValue={(name, newValue) =>
                    formikEdit.setFieldValue(name, newValue)
                  }
                  value={formikEdit.values.accountName}
                  optionValue="accountName"
                  label="Accounts"
                  required={true}
                  error={formikEdit.touched.accountName && Boolean(formikEdit.errors.accountName)}
                  helperText={formikEdit.touched.accountName && formikEdit.errors.accountName}
                /> */}
            <MyTextInput
              label="Template Name"
              type="text"
              name="templateName"
              required={true}
              fullWidth={true}
              margin="normal"
              id="templateName"
              defaultValue={formikEdit.values.templateName}
              value={formikEdit.values.templateName}
              onChange={formikEdit.handleChange}
              error={
                formikEdit.touched.templateName &&
                Boolean(formikEdit.errors.templateName)
              }
              helperText={
                formikEdit.touched.templateName &&
                formikEdit.errors.templateName
              }
            />

            <MyTextInput
              label="Template Display Name"
              type="text"
              name="templateDisplayName"
              required={true}
              fullWidth={true}
              margin="normal"
              id="templateDisplayName"
              defaultValue={formikEdit.values.templateDisplayName}
              value={formikEdit.values.templateDisplayName}
              onChange={formikEdit.handleChange}
              error={
                formikEdit.touched.templateDisplayName &&
                Boolean(formikEdit.errors.templateDisplayName)
              }
              helperText={
                formikEdit.touched.templateDisplayName &&
                formikEdit.errors.templateDisplayName
              }
            />
            <FormControl sx={{ marginTop: "1rem" }} fullWidth>
              <InputLabel id="demo-simple-select-label">
                Template Status *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                defaultValue={formikEdit.values.status}
                value={formikEdit.values.status}
                label="Template Status"
                onChange={formikEdit.handleChange}
                error={
                  formikEdit.touched.status &&
                  Boolean(formikEdit.errors.status)
                }
              >
                {assessmentTemplateStatusData.map((option) => {
                  return (
                    <MenuItem
                      key={option.id}
                      value={option.assessmentTemplateStatus}
                    >
                      {option.assessmentTemplateStatus}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error={true} sx={{ color: "red" }}>
                {formikEdit.touched.status && formikEdit.errors.status}
              </FormHelperText>
            </FormControl>
            <MyTextInput
              label="Description"
              type="comments"
              name="description"
              fullWidth={true}
              margin="normal"
              id="description"
              value={formikEdit.values.description}
              onChange={formikEdit.handleChange}
              error={
                formikEdit.touched.description &&
                Boolean(formikEdit.errors.description)
              }
              helperText={
                formikEdit.touched.description &&
                formikEdit.errors.description
              }
            />
            {/* <MyTextInput
                  label="Comments"
                  type="comments"
                  name="comments"
                  fullWidth={true}
                  margin="normal"
                  id="comments"
                  value={formikEdit.values.comments}
                  onChange={formikEdit.handleChange}
                  error={
                    formikEdit.touched.comments &&
                    Boolean(formikEdit.errors.comments)
                  }
                  helperText={
                    formikEdit.touched.comments && formikEdit.errors.comments
                  }
                /> */}
            <Box style={{ width: "100%", textAlign: "center" }} mt={2} mb={10}>

              <FormSubmitButton onClick={formikEdit?.handleSubmit} type="submit" variant="contained">
                Submit
              </FormSubmitButton>
            </Box>
          </Grid>
        </Grid>
        {/* </div> */}





      </CommonCurdPopUp>}

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />

      <MicrositeConfirmDialog
        confirmDialog={forceDelConfirmDialog}
        setConfirmDialog={(obj) => handleSetForceDelConfirmDialog(obj)}
      />
      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>

  );
};

export default PrepareAssessments;
