import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_API_MANAGEMENT } from "../../../utils/routes";

// ---------------------------------------------------------------------

export const createApiManagement = createAsyncThunk(
  "apiManagement/createApiManagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_API_MANAGEMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getApiManagement = createAsyncThunk(
  "apiManagement/getApiManagement",
  async (obj, {rejectWithValue}) => {
    try {
      const response = await axios.get(CURD_API_MANAGEMENT, { withCredentials: true });
      return response;
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
);

export const updateApiManagement = createAsyncThunk(
  "apiManagement/updateApiManagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_API_MANAGEMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteApiManagement = createAsyncThunk(
  "apiManagement/deleteApiManagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_API_MANAGEMENT, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const apiManagementSlice = createSlice({
  name: "apiManagement",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    apiManagementData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createApiManagement.pending]: (state, action) => {
      state.loading = true;
    },
    [createApiManagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createApiManagement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getApiManagement.pending]: (state, action) => {
      state.loading = true;
    },
    [getApiManagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.apiManagementData = action?.payload?.data;
    },
    [getApiManagement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateApiManagement.pending]: (state, action) => {
      state.loading = true;
    },
    [updateApiManagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateApiManagement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteApiManagement.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteApiManagement.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteApiManagement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  apiManagementSlice.actions;
export default apiManagementSlice.reducer;
