import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { PageHeader } from '@excoleadershipui/pionweblib';
import { useDispatch } from 'react-redux';
// material ui
import { Grid } from '@mui/material'
// material icon
import CancelIcon from "@mui/icons-material/Cancel";
// components
import AssessmentTemplateDimension from './AssessmentTemplateDimension'
import AssessmentTemplateSection from './AssessmentTemplateSection'
import Layout from '../../../layout/Layout';

import { sectionRowSelect, rowSelect } from '../../../features/prepare_assessments/assessmentTemplateSectionSlice';

const ConfigureAssessmentTemplate = (props) => {

  const dispatch = useDispatch()

  useEffect(() => {
    try {

      dispatch(rowSelect({}));
      dispatch(sectionRowSelect({}));

    } catch (err) {
      
    }
  }, []);

  const location = useLocation();

  return (
    <Layout>
      <Link to="/exco/prepare" state={{ permissions: location?.state?.permissions }} >
        <CancelIcon color="red" sx={{ position: "absolute", right: 30, zIndex: 1 }} />
      </Link>
      <PageHeader variant="pageHeader">

      </PageHeader>
      <Grid container spacing={2} mt-4>

        <Grid item xs={12} sm={12} md={6} >
          <AssessmentTemplateSection permissions={location?.state?.permissions} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} >
          <AssessmentTemplateDimension permissions={location?.state?.permissions} />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default ConfigureAssessmentTemplate