import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_ASSESSMENT_FRAMEWORK_SECTION, GET_ASSESSMENT_FRAMEWORK_SECTION_BY_FRAMEWORK_ID } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createAssessmentFrameworkSectionConfig = createAsyncThunk(
  "assessmentFrameworkSectionConfig/createAssessmentFrameworkSectionConfig",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_ASSESSMENT_FRAMEWORK_SECTION, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAssessmentFrameworkSectionConfig = createAsyncThunk(
  "assessmentFrameworkSectionConfig/getAssessmentFrameworkSectionConfig",
  async (data, {rejectWithValue}) => {
    try {
      const response = await axios.post(GET_ASSESSMENT_FRAMEWORK_SECTION_BY_FRAMEWORK_ID, data, { withCredentials: true });
      return response
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
);

export const updateAssessmentFrameworkSectionConfig = createAsyncThunk(
  "assessmentFrameworkSectionConfig/updateAssessmentFrameworkSectionConfig",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_ASSESSMENT_FRAMEWORK_SECTION, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAssessmentFrameworkSectionConfig = createAsyncThunk(
  "assessmentFrameworkSectionConfig/deleteAssessmentFrameworkSectionConfig",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_ASSESSMENT_FRAMEWORK_SECTION, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const assessmentFrameworkSectionConfigSlice = createSlice({
  name: "assessmentFrameworkSectionConfig",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    assessmentFrameworkSectionConfigData: [],
    selectedData: {},
    selectedSectionData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
    sectionRowSelect: (state, action) => {
      state.selectedSectionData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createAssessmentFrameworkSectionConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [createAssessmentFrameworkSectionConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createAssessmentFrameworkSectionConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getAssessmentFrameworkSectionConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentFrameworkSectionConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentFrameworkSectionConfigData = action?.payload?.data;
    },
    [getAssessmentFrameworkSectionConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateAssessmentFrameworkSectionConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [updateAssessmentFrameworkSectionConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateAssessmentFrameworkSectionConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteAssessmentFrameworkSectionConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteAssessmentFrameworkSectionConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteAssessmentFrameworkSectionConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect,sectionRowSelect } =
  assessmentFrameworkSectionConfigSlice.actions;
export default assessmentFrameworkSectionConfigSlice.reducer;
