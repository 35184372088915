import React from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
const ModalCloseBtn = (props) => {
  const useStyles = makeStyles((theme) => ({

    close_btn : {
      [theme.breakpoints.down('md')]: {
      position:'absolute',
      
      top:'0.375rem',
      left:'-1.6rem'
      },
    }
    
      }))
      const classes = useStyles();
  return (
    <IconButton
      sx={{ position: "absolute", left: "94%", top: 0 }}
      
      onClick={() => props.close()}
    >
      <CloseIcon color="red" className={classes.close_btn}/>
    </IconButton>
  );
};

export default ModalCloseBtn;
