import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useLocation } from "react-router-dom";
// material ui
import { makeStyles } from "@mui/styles";
import {
  Box,
  Stack,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import Layout from "../../layout/Layout";
import {
  MuiDataGrid,
  GridCellExpand,
  ModalPopUp,
  FormSubmitButton,
  FormTextField as MyTextInput,
  Notifier,
  ErrorNotifier,
  ModalCloseBtn,
  ConfirmDialog,
  MuiAutocomplete,
  AppPageHeader,
  PageHeader,
  CommonCurdPopUp
} from "../../components";
// redux
import {
  createAssessmentTemplateQuestionMain,
  getAssessmentTemplateQuestionBySectionIdMain,
  updateAssessmentTemplateQuestionMain,
  deleteAssessmentTemplateQuestionMain,
  downloadPrepareQuestionsTemplate,
  uploadPrepareQuestionsTemplate,
  rowSelect,
  resetMessage,
  resetErrMessage,
} from "../../features/prepare_assessments/prepareQuestionSlice";
import { getAssessmentTemplateSection } from "../../features/prepare_assessments/assessmentTemplateSectionSlice";
import { getAssessmentTemplateDimension } from "../../features/prepare_assessments/assessmentTemplateDimensionSlice";
import { getQuestionType } from "../../features/config/questionTypeSlice";
import { textChecker } from "../../components/common/form_validation/regularExpressions";
import { textFieldErr } from "../../components/common/form_validation/validationErrMessages";
import { checkObjectLength } from "../../components/common/checkObjectLength/checkObjectLength";

// ------------------------------------------------------------------------------

const validationSchema = Yup.object({
  questionNo: Yup.number().required("Question Number is required"),
  questionType: Yup.object().required("Question Type is required"),
  question: Yup.string().required("Assessor Question is required"),
  clientQuestion : Yup.string().required("Client Question is required"),
  // .matches(textChecker, textFieldErr),
  // clientQuestion: Yup.string().when([], {
  //   is: () =>
  //     location?.templateSelectedData?.assessmentType?.toLowerCase() ===
  //     "talent",
  //   then: Yup.string().required("Client Question is required"),
  // }),
  // standardValue: Yup.number().when("questionType", {
  //   is: (value) =>
  //     location?.templateSelectedData?.assessmentType?.toLowerCase() ===
  //       "maturity" && value?.toLowerCase() === "rating",
  //   then: Yup.number().required("Standard Value is required"),
  // }),
  // targetValue: Yup.number().when("questionType", {
  //   is: (value) =>
  //     location?.templateSelectedData?.assessmentType?.toLowerCase() ===
  //       "maturity" && value?.toLowerCase() === "rating",
  //   then: Yup.number(),
  // }),
});

const editValidationSchema = Yup.object({
  questionNo: Yup.number().required("Question Number is required"),
  questionType: Yup.string().required("Question Type is required"),
  question: Yup.string().required("Assessor Question is required"),
  clientQuestion : Yup.string().required("Client Question is required"),
  // .matches(textChecker, textFieldErr),
  // clientQuestion: Yup.string()
  //   .when([], {
  //     is: () =>
  //       location?.templateSelectedData?.assessmentType?.toLowerCase() ===
  //       "talent",
  //     then: Yup.string().required("Client Question is required"),
  //   })
  //   .nullable(),
  // standardValue: Yup.number()
  //   .when("questionType", {
  //     is: (value) =>
  //       location?.templateSelectedData?.assessmentType?.toLowerCase() ===
  //         "maturity" && value?.toLowerCase() === "rating",
  //     then: Yup.number().required("Standard Value is required"),
  //   })
  //   .nullable(),
  // targetValue: Yup.number()
  //   .when("questionType", {
  //     is: (value) =>
  //       location?.templateSelectedData?.assessmentType?.toLowerCase() ===
  //         "maturity" && value?.toLowerCase() === "rating",
  //     then: Yup.number(),
  //   })
  //   .nullable(),
});

// -------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ------------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  rootbox: {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: "56.25rem !important",
    [theme.breakpoints.down("sm")]: {
      width: "20rem !important",
      height: "60% !important",
      display: "block !important",
      overflow: "scroll !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "33.375rem !important",
      height: "49% !important",
      display: "block !important",
      overflow: "scroll !important",
      // margin: '-3.188rem',
      marginTop: "-3.75rem  !important",
      marginLeft: "-3.563rem",
      padding: "2.125rem !important",
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: "1.5rem !important",
    paddingTop: "1rem !important",
    paddingLeft: "2rem !important",
    paddingRight: "2rem !important",
    paddingBottom: "1.5rem",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },

  iconResponsive: {
    [theme.breakpoints.down("md")]: {
      marginRight: "-4.5rem",
    },
  },
  text_responsive: {
    width: "40rem",
    [theme.breakpoints.down("md")]: {
      width: "17rem",
    },
  },
  responsive_close: {
    [theme.breakpoints.down("md")]: {
      marginTop: "2.063rem",
      marginRight: "-3.688rem",
    },
  },
}));

const PrepareSectionQuestions = (props) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const dispatch = useDispatch();

  const location = useLocation();

  const { message, errMessage, assessmentTemplateQuestionData, selectedData } =
    useSelector((state) => ({ ...state.assessmentTemplateQuestion }));

  const { assessmentTemplateSectionData } = useSelector((state) => ({
    ...state.assessmentTemplateSection,
  }));
  const { assessmentTemplateDimensionData } = useSelector((state) => ({
    ...state.assessmentTemplateDimension,
  }));
  const { questionTypeData } = useSelector((state) => ({
    ...state.questionType,
  }));
  const { permissionsData } = useSelector((state) => ({
    ...state.permissions,
  }));
  const showAddModel = () => {
    setShowAdd(!showAdd);
  };

  const closeModel = () => {
    setShowAdd(false);
    formik.resetForm({
      values: {
        questionNo: "",
        questionType: "",
        question: "",
        clientQuestion: "",
        isRequiredForClientOrNot: true,
      },
    });
  };

  const showEditModal = () => {
    setShowEdit(true);
    formikEdit.resetForm({
      values: {
        questionNo: "",
        questionType: "",
        question: "",
        clientQuestion: "",
        isRequiredForClientOrNot: true,
      },
    });
  };

  const closeEditModal = () => {
    setShowEdit(false);
  };

  const showDelete = () =>
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => handleDeleteCustomer(),
    });

  const handleDeleteCustomer = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deleteAssessmentTemplateQuestionMain(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(
            getAssessmentTemplateQuestionBySectionIdMain({
              assessmentSectionId: location?.state?.sectionSelectedData?.id,
            })
          );
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  function getSectionName(params) {
    return params?.row?.assessmentSection?.sectionName;
  }

  function getDimensionName(params) {
    return params?.row?.assessmentDimension?.dimensionName;
  }

  function getQuestionNo(params) {
    if (
      params?.row?.assessmentSection?.sectionNo &&
      params?.row?.assessmentDimension?.dimensionNo
    ) {
      return `${params?.row?.assessmentSection?.sectionNo}.${params?.row?.assessmentDimension?.dimensionNo}.${params?.row?.questionNo}`;
    } else if (params?.row?.assessmentSection?.sectionNo) {
      return `${params?.row?.assessmentSection?.sectionNo}.${params?.row?.questionNo}`;
    } else if (params?.row?.assessmentDimension?.dimensionNo) {
      return `${params?.row?.assessmentDimension?.dimensionNo}.${params?.row?.questionNo}`;
    } else {
      return params?.row?.questionNo;
    }
  }

  function getIsRequiredForClientOrNot(params) {
    return params.row.isRequiredForClientOrNot ? "Yes" : "No";
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const [sortModel, setSortModel] = useState([
    { field: "questionNo", sort: "asc" },
  ]);
  const data = {
    rows: assessmentTemplateQuestionData,
    columns: [
      {
        field: "sectionName",
        headerName: "Section Name",
        width: 200,
        valueGetter: (params)=> params?.row?.assessmentSection?.sectionName,
        sortable: true,
      },
      {
        field: "questionNo",
        headerName: "Question No",
        width: 90,
        valueGetter: getQuestionNo,
        sortable: true,
      },
      {
        field: "questionName",
        headerName: "Assessor Question",
        width: 300,
        renderCell: renderCellExpand,
      },
      {
        field: "clientQuestion",
        headerName: "Client Question",
        width: 300,
        renderCell: renderCellExpand,
      },

      {
        field: "questionType",
        headerName: "Question Type",
        width: 120,
        renderCell: renderCellExpand,
      },
      {
        field: "isRequiredForClientOrNot",
        headerName: "Required For Client",
        width: 140,

        valueGetter: getIsRequiredForClientOrNot,
      },
    ],
  };



  const formik = useFormik({
    reset: true,
    initialValues: {
      questionNo: "",
      questionType: "",
      question: "",
      clientQuestion: "",
      standardValue: "",
      targetValue: "",
      isRequiredForClientOrNot: true,
    },

    validationSchema: validationSchema,
    onSubmit: (values, onSubmitProps) => {
      dispatch(
        createAssessmentTemplateQuestionMain({
          assessmentTemplateId: location?.state?.templateSelectedData?.id,
          assessmentSectionId: location?.state?.sectionSelectedData?.id,
          questionNo: values.questionNo,
          questionType: values?.questionType?.questionTypeName,
          questionName: values.question,
          clientQuestion: values.clientQuestion,


          isRequiredForClientOrNot: values.isRequiredForClientOrNot

        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeModel();
            dispatch(
              getAssessmentTemplateQuestionBySectionIdMain({
                assessmentSectionId: location?.state?.sectionSelectedData?.id,
              })
            );
            // onSubmitProps.resetForm();
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      questionNo: selectedData?.questionNo,
      questionType: selectedData?.questionType,
      question: selectedData?.questionName,
      clientQuestion: selectedData?.clientQuestion,
      standardValue: selectedData?.standardValue,
      targetValue: selectedData?.standardValue,
      isRequiredForClientOrNot: selectedData?.isRequiredForClientOrNot,
    },

    validationSchema: editValidationSchema,
    onSubmit: (values) => {
      dispatch(
        updateAssessmentTemplateQuestionMain({
          id: selectedData?.id,
          assessmentTemplateId: location?.state?.templateSelectedData?.id,
          assessmentSectionId: location?.state?.sectionSelectedData?.id,
          assessmentDimensionId: checkObjectLength(
            selectedData?.assessmentDimension
          ) ? selectedData?.assessmentDimension?.id : null,
          questionNo: values?.questionNo,
          questionType: values?.questionType,
          questionName: values?.question,
          clientQuestion: values?.clientQuestion,

          isRequiredForClientOrNot: values?.isRequiredForClientOrNot

        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeEditModal();
            dispatch(
              getAssessmentTemplateQuestionBySectionIdMain({
                assessmentSectionId: location?.state?.sectionSelectedData?.id,
              })
            );
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    let data = {
      assessmentSectionId: location?.state?.sectionSelectedData?.id,
    };
    // dispatch(getAssessmentTemplateSection(data));
    // dispatch(getAssessmentTemplateDimension(data));
    dispatch(getAssessmentTemplateQuestionBySectionIdMain(data));
    dispatch(getQuestionType());
  }, []);

  const handleDownloadTemplete = () => {
    dispatch(downloadPrepareQuestionsTemplate()).then((res) => {
      if (res?.payload?.data?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 2000);
      }
    });
  };

  const handleUpload = (e) => {
    try {
      let formdata = new FormData();
      // formdata.append("projectInfoId", props?.myProject?.selectedProject?.id);
      props?.myProject?.selectedApplication?.id &&
        formdata.append("appInfoId", props?.myProject?.selectedApplication?.id);
      props?.myProject?.selectedModule?.id &&
        formdata.append("moduleInfoId", props?.myProject?.selectedModule?.id);
      formdata.append("myfile", e.target.files[0]);

      dispatch(uploadPrepareQuestionsTemplate(formdata)).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 2000);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const classes = useStyles();

  let shrink = props.sidebar ? "scale" : "no-scale";

  return (
    <Layout>
      <AppPageHeader
        variant="pageHeader"
        pathname="/exco/prepare/section-dimension"

        permissions={location?.state?.permissions}
        serviceAccessData={location?.state?.serviceAccessData}
        templateSelectedData={location?.state?.templateSelectedData}
      >
        Prepare Questions For{" "}
        {
          location?.state?.sectionSelectedData?.sectionName
        }
        {/* {location?.state?.dimensionSelectedData?.sectionName} */}
      </AppPageHeader>

      {/* <Stack flexDirection="row" justifyContent="space-between">
        <Box sx={{ width: "100%" }}>
          <h5 className="center bold pt-2 res_margin_top">
            Prepare Questions For {location?.sectionSelectedData?.sectionName}
          </h5>
        </Box>
        <Link
          to={{
            pathname: "/piedap/assessment360/prepare/section-dimension",
            serviceAccessData: location?.permissions,
            templateSelectedData: location?.templateSelectedData,
          }}
        >
          <CancelIcon
            sx={{
              position: "absolute",
              right: 15,
              color: "red !important",
            }}
          />
        </Link>
      </Stack> */}
      <>
        <MuiDataGrid
          setShowAdd={
            // Boolean(
            //   permissionsData?.assessment?.prepare?.prepareAssessmentQuestion
            //     ?.create
            // ) && 

            showAddModel
          }
          setShowEdit={
            // Boolean(
            //   permissionsData?.assessment?.prepare?.prepareAssessmentQuestion
            //     ?.update
            // ) && 
            showEditModal
          }
          setShowDelete={
            // Boolean(
            //   permissionsData?.assessment?.prepare?.prepareAssessmentQuestion
            //     ?.delete
            // ) &&
            showDelete
          }
          // setShowAdd={
          //   location?.permissions?.userRoleL2ServicePermission?.length > 0 &&
          //   location?.permissions?.userRoleL2ServicePermission?.find(
          //     (e) =>
          //       e.applicationService?.name?.split(".")[
          //         e?.applicationService?.name?.split(".")?.length - 1
          //       ] === "createAssesQn"
          //   ) &&
          //   showAddModel
          // }
          // setShowEdit={
          //   location?.permissions?.userRoleL2ServicePermission?.length > 0 &&
          //   location?.permissions?.userRoleL2ServicePermission?.find(
          //     (e) =>
          //       e.applicationService?.name?.split(".")[
          //         e?.applicationService?.name?.split(".")?.length - 1
          //       ] === "updateAssesQn"
          //   ) &&
          //   showEditModal
          // }
          // setShowDelete={
          //   location?.permissions?.userRoleL2ServicePermission?.length > 0 &&
          //   location?.permissions?.userRoleL2ServicePermission?.find(
          //     (e) =>
          //       e.applicationService?.name?.split(".")[
          //         e?.applicationService?.name?.split(".")?.length - 1
          //       ] === "deleteAssesQn"
          //   ) &&
          //   showDelete
          // }
          toolbarColumn={true}
          // toolbarFilter={true}
          toolbarDensity={true}
          toolbarExport={true}
          toolbarUpload={true}
          handleUpload={handleUpload}
          toolbarPageObjectDownload={true}
          handlePageObjectDownload={handleDownloadTemplete}
          data={data}
          select={(data) => dispatch(rowSelect(data))}
          // For Sorting
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        />
      </>
      {/* <ModalPopUp
        open={showAdd}
        handleClose={closeModel}
        closeModel={closeModel}
      >
        <Box
        
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={closeModel} />
          <h5 className="center bold pt-2 res_margin_top">
            Add Question For  {
            location?.state?.sectionSelectedData?.sectionName
          }
          </h5>
          <div className={classes.responsive_text}> */}

      {showAdd && <CommonCurdPopUp
        heading={`Add Question For ${location?.state?.sectionSelectedData?.sectionName
          }`}
        mediumScreen={true}
        close={
          closeModel
        }
      >
        <Grid container >
          <Grid item xs={12} sm={12} md={0.5} ></Grid>
          <Grid item xs={12} sm={12} md={11} container spacing={1}  >
            <Grid item xs={12} sm={12} md={6} >
              <MyTextInput
                label="Question No."
                type="number"
                name="questionNo"
                required={true}
                fullWidth={true}
                margin="normal"
                id="questionNo"
                value={formik.values.questionNo}
                // onChange={formik.handleChange}
                onChange={(e) => {
                  const inputValue = e.target.value;

                  // Use a regular expression to check if the input is a number
                  if (/^\d*$/.test(inputValue)) {
                    // If it's a number, update the field value
                    formik.handleChange(e);
                  }
                  // You can also handle invalid input here if needed
                }}
                error={
                  formik.touched.questionNo &&
                  Boolean(formik.errors.questionNo)
                }
                helperText={
                  formik.touched.questionNo && formik.errors.questionNo
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} >
              <MuiAutocomplete
                name="questionType"
                options={questionTypeData}
                setValue={(name, newValue) =>
                  formik.setFieldValue(name, newValue)
                }
                value={formik.values.questionType}
                optionValue="questionTypeName"
                optionSecValue="comments"
                label="Question Type"
                required={true}
                error={
                  formik.touched.questionType &&
                  Boolean(formik.errors.questionType)
                }
                helperText={
                  formik.touched.questionType && formik.errors.questionType
                }
              />
            </Grid>
            <Grid item xs={11} md={12}>
              <MyTextInput
                label="Assessor Question"
                type="text"
                name="question"
                required={true}
                fullWidth={true}
                margin="normal"
                id="question"
                multiline={true}
                maxRows={5}
                value={formik.values.question}
                onChange={formik.handleChange}
                error={
                  formik.touched.question && Boolean(formik.errors.question)
                }
                helperText={formik.touched.question && formik.errors.question}
              />
            </Grid>
            {location?.templateSelectedData?.assessmentType?.toLowerCase() ===
              "maturity" &&
              formik?.values?.questionType?.questionTypeName?.toLowerCase() ===
              "rating" && (
                <>
                  <Grid item xs={12} sm={12} md={6}>
                    <MyTextInput
                      label="Standard Value"
                      type="number"
                      name="standardValue"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="standardValue"
                      value={formik.values.standardValue}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.standardValue &&
                        Boolean(formik.errors.standardValue)
                      }
                      helperText={
                        formik.touched.standardValue &&
                        formik.errors.standardValue
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MyTextInput
                      label="Target Value"
                      type="number"
                      name="targetValue"
                      required={false}
                      fullWidth={true}
                      margin="normal"
                      id="targetValue"
                      value={formik.values.targetValue}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.targetValue &&
                        Boolean(formik.errors.targetValue)
                      }
                      helperText={
                        formik.touched.targetValue &&
                        formik.errors.targetValue
                      }
                    />
                  </Grid>
                </>
              )}

            <Grid item xs={12} container spacing={1}>
              <Grid item xs={12} sm={12} md={9}>
                <MyTextInput
                  label="Client Question"
                  type="text"
                  name="clientQuestion"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="clientQuestion"
                  value={formik.values.clientQuestion}
                  onChange={formik.handleChange}
                  // onFocus={() =>
                  //   formik.setFieldValue(
                  //     "clientQuestion",
                  //     formik?.values?.question
                  //   )
                  // }
                  error={
                    formik.touched.clientQuestion &&
                    Boolean(formik.errors.clientQuestion)
                  }
                  helperText={
                    formik.touched.clientQuestion &&
                    formik.errors.clientQuestion
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Required For Client
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="isRequiredForClientOrNot"
                    value={formik.values.isRequiredForClientOrNot}
                    label="Required For Client"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.isRequiredForClientOrNot &&
                      Boolean(formik.errors.isRequiredForClientOrNot)
                    }
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {formik.touched.isRequiredForClientOrNot &&
                      formik.errors.isRequiredForClientOrNot}
                  </FormHelperText>
                </FormControl>
              </Grid>


            </Grid>
            <Box style={{ width: "100%", textAlign: "center" }} mt={2}>

              <FormSubmitButton onClick={formik?.handleSubmit} type="submit" variant="contained">
                Submit
              </FormSubmitButton>
            </Box>
          </Grid>
        </Grid>

      </CommonCurdPopUp>}


      {showEdit && <CommonCurdPopUp
        heading={`  Update Question For ${location?.state?.sectionSelectedData?.sectionName
          }`}
        mediumScreen={true}
        close={closeEditModal}
      >
        <Grid container >
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11} container spacing={1}  >
          <Grid item xs={12} sm={12} md={6}>
            <MyTextInput
              label="Question No *"
              type="number"
              name="questionNo"
              fullWidth={true}
              margin="normal"
              id="questionNo"
              value={formikEdit.values.questionNo}
              // onChange={formikEdit.handleChange}
              onChange={(e) => {
                const inputValue = e.target.value;

                // Use a regular expression to check if the input is a number
                if (/^\d*$/.test(inputValue)) {
                  // If it's a number, update the field value
                  formikEdit.handleChange(e);
                }
                // You can also handle invalid input here if needed
              }}
              disabled={
                location?.state?.templateSelectedData?.assessmentTracking &&
                location?.state?.templateSelectedData?.assessmentTracking
                  ?.assessmentStatus === "Published"
              }
              error={
                formikEdit.touched.questionNo &&
                Boolean(formikEdit.errors.questionNo)
              }
              helperText={
                formikEdit.touched.questionNo &&
                formikEdit.errors.questionNo
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl sx={{ marginTop: "1rem" }} fullWidth>
              <InputLabel id="demo-simple-select-label">
                Question Type *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="questionType"
                defaultValue={formikEdit.values.questionType}
                value={formikEdit.values.questionType}
                label="Question Type"
                onChange={formikEdit.handleChange}
                disabled={
                  location?.state?.templateSelectedData
                    ?.assessmentTracking &&
                  location?.state?.templateSelectedData?.assessmentTracking
                    ?.assessmentStatus === "Published"
                }
                error={
                  formikEdit.touched.questionType &&
                  Boolean(formikEdit.errors.questionType)
                }
              >
                {questionTypeData.map((option) => {
                  return (
                    <MenuItem
                      key={option.id}
                      value={option.questionTypeName}
                    >
                      {option.questionTypeName}-{option.comments}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error={true} sx={{ color: "red" }}>
                {formikEdit.touched.questionType &&
                  formikEdit.errors.questionType}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <MyTextInput
              label="Assessor Question *"
              type="text"
              name="question"
              fullWidth={true}
              margin="normal"
              id="question"
              value={formikEdit.values.question}
              onChange={formikEdit.handleChange}
              multiline={true}
              maxRows={5}
              error={
                formikEdit.touched.question &&
                Boolean(formikEdit.errors.question)
              }
              helperText={
                formikEdit.touched.question && formikEdit.errors.question
              }
            />
          </Grid>


          <Grid item xs={12} container spacing={1}>
            <Grid item xs={12} sm={12} md={9}>
              <MyTextInput
                label="Client Question"
                type="text"
                name="clientQuestion"
                required={true}
                fullWidth={true}
                margin="normal"
                id="clientQuestion"
                value={formikEdit.values.clientQuestion}
                onChange={formikEdit.handleChange}
                error={Boolean(formikEdit.errors.clientQuestion)}
                helperText={formikEdit.errors.clientQuestion}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Required For Client
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="isRequiredForClientOrNot"
                  defaultValue={
                    formikEdit.values.isRequiredForClientOrNot
                  }
                  value={formikEdit.values.isRequiredForClientOrNot}
                  label="Required For Client"
                  onChange={formikEdit.handleChange}
                  error={
                    formikEdit.touched.isRequiredForClientOrNot &&
                    Boolean(formikEdit.errors.isRequiredForClientOrNot)
                  }
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formikEdit.touched.isRequiredForClientOrNot &&
                    formikEdit.errors.isRequiredForClientOrNot}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Box style={{ width: "100%", textAlign: "center" }} mt={2}>

              <FormSubmitButton onClick={formikEdit?.handleSubmit} type="submit" variant="contained">
                Submit
              </FormSubmitButton>
            </Box>
          </Grid>

       </Grid>

        </Grid>


      </CommonCurdPopUp>}




      {/* <ModalPopUp
        open={showEdit}
        handleClose={() => setShowEdit(false)}
        closeModel={() => setShowEdit(false)}
      >
        <Box
        
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formikEdit.handleSubmit}
        >
          <ModalCloseBtn close={() => setShowEdit(false)} />
          <h5 className="center bold pt-2 res_margin_top">
            Update Question For {" "} {
            location?.state?.sectionSelectedData?.sectionName
          }
          </h5>
          <div className={classes.responsive_text}> */}



      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />

      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default PrepareSectionQuestions;
