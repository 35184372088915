import React, { useEffect } from 'react'
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
// material ui 
import {Grid, Box, InputLabel, FormControl, Select, MenuItem, FormHelperText} from '@mui/material'
// components
import { MuiAutocomplete,CommonCurdPopUp, ModalPopUp, FormSubmitButton, ModalCloseBtn, Notifier, ErrorNotifier } from '../../../../components'
// redux
import {createL3ServicePermission, getL3ServicePermissionRole, resetMessage, resetErrMessage} from '../../../../features/iam/role_management/access_permissions/l3AccessPermissionSlice'
import { getApiManagement } from '../../../../features/iam/application_management/apiManagementSlice';

// ----------------------------------------------------------------------------------
const style = {
    position: "absolute",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

// ------------------------------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  rootbox : {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: '56.25rem !important',
    [theme.breakpoints.down('sm')]: {
      width:'20rem !important',
      height:'60% !important' ,
      display:'block !important',
      overflow:'scroll !important',
    },
    [theme.breakpoints.between('sm','md')]: {
      width: '24.375rem  !important',
    height: '34% !important',
    display: 'block !important',
    overflow: 'scroll !important',
    margin: '-3.188rem',
    marginTop: '-3.75rem  !important',
    marginLeft:'-3.563rem',
    padding: '2.125rem !important',
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: '1.5rem !important',
    paddingTop: '1rem !important',
    paddingLeft: '2rem !important',
    paddingRight: '2rem !important',
    paddingBottom:'1.5rem',
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },



  text_responsive : {
    width:'50rem',
    [theme.breakpoints.down('md')]: {
      width:'17rem',
      
    },
  },

  cutoff_box : {
    
  [theme.breakpoints.down('sm')]: {
    width:'16.875rem !important'
  },
  [theme.breakpoints.between('sm','lg')]: {
    width:'20.875rem !important',
    marginLeft:'-7.313rem',
    marginTop:'-11.313rem'
  }
},
responsive_field : {
  [theme.breakpoints.down('sm')]: {
    width:'15rem !important'
  }
},
responsive_field : {
  [theme.breakpoints.down('md')]: {
    width:'15rem !important'
  }
}
}))
const validationSchema = Yup.object({
    level3MenuPermissionId: Yup.object().required("L3 Menu Permission is required"),
    service: Yup.object().required("Service is required")
   
  });

//   -------------------------------------------------------------------------------------------

const AddL3ServicePermission = ({showAdd, setShowAdd}) => {

  const dispatch = useDispatch();
const {message, errMessage, } = useSelector((state) => ({
    ...state.l3AccessPermission
}))
const {l3MenuPermissionData} = useSelector((state) => ({
    ...state.l3AccessPermission
}))
const {apiManagementData} = useSelector((state) => ({...state.apiManagement}))

const {selectedData} = useSelector((state) => ({...state.roles}))

      const formRefresh = () => {
        formik.resetForm({
            values: {
              userRoleId: "",
              roleCode: "",
              l1MenuId: "",
            },
          });
      }

    const formik = useFormik({
        reset: true,
        initialValues: {
          level3MenuPermissionId: "",
          service: "",
        },
    
        validationSchema: validationSchema,
        onSubmit: (values) => {
          let data = {
            userRoleL3MenuPermissionId: values?.level3MenuPermissionId?.id,
            applicationServiceId: values?.service?.id,
          }
          dispatch(createL3ServicePermission(data)).then((res) => {
            if (res?.payload?.data?.ok) {
              setTimeout(() => {
                dispatch(resetMessage());
                dispatch(getL3ServicePermissionRole(selectedData?.id));
                formRefresh()
                setShowAdd(false);

              }, 2000);
            }
            if (res?.payload?.error) {
              setTimeout(() => {
                dispatch(resetErrMessage());
              }, 3000);
            }
          });
        },
      });

      useEffect(() => {
        dispatch(getApiManagement())
      }, [])
      const classes = useStyles()    
  return (
    <>
    {showAdd && <CommonCurdPopUp
        heading={`Create L3 Service Permission`}
        mediumScreen={true}
        close={() => {
          setShowAdd(false)
          formRefresh()
        }}
      >
        <Grid container >
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11} >
            <MuiAutocomplete
              name="level3MenuPermissionId"
              options={l3MenuPermissionData}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.level3MenuPermissionId}
              innerValue={true}
              optionValue="level3Menu"
              optionSecValue="l3MenuName"
              label="L3 Menu Permission"
              required={true}
              error={formik.touched.level3MenuPermissionId && Boolean(formik.errors.level3MenuPermissionId)}
              helperText={formik.touched.level3MenuPermissionId && formik.errors.level3MenuPermissionId}
            />
            <MuiAutocomplete
              name="service"
              options={apiManagementData}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.service}
              optionValue="name"
              label="API Service"
              required={true}
              error={formik.touched.service && Boolean(formik.errors.service)}
              helperText={formik.touched.service && formik.errors.service}
            />
         </Grid>


<Box style={{ width: "100%", textAlign: "center" }} >

  <FormSubmitButton onClick={formik?.handleSubmit} type="submit" variant="contained">
    Submit
  </FormSubmitButton>
</Box>
</Grid>
</CommonCurdPopUp>}
  {message && <Notifier message={message} />}
  {errMessage && <ErrorNotifier message={errMessage} />}
  </>
  )
}

export default AddL3ServicePermission