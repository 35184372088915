import React from "react";
import { PageHeader, MyTextInput } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
// material ui
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Box,
  CardContent,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import { FormSubmitButton,CommonCurdPopUp } from "../../../../components";
import Notifier from "../../../../components/common/Notifier";
import ErrorNotifier from "../../../../components/common/ErrorNotifier";
// redux
import {
  updateRelationshipType,
  getRelationshipType,
  resetMessage,
  resetErrMessage,
} from "../../../../features/config/relationshipTypeSlice";

// --------------------------------------------------------------

const validationSchema = Yup.object({
  relationshipType: Yup.string().required("Relationship Type is required"),
  comments: Yup.string(),
});

//   ------------------------------------------------------------------------------------------

const UpdateRelationshipType = (props) => {
  const {  message, errMessage, selectedData } = useSelector(
    (state) => ({ ...state.relationshipType })
  );

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      relationshipType: selectedData?.relationshipTypeName,
      comments: selectedData?.comments,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        updateRelationshipType({
          id: selectedData.id,
          relationshipTypeName: values.relationshipType,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.close();
            dispatch(getRelationshipType());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  return (
    <>
 
 {props?.showEdit && <CommonCurdPopUp
        heading={`Update Relationship Type`}
        mediumScreen={true}
        close={props.close}
      >
        <Grid container >
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11} >
              <MyTextInput
                label="Relationship Type"
                type="text"
                name="relationshipType"
                required={true}
                fullWidth={true}
                margin="normal"
                id="relationshipType"
                value={formik.values.relationshipType}
                onChange={formik.handleChange}
                error={
                  formik.touched.relationshipType &&
                  Boolean(formik.errors.relationshipType)
                }
                helperText={
                  formik.touched.relationshipType &&
                  formik.errors.relationshipType
                }
              />
              <MyTextInput
                label="Comments"
                type="text"
                name="comments"
                fullWidth={true}
                margin="normal"
                id="comments"
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={
                  formik.touched.comments && Boolean(formik.errors.comments)
                }
                helperText={formik.touched.comments && formik.errors.comments}
              />
                    </Grid>
          <Box style={{ width: "100%", textAlign: "center" }} >

            <FormSubmitButton onClick={formik?.handleSubmit} type="submit" variant="contained">
              Submit
            </FormSubmitButton>
          </Box>
        </Grid>

      </CommonCurdPopUp>}
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
  
    </>
  );
};

export default UpdateRelationshipType;
