import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useLocation } from "react-router-dom";
// material ui
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
  LinearProgress,
  IconButton,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
// components
import {
  MuiDataGrid,
  GridCellExpand,
  ModalPopUp,
  FormSubmitButton,
  FormTextField as MyTextInput,
  Notifier,
  ErrorNotifier,
  ModalCloseBtn,
  ConfirmDialog,
  MuiAutocomplete,
  MuiTooltip,
  PageHeader,
  CommonCurdPopUp
} from "../../../../../components";
// redux
// import {
//   createAssessmentFrameworkDimensionConfig,

//    getAssessmentFrameworkDimensionConfig,
//   updateAssessmentFrameworkDimensionConfig,
//  deleteAssessmentFrameworkDimensionConfig,
//   rowSelect,
//   resetMessage,
//   resetErrMessage,
// } from "../../../../../features/config/assessmentTemplateDimensionConfigSlice";

import {
  createAssessmentFrameworkDimensionConfig,
  getAssessmentFrameworkDimensionConfig,
  updateAssessmentFrameworkDimensionConfig,
  deleteAssessmentFrameworkDimensionConfig,
  rowSelect,
  resetMessage,
  resetErrMessage,

} from "../../../../../features/config/assessmentFrameworkDimensionConfigSlice";

import { textAreaChecker, textChecker } from "../../../../../components/common/form_validation/regularExpressions";
import { textAreaFieldErr, textFieldErr } from "../../../../../components/common/form_validation/validationErrMessages";

// ------------------------------------------------------------------------------

const validationSchema = Yup.object({
  // section: Yup.object().required("Section Name is required"),
  dimensionName: Yup.string().required("Dimension Name is required").matches(textChecker, textFieldErr),
  dimensionNo: Yup.number().required("Dimension Number is required"),
  comments: Yup.string().matches(textAreaChecker, textAreaFieldErr),
});

const editValidationSchema = Yup.object({
  // section: Yup.string().required("Section Name is required"),
  dimensionName: Yup.string().required("Dimension Name is required").matches(textChecker, textFieldErr),
  dimensionNo: Yup.number().required("Dimension Number is required"),
  comments: Yup.string().nullable().matches(textAreaChecker, textAreaFieldErr),
});

// -------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ------------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  rootbox: {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: "56.25rem !important",
    [theme.breakpoints.down("sm")]: {
      width: "20rem !important",
      height: "60% !important",
      display: "block !important",
      overflow: "scroll !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "24.375rem  !important",
      height: "34% !important",
      display: "block !important",
      overflow: "scroll !important",
      margin: "-3.188rem",
      marginTop: "-3.75rem  !important",
      marginLeft: "-3.563rem",
      padding: "2.125rem !important",
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: "1.5rem !important",
    paddingTop: "1rem !important",
    paddingLeft: "2rem !important",
    paddingRight: "2rem !important",
    paddingBottom: "1.5rem",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },

  text_responsive: {
    width: "50rem",
    [theme.breakpoints.down("md")]: {
      width: "17rem",
    },
  },

  cutoff_box: {
    [theme.breakpoints.down("sm")]: {
      width: "16.875rem !important",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      width: "20.875rem !important",
      marginLeft: "-7.313rem",
      marginTop: "-11.313rem",
    },
  },
  responsive_field: {
    [theme.breakpoints.down("sm")]: {
      width: "15rem !important",
    },
  },
  responsive_field: {
    [theme.breakpoints.down("md")]: {
      width: "15rem !important",
    },
  },
}));

const AssessmentTemplateDimensionConfig = (props) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const dispatch = useDispatch();

  const location = useLocation();

  const {  assessmentFrameworkSectionConfigData, selectedSectionData } =
    useSelector((state) => ({ ...state.assessmentFrameworkSectionConfig }));

  const { permissionsData } = useSelector((state) => ({
    ...state.permissions,
  }));

  const {
    assessmentFrameworkDimensionData,
    message,
    errMessage,
    selectedData,
    loading,
  } = useSelector((state) => ({ ...state.assessmentFrameworkDimensionConfig }));

  const showAddModel = () => {
    setShowAdd(!showAdd);
  };

  const closeModel = () => {
    setShowAdd(false);
    formik.resetForm({
      values: {
        section: "",
        dimensionName: "",
        dimensionNo: "",
        comments: "",
      },
    });
  };

  const showEditModal = () => {
    setShowEdit(true);
  };

  const closeEditModal = () => {
    setShowEdit(false);
  };

  const showDelete = () =>
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => handleDeleteCustomer(),
    });

  const handleDeleteCustomer = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deleteAssessmentFrameworkDimensionConfig(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(
            getAssessmentFrameworkDimensionConfig({
              id: selectedSectionData?.id,
            })
          );
          // dispatch(getAssessmentTemplateDimension({assessmentTemplateId: location?.state?.templateSelectedData?.id}));
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  function getSectionName(params) {
    return params?.row?.assessmentSection?.sectionName;
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const [sortModel, setSortModel] = useState([
    { field: "dimensionNo", sort: "asc" },
  ]);

  const data = {
    // rows: assessmentTemplateDimensionData,

    rows: selectedSectionData ? assessmentFrameworkDimensionData : [],
    columns: [
      // {
      //   field: "section",
      //   headerName: "Section",
      //   width: 220,
      //   valueGetter: getSectionName,
      // },
      {
        field: "dimensionName",
        headerName: "Dimension",
        width: 210,
        renderCell: renderCellExpand,
      },
      {
        field: "dimensionNo",
        headerName: "Dimension No.",
        width: 120,
        renderCell: renderCellExpand,
        valueGetter: (params) =>
          `${params.row.frameworkSection?.sectionNo}.${params.row.dimensionNo}`,
        sortable: true,
      },

      // {
      //   field: "prepareQuestion",

      //   type: "actions",
      //   width: 50,
      //   renderCell: (params) => {
      //     return (
      //       <MuiTooltip title="Questions" placement="right">
      //           {  location?.state?.
      //     permissions?.find(
      //           (e) =>
      //             e.applicationService?.name?.split(".")[
      //               e?.applicationService?.name?.split(".")?.length - 1
      //             ] === "getAssessQnByDimId"
      //         ) && (

      //         <Link
      //           to="/exco/config/assessment-template/section-dimension/dimension-questions"
      //           state={{
      //             templateSelectedData: location?.state?.templateSelectedData,
      //             permissions:location?.state?.permissions,
      //             dimensionSelectedData: params.row,
      //             sectionSelectedData: selectedSectionData,
      //             l3Menu: location?.state?.l3Menu,
      //           }}


      //         >
      //           <IconButton sx={{ p: 0 }}>
      //             <HelpIcon style={{ color: "#808080" }} />
      //           </IconButton>
      //         </Link>
      //         )}
      //       </MuiTooltip>
      //     );
      //   },
      // },
      {
        field: "comments",
        headerName: "Comments",
        width: 350,
        renderCell: renderCellExpand,
      },
    ],
  };


  const formik = useFormik({
    reset: true,
    initialValues: {
      section: "",
      dimensionName: "",
      dimensionNo: "",
      comments: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values, onSubmitProps) => {
      dispatch(
        createAssessmentFrameworkDimensionConfig({
          // assessmentSectionId: selectedSectionData?.id,
          // frameworkTemplateId : location?.state?.templateSelectedData?.id,
          frameworkSectionId: selectedSectionData?.id,
          dimensionName: values?.dimensionName,
          dimensionNo: values?.dimensionNo,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeModel();
            dispatch(
              getAssessmentFrameworkDimensionConfig({
                id: selectedSectionData?.id,
              })
            );
            // dispatch(getAssessmentTemplateDimension({assessmentTemplateId: location?.state?.templateSelectedData?.id}));
            onSubmitProps.resetForm();
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      section: selectedSectionData?.id,
      dimensionName: selectedData?.dimensionName,
      dimensionNo: selectedData?.dimensionNo,
      comments: selectedData?.comments,
    },

    validationSchema: editValidationSchema,
    onSubmit: (values) => {
      dispatch(
        updateAssessmentFrameworkDimensionConfig({
          id: selectedData?.id,
          // assessmentSectionId: values.section,
          frameworkSectionId: values.section,
          dimensionName: values?.dimensionName,
          dimensionNo: values?.dimensionNo,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeEditModal();
            dispatch(
              getAssessmentFrameworkDimensionConfig({
                id: selectedSectionData?.id,
              })
            );
            // dispatch(getAssessmentTemplateDimension({assessmentTemplateId: location?.state?.templateSelectedData?.id}));
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    // dispatch(getAssessmentTemplateDimension(data))

    if (selectedSectionData) {
      let data = {
        // id : location?.state?.templateSelectedData?.id
        id: selectedSectionData?.id,
      };

      if (Boolean(data?.id)) {
        dispatch(getAssessmentFrameworkDimensionConfig(data)).then(
          (res) => {
            if (res?.payload?.data?.error) {
              setTimeout(() => {
                dispatch(resetErrMessage());
              }, 3000);
            }
          }
        );
      }
      // selectedSectionData?.id &&
    }
  }, [selectedSectionData]);
  // useEffect(() => {
  //   let data = {
  //     // assessmentTemplateId : location?.state?.templateSelectedData?.id
  //     assessmentTemplateId : selectedSectionData?.id
  //   }
  //   dispatch(getAssessmentTemplateDimension(data))
  // }, [selectedSectionData]);

  const classes = useStyles();

  return (
    <>
      <PageHeader variant="pageThirdHeader">Dimensions</PageHeader>
      <>
        {loading && <LinearProgress variant="determinate" />}

        {!loading && (
          <MuiDataGrid
            isMuidatagridSmall={true}
            setShowAdd={
              selectedSectionData && 
              // location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "createAssessDim") &&
              showAddModel
            }
            setShowEdit={
              // location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "updateAssessDim") &&
              showEditModal
            }
            setShowDelete={
              // location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "deleteAssessDim") &&
              showDelete
            }
            // setShowAdd={
            //   location?.serviceAccessData?.userRoleL3ServicePermission?.find(
            //     (e) =>
            //       e.applicationService?.name?.split(".")[
            //         e?.applicationService?.name?.split(".")?.length - 1
            //       ] === "createAssesDims"
            //   ) &&

            // }
            // setShowEdit={
            //   location?.serviceAccessData?.userRoleL3ServicePermission?.find(
            //     (e) =>
            //       e.applicationService?.name?.split(".")[
            //         e?.applicationService?.name?.split(".")?.length - 1
            //       ] === "updateAssesDims"
            //   ) && showEditModal
            // }
            // setShowDelete={
            //   location?.serviceAccessData?.userRoleL3ServicePermission?.find(
            //     (e) =>
            //       e.applicationService?.name?.split(".")[
            //         e?.applicationService?.name?.split(".")?.length - 1
            //       ] === "deleteAssesDims"
            //   ) && showDelete
            // }
            // setShowAdd={showAddModel}
            // setShowEdit={showEditModal}
            // setShowDelete={showDelete
            // }

            toolbarColumn={true}
            // toolbarFilter={true}
            // toolbarDensity = {true}
            toolbarExport={true}
            data={data}
            select={(data) => dispatch(rowSelect(data))}
            // For Sorting
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          />
        )}
      </>
      {/* <ModalPopUp
        open={showAdd}
        handleClose={closeModel}
        closeModel={closeModel}
      >
        <Box
        
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <PageHeader>Add Dimension for {selectedSectionData?.sectionName}</PageHeader>
          <ModalCloseBtn close={closeModel} />
          <div className={classes.text_responsive}> */}

      {showAdd && <CommonCurdPopUp
        heading={`Add Dimension for ${selectedSectionData?.sectionName}`}
        mediumScreen={true}
        close={
          closeModel
        }
      >
        <Grid container >
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11} >
            {/* <MuiAutocomplete
              name="section"
              options={assessmentTemplateSectionData}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.section}
              optionValue="sectionName"
              label="Section"
              required={true}
              error={formik.touched.section && Boolean(formik.errors.section)}
              helperText={formik.touched.section && formik.errors.section}
            /> */}
            <div style={{ display: "flex" }}>
              <div style={{ width: "75%" }}>
                <MyTextInput
                  label="Dimension Name"
                  type="text"
                  name="dimensionName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="dimensionName"
                  value={formik.values.dimensionName}
                  onChange={formik.handleChange}
                  tabIndex={1}
                  error={
                    formik.touched.dimensionName &&
                    Boolean(formik.errors.dimensionName)
                  }
                  helperText={
                    formik.touched.dimensionName &&
                    formik.errors.dimensionName
                  }
                />
              </div>
              <div style={{ width: "25%", paddingLeft: "0.5rem" }}>
                <MyTextInput
                  label="Dimension No."
                  type="number"
                  name="dimensionNo"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="dimensionNo"
                  value={formik.values.dimensionNo}
                  // onChange={formik.handleChange}
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    // Use a regular expression to check if the input is a number
                    if (/^\d*$/.test(inputValue)) {
                      // If it's a number, update the field value
                      formik.handleChange(e);
                    }
                    // You can also handle invalid input here if needed
                  }}
                  tabIndex={1}
                  error={
                    formik.touched.dimensionNo &&
                    Boolean(formik.errors.dimensionNo)
                  }
                  helperText={
                    formik.touched.dimensionNo && formik.errors.dimensionNo
                  }
                />
              </div>
            </div>
            <MyTextInput
              label="Comments"
              type="comments"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formik.values.comments}
              multiline={true}
              maxRows={5}
              onChange={formik.handleChange}
              error={
                formik.touched.comments && Boolean(formik.errors.comments)
              }
              helperText={formik.touched.comments && formik.errors.comments}
            />
            <Box style={{ width: "100%", textAlign: "center" }} mt={2}>

              <FormSubmitButton onClick={formik?.handleSubmit} type="submit" variant="contained">
                Submit
              </FormSubmitButton>
            </Box>
          </Grid>
        </Grid>
      </CommonCurdPopUp>}

      {/* </div> */}

      {/* <FormSubmitButton type="submit" variant="contained">
        Submit
      </FormSubmitButton> */}
      {/* </Box>
      </ModalPopUp> */}
      {/* <ModalPopUp
        open={showEdit}
        handleClose={() => setShowEdit(false)}
        closeModel={() => setShowEdit(false)}
      >
        <Box
         
          className={classes.rootbox}
          text_responsive
          component="form"
          noValidate
          onSubmit={formikEdit.handleSubmit}
        >
          <PageHeader>Update Dimension for {selectedSectionData?.sectionName}</PageHeader>
          <ModalCloseBtn close={() => setShowEdit(false)} />
          <div className={classes.text_responsive}> */}

      {showEdit && <CommonCurdPopUp
        heading={`Update Dimension for ${selectedSectionData?.sectionName}`}
        mediumScreen={true}
        close={
          () => setShowEdit(false)
        }
      >
        <Grid container>
        <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11} >
          <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Section *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="section"
                  value={formikEdit.values.section}
                  error={
                    formikEdit.touched.section && Boolean(formikEdit.errors.section)
                  }
                  label="Section"
                  onChange={formikEdit.handleChange}
                >
                  { assessmentFrameworkSectionConfigData?.length > 0 &&
                   assessmentFrameworkSectionConfigData?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.sectionName}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formikEdit.touched.section && formikEdit.errors.section}
                </FormHelperText>
              </FormControl>

            {/* <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Section *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="section"
                  value={formikEdit.values.section}
                  error={
                    formikEdit.touched.section && Boolean(formikEdit.errors.section)
                  }
                  label="Section"
                  onChange={formikEdit.handleChange}
                >
                  {assessmentTemplateSectionData.length > 0 &&
                    assessmentTemplateSectionData.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.sectionName}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formikEdit.touched.section && formikEdit.errors.section}
                </FormHelperText>
              </FormControl> */}
            <div style={{ display: "flex" }}>
              <div style={{ width: "75%" }}>
                <MyTextInput
                  label="Dimension Name"
                  type="text"
                  name="dimensionName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="dimensionName"
                  defaultValue={formikEdit.values.dimensionName}
                  value={formikEdit.values.dimensionName}
                  onChange={formikEdit.handleChange}
                  error={
                    formikEdit.touched.dimensionName &&
                    Boolean(formikEdit.errors.dimensionName)
                  }
                  helperText={
                    formikEdit.touched.dimensionName &&
                    formikEdit.errors.dimensionName
                  }
                />
              </div>
              <div style={{ width: "25%", paddingLeft: "0.5rem" }}>
                <MyTextInput
                  label="Dimension No."
                  type="number"
                  name="dimensionNo"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="dimensionNo"
                  defaultValue={formikEdit.values.dimensionNo}
                  value={formikEdit.values.dimensionNo}
                  // onChange={formikEdit.handleChange}
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    // Use a regular expression to check if the input is a number
                    if (/^\d*$/.test(inputValue)) {
                      // If it's a number, update the field value
                      formikEdit.handleChange(e);
                    }
                    // You can also handle invalid input here if needed
                  }}
                  error={
                    formikEdit.touched.dimensionNo &&
                    Boolean(formikEdit.errors.dimensionNo)
                  }
                  helperText={
                    formikEdit.touched.dimensionNo &&
                    formikEdit.errors.dimensionNo
                  }
                />
              </div>
            </div>

            <MyTextInput
              label="Comments"
              type="comments"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formikEdit.values.comments}
              multiline={true}
              maxRows={5}
              onChange={formikEdit.handleChange}
              error={
                formikEdit.touched.comments &&
                Boolean(formikEdit.errors.comments)
              }
              helperText={
                formikEdit.touched.comments && formikEdit.errors.comments
              }
            />
            <Box style={{ width: "100%", textAlign: "center" }} mt={2}>

              <FormSubmitButton onClick={formikEdit?.handleSubmit} type="submit" variant="contained">
                Submit
              </FormSubmitButton>
            </Box>
          </Grid>
        </Grid>


      </CommonCurdPopUp>}

      {/* </div> */}

      {/* <FormSubmitButton type="submit" variant="contained">
        Submit
      </FormSubmitButton>
    </Box >
      </ModalPopUp > */}

  <ConfirmDialog
    confirmDialog={confirmDialog}
    setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
  />

{ errMessage && <ErrorNotifier message={errMessage} /> }
{ message && <Notifier message={message} /> }
    </>
  );
};

export default AssessmentTemplateDimensionConfig;
