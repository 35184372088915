import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
// material ui 
import { Grid, Box, InputLabel, FormControl, Select, MenuItem, FormHelperText } from '@mui/material'
// components
import { MuiAutocomplete,CommonCurdPopUp, ModalPopUp, FormSubmitButton, ModalCloseBtn, Notifier, ErrorNotifier } from '../../../../components'
// redux
import { createL3MenuPermission, getL3MenuPermissionRole, resetMessage, resetErrMessage } from '../../../../features/iam/role_management/access_permissions/l3AccessPermissionSlice'
import { getL3Menu } from '../../../../features/iam/application_management/menu_management/l3MenuSlice';

// ----------------------------------------------------------------------------------
const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ------------------------------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  rootbox: {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: '56.25rem !important',
    [theme.breakpoints.down('sm')]: {
      width: '20rem !important',
      height: '60% !important',
      display: 'block !important',
      overflow: 'scroll !important',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '24.375rem  !important',
      height: '34% !important',
      display: 'block !important',
      overflow: 'scroll !important',
      margin: '-3.188rem',
      marginTop: '-3.75rem  !important',
      marginLeft: '-3.563rem',
      padding: '2.125rem !important',
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: '1.5rem !important',
    paddingTop: '1rem !important',
    paddingLeft: '2rem !important',
    paddingRight: '2rem !important',
    paddingBottom: '1.5rem',
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },



  text_responsive: {
    width: '50rem',
    [theme.breakpoints.down('md')]: {
      width: '17rem',

    },
  },

  cutoff_box: {

    [theme.breakpoints.down('sm')]: {
      width: '16.875rem !important'
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      width: '20.875rem !important',
      marginLeft: '-7.313rem',
      marginTop: '-11.313rem'
    }
  },
  responsive_field: {
    [theme.breakpoints.down('sm')]: {
      width: '15rem !important'
    }
  },
  responsive_field: {
    [theme.breakpoints.down('md')]: {
      width: '15rem !important'
    }
  }
}))
const validationSchema = Yup.object({
  l2MenuPermission: Yup.object().required("L2 Menu Permission is required"),
  level3MenuId: Yup.object().required("L3 Menu Name is required"),

});

//   -------------------------------------------------------------------------------------------

const AddL3MenuPermission = ({ showAdd, setShowAdd }) => {
  const [l3MenuArr, setL3MenuArr] = useState([])

  const dispatch = useDispatch();
  const { l2MenuPermissionData } = useSelector((state) => ({ ...state.l2AccessPermission }))
  const { message, errMessage, l3MenuData } = useSelector((state) => ({
    ...state.l3Menus
  }))
  const { selectedData } = useSelector((state) => ({ ...state.roles }))

  const formRefresh = () => {
    formik.resetForm({
      values: {
        l2MenuPermission: "",
        l3MenuId: "",
      },
    });
  }

  const formik = useFormik({
    reset: true,
    initialValues: {
      l2MenuPermission: "",
      level3MenuId: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      let data = {
        userRoleL2MenuPermissionId: values?.l2MenuPermission?.id,
        level3MenuId: values?.level3MenuId?.id
      }
      dispatch(createL3MenuPermission(data)).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            dispatch(getL3MenuPermissionRole(selectedData?.id));
            formRefresh()
            setShowAdd(false);

          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getL3Menu())
  }, [])

  useEffect(() => {
    let l3Menus = []
    l3MenuData.length > 0 && l3MenuData.map(item => {
      if (item?.level2Menu?.l2MenuName === formik?.values?.l2MenuPermission?.level2Menu?.l2MenuName) {
        return l3Menus.push(item)
      }
      setL3MenuArr(l3Menus)
    })
  }, [formik?.values?.l2MenuPermission])
  const classes = useStyles()
  return (
    <>
      {showAdd && <CommonCurdPopUp
        heading={`Create L3 Menu Permission`}
        mediumScreen={true}
        close={() => {
          setShowAdd(false)
          formRefresh()
        }}
      >
        <Grid container >
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11} >
          <MuiAutocomplete
            name="l2MenuPermission"
            options={l2MenuPermissionData}
            setValue={(name, newValue) =>
              formik.setFieldValue(name, newValue)
            }
            value={formik.values.l2MenuPermission}
            innerValue={true}
            optionValue="level2Menu"
            optionSecValue="l2MenuName"
            label="L2 Menu Name"
            required={true}
            error={formik.touched.l2MenuPermission && Boolean(formik.errors.l2MenuPermission)}
            helperText={formik.touched.l2MenuPermission && formik.errors.l2MenuPermission}
          />
          <MuiAutocomplete
            name="level3MenuId"
            options={l3MenuArr}
            setValue={(name, newValue) =>
              formik.setFieldValue(name, newValue)
            }
            value={formik.values.level3MenuId}
            optionValue="l3MenuName"
            label="L3 Menu Name"
            required={true}
            error={formik.touched.level3MenuId && Boolean(formik.errors.level3MenuId)}
            helperText={formik.touched.level3MenuId && formik.errors.level3MenuId}
          />
          </Grid>


          <Box style={{ width: "100%", textAlign: "center" }} >

            <FormSubmitButton onClick={formik?.handleSubmit} type="submit" variant="contained">
              Submit
            </FormSubmitButton>
          </Box>
        </Grid>
      </CommonCurdPopUp>}


      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </>
  )
}

export default AddL3MenuPermission