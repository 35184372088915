import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_ASSESSMENT_TEMPLATE_DIMENSION, GET_ASSESSMENT_TEMPLATE_DIMENSION_BY_TEMPLATE_ID,GET_ASSESSMENT_TEMPLATE_DIMENSION_BY_SECTION_ID } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createAssessmentTemplateDimension = createAsyncThunk(
  "assessmentTemplateDimension/createAssessmentTemplateDimension",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_ASSESSMENT_TEMPLATE_DIMENSION, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAssessmentTemplateDimension = createAsyncThunk(
  "assessmentTemplateDimension/getAssessmentTemplateDimension",
  async (data, {rejectWithValue}) => {
    try {
      const response = await axios.post(GET_ASSESSMENT_TEMPLATE_DIMENSION_BY_TEMPLATE_ID, data, { withCredentials: true });
      return response
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
);

export const getAssessmentTemplateDimensionBySectionId = createAsyncThunk(
  "assessmentTemplateDimension/getAssessmentTemplateDimensionBySectionId",
  async (data, {rejectWithValue}) => {


    try {
      const response = await axios.post(GET_ASSESSMENT_TEMPLATE_DIMENSION_BY_SECTION_ID, data, { withCredentials: true });
      return response
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
);

export const updateAssessmentTemplateDimension = createAsyncThunk(
  "assessmentTemplateDimension/updateAssessmentTemplateDimension",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_ASSESSMENT_TEMPLATE_DIMENSION, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAssessmentTemplateDimension = createAsyncThunk(
  "assessmentTemplateDimension/deleteAssessmentTemplateDimension",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_ASSESSMENT_TEMPLATE_DIMENSION, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const assessmentTemplateDimensionSlice = createSlice({
  name: "assessmentTemplateDimension",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    assessmentTemplateDimensionData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createAssessmentTemplateDimension.pending]: (state, action) => {
      state.loading = true;
    },
    [createAssessmentTemplateDimension.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createAssessmentTemplateDimension.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getAssessmentTemplateDimension.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentTemplateDimension.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentTemplateDimensionData = action?.payload?.data;
    },
    [getAssessmentTemplateDimension.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getAssessmentTemplateDimensionBySectionId.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentTemplateDimensionBySectionId.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentTemplateDimensionData = action?.payload?.data;
    },
    [getAssessmentTemplateDimensionBySectionId.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateAssessmentTemplateDimension.pending]: (state, action) => {
      state.loading = true;
    },
    [updateAssessmentTemplateDimension.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateAssessmentTemplateDimension.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteAssessmentTemplateDimension.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteAssessmentTemplateDimension.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteAssessmentTemplateDimension.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  assessmentTemplateDimensionSlice.actions;
export default assessmentTemplateDimensionSlice.reducer;
