// import { customUseEffect } from "../../../../../../common";
import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { CommonCurdPopUp, ConfirmDialog, MuiDataGrid } from "../../components";
import { GET_ALL_STATUS_EXCEL_DOWNLOAD } from "../../utils/routes";
import { getDownLoadAssessmentPie } from "../../features/dashboard/dashboardSlice";

// import CommonErrorMessage from "../../../../../../common/error-message/CommonErrorMessage";
// import { deleteProjectGoal, getProjectGoalsByStatus } from "../../../../../../../features/project/projectConfig/projectGoalSlice";
// import DateFns from 'date-fns';
// import ViewGoalDetails from "../../../../viewComponents/project/ViewGoalDetails";
// -------------------------------------------------------------------------------
const ViewAssessmentStatusDetails = ({ ...props }) => {
  // try {
  const [showDetail, setShowDetail] = useState(false);
  const [showDetailData, setShowDetailData] = useState(false);
  const dispatch = useDispatch();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const { customerStatusDetails } = useSelector((state) => ({ ...state?.dashboard }));
  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  useEffect(() => {
    // dispatch(getProjectGoalsByStatus(props?.data));
  }, []);
  const getFunction = () => {
    // dispatch(getProjectGoalsByStatus(prop?.data));
  };



  function getCutoffDate(params) {
    if (params?.row?.cutOffDate
    ) {
      const utcTimestamp = params?.row?.cutOffDate;

    // Convert to local time
    const date = new Date(utcTimestamp);

    // Get local date string
    const localDateString = date.toLocaleString();

    // Display in the div
    // document.getElementById('localTime').textContent = localDateString;

    // If you want more control over the format, you can use options:
    
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    };

    const formattedDate = date.toLocaleString(undefined, options);
    return formattedDate ? formattedDate?.split(",")[0] : ""
    }
  }


  const data = {
    rows: customerStatusDetails?.length > 0 ? customerStatusDetails : [],
    columns: [
      {
        field: "customerName",
        headerName: "Customer",
        renderHeader: () => <strong>Customer</strong>,
        width: 200,
      },
      {
        field: "accountName",
        headerName: "Account",
        renderHeader: () => <strong>Account</strong>,

        width: 200,
      },
      {
        field: "assessmentTemplateName",
        headerName: "Assessment Name",
        renderHeader: () => <strong>Assessment Name</strong>,

        width: 230,
      },
      // {
      //   field: "userName",
      //   headerName: "User Name",
      //   renderHeader: () => <strong>User Name</strong>,
      //   valueGetter: (params) => `${params?.row?.firstName} ${params?.row?.lastName}`,

      //   width: 250,
      // },
      {
        field: "clientName",
        headerName: "User Name",
        renderHeader: () => <strong>Client Name</strong>,
        valueGetter: (params) => `${params?.row?.firstName} ${params?.row?.lastName}`,
        width: 250,
      },
      {
        field: "email",
        headerName: "Email",
        renderHeader: () => <strong>Email</strong>,


        width: 250,
      },

      {
        field: "cutOffDate",
        headerName: "cutOffDate",
        renderHeader: () => <strong>Cut Off Date</strong>,
        valueGetter: getCutoffDate,
        width: 200,
      },
    ],
  };
  const handleDelete = () => {
    try {
      let data = {
        id: showDetailData[0]?.id,
      };
      // dispatch(deleteProjectGoal(data)).then((res) => {
      //   if (res?.payload?.data?.ok) {
      //     setTimeout(() => {
      //       getFunction();
      //       handleCloseConfirmDialog();
      //       setShowDetailData(false);
      //       props?.close(false);
      //     }, 2000);
      //   }
      // });
    } catch (e) {
      console.log(e);
    }
  };
  const showDeleteFunction = () => {

    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => handleDelete(),
    });
  }

 


  const downLoadFunc = () => {
    let body = {
      fromDate: props?.downloadData?.fromDate,
      endDate: props?.downloadData?.endDate,
      accountId: props?.downloadData?.accountId,
      customerId: props?.downloadData?.customerId,
      status : props?.downloadData?.status
    }

    dispatch(getDownLoadAssessmentPie(body))
  }

  // try {
  return (
    <>
      {!showDetail && <CommonCurdPopUp
        isDownload={true}
        TARGET_URL={GET_ALL_STATUS_EXCEL_DOWNLOAD}
        targetId={props?.statusTracking}
        downloadFunc={downLoadFunc}
        heading={`Details for Assessment Status `}
        bigScreen={true}
        close={() => props.close()}
      >
        <div
          style={{ marginLeft: "20px", marginRight: "20px" }}
          className="res_tcTestData"
        >
          <MuiDataGrid

            data={data}
            select={(item) => {
              if (item.length > 0) {
                setShowDetailData(item);
                // setShowDetail(true);
              }
            }}
          />
        </div>
      </ CommonCurdPopUp>}

      {/* {
          showDetail && (
            <ViewGoalDetails
              open={showDetail}
              close={() => setShowDetail(false)}
              data={showDetailData[0]}
              showDeleteFunction={showDeleteFunction}
              getFunction={getFunction}
            />
          )} */}



      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />
    </>
  );
  // } 

  //   catch (err) {
  //     console.log(err);
  //     return <CommonErrorMessage />;
  //   }
  // } 

  // catch (err) {
  //   console.log(err);
  //   return <CommonErrorMessage />;
  // }
};

const mapStateToProps = (state) => ({
  projectGoal: state?.projectGoal,
  myProject: state?.myProject,
});

export default connect(mapStateToProps, {
})(ViewAssessmentStatusDetails);
