import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_PLATFORM_STATUS } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createPlatformStatus = createAsyncThunk(
  "platformStatus/createplatformStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_PLATFORM_STATUS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPlatformStatus = createAsyncThunk(
  "platformStatus/getplatformStatus",
  async (obj, {rejectWithValue}) => {
    try{
      const response = await axios.get(CURD_PLATFORM_STATUS, { withCredentials: true });
      return response;
    } catch (err) {
      return rejectWithValue(err.response) 
    }
  }
);

export const updatePlatformStatus = createAsyncThunk(
  "platformStatus/updateplatformStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_PLATFORM_STATUS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deletePlatformStatus = createAsyncThunk(
  "platformStatus/deleteplatformStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_PLATFORM_STATUS, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const assessmentStatusSlice = createSlice({
  name: "assessmentStatus",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    assessmentTemplateStatusData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createPlatformStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [createPlatformStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createPlatformStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getPlatformStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [getPlatformStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentTemplateStatusData = action?.payload?.data;
    },
    [getPlatformStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updatePlatformStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [updatePlatformStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updatePlatformStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deletePlatformStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [deletePlatformStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deletePlatformStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  assessmentStatusSlice.actions;
export default assessmentStatusSlice.reducer;
