import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_ASSESSMENT_STATUS } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createAssessmentStatus = createAsyncThunk(
  "assessmentStatus/createAssessmentStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_ASSESSMENT_STATUS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAssessmentStatus = createAsyncThunk(
  "assessmentStatus/getAssessmentStatus",
  async (obj, {rejectWithValue}) => {
    try{
      const response = await axios.get(CURD_ASSESSMENT_STATUS, { withCredentials: true });
      return response;
    } catch (err) {
      return rejectWithValue(err.response) 
    }
  }
);

export const updateAssessmentStatus = createAsyncThunk(
  "assessmentStatus/updateAssessmentStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_ASSESSMENT_STATUS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAssessmentStatus = createAsyncThunk(
  "assessmentStatus/deleteAssessmentStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_ASSESSMENT_STATUS, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const assessmentStatusSlice = createSlice({
  name: "assessmentStatus",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    assessmentStatusData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createAssessmentStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [createAssessmentStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createAssessmentStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getAssessmentStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentStatusData = action?.payload?.data;
    },
    [getAssessmentStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateAssessmentStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [updateAssessmentStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateAssessmentStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteAssessmentStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteAssessmentStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteAssessmentStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  assessmentStatusSlice.actions;
export default assessmentStatusSlice.reducer;
