import React from "react";
import { Link } from "react-router-dom";
// material ui
import { Stack, Box } from "@mui/material";
// material icons
import CancelIcon from "@mui/icons-material/Cancel";

function PageHeader({
  children,
  pathname,
  moduleName,
  data,
  templateSelectedData,
  permissions,
  l3Menu
}) {
  return (
    <Stack flexDirection="row" justifyContent="space-between">
      <Box sx={{ width: "100%" }}>
        <h2 style={{ textAlign: "center", fontWeight: "bold",  }} >{children}</h2>
      </Box>
      {pathname && (
        <Link
         to={pathname}
         state={{
            moduleName: moduleName,
            data: data,
            templateSelectedData: templateSelectedData,
            permissions:permissions,
            l3Menu :   l3Menu
         }}
         
        >
          <CancelIcon
            sx={{
              position: "absolute",
              right: 15,
              color: "red !important",
            }}
          />
        </Link>
      )}
    </Stack>
  );
}

export default PageHeader;

export function AppPageHeader({
  children,
  pathname,
  moduleName,
  data,
  templateSelectedData,
  serviceAccessData,
  trackingSelectedData,
permissions,
l3Menu
}) {

  return (
    <Stack  flexDirection="row" justifyContent="space-between">
      <Box sx={{ width: "100%" }}>
        <h2 style={{ textAlign: "center", fontWeight: "bold", }} >{children}</h2>
      </Box>
      {pathname && (
        <Link
            to={pathname}
            state={{
              moduleName: moduleName,
              serviceAccessData: serviceAccessData,
              permissions:permissions,
              templateSelectedData: templateSelectedData,
              data: data,
              trackingSelectedData: trackingSelectedData,
              l3Menu :   l3Menu
            }}
         
        >
          <CancelIcon
            sx={{
              position: "absolute",
              right: 30,
              color: "red !important",
            }}
          />
        </Link>
      )}
    </Stack>
  );
}

{
  /* <Box textAlign="center" sx={{ paddingBottom: "1rem" }}>
      <Typography variant={variant} fontSize="2rem">
        {children}
      </Typography>
    </Box> */
}
