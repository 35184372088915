import React, { useState, useEffect } from "react";
import { Link , useLocation } from "react-router-dom";
import { useDispatch, useSelector ,  } from "react-redux";
// material ui
import { makeStyles } from "@mui/styles";
import {
  DataGrid,
  GridRow,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Button, IconButton } from "@mui/material";
// icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from '@mui/icons-material/Preview';
import Groups2Icon from '@mui/icons-material/Groups2';
import {
  Box,
  Grid,
  Typography
} from "@mui/material";

import {

  MuiAutocomplete,
  MuiTooltip
} from "../../components";
import { getAccount, getAccountById } from "../../features/customer/accountSlice";
import { getCustomer } from "../../features/customer/customerSlice";
import { checkObjectLength } from "../common/checkObjectLength/checkObjectLength";

// ---------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  dataGridStyle: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  filter_grid: {
    "& .css-1t5wrdm-MuiDataGrid-filterForm": {

      [theme.breakpoints.down('md')]: {
        display: 'flex !important',
        flexDirection: 'column !important',

      },
    }
  }
}));

// ----------------------------------------------------------------------------------

const CustomRow = (props) => {
  const classes = useStyles();
  const { className, index, ...other } = props;

  return <GridRow index={index} className={classes.dataGridStyle} {...other} />;
};

//   ------------------------------------------------------------------------------------

const GridToolbarAddButton = (props) => {
  return (
    <Button
      variant="text"
      startIcon={<AddCircleIcon />}
      onClick={() => props.handleClick()}
    >
      New
    </Button>
  );
};

const GridToolbarProjectPhase = ({ onChangeCustomerHandle, customerName }) => {

  const { customersData } =
    useSelector((state) => ({ ...state.customer }));

  // const dispatch = useDispatch();

  // const onChangeCustomer = async (value) => {
  //   setCustName(value)

  // }



  return (
    <>
      <MuiAutocomplete
        name="customerName"
        options={customersData}
        value={customerName}
        setValue={(name, newValue) => {

          onChangeCustomerHandle(newValue)

          // dispatch(getAccountById({ assessmentCustomerId: newValue?.id }))

          // .then((res) => {
          //   if (res?.payload?.data?.error) {
          //     setTimeout(() => {
          //       dispatch(taskTypeResetErrMessage());
          //     }, 2000);
          //   }
          // });


          // dispatch(phaseSelect(newValue));
        }}
        optionValue="customerName"
        label="Customers"
        required={false}
        sx={{
          width: "14rem",
          // display: "inline-flex",
          margin: "0.2rem",
          // whiteSpace: "pre-wrap"

          // marginTop: "1rem",
        }}
        size="small"
      />
    </>
  );
};

const GridToolbarProjectPhaseTwo = ({ onChangeAccountHandle, account,customerName }) => {

  const { accountData, accountIdData } = useSelector((state) => ({ ...state?.account }));

  return (
    <>
      <MuiAutocomplete
        // disabled={customerName?.id ? false : true}
        name="account"
        options={customerName?.id ? accountIdData : []}
        value={account}
        setValue={(name, newValue) => {

       
          onChangeAccountHandle(newValue)
          // .then((res) => {
          //   if (res?.payload?.data?.error) {
          //     setTimeout(() => {
          //       dispatch(taskTypeResetErrMessage());
          //     }, 2000);
          //   }
          // });

          //     dispatch(phaseSelect(newValue));
        }}
        optionValue="accountName"
        label="Account"
        required={false}
        sx={{
          width: "14rem",
          // display: "inline-flex",

          margin: "0.2rem",
          // whiteSpace: "pre-wrap"
          // marginTop: "1rem",
        }}
        size="small"
      />
    </>
  );
};

// ------------------------------------------------------------------------------------------

const GridToolbarEditButton = (props) => {
  return (
    <Button
      variant="text"
      startIcon={<EditIcon />}
      onClick={() => props.handleClick()}
    >
      Edit
    </Button>
  );
};
const GridToolbarEditOriginalButton = (props) => {
  return (
    <Button
      sx={{ whiteSpace: "pre-wrap" }}
      variant="contained"
      size="small"
      onClick={() => props.handleClick()}
    >
      Change Status
    </Button>
  );
};


// ---------------------------------------------------------------------------------------------

const GridToolbarDeleteButton = (props) => {
  return (
    <Button
      variant="text"
      startIcon={<DeleteIcon />}
      onClick={() => props.handleClick()}
    >
      Delete
    </Button>
  );
};

// ----------------------------------------------------------------------------------------------

export default function MuiDataGrid(props) {

  const [selectedRow, setSelectedRow] = useState([]);
  const [custName, setCustName] = useState("")
  const [accountName, setAccountName] = useState("")

  const dispatch = useDispatch()

  const onChangeCustomerHandle = (value) => {
    setCustName(value)
    if (props.getCustomerSelectedValue) {
      props?.getCustomerSelectedValue(value)
    }

  }

  const onChangeAccountHandle = (value) => {
    setAccountName(value)
    if (props.getAccountSelectedValue) {
      props?.getAccountSelectedValue(value)
    }
  }

  useEffect(() => {

    if (custName?.id) {
      dispatch(getAccountById({ assessmentCustomerId: custName?.id }))
    }
    if (props?.getCallBackCustomer && custName?.id) {
      dispatch(props?.getCallBackCustomer(custName?.id))
    }

    if (!custName?.id && props?.getCallBackOne) {
      dispatch(props?.getCallBackOne())

    }
    if (!custName?.id && props?.getCallBackargs) {
      props?.getCallBackargs()
    }
    setAccountName("")
    if (props.getAccountSelectedValue) {
      props?.getAccountSelectedValue("")
    }


  }, [custName])

  useEffect(() => {

    if (props?.getCallBackTwo && accountName?.id) {
      dispatch(props?.getCallBackTwo(accountName?.id))
    }
    else if(custName?.id) {
      dispatch(props?.getCallBackCustomer(custName?.id))
    }

  }, [accountName])

  // useEffect(() => {
 
  //   if (props?.getCallBackTwo) {
  //     dispatch(props?.getCallBackTwo(accountName?.id ? accountName?.id : null))
  //   }
 
  // }, [accountName])




  const classes = useStyles();

  const CustomToolbar = () => {
    
    return (
      <GridToolbarContainer>
        <Grid container style={{ display: 'flex', flexWrap: 'nowrap', }}>

          <Grid item xs={12} sm={7} md={12} style={{ display: "flex", flexDirection: "row", flexGrow: 1, flexShrink: 1 }}>

            {props.setShowAdd && (
              <GridToolbarAddButton handleClick={props.setShowAdd} />
            )}
            {props.toolbarColumn && <GridToolbarColumnsButton />}
            {props.toolbarFilter && <GridToolbarFilterButton />}
            {props.toolbarDensity && <GridToolbarDensitySelector />}
            {props.toolbarExport && <GridToolbarExport />}
            {selectedRow.length === 1 && props?.setShowEdit && (
              <GridToolbarEditButton handleClick={props.setShowEdit} />
            )}
            {selectedRow.length === 1 && props.setShowDelete && (
              <GridToolbarDeleteButton handleClick={props.setShowDelete} />
            )}
            {selectedRow.length === 1 && props?.setShowEditButton && (
              <GridToolbarEditOriginalButton handleClick={props.handleUpdateClickOpen} />
            )}

          </Grid>
          <Grid item xs={12} sm={7} md={5} style={{ flexGrow: 1, flexShrink: 1 }}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }} >
              {props?.isCustomer && <GridToolbarProjectPhase customerName={custName} onChangeCustomerHandle={(data) => onChangeCustomerHandle(data)} />}
              {props?.isAccount && <GridToolbarProjectPhaseTwo customerName={custName} account={accountName} onChangeAccountHandle={(data) => onChangeAccountHandle(data)} />}


              {props?.isPreview && <Typography sx={{alignSelf:"center",marginRight:"2rem"}} >Total Questions:{props?.data?.rows?.length}</Typography>}
            
              {props?.isPreview && <MuiTooltip title="Client Preview" placement="bottom">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <Link
                   style={{ textDecoration: 'none', color: 'inherit' }}
                    to="/survey_start"
                    state={{
                      fromOne: "Self",
                      from: props?.template?.id,
                      company:props?.template?.assessmentCustomerAccount?.assessmentCustomer?.customerName,
                      permissions: props?.permissions,
                      templateSelectedData : props?.template
                    }}
                  >
                    <PreviewIcon sx={{ fontSize: "2rem" }} />

                  </Link>

                </IconButton>
              </MuiTooltip>}

              {props?.isPreview && <MuiTooltip title="Assessor Preview" placement="bottom">
                <IconButton
                  color="primary"
              
                  component="span"
                >
                  <Link
                     style={{ textDecoration: 'none', color: 'inherit' }}
                    to="/survey_start"
                    state={{
                      fromOne: "stakeholder",
                      from: props?.template?.id,
                      company:props?.template?.assessmentCustomerAccount?.assessmentCustomer?.customerName,
                      permissions: props?.permissions,
                      templateSelectedData : props?.template
                    }}
                  >
                    <Groups2Icon sx={{ fontSize: "2rem" }} />

                  </Link>

                </IconButton>
              </MuiTooltip>}
            </Box>


          </Grid>





        </Grid>

      </GridToolbarContainer>
    );
  };

  return (
    <div
      style={{
        height: props.height ? props.height : 500,
        width: "100%",
        "&.superAppTheme": {
          "&:nth-of-type(odd)": {
            bgcolor: (theme) => theme.palette.primary.light,

          },
        },
      }}
    >
      <DataGrid
        className={classes.filter_grid}
        {...props.data}
        components={{
          Toolbar: CustomToolbar,
          Row: CustomRow,

        }}
        pageSize={100}
        // rowsPerPageOptions={[25]}
        // pagination
        onSelectionModelChange={(ids) => {
          const selectedRowData =
            props.data.rows.length > 0 &&
            props.data.rows.filter(
              (row) => ids.length > 0 && row.id === ids[0]
            );
          props.select(selectedRowData);
          setSelectedRow(selectedRowData);
        }}
      />
    </div>
  );
}