import React, { useState, useEffect } from "react";
// components
import Layout from "../../../layout/Layout";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import Pagination from "@mui/material/Pagination";
import LinearProgress from "@mui/material/LinearProgress";
import { Link,useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Excoimage from "../../../../src/assets/images/signin-logo.png";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import ShortResponse from "../Ratings/short response";
import SurveyFinishPage from "../surveyFinish";
import CancelIcon from "@mui/icons-material/Cancel";

//react-redux
import { useSelector, useDispatch } from "react-redux";
// import {
//   addingQuestionTemplate,
//   getPreviewByTemplateIdForAssessor,
//   finishAssessmentForClients,
//  getPreviewByTemplateIdForClients
// } from "../../features/dashboard/dashBoardSlice";

import { getPreviewByTemplateIdForAssessor,getPreviewByTemplateIdForClients } from "../../../features/prepare_assessments/prepareQuestionSlice";
import { IndeterminateCheckBoxTwoTone } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem",
    },
  },
  sliderParent: {
    display: "flex !important",
    flexDirection: "row !important",
    alignItems: "center !important",
    marginLeft: "2rem !important",
    width: "90% !important",

    [theme.breakpoints.down("sm")]: {
      display: "flex !important",
      flexDirection: "column !important",
      alignItems: "center !important",
      marginLeft: "2rem !important",
      width: "`100% !important",
    },
  },

  radiocontrol: {
    marginLeft: "5rem !important",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0rem !important",
      alignSelf: "start",
    },
  },
}));

const Assesment = () => {
  const [countValue, setCountValue] = useState(0);
  const [progress, setProgress] = React.useState(0);
  const [dataSet, setDataSet] = React.useState([]);
  const [pageNo, setPageNo] = React.useState(0);
  const [pageArr, setPageArr] = React.useState([]);
  const [sliderValue, setSliderValue] = React.useState(20);
  const [slValue, setSlValue] = React.useState("");
  const [ratingData, setRatingData] = React.useState([]);
  const [radioValue, setRadioValue] = React.useState(null);
  const [progressValue, setProgressValue] = React.useState(0);
  const [lastPageToggle, setLastPageToggle] = React.useState(false);

  const { assessmentTemplateData } = useSelector((props) => ({
    ...props.assessmentTemplateQuestion,
  }));

  const location = useLocation();
  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const marks = [
    {
      value: 0,
      label: " Never",
    },
    {
      value: 25,
      label: "Rarely",
    },
    {
      value: 50,
      label: "Sometimes",
    },
    {
      value: 75,
      label: "Often",
    },
    {
      value: 100,
      label: "Always",
    },
  ];

  let dispatch = useDispatch();

  // Arrage Data for Pagenation
  let currentSection = "";
  let currentDimension = "";
  let currentQuestion = "";
  let currentQuestionType = "";
  let questionType = "";
  let obj = {};
  let data = [];
  let currentPageNo = 1;

  let isCheckingSelf = localStorage.getItem("role");

  assessmentTemplateData?.length > 0 &&
  assessmentTemplateData.forEach((item) => {
    if (
      currentSection !== item.as_sectionName ||
      currentDimension !== item.ad_dimensionName ||
      ((currentQuestionType === "SR" || currentQuestionType === "SLR") &&
        (item.aq_questionType === "Rating" ||
          item.aq_questionType === "OC")) ||
      item.aq_questionType === "SR" ||
      item.aq_questionType === "SLR"
    ) {
      obj.pageNumber = currentPageNo;
      obj.data = [];
      let newobj;

      if (item?.rt_ratingResponse === 0) {
        newobj = JSON.parse(JSON.stringify(item));

        newobj.rt_ratingResponse = 9;
      }
      if (newobj) {
        obj.data.push(newobj);
      } else {
        obj.data.push(item);
      }
      data.push(obj);
      obj = {};
      currentPageNo++;
      currentSection = item.as_sectionName;
      currentDimension = item.ad_dimensionName;
      currentQuestionType = item.aq_questionType;
    } else {
      currentSection = item.as_sectionName;
      currentDimension = item.ad_dimensionName;
      currentQuestion =   item?.aq_questionName ? item?.aq_questionName : item?.aq_clientQuestion ;
      const lastIndex = data.length - 1;

      let newobj;

      if (item?.rt_ratingResponse === 0) {
        newobj = JSON.parse(JSON.stringify(item));

        newobj.rt_ratingResponse = 9;
      }
      if (newobj) {
        data[lastIndex].data.push(newobj);
      } else {
        data[lastIndex].data.push(item);
      }
    }
  });

  const handleSlChange = (e, items) => {
    let body =
      items.aq_questionType === "SR"
        ? {
            assessmentTrackingId: localStorage.getItem("state"),
            assessmentQuestionId: items?.aq_id,
            shortResponse: e.target.value,
          }
        : {
            assessmentTrackingId: localStorage.getItem("state"),
            assessmentQuestionId: items?.aq_id,
            longResponse: e.target.value,
          };

    setSlValue(e.target.value);
    // dispatch(addingQuestionTemplate(body));
  };

  useEffect(() => {
    if(isCheckingSelf === "Self"){
      dispatch(
       getPreviewByTemplateIdForClients({
          assessmentTrackingId: localStorage.getItem("state"),
        })
      );
     }
     else  if(isCheckingSelf === "stakeholder") {
      dispatch(
        getPreviewByTemplateIdForAssessor({
          assessmentTrackingId: localStorage.getItem("state"),
        })
      );
     }
  }, []);

  useEffect(() => {
    let result1 = data.length > 0 && data.length;
    let result2 =
      (pageNo / result1) * 100 === NaN ? 0 : (pageNo / result1) * 100;
    setProgress(result2);
  }, [pageNo]);

  const handlePageFinish = () => {
    // dispatch(finishAssessmentForClients({ id: localStorage.getItem("state") }));
    setLastPageToggle(true);
  };

  useEffect(() => {
    if (data[pageNo]?.data[0]?.aq_questionType === "Rating") {
      setRatingData(data[pageNo].data);
    }
    if (data[pageNo]?.data[0].aq_questionType === "SR") {
      setSlValue(data[pageNo]?.data[0]?.rt_shortResponse);
    } else if (data[pageNo]?.data[0].aq_questionType === "SLR") {
      setSlValue(data[pageNo]?.data[0]?.rt_longResponse);
    }
  }, [pageNo]);

  const classes = useStyles();
  const client = `${localStorage.getItem("clientName")}`;
  let firstName = client?.split("\n")[0];



  return (
    <Layout isSidebarNotShow={true}>
       <Link
        to="/exco/prepare/prepare-questions"
        state={{permissions: location?.state?.permissions,templateSelectedData : location?.state?.templateSelectedData}} 
        // state={{
        //   l3Menu: location?.state?.l3Menu,
        //   permissions: location?.state?.permissions,
        // }}
      >
        <CancelIcon
          color="red"
          sx={{ position: "absolute", right: 80, zIndex: 1 }}
          // className={classes.responsive_close}
        />
      </Link>
      {!lastPageToggle && (
        <Grid className={classes.root} container spacing={2}>
          {data.length > 0 &&
            data.map((item, index) => {
              return data[index]?.data[0]?.aq_questionType === "Rating" ||
                data[index]?.data[0]?.aq_questionType === "OC" ? (
                <>
                  <Grid item xs={12} md={12}>
                    <Typography
                      sx={{
                        fontSize: "1.5rem",
                        fontWeight: "700",
                        fontFamily: "dinBold",
                        alignSelf: "flex-start",
                      }}
                      variant="h6"
                      gutterBottom
                      component="div"
                    >
                      {data[index]?.data[0]?.as_sectionName}
                    </Typography>
                    <Typography
                  sx={{
                    fontSize: "1.4rem",
                    // fontWeight: "400",
                    fontFamily: "dinBold",
                    alignSelf: "flex-start",
                  }}
                  variant="h6"
                  gutterBottom
                  component="div"
                >
                  {data[index]?.data[0]?.ad_dimensionName}
                </Typography>
                  </Grid>

                  {data[index]?.data.length > 0 &&
                    data[index]?.data.map((items, ind) => {
                      if (
                        data[index]?.data[ind]?.aq_questionType === "Rating"
                      ) {
                        return (
                          <Grid
                            sx={{ marginTop: "1rem", marginLeft: "1rem" }}
                            item
                            sm={12}
                            xs={12}
                            md={12}
                          >
                            <Typography
                              sx={{
                                fontSize: "1rem",
                                fontWeight: 400,
                              }}
                              component="div"
                            >
                               {isCheckingSelf === "Self" ? `I` : `<<Client Name>>`}{" "}
                               {items?.aq_questionName ? items?.aq_questionName : items?.aq_clientQuestion }
                            </Typography>

                            <Stack className={classes.sliderParent}>
                              <Slider
                                disabled
                                track={false}
                                size="small"
                                sx={{
                                  marginTop: "0.3rem",
                                  " & .MuiSlider-mark": {
                                    width: "0.25rem",
                                    height: "0.25rem",
                                    borderRadius: "3rem",
                                    background: "white",
                                    borderStyle: "solid",
                                    borderColor: "#808080",
                                  },

                                  " & .MuiSlider-thumb": {
                                    position: "absolute !important",
                                    width: "3rem !important",
                                    height: "3rem !important",
                                    marginLeft: "0.3rem !important",
                                    boxSizing: "border-box !important",
                                    backgroundColor: "#ffffff00 !important",
                                    display: "flex",
                                    webkitBoxAlign: "center !important",
                                    alignItems: "center",
                                    WebkitBoxPack: "center !important",
                                    justifyContent: "center",
                                    top: "50%",
                                    transform: "translate(-50%,-50%)",
                                    left: "0%",
                                    backgroundImage:
                                      ((items?.rt_ratingResponse &&
                                        items?.rt_ratingResponse !== 9) ||
                                        items?.rt_ratingResponse === 0) &&
                                      `url(${Excoimage}) !important`,

                                    backgroundRepeat: "no-repeat !important",
                                    backgroundSize: "45px 45px !important",
                                    border: "none",
                                    transition: "none 0s ease 0s",
                                  },
                                }}
                                //  sx={{flex:1}}
                                value={
                                  (items?.rt_ratingResponse === 1 && 0) ||
                                  (items?.rt_ratingResponse === 2 && 25) ||
                                  (items?.rt_ratingResponse === 3 && 50) ||
                                  (items?.rt_ratingResponse === 4 && 75) ||
                                  (items?.rt_ratingResponse === 5 && 100)
                                }
                                step={20}
                                marks={marks}
                              />
                              <FormControl className={classes.radiocontrol}>
                                <RadioGroup
                                  column
                                  aria-labelledby="demo-form-control-label-placement"
                                  name="position"
                                >
                                  <FormControlLabel
                                    value="initialVal"
                                    control={
                                      <Radio
                                        disabled
                                        checked={items?.rt_ratingResponse === 9}
                                        size="small"
                                      />
                                    }
                                    labelPlacement="bottom"
                                    label={
                                      <FormLabel
                                        style={{
                                          fontSize: "0.8rem",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        Not in a position to observe
                                      </FormLabel>
                                    }
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Stack>
                          </Grid>
                        );
                      } else {
                        return (
                          <Grid
                            sx={{ marginLeft: "1rem" }}
                            item
                            xs={12}
                            md={12}
                          >
                            <TextField
                              sx={{ width: "100%" }}
                              hiddenLabel
                              variant="filled"
                              placeholder="Optional Comment"
                              value={items?.rt_optionalComment}
                            />
                          </Grid>
                        );
                      }
                    })}
                </>
              ) : (
                <Grid xs={12} md={12}>
                  {data.length > 0 &&
                    data[index].data.length > 0 &&
                    (data[index].data[0].aq_questionType === "SR" ||
                      data[index].data[0].aq_questionType === "SLR") && (
                      <Stack
                        sx={{ paddingBottom: "1.4rem", marginTop: "2rem" }}
                      >
                        <ShortResponse
                          isPreview={true}
                          value={
                            data[index]?.data[0]?.rt_longResponse ||
                            data[index]?.data[0]?.rt_shortResponse
                          }
                          change={handleSlChange}
                          data={data[index]?.data[0]}
                          title={data[index].data[0].aq_questionType}
                        />
                      </Stack>
                    )}
                </Grid>
              );
            })}

          <Grid sx={{ marginLeft: "1rem" }} item xs={12} md={12}>
            <Stack
              sx={{
                paddingBottom: "1rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Link style={{ textDecoration: "none" }} to="/rating">
                <Button
                  sx={{ width: "8rem" }}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Modify
                </Button>
              </Link>

              <Button
                onClick={handlePageFinish}
                sx={{ width: "8rem", marginLeft: "1rem" }}
                variant="contained"
                color="primary"
                size="large"
              >
                Submit
              </Button>
            </Stack>
          </Grid>
        </Grid>
      )}

      {lastPageToggle && (
        <Grid xs={12} md={12}>
          <SurveyFinishPage />
        </Grid>
      )}
    </Layout>
  );
};

export default Assesment;
