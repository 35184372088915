import { ASSESSMENT_BASE_URL } from "./config";

// ----------------------------------------------------------

// login authentication apis
export const IS_LOGGED_IN = `${ASSESSMENT_BASE_URL}/v1/api/assessment-user/is-assessment-user-logged-in`

export const EMAIL_VERIFY = `${ASSESSMENT_BASE_URL}/v1/api/assessment-user/verify/login`;

export const RESEND_EXCO_USER_EMAIL = `${ASSESSMENT_BASE_URL}/v1/api/authentication/resend-verification-email`;

export const GET_ALL_ROLES = `${ASSESSMENT_BASE_URL}/v1/api/authentication/roles`;

export const CREATE_ACCOUNT = `${ASSESSMENT_BASE_URL}/v1/api/assessment-user`;

export const UPDATE_ACCOUNT = `${ASSESSMENT_BASE_URL}/v1/api/assessment-user`;

export const GET_ACCOUNTS = `${ASSESSMENT_BASE_URL}/v1/api/assessment-user`;

export const DELETE_ACCOUNT = `${ASSESSMENT_BASE_URL}/v1/api/assessment-user`;

export const CHANGE_PRIMARY_ROLE = `${ASSESSMENT_BASE_URL}/v1/api/assessment-user/change-primary-role`


export const ACTIVATE_ACCOUNT = `${ASSESSMENT_BASE_URL}/v1/api/assessment-user/confirm-account`;

export const ACTIVATE_ACCOUNT_MAIL_SEND = `${ASSESSMENT_BASE_URL}/v1/api/assessment-user/send-secret`

export const RESEND_ACTIVATION_MAIL = `${ASSESSMENT_BASE_URL}/v1/api/assessment-user/resend-verification-email`


export const GET_ALL_EXEC = `${ASSESSMENT_BASE_URL}/v1/api/authentication/all/exco-exec`;

export const GET_ALL_AM = `${ASSESSMENT_BASE_URL}/v1/api/authentication/all/exco-am`

export const LOGIN_ACCOUNT = `${ASSESSMENT_BASE_URL}/v1/api/assessment-user/login`;

export const VERIFY_TOKEN = `${ASSESSMENT_BASE_URL}/v1/api/assessment-user/verify/login-code`

export const LOGOUT_ACCOUNT = `${ASSESSMENT_BASE_URL}/v1/api/assessment-user/logout`;

export const GET_CURRENT_USER = `${ASSESSMENT_BASE_URL}/v1/api/assessment-user/current-user`;

export const GET_CURRENT_USER_BY_ROLE = `${ASSESSMENT_BASE_URL}/v1/api/assessment-user/current-user`;

export const GET_CURRENT_ROLES = `${ASSESSMENT_BASE_URL}/v1/api/assessment-user/get-all-roles-of-user`;

export const UPLOAD_PROFILE_PIC = `${ASSESSMENT_BASE_URL}/v1/api/assessment-user/profile-pic`

// forgot password
export const FORGOT_PASSWORD_EMAIL_VERIFY = `${ASSESSMENT_BASE_URL}/v1/api/assessment-user/forgot-password`

export const FORGOT_PASSWORD_RESET = `${ASSESSMENT_BASE_URL}/v1/api/assessment-user/forgot-password-confirm`

export const FORGOT_PASSWORD_SEND_OTP = `${ASSESSMENT_BASE_URL}/v1/api/assessment-user/send-forgot-secret`

// Business
export const CURD_CUSTOMERS = `${ASSESSMENT_BASE_URL}/v1/api/assessment-customer`

export const CURD_ACCOUNTS = `${ASSESSMENT_BASE_URL}/v1/api/assessment-customer-account`

// customer user
export const PREVIEW_CUSTOMER_EMAIL_TEMPLATE = `${ASSESSMENT_BASE_URL}/v1/api/preview-mail/preview-reminder-email-new-activate-user`
export const RESEND_CUST_USER_EMAIL = `${ASSESSMENT_BASE_URL}/v1/api/assessment-customer-user/resend-verification-email`
export const CREATE_CUST_USER = `${ASSESSMENT_BASE_URL}/v1/api/assessment-customer-user`  
export const GET_CUST_USER = `${ASSESSMENT_BASE_URL}/v1/api/assessment-customer-user`  
export const GET_CUST_USER_BY_ACCOUNT_ID = `${ASSESSMENT_BASE_URL}/v1/api/assessment-customer-user/get-by-assessment-customer-account-id`
export const GET_CUST_USER_BY_CUSTOMER_ID = `${ASSESSMENT_BASE_URL}/v1/api/assessment-customer-user/get-by-assessment-customer-id`
export const UPDATE_CUST_USER = `${ASSESSMENT_BASE_URL}/v1/api/assessment-customer-user`  
export const DELETE_CUST_USER = `${ASSESSMENT_BASE_URL}/v1/api/assessment-customer-user`  
export const FORCE_DELETE_CUST_USER = `${ASSESSMENT_BASE_URL}/v1/api/assessment-customer-user/force`
export const GET_CUST_CLIENTS = `${ASSESSMENT_BASE_URL}/v1/api/authentication/forgot-password/reset`  
export const GET_CUST_HR = `${ASSESSMENT_BASE_URL}/v1/api/authentication/forgot-password/reset`  
export const GET_CUST_MANAGER = `${ASSESSMENT_BASE_URL}/v1/api/authentication/forgot-password/reset`  
export const GET_CLIENT_BY_CUST = `${ASSESSMENT_BASE_URL}/v1/api/authentication/forgot-password/reset`  
export const GET_CUST_USER_NOT_MAPPED = `${ASSESSMENT_BASE_URL}/v1/api/authentication/forgot-password/reset`  
export const GET_CUST_USER_WITHOUT_OPP = `${ASSESSMENT_BASE_URL}/v1/api/authentication/forgot-password/reset`  
export const GET_ALL_CLIENT_BY_ACC = `${ASSESSMENT_BASE_URL}/v1/api/authentication/forgot-password/reset`  
export const GET_CUST_USER_ROLES = `${ASSESSMENT_BASE_URL}/v1/api/authentication/forgot-password/reset`  


// config
export const CURD_L1_MENU = `${ASSESSMENT_BASE_URL}/v1/api/level1-menu`;

export const CURD_L2_MENU = `${ASSESSMENT_BASE_URL}/v1/api/level2-menu`;

export const CURD_L3_MENU = `${ASSESSMENT_BASE_URL}/v1/api/level3-menu`;

export const CURD_API_MANAGEMENT = `${ASSESSMENT_BASE_URL}/v1/api/application-service`;

export const CURD_USER_GROUPS = `${ASSESSMENT_BASE_URL}/v1/api/user-group`;

export const CURD_ROLES = `${ASSESSMENT_BASE_URL}/v1/api/user-role`;

export const CURD_L1_MENU_PERMISSIONS = `${ASSESSMENT_BASE_URL}/v1/api/user-role-l1menu-permission`

export const GET_L1_MENU_PERMISSIONS_ROLE = `${ASSESSMENT_BASE_URL}/v1/api/user-role-l1menu-permission/get-all-menu`

export const CURD_L1_SERVICE_PERMISSIONS = `${ASSESSMENT_BASE_URL}/v1/api/user-role-l1service-permission`

export const GET_L1_SERVICE_PERMISSIONS_ROLE = `${ASSESSMENT_BASE_URL}/v1/api/user-role-l1service-permission/get-all-service`

export const CURD_L2_MENU_PERMISSIONS = `${ASSESSMENT_BASE_URL}/v1/api/user-role-l2menu-permission`

export const GET_L2_MENU_PERMISSIONS_ROLE = `${ASSESSMENT_BASE_URL}/v1/api/user-role-l2menu-permission/get-all-menu`

export const CURD_L2_SERVICE_PERMISSIONS = `${ASSESSMENT_BASE_URL}/v1/api/user-role-l2service-permission`

export const GET_L2_SERVICE_PERMISSIONS_ROLE = `${ASSESSMENT_BASE_URL}/v1/api/user-role-l2service-permission/get-all-service`

export const CURD_L3_MENU_PERMISSIONS = `${ASSESSMENT_BASE_URL}/v1/api/user-role-l3menu-permission`

export const GET_L3_MENU_PERMISSIONS_ROLE = `${ASSESSMENT_BASE_URL}/v1/api/user-role-l3menu-permission/get-all-menu`

export const CURD_L3_SERVICE_PERMISSIONS = `${ASSESSMENT_BASE_URL}/v1/api/user-role-l3service-permission`

export const GET_L3_SERVICE_PERMISSIONS_ROLE = `${ASSESSMENT_BASE_URL}/v1/api/user-role-l3service-permission/get-all-service`

export const CURD_ASSESSMENT_PROGRESS = `${ASSESSMENT_BASE_URL}/v1/api/assessment-progress`
export const CURD_ASSESSMENT_STATUS = `${ASSESSMENT_BASE_URL}/v1/api/assessment-status`
export const CURD_PLATFORM_STATUS = `${ASSESSMENT_BASE_URL}/v1/api/assessment-template-status`
export const CURD_QUESTION_TYPE = `${ASSESSMENT_BASE_URL}/v1/api/question-type`
export const CURD_QUESTION_RATING_VALUE = `${ASSESSMENT_BASE_URL}/v1/api/question-rating-value`
export const CURD_RESPONSE_STATUS = `${ASSESSMENT_BASE_URL}/v1/api/response-status`
export const CURD_INDIVIDUAL_RESPONSE_STATUS = `${ASSESSMENT_BASE_URL}/v1/api/individual-response-status`
export const CURD_RELATIONSHIP_TYPE = `${ASSESSMENT_BASE_URL}/v1/api/relationship-type`

// notification
export const GET_ALL_NOTIFICATION = `${ASSESSMENT_BASE_URL}/v1/api/notification-service/admin`

// Prepare Assessment 
export const ARCHIVED_ASSESSMENT_TEMPLATE= `${ASSESSMENT_BASE_URL}/v1/api/assessment-template/archived`

export const ASSESSMENT_TEMPLATE_BY_ACCOUNT_ID = `${ASSESSMENT_BASE_URL}/v1/api/assessment-template/get-template-by-account-id`
export const ASSESSMENT_TEMPLATE_BY_CUSTOMER_ID = `${ASSESSMENT_BASE_URL}/v1/api/assessment-template/get-template-by-customer-id`
export const CURD_ASSESSMENT_TEMPLATE = `${ASSESSMENT_BASE_URL}/v1/api/assessment-template`
export const COPY_ASSESSMENT_TEMPLATE = `${ASSESSMENT_BASE_URL}/v1/api/assessment-template/create-deep-copy`
export const FORCE_DELETE_ASSESSMENT_TEMPLATE = `${ASSESSMENT_BASE_URL}/v1/api/assessment-template/force`
export const CURD_ASSESSMENT_TEMPLATE_SECTION = `${ASSESSMENT_BASE_URL}/v1/api/assessment-section`
export const GET_ASSESSMENT_TEMPLATE_SECTION_BY_TEMPLATE_ID = `${ASSESSMENT_BASE_URL}/v1/api/assessment-section/get-by-template-id`
export const CURD_ASSESSMENT_TEMPLATE_DIMENSION = `${ASSESSMENT_BASE_URL}/v1/api/assessment-dimension`
export const GET_ASSESSMENT_TEMPLATE_DIMENSION_BY_TEMPLATE_ID = `${ASSESSMENT_BASE_URL}/v1/api/assessment-dimension/get-by-template-id`
export const CURD_ASSESSMENT_TEMPLATE_QUESTION = `${ASSESSMENT_BASE_URL}/v1/api/assessment-question-config`
export const GET_ASSESSMENT_TEMPLATE_QUESTION_BY_TEMPLATE_ID = `${ASSESSMENT_BASE_URL}/v1/api/assessment-question-config/get-by-template-config-id`
//
export const GET_ASSESSMENT_TEMPLATE_QUESTION_BY_SECTION_ID = `${ASSESSMENT_BASE_URL}/v1/api/assessment-question/get-by-section-id-config`;
export const GET_ASSESSMENT_TEMPLATE_QUESTION_BY_SECTION_ID_MAIN = `${ASSESSMENT_BASE_URL}/v1/api/assessment-question/get-by-section-id`;
export const GET_ASSESSMENT_TEMPLATE_QUESTION_BY_DIMENSION_ID_MAIN = `${ASSESSMENT_BASE_URL}/v1/api/assessment-question/get-by-dimension-id`;
export const GET_ASSESSMENT_TEMPLATE_QUESTION_BY_DIMENSION_ID = `${ASSESSMENT_BASE_URL}/v1/api/assessment-question/get-by-dimension-id-config`;
export const CURD_ASSESSMENT_TEMPLATE_QUESTION_MAIN = `${ASSESSMENT_BASE_URL}/v1/api/assessment-question`;
export const GET_ASSESSMENT_TEMPLATE_QUESTION_BY_TEMPLATE_ID_MAIN = `${ASSESSMENT_BASE_URL}/v1/api/assessment-question/get-by-template-id`;

export const GET_ASSESSMENT_TEMPLATE_DIMENSION_BY_SECTION_ID = `${ASSESSMENT_BASE_URL}/v1/api/assessment-dimension/get-by-section-id`;

export const UPLOAD_PREPARE_QUESTIONS_TEMPLATE = `${ASSESSMENT_BASE_URL}/v1/api/excel-parser/batch-upload-question-template-prepare`;
export const DOWNLOAD_PREPARE_QUESTIONS_TEMPLATE = `${ASSESSMENT_BASE_URL}/v1/api/excel-parser/template/batch-upload-assessment`;

// Publish Assessment
export const CURD_PUBLISH_ASSESSMENT = `${ASSESSMENT_BASE_URL}/v1/api/assessment-tracking`
export const GET_ARCHIVED_PUBLISH_ASSESSMENT = `${ASSESSMENT_BASE_URL}/v1/api/assessment-tracking/archived`
export const CURD_MAP_ASSESSOR = `${ASSESSMENT_BASE_URL}/v1/api/assessor-mapping`
export const PUBLISH_ASSESSMENT = `${ASSESSMENT_BASE_URL}/v1/api/assessment-tracking/publish-assessment`
export const PUBLISH_ASSESSMENT_BY_ACCOUNT_ID = `${ASSESSMENT_BASE_URL}/v1/api/assessment-tracking/get-tracking-by-account-id`
export const PUBLISH_ASSESSMENT_BY_CUSTOMER_ID = `${ASSESSMENT_BASE_URL}/v1/api/assessment-tracking/get-tracking-by-customer-id`
export const BATCH_UPLOAD_CUSTOMER_USER = `${ASSESSMENT_BASE_URL}/v1/api/excel-parser/batch-upload-assessment-customer-user`
export const GET_MAP_ASSESSORS_BY_TRACKING_ID = `${ASSESSMENT_BASE_URL}/v1/api/assessor-mapping/get-by-tracking-id`
export const GET_NOT_MAPPED_ASSESSORS = `${ASSESSMENT_BASE_URL}/v1/api/assessment-customer-user/get-by-tracking-id-and-customer-id-not-mapped`
export const BATCH_UPLOAD_ASSESSOR = `${ASSESSMENT_BASE_URL}/v1/api/excel-parser/batch-upload-assessor`
export const BULK_UPLOAD_CUSTOMER_USER = `${ASSESSMENT_BASE_URL}/v1/api/excel-parser/batch-upload-assessment`
export const PREVIEW_PUBLISH_REMMINDER = `${ASSESSMENT_BASE_URL}/v1/api/preview-mail/preview-publish-email`
export const PREVIEW_REPORT_REMMINDER = `${ASSESSMENT_BASE_URL}/v1/api/preview-mail/preview-reminder-email`

// Report Assessment
export const REPORT_ASSESSMENT_BY_ID = `${ASSESSMENT_BASE_URL}/v1/api/assessment-template/get-by-customer-account-id`
export const CURD_ASSESSMENT_RESPONSE = `${ASSESSMENT_BASE_URL}/v1/api/assessment-tracking/get-by-template-id`

export const CURD_ASSESSORS_RESPONSE_LIST = `${ASSESSMENT_BASE_URL}/v1/api/assessment-tracking/get-all-accessors-by-id`

export const CURD_REMAINDER_RESPONSE_LIST = `${ASSESSMENT_BASE_URL}/v1/api/assessment-tracking-reminder`

export const SEND_REMINDER_MAIL = `${ASSESSMENT_BASE_URL}/v1/api/assessment-tracking/send-reminder-email`

export const GET_REPORT = `${ASSESSMENT_BASE_URL}/v1/api/assessment-tracking/get-report`

export const GET_OPTIONAL_COMMENTS_REPORT = `${ASSESSMENT_BASE_URL}/v1/api/assessment-tracking/get-report-optional-comments`

export const GET_SHORT_RESPONSE_REPORT = `${ASSESSMENT_BASE_URL}/v1/api/assessment-tracking/get-report-short-response`

export const GET_LONG_RESPONSE_REPORT = `${ASSESSMENT_BASE_URL}/v1/api/assessment-tracking/get-report-long-response`


export const UPDATE_ASSESSOR_CLIENT_REPORT = `${ASSESSMENT_BASE_URL}/v1/api/assessment-tracking/update-self-or-assessor-assessment-status`

export const CURD_CONTACT_DESIGNATION = `${ASSESSMENT_BASE_URL}/v1/api/contact-designation`;

export const CURD_CUSTOMER = `${ASSESSMENT_BASE_URL}/v1/api/customer`;


export const CURD_TEMPLATE_TYPE = `${ASSESSMENT_BASE_URL}/v1/api/assessment-type`;

export const CURD_ASSESSMENT_TEMPLATE_CONFIG = `${ASSESSMENT_BASE_URL}/v1/api/assessment-template-config`;
export const COPY_ASSESSMENT_TEMPLATE_CONFIG = `${ASSESSMENT_BASE_URL}/v1/api/assessment-template-config/create-deep-copy`;
export const FORCE_DELETE_ASSESSMENT_TEMPLATE_CONFIG = `${ASSESSMENT_BASE_URL}/v1/api/assessment-template-config/force`;
export const CURD_ASSESSMENT_TEMPLATE_SECTION_CONFIG = `${ASSESSMENT_BASE_URL}/v1/api/assessment-section-config`;
export const GET_ASSESSMENT_TEMPLATE_SECTION_CONFIG_BY_TEMPLATE_ID = `${ASSESSMENT_BASE_URL}/v1/api/assessment-section-config/get-by-template-id`;
export const CURD_ASSESSMENT_TEMPLATE_DIMENSION_CONFIG = `${ASSESSMENT_BASE_URL}/v1/api/assessment-dimension-config`;
export const GET_ASSESSMENT_TEMPLATE_DIMENSION_CONFIG_BY_TEMPLATE_ID = `${ASSESSMENT_BASE_URL}/v1/api/assessment-dimension-config/get-by-template-id`;
export const GET_ASSESSMENT_TEMPLATE_DIMENSION_CONFIG_BY_SECTION_ID = `${ASSESSMENT_BASE_URL}/v1/api/assessment-dimension-config/get-by-section-id`;


export const CRUD_SECTION_CONFIG_QUESTIONS = `${ASSESSMENT_BASE_URL}/v1/api/assessment-question-config`;
export const GET_SECTION_CONFIG_QUESTIONS = `${ASSESSMENT_BASE_URL}/v1/api/assessment-question-config/get-by-section-config-id`;
export const GET_DIMENSION_CONFIG_QUESTIONS = `${ASSESSMENT_BASE_URL}/v1/api/assessment-question-config/get-by-dimension-config-id`;


export const GET_ACCOUNT_BY_ID = `${ASSESSMENT_BASE_URL}/v1/api/assessment-customer-account/get-by-customer-id`

export const CREATE_ASSESSMENT_TEMPLATE_PRE_DEFINED = `${ASSESSMENT_BASE_URL}/v1/api/assessment-template/create-deep-copy/framework-template`;


//Assessment framework
export const CURD_ASSESSMENT_FRAMEWORK = `${ASSESSMENT_BASE_URL}/v1/api/framework-template`;
export const CURD_ASSESSMENT_FRAMEWORK_COMPLETED = `${ASSESSMENT_BASE_URL}/v1/api/framework-template/completed`;
export const CURD_ASSESSMENT_FRAMEWORK_DIMENSION = `${ASSESSMENT_BASE_URL}/v1/api/framework-dimension`;
export const CURD_ASSESSMENT_FRAMEWORK_SECTION = `${ASSESSMENT_BASE_URL}/v1/api/framework-section`;
export const GET_ASSESSMENT_FRAMEWORK_SECTION_BY_FRAMEWORK_ID = `${ASSESSMENT_BASE_URL}/v1/api/framework-section/get-by-framework-id`;
export const GET_ASSESSMENT_FRAMEWORK_DIMENSION_BY_FRAMEWORK_ID = `${ASSESSMENT_BASE_URL}/v1/api/framework-dimension/get-by-section-framework-id`;
export const GET_ASSESSMENT_FRAMEWORK_DIMENSION_BY_SECTION_ID = `${ASSESSMENT_BASE_URL}/v1/api/assessment-dimension-framework/get-by-section-framework-id`;


export const VIEW_ASSESSMENT_FRAMEWORK_RADAR_GRAPH = `${ASSESSMENT_BASE_URL}/v1/api/framework-template/get-report`;
export const COPY_ASSESSMENT_FRAMEWORK= `${ASSESSMENT_BASE_URL}/v1/api/framework-template/create-deep-copy`;
export const FORCE_DELETE_ASSESSMENT_FRAMEWORK = `${ASSESSMENT_BASE_URL}/v1/api/framework-template/force`;

//dashboard

export const GET_ALL_STATUS_ALL_CUSTOMERS = `${ASSESSMENT_BASE_URL}/v1/api/dashboard-graph/all-status-count`;
export const GET_STATUS_ALL_CUSTOMERS_ACCOUNTS = `${ASSESSMENT_BASE_URL}/v1/api/dashboard-graph/all-status-count-by-cutomer`;
export const GET_STATUS_DETAILS_CUSTOMERS = `${ASSESSMENT_BASE_URL}/v1/api/dashboard-graph/pie-graph-details`;


export const GET_STATUS_ALL_CUSTOMERS_ACCOUNTS_TWO = `${ASSESSMENT_BASE_URL}/v1/api/dashboard-graph/all-status-count-by-cutomer`;
export const GET_CLIENTS_DATA_CUSTOMERS_DETAILS_ACCOUNTS_GRAPH= `${ASSESSMENT_BASE_URL}/v1/api/dashboard-graph/bar-graph-by-details`;

export const GET_CLIENTS_DATA_CUSTOMERS_ACCOUNTS_GRAPH= `${ASSESSMENT_BASE_URL}/v1/api/dashboard-graph/bar-graph-all-status-count-by-cutomer`;
export const GET_ALL_STATUS_ALL_CUSTOMERS_TWO = `${ASSESSMENT_BASE_URL}/v1/api/dashboard-graph/bar-graph`;

export const GET_ALL_STATUS_EXCEL_DOWNLOAD = `${ASSESSMENT_BASE_URL}/v1/api/dashboard-graph/execl-download-pie`;
export const GET_ALL_USERS_EXCEL_DOWNLOAD = `${ASSESSMENT_BASE_URL}/v1/api/dashboard-graph/execl-download-bar`;


export const GET_PREVIEW_BY_TEMPLATE_ID_CLIENT = `${ASSESSMENT_BASE_URL}/v1/api/assessment-tracking/client-preview-by-template-id`;

export const GET_PREVIEW_BY_TEMPLATE_ID_ASSESSOR = `${ASSESSMENT_BASE_URL}/v1/api/assessment-tracking/preview-by-template-id`;
