import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_INDIVIDUAL_RESPONSE_STATUS } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createIndividualResponseStatus = createAsyncThunk(
  "individualResponseStatus/createIndividualResponseStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_INDIVIDUAL_RESPONSE_STATUS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getIndividualResponseStatus = createAsyncThunk(
  "individualResponseStatus/getIndividualResponseStatus",
  async (obj, {rejectWithValue}) => {
    try {
      const response = await axios.get(CURD_INDIVIDUAL_RESPONSE_STATUS, { withCredentials: true });
      return response
    } catch (err) {
      return rejectWithValue(err.response) 
    }
  }
);

export const updateIndividualResponseStatus = createAsyncThunk(
  "individualResponseStatus/updateIndividualResponseStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_INDIVIDUAL_RESPONSE_STATUS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteIndividualResponseStatus = createAsyncThunk(
  "individualResponseStatus/deleteIndividualResponseStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_INDIVIDUAL_RESPONSE_STATUS, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const individualResponseStatusSlice = createSlice({
  name: "individualResponseStatus",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    individualResponseStatusData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createIndividualResponseStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [createIndividualResponseStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createIndividualResponseStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getIndividualResponseStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [getIndividualResponseStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.individualResponseStatusData = action?.payload?.data;
    },
    [getIndividualResponseStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateIndividualResponseStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [updateIndividualResponseStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateIndividualResponseStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteIndividualResponseStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteIndividualResponseStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteIndividualResponseStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  individualResponseStatusSlice.actions;
export default individualResponseStatusSlice.reducer;
