import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_L3_MENU } from "../../../../utils/routes";

// ---------------------------------------------------------------------

export const createL3Menu = createAsyncThunk(
  "l3Menu/createL3Menu",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_L3_MENU, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getL3Menu = createAsyncThunk(
  "l3Menu/getL3Menu",
  async (obj, {rejectWithValue}) => {
    try {
      const response = await axios.get(CURD_L3_MENU, { withCredentials: true });
      return response;
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
);

export const getL3MenuByL2Menu = createAsyncThunk(
  "l3Menu/getL3MenuByL2Menu",
  async (id) => {
    return axios.get(`${CURD_L3_MENU}/${id}`, { withCredentials: true });
  }
);

export const updateL3Menu = createAsyncThunk(
  "l3Menu/updateL3Menu",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_L3_MENU, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteL3Menu = createAsyncThunk(
  "l3Menu/deleteL3Menu",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_L3_MENU, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const l3MenuSlice = createSlice({
  name: "l3Menu",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    l3MenuData: [],
    l3SelectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.l3SelectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createL3Menu.pending]: (state, action) => {
      state.loading = true;
    },
    [createL3Menu.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createL3Menu.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getL3Menu.pending]: (state, action) => {
      state.loading = true;
    },
    [getL3Menu.fulfilled]: (state, action) => {
      state.loading = false;
      state.l3MenuData = action?.payload?.data;
    },
    [getL3Menu.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getL3MenuByL2Menu.pending]: (state, action) => {
      state.loading = true;
    },
    [getL3MenuByL2Menu.fulfilled]: (state, action) => {
      state.loading = false;
      state.l3MenuData = action?.payload?.data;
    },
    [getL3MenuByL2Menu.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateL3Menu.pending]: (state, action) => {
      state.loading = true;
    },
    [updateL3Menu.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateL3Menu.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteL3Menu.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteL3Menu.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteL3Menu.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  l3MenuSlice.actions;
export default l3MenuSlice.reducer;
