import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useLocation } from "react-router-dom";
// material ui
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
  IconButton
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
// components
import Layout from "../../../layout/Layout";
import {
  MuiDataGrid,
  GridCellExpand,
  ModalPopUp,
  FormSubmitButton,
  FormTextField as MyTextInput,
  Notifier,
  MuiTooltip,
  ErrorNotifier,
  ModalCloseBtn,
  ConfirmDialog,
  PageHeader,
  CommonCurdPopUp
} from "../../../components";
// redux
import {
  createAssessmentTemplateSection,
  getAssessmentTemplateSection,
  updateAssessmentTemplateSection,
  deleteAssessmentTemplateSection,
  rowSelect,
  resetMessage,
  resetErrMessage,
  sectionRowSelect
} from "../../../features/prepare_assessments/assessmentTemplateSectionSlice";

// ------------------------------------------------------------------------------

const validationSchema = Yup.object({
  sectionName: Yup.string().required("Section Name is required"),
  sectionNo: Yup.number().required("Section Number is required"),
  comments: Yup.string(),
});

const editValidationSchema = Yup.object({
  sectionName: Yup.string().required("Section Name is required"),
  sectionNo: Yup.number().required("Section Number is required"),
  comments: Yup.string().nullable(),
});

// -------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  rootbox: {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: '56.25rem !important',
    [theme.breakpoints.down('sm')]: {
      width: '20rem !important',
      height: '60% !important',
      display: 'block !important',
      overflow: 'scroll !important',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '24.375rem  !important',
      height: '34% !important',
      display: 'block !important',
      overflow: 'scroll !important',
      margin: '-3.188rem',
      marginTop: '-3.75rem  !important',
      marginLeft: '-3.563rem',
      padding: '2.125rem !important',
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: '1.5rem !important',
    paddingTop: '1rem !important',
    paddingLeft: '2rem !important',
    paddingRight: '2rem !important',
    paddingBottom: '1.5rem',
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },



  text_responsive: {
    width: '50rem',
    [theme.breakpoints.down('md')]: {
      width: '17rem',

    },
  },

  cutoff_box: {

    [theme.breakpoints.down('sm')]: {
      width: '16.875rem !important'
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      width: '20.875rem !important',
      marginLeft: '-7.313rem',
      marginTop: '-11.313rem'
    }
  },
  responsive_field: {
    [theme.breakpoints.down('sm')]: {
      width: '15rem !important'
    }
  },
  responsive_field: {
    [theme.breakpoints.down('md')]: {
      width: '15rem !important'
    }
  }
}))
const AssessmentTemplateSection = (props) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const dispatch = useDispatch();

  const location = useLocation();

  const { assessmentTemplateSectionData, message, errMessage, selectedSectionData } = useSelector((state) => ({ ...state.assessmentTemplateSection }))

  const showAddModel = () => {
    setShowAdd(!showAdd);
  };

  const closeModel = () => {
    setShowAdd(false);
    formik.resetForm({
      values: {
        sectionName: '',
        sectionNo: '',
        comments: "",
      },
    });
  };

  const showEditModal = () => {
    setShowEdit(true);
  };

  const closeEditModal = () => {
    setShowEdit(false);
    formikEdit.resetForm({
      values: {
        sectionName: '',
        sectionNo: '',
        comments: "",
      },
    });
  };

  const showDelete = () =>
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => handleDeleteCustomer(),
    })

  const handleDeleteCustomer = () => {
    let data = {
      id: selectedSectionData?.id,
    };
    dispatch(deleteAssessmentTemplateSection(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(getAssessmentTemplateSection({ assessmentTemplateId: location?.state?.templateSelectedData?.id }));
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const classes = useStyles()
  function renderCellExpand(params) {
    return (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
  }



  const data = {
    rows: assessmentTemplateSectionData,
    columns: [
      { field: "sectionName", headerName: "Section", width: 150, renderCell: renderCellExpand },
      { field: "sectionNo", headerName: "Section No.", width: 150, renderCell: renderCellExpand },
      {
        field: "questionCount", headerName: "No. of Questions",
        width: 150,
        // valueGetter: (params) => `${params?.row?.assessmentSection?.sectionNo}.${params?.row?.dimensionNo}`,
        renderCell: renderCellExpand
      },
      {
        field: "prepareQuestion",
        // headerName: 'Actions',
        type: "actions",
        width: 50,
        renderCell: (params) => {
          return (
            <MuiTooltip title="Questions" placement="right">
              <Link
                to="/exco/prepare/prepare-section-questions"
                state={{
                  templateSelectedData: location?.state?.templateSelectedData,
                  permissions: location?.state?.permissions,
                  sectionSelectedData: params?.row,

                }}


              >
                <IconButton sx={{ p: 0 }}>
                  <HelpIcon style={{ color: "#808080" }} />
                </IconButton>
              </Link>
            </MuiTooltip>
          );
        },
      },
      {
        field: "comments",
        headerName: "Comments",
        width: 350,
        renderCell: renderCellExpand
      },
    ],
  };

  const formik = useFormik({
    reset: true,
    initialValues: {
      sectionName: '',
      sectionNo: '',
      comments: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values, onSubmitProps) => {
      dispatch(
        createAssessmentTemplateSection({
          assessmentTemplateId: location?.state?.templateSelectedData?.id,
          sectionName: values?.sectionName,
          sectionNo: values?.sectionNo,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeModel();
            dispatch(getAssessmentTemplateSection({ assessmentTemplateId: location?.state?.templateSelectedData?.id }));
            onSubmitProps.resetForm();
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      sectionName: selectedSectionData?.sectionName,
      sectionNo: selectedSectionData?.sectionNo,
      comments: selectedSectionData?.comments,
    },

    validationSchema: editValidationSchema,
    onSubmit: (values) => {
      dispatch(
        updateAssessmentTemplateSection({
          id: selectedSectionData?.id,
          assessmentTemplateId: location?.state?.templateSelectedData?.id,
          sectionName: values?.sectionName,
          sectionNo: values?.sectionNo,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeEditModal();
            dispatch(getAssessmentTemplateSection({ assessmentTemplateId: location?.state?.templateSelectedData?.id }));
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });


  useEffect(() => {
    let data = {
      assessmentTemplateId: location?.state?.templateSelectedData?.id
    }

    dispatch(getAssessmentTemplateSection(data))
  }, []);


  return (
    <>
      <PageHeader variant="pageHeader">Sections</PageHeader>
      <>
        <MuiDataGrid
          setShowAdd={
            // props?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "createAssesSec") && 
            showAddModel}
          setShowEdit={props?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "updateAssesSec") && showEditModal}
          setShowDelete={props?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "deleteAssesSec") && showDelete
          }

          isMuidatagridSmall={true}
          // setShowAdd={showAddModel}
          // setShowEdit={showEditModal}
          // setShowDelete={showDelete
          // }

          toolbarColumn={true}
          // toolbarFilter={true}
          toolbarDensity={true}
          toolbarExport={true}
          data={data}
          select={(data) => dispatch(sectionRowSelect(data))}
        />
      </>
      {/* <ModalPopUp
        open={showAdd}
        handleClose={closeModel}
        closeModel={closeModel}
      >
        <Box
          // sx={{ ...style, width: 900 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
           <PageHeader>Add Section</PageHeader>
          <ModalCloseBtn close={closeModel} />
          <div className={classes.text_responsive}> */}


      {showAdd && <CommonCurdPopUp
        heading="Add Section"
        mediumScreen={true}
        close={
          closeModel
        }
      >
        <Grid container >
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>

          <Grid item xs={12} sm={12} md={11} container >
            <Grid xs={12} sm={12} md={9} >
              <MyTextInput
                label="Section Name"
                type="text"
                name="sectionName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="sectionName"
                value={formik.values.sectionName}
                onChange={formik.handleChange}
                tabIndex={1}
                error={
                  formik.touched.sectionName &&
                  Boolean(formik.errors.sectionName)
                }
                helperText={
                  formik.touched.sectionName && formik.errors.sectionName
                }
              />
            </Grid>
            <Grid xs={12} sm={12} md={3} sx={{paddingLeft:"0.5rem"}}>
              <MyTextInput
                label="Section No."
                type="number"
                name="sectionNo"
                required={true}
                fullWidth={true}
                margin="normal"
                id="sectionNo"
                value={formik.values.sectionNo}
                onChange={formik.handleChange}
                tabIndex={1}
                error={
                  formik.touched.sectionNo &&
                  Boolean(formik.errors.sectionNo)
                }
                helperText={
                  formik.touched.sectionNo && formik.errors.sectionNo
                }
              />
            </Grid>

            <MyTextInput
              label="Comments"
              type="comments"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formik.values.comments}
              onChange={formik.handleChange}
              error={
                formik.touched.comments && Boolean(formik.errors.comments)
              }
              helperText={formik.touched.comments && formik.errors.comments}
            />

            <Box style={{ width: "100%", textAlign: "center" }} mt={2}>

              <FormSubmitButton onClick={formik?.handleSubmit} type="submit" variant="contained">
                Submit
              </FormSubmitButton>
            </Box>


          </Grid>
        </Grid>


      </CommonCurdPopUp>}



      {showEdit && <CommonCurdPopUp
        heading="Update Section"
        mediumScreen={true}
        close={
          closeEditModal
        }
      >
        <Grid container>
        <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11}  container >
            <Grid xs={12} sm={12} md={9} >
              <MyTextInput
                label="Section Name"
                type="text"
                name="sectionName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="sectionName"
                defaultValue={formikEdit.values.sectionName}
                value={formikEdit.values.sectionName}
                onChange={formikEdit.handleChange}
                error={
                  formikEdit.touched.sectionName &&
                  Boolean(formikEdit.errors.sectionName)
                }
                helperText={
                  formikEdit.touched.sectionName &&
                  formikEdit.errors.sectionName
                }
              />
            </Grid>
            <Grid xs={12} sm={12} md={3} sx={{paddingLeft:"0.5rem"}}>
              <MyTextInput
                label="Section No."
                type="number"
                name="sectionNo"
                required={true}
                fullWidth={true}
                margin="normal"
                id="sectionNo"
                defaultValue={formikEdit.values.sectionNo}
                value={formikEdit.values.sectionNo}
                onChange={formikEdit.handleChange}
                error={
                  formikEdit.touched.sectionNo &&
                  Boolean(formikEdit.errors.sectionNo)
                }
                helperText={
                  formikEdit.touched.sectionNo &&
                  formikEdit.errors.sectionNo
                }
              />
            </Grid>

            <MyTextInput
              label="Comments"
              type="comments"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formikEdit.values.comments}
              onChange={formikEdit.handleChange}
              error={
                formikEdit.touched.comments &&
                Boolean(formikEdit.errors.comments)
              }
              helperText={
                formikEdit.touched.comments && formikEdit.errors.comments
              }
            />

            <Box style={{ width: "100%", textAlign: "center" }} mt={2}>

              <FormSubmitButton onClick={formikEdit?.handleSubmit} type="submit" variant="contained">
                Submit
              </FormSubmitButton>
            </Box>
          </Grid>
        </Grid>


      </CommonCurdPopUp>}







      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />

      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </>
  );
};

export default AssessmentTemplateSection;
