import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_L2_MENU } from "../../../../utils/routes";

// ---------------------------------------------------------------------

export const createL2Menu = createAsyncThunk(
  "l2Menu/createL2Menu",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_L2_MENU, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getL2Menu = createAsyncThunk(
  "l2Menu/getL2Menu",
  async (obj, {rejectWithValue}) => {
    try {
      const response = await axios.get(CURD_L2_MENU, { withCredentials: true });
      return response;
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
);

export const getL2MenuByL1Menu = createAsyncThunk(
  "l2Menu/getL2MenuByL1Menu",
  async (id) => {
    return axios.get(`${CURD_L2_MENU}/${id}`, { withCredentials: true });
  }
);

export const updateL2Menu = createAsyncThunk(
  "l2Menu/updateL2Menu",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_L2_MENU, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteL2Menu = createAsyncThunk(
  "l2Menu/deleteL2Menu",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_L2_MENU, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const l2MenuSlice = createSlice({
  name: "l2Menu",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    l2MenuData: [],
    l2SelectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.l2SelectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createL2Menu.pending]: (state, action) => {
      state.loading = true;
    },
    [createL2Menu.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createL2Menu.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getL2Menu.pending]: (state, action) => {
      state.loading = true;
    },
    [getL2Menu.fulfilled]: (state, action) => {
      state.loading = false;
      state.l2MenuData = action?.payload?.data;
    },
    [getL2Menu.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getL2MenuByL1Menu.pending]: (state, action) => {
      state.loading = true;
    },
    [getL2MenuByL1Menu.fulfilled]: (state, action) => {
      state.loading = false;
      state.l2MenuData = action?.payload?.data;
    },
    [getL2MenuByL1Menu.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateL2Menu.pending]: (state, action) => {
      state.loading = true;
    },
    [updateL2Menu.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateL2Menu.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteL2Menu.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteL2Menu.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteL2Menu.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  l2MenuSlice.actions;
export default l2MenuSlice.reducer;
