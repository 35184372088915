import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { Link, useLocation } from "react-router-dom";
// material ui
import { Stack, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import AddRoles from "./AddRoles";
import UpdateRoles from "./UpdateRoles";
import { MuiDataGrid, GridCellExpand, Notifier, ErrorNotifier } from "../../../../components";
import ConfirmDialog from "../../../../components/common/ConfirmDialog";
import Layout from "../../../../layout/Layout";
// redux
import {
  getRoles,
  deleteRoles,
  rowSelect,
  resetMessage,
  resetErrMessage,
} from "../../../../features/iam/role_management/rolesSlice";

// ------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  cell: {
    whiteSpace: "none !important",
  },
  cell2: {
    whiteSpace: "none !important",
  },
}));

// ----------------------------------------------------------------------------------

const Roles = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const classes = useStyles();
const location = useLocation()
  const dispatch = useDispatch();
  const {  rolesData, selectedData, message, errMessage } = useSelector(
    (state) => ({ ...state.roles })
  );

  function getGroupName(params) {
    return params?.row?.userGroup?.groupName
  }
  
  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const data = {
    rows: rolesData,
    columns: [
      { field: "userGroup", headerName: "Group Name", width: 150, valueGetter: getGroupName },
      { field: "roleCode", headerName: "Role Code", width: 100 },
      {
        field: "roleName",
        headerName: "Role Name",
        width: 200,
        cellClassName: (params) => {
          return classes.cell2;
        },
        renderCell: renderCellExpand
      },
      { field: "securityLevel", headerName: "Security Level", width: 100 },
      {
        field: "roleDescription",
        headerName: "Role Description",
        width: 600,
        renderCell: renderCellExpand
      },
    ],
  };

  useEffect(() => {
    dispatch(getRoles());
  }, []);

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleDelete = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deleteRoles(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(getRoles());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  return (
    <Layout>
    
        <>
          <Stack flexDirection="row" justifyContent="space-between">
            <Box sx={{ width: "100%" }}>
              <PageHeader variant="pageHeader">Roles</PageHeader>
            </Box>
            <Link to="/exco/iam/roles" state={{l3Menu: location?.state?.l3Menu}}>
              <CancelIcon color="red" />
            </Link>
          </Stack>
          <MuiDataGrid
            setShowAdd={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "create") ? () => setShowAdd(true) : null}
            setShowEdit={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "update") ?() => setShowEdit(true) : null}
            setShowDelete={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "delete") ? () =>
              setConfirmDialog({
                isOpen: true,
                title: "Are you sure to delete this record?",
                subTitle: "You can't undo this operation",
                onConfirm: () => handleDelete(),
              }) : null
            }
            // setShowAdd={() => setShowAdd(true)}
            // setShowEdit={() => setShowEdit(true)}
            // setShowDelete={() =>
            //   setConfirmDialog({
            //     isOpen: true,
            //     title: "Are you sure to delete this record?",
            //     subTitle: "You can't undo this operation",
            //     onConfirm: () => handleDelete(),
            //   })
            // }
            toolbarColumn = {true}
            toolbarFilter = {true}
            toolbarDensity = {true}
            toolbarExport = {true}
            data={data}
            select={(data) => dispatch(rowSelect(data))}
          />
        </>
     
      {showAdd && (
        <AddRoles showAdd={showAdd} close={() => setShowAdd(false)} />
      )}
      {showEdit && (
        <UpdateRoles showEdit={showEdit} close={() => setShowEdit(false)} />
      )}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </Layout>
  );
};

export default Roles;
