import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// routes
import {
  FORGOT_PASSWORD_EMAIL_VERIFY,
  FORGOT_PASSWORD_RESET,
  FORGOT_PASSWORD_SEND_OTP,
} from "../../utils/routes";

// ---------------------------------------------------------------------------------

export const forgotPassVerifyEmail = createAsyncThunk(
  "forgotPassword/forgotPassVerifyEmail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(FORGOT_PASSWORD_EMAIL_VERIFY, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const forgotPassReset = createAsyncThunk(
  "forgotPassword/forgotPassReset",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(FORGOT_PASSWORD_RESET, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const forgotPassSendOTP = createAsyncThunk(
  "forgotPassword/forgotPassSendOTP",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(FORGOT_PASSWORD_SEND_OTP, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState: {
    loading: false,
    loginToken: "",
    baseString: "",
    rq: "",
    selectedData: {},
    message: "",
    errMessage: "",
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    selectRow: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
    resetBaseString: (state, action) => {
      state.baseString = "";
    }
  },
  extraReducers: {
    [forgotPassVerifyEmail.pending]: (state, action) => {
      state.loading = true;
    },
    [forgotPassVerifyEmail.fulfilled]: (state, action) => {
      state.loading = false;
      state.loginToken = action?.payload?.data?.data?.loginToken;
      state.rq = action?.payload?.data?.data?.rq;
      state.message = action?.payload?.data?.message;
    },
    [forgotPassVerifyEmail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [forgotPassReset.pending]: (state, action) => {
      state.loading = true;
    },
    [forgotPassReset.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [forgotPassReset.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [forgotPassSendOTP.pending]: (state, action) => {
      state.loading = true;
    },
    [forgotPassSendOTP.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
      state.baseString = action?.payload?.data?.data?.baseString;
    },
    [forgotPassSendOTP.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, selectRow, resetBaseString } =
  forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
