
import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
// import DateFns from "date-fns";
// import { getRiskByStatusAndDate } from "../../../../../../features/project";
// import ShowRiskList from "./projectDashboard/ShowRiskList";
import ViewAssessmentUsersDetails from "./ViewAssessmentUsersDetails";
import { getAllUserDetailsForAllCustomersGraph } from "../../features/dashboard/dashboardSlice";
import { MuiTooltip } from "../../components";

// Debounce function to handle ResizeObserver events
const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const NewBarGraph = (props) => {
  const projectDashboard = useSelector((state) => state.projectDashboard);
  const { customerCountData } = useSelector((state) => ({ ...state?.dashboard }));
  const [showStatusDetail, setShowStatusDetails] = useState(false);
  const [showStatusData, setShowsStatusData] = useState(false);
  const [trackingId, setTrackingId] = useState(null)
  const [data, setData] = useState([]);
  const dispatch = useDispatch();



  useEffect(() => {
    // if (customerCountData && customerCountData.length > 0) {
    const newData = customerCountData?.map((item) => {
      const objectFormat = {
        templateId: item?.templateId,
        // name: DateFns.format(item.riskMonthStartDate, "MMM"),
        name: `${item.templateName}-${item.customerName ? item.customerName : item?.clientName}`,
        // id: item?.trackingId

      };
      item.users?.forEach(innerItem => {
        objectFormat[
          innerItem?.publishedStatus === "Published" ?
            "Publised Assessment" :
            (innerItem?.clientPendingStatus || innerItem?.clientCompletedStatus) ?
              innerItem?.clientPendingStatus === "Pending" ?
                `${innerItem?.clientPendingStatus}-Clients` :
                `${innerItem?.clientCompletedStatus}-Clients` :
              innerItem?.assessorPendingStatus ?
                `${innerItem?.assessorPendingStatus}-Assesors` :
                `${innerItem?.assessorCompletedStatus}-Assessors`

        ] = innerItem?.publishedStatus === "Published" ?
            innerItem?.publishedStatusCount :
            (innerItem?.clientPendingStatus || innerItem?.clientCompletedStatus) ?
              innerItem?.clientPendingStatus === "Pending" ?
                innerItem?.clientPendingStatusCount
                :
                innerItem?.clientCompletedStatusCount
              :
              innerItem?.assessorPendingStatus ?
                innerItem?.assessorPendingStatusCount :
                innerItem?.assessorCompletedStatusCount
          ;
      });
      return objectFormat;
    });

    setData(customerCountData?.length > 0 ? newData : []);
    // }
    return () => { };
  }, [customerCountData]);



  const legendItemStyle = {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0.8rem',
  };

  const rectangleStyle = (color) => ({
    width: '0.75rem', // 12px to rem
    height: '0.75rem', // 12px to rem
    backgroundColor: color,
    marginRight: '0.3125rem', // 5px to rem
  });

  const CustomLegend = () => (
    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '0.8rem' }}>
      <div style={legendItemStyle}>
        <div style={rectangleStyle('#f9d806')}></div>
        <span style={{ color: '#f9d806' }}>Published</span>
      </div>
      <div style={legendItemStyle}>
        <div style={rectangleStyle('#f40b0b')}></div>
        <span style={{ color: '#f40b0b' }}>Pending</span>
      </div>
      <div style={legendItemStyle}>
        <div style={rectangleStyle('#10f420')}></div>
        <span style={{ color: '#10f420' }}>Completed</span>
      </div>
    </div>
  );






  const handleCallApi = (e, item) => {
    console.log(e)

    setTrackingId(e?.
      templateId
    )

    console.log(props?.date)

    const apiPayload = {
      templateId: e?.templateId,
      accountId: props?.accountName?.id,
      customerId: props?.custName?.id,
      fromDate: props?.date?.fromdate,
      endDate: props?.date?.toDate,

    };
    dispatch(getAllUserDetailsForAllCustomersGraph(apiPayload))
    // setShowRiskListData(
    //   `${DateFns.format(e.payload.riskMonthStartDate, "MMM")}-${status} Risks`
    // );
    setShowStatusDetails(true);
  };

  const innerFunction = useCallback(() => {
    let colors = {};
    if (data && data.length > 0) {
      customerCountData[0]?.users?.forEach(item => {
        colors[item?.publishedStatus === "Published" ?
          "Publised Assessment" :
          (item?.clientPendingStatus || item?.clientCompletedStatus) ?
            item?.clientPendingStatus === "Pending" ?
              `${item.clientPendingStatus}-Clients` :
              `${item?.clientCompletedStatus}-Clients` :
            item?.assessorPendingStatus ?
              `${item?.assessorPendingStatus}-Assesors` :
              `${item?.assessorCompletedStatus}-Assessors`]
          =
          item?.publishedStatus === "Published" ?
            item?.publishedStatusColorCode :
            (item?.clientPendingStatus || item?.clientCompletedStatus) ?
              item?.clientPendingStatus === "Pending" ?
                item.clientPendingStatusColorCode :
                item.clientCompletedStatusColorCode
              :
              item?.assessorPendingStatus ?
                item.assessorPendingStatusColorCode :
                item.assessorCompletedStatusColorCode

      });
    }

    const returnX = [];
    for (let item in data[0]) {

      if (
        item === 'name'
      ) {
        continue;
      }

      if (item !== 'trackingId' && item !== 'templateId') {
        returnX.push(
          <Bar
            key={item}
            dataKey={item}
            stackId="a"
            fill={colors[item]}
            onClick={(e) => handleCallApi(e, item)}
          />
        );

      }

    }

    const CustomXAxisTick = ({ x, y, payload }) => {
      const label = payload.value;
      const isLongLabel = label.length > 10; // Adjust label length threshold as needed

      return (
        <g transform={`translate(${x},${y})`}>
          <MuiTooltip title={isLongLabel ? label : ''}>
            <text
              x={0}
              y={0}
              dy={16}
              textAnchor="end"
              style={{ fontSize: ".80rem" }}
              fill="#666"
              transform="rotate(-25)"  // Tilt labels for better readability
            >
              {label.length > 10 ? `${label.slice(0, 26)}...` : label}
            </text>
          </MuiTooltip>
        </g>
      );
    };

    return (
      <ResponsiveContainer width={500} height={450}>
        <BarChart
          data={data}
          margin={{
            top: 20, right: 30,
            left: -5,
            bottom: 20
          }}
          reverseStackOrder
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis tick={<CustomXAxisTick />} height={100} angle={-25} textAnchor="end" interval={0} dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend
            content={<CustomLegend />}
            layout="horizontal"
            verticalAlign="bottom"
            align="center"
            wrapperStyle={{ paddingTop: '10px' }} // Adds extra space between X-axis and legend
          />
          {returnX}
        </BarChart>
      </ResponsiveContainer>
    );
  }, [data]);



  useEffect(() => {
    const handleResize = debounce(() => {
      // You can handle additional resizing logic here if necessary
    }, 100);

    const observer = new ResizeObserver(() => {
      handleResize();
    });

    const container = document.querySelector('#bar-graph-container');
    if (container) {
      observer.observe(container);
    }

    return () => {
      if (container) {
        observer.unobserve(container);
      }
    };
  }, []);

  return (
    <div id="bar-graph-container">
      {innerFunction()}
      {showStatusDetail && (
        <ViewAssessmentUsersDetails
          downloadData={{
            fromDate: props?.date?.fromdate,
            endDate: props?.date?.toDate,
            accountId: props?.accountName?.id,
            customerId: props?.custName?.id,
            trackingId :   trackingId
          }
          }
          trackingId={trackingId}
          open={showStatusDetail}
          data={projectDashboard?.riskStatusDataList}
          close={() => setShowStatusDetails(false)}
          headerName={showStatusData}
        />
      )}
    </div>
  );
};

export default NewBarGraph;
