import React from 'react'
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';

// -------------------------------------------------------------

function ErrorNotifier(props) {

  const navigate = useNavigate()

  if(props?.message === "Login Session is Expired. Please Login Again.") {
    navigate("/");
    window.location.reload(true)
  }

  return (
    <div className="error-notification-box">
      <span className="error-notification">
      <Alert variant="filled" severity="error">
      {props.message}
     </Alert>
      
      </span>
    </div>
  )
}
export default ErrorNotifier;