import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { Link, useLocation } from "react-router-dom";
// material ui
import { Stack, Box } from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import AddAssessmentStatus from "./addPlatformStatus";
import UpdateAssessmentStatus from "./updatePlatformStatus";
import Layout from "../../../../layout/Layout";
import {
  MuiDataGrid,
  GridCellExpand,
  Notifier,
  ErrorNotifier,
} from "../../../../components";
import ConfirmDialog from "../../../../components/common/ConfirmDialog";
// redux
import {
  getPlatformStatus,
  deletePlatformStatus,
  rowSelect,
  resetMessage,
  resetErrMessage,
} from "../../../../features/config/platformStatusSlice";

// ----------------------------------------------------------------------------------

const AssessmentStatus = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const location = useLocation();
  const dispatch = useDispatch();
  const { assessmentTemplateStatusData, selectedData, message, errMessage } =
    useSelector((state) => ({ ...state.assessmentTemplateStatus }));

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const data = {
    rows: assessmentTemplateStatusData,
    columns: [
      { field: "assessmentTemplateStatus", headerName: "Template Status", width: 250 },
      {
        field: "color",
        headerName: "Color",
        type: "actions",
        width: 100,
        renderCell: (params) => {
          return (
            <div
              style={{
                backgroundColor: params?.row?.colorCode,
                width: "20px",
                height: "20px",
              }}
            ></div>
          );
        },
      },
      { field: "colorCode", headerName: "Color Code", width: 100 },
      {
        field: "comments",
        headerName: "Comments",
        width: 600,
        renderCell: renderCellExpand,
      },
    ],
  };

  const showAddPage = () => {
    setShowAdd(!showAdd);
  };

  const showEditPage = () => {
    setShowEdit(true);
  };

  const showDelete = () =>
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => handleDelete(),
    });

  useEffect(() => {
    dispatch(getPlatformStatus());
  }, []);

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleDelete = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deletePlatformStatus(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(getPlatformStatus());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  return (
    <Layout>
      
        <>
          <Stack flexDirection="row" justifyContent="space-between">
            <Box sx={{ width: "100%" }}>
              <PageHeader variant="pageHeader">
                 Template Status
              </PageHeader>
            </Box>
            <Link
              to="/exco/platform/config"
              state={{ l3Menu: location?.state?.l3Menu }}
            >
              <CancelIcon color="red" />
            </Link>
          </Stack>
          <MuiDataGrid
            // setShowAdd={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "create") && showAddPage}
            // setShowEdit={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "update") && showEditPage}
            // setShowDelete={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "delete") && showDelete
            // }
            setShowAdd={showAddPage}
            setShowEdit={showEditPage}
            setShowDelete={showDelete}
            // setShowAdd={showAddPage}
            // setShowEdit={ showEditPage}
            // setShowDelete={showDelete
            // }
            toolbarColumn={true}
            // toolbarFilter={true}
            toolbarDensity={true}
            toolbarExport={true}
            data={data}
            select={(data) => dispatch(rowSelect(data))}
          />
        </>
   
      {showAdd && (
        <AddAssessmentStatus
          showAdd={showAdd}
          close={() => setShowAdd(false)}
        />
      )}
      {showEdit && (
        <UpdateAssessmentStatus
          showEdit={showEdit}
          close={() => setShowEdit(false)}
        />
      )}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </Layout>
  );
};

export default AssessmentStatus;
