import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_ASSESSMENT_TEMPLATE,ARCHIVED_ASSESSMENT_TEMPLATE, COPY_ASSESSMENT_TEMPLATE,CREATE_ASSESSMENT_TEMPLATE_PRE_DEFINED, FORCE_DELETE_ASSESSMENT_TEMPLATE,ASSESSMENT_TEMPLATE_BY_CUSTOMER_ID ,ASSESSMENT_TEMPLATE_BY_ACCOUNT_ID,  REPORT_ASSESSMENT_BY_ID } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createAssessmentTemplate = createAsyncThunk(
  "assessmentTemplate/createAssessmentTemplate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_ASSESSMENT_TEMPLATE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getReportListById = createAsyncThunk(
  "assessmentTemplateReport/getReportById",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(REPORT_ASSESSMENT_BY_ID , data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createAssessmentTemplateByPreDefined = createAsyncThunk(
  "assessmentTemplate/createAssessmentTemplateByPreDefined",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        CREATE_ASSESSMENT_TEMPLATE_PRE_DEFINED,
        data,
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAssessmentTemplate = createAsyncThunk(
  "assessmentTemplate/getAssessmentTemplate",
  async (obj, {rejectWithValue}) => {
    try {
      const response = await axios.get(CURD_ASSESSMENT_TEMPLATE, { withCredentials: true });
      return response;
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
);

export const getArchivedAssessmentTemplate = createAsyncThunk(
  "assessmentTemplate/getArchivedAssessmentTemplate",
  async (obj, {rejectWithValue}) => {
    try {
      const response = await axios.post(ARCHIVED_ASSESSMENT_TEMPLATE,obj, { withCredentials: true });
      return response;
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
);

export const updateArchivedAssessmentTemplate = createAsyncThunk(
  "assessmentTemplate/updateArchivedAssessmentTemplate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(ARCHIVED_ASSESSMENT_TEMPLATE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);




export const getAssessmentTemplateBycustomerId = createAsyncThunk(
  "assessmentTemplate/getAssessmentTemplateCustomerId",
  async (obj, {rejectWithValue}) => {
    try {
      const response = await axios.post(ASSESSMENT_TEMPLATE_BY_CUSTOMER_ID,obj, { withCredentials: true });
      return response;
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
);

export const getAssessmentTemplateByAccountId = createAsyncThunk(
  "assessmentTemplate/getAssessmentTemplateAccountId",
  async (obj, {rejectWithValue}) => {
    try {
      const response = await axios.post(ASSESSMENT_TEMPLATE_BY_ACCOUNT_ID,obj, { withCredentials: true });
      return response;
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
);

export const updateAssessmentTemplate = createAsyncThunk(
  "assessmentTemplate/updateAssessmentTemplate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_ASSESSMENT_TEMPLATE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAssessmentTemplate = createAsyncThunk(
  "assessmentTemplate/deleteAssessmentTemplate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_ASSESSMENT_TEMPLATE, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const copyAssessmentTemplate = createAsyncThunk(
  "assessmentTemplate/copyAssessmentTemplate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(COPY_ASSESSMENT_TEMPLATE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const forceDeleteAssessmentTemplate = createAsyncThunk(
  "assessmentTemplate/forceDeleteAssessmentTemplate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(FORCE_DELETE_ASSESSMENT_TEMPLATE, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const prepareAssessmentSlice = createSlice({
  name: "assessmentTemplate",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    assessmentTemplateData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
    rowSelectEmpty: (state, action) => {
      state.selectedData = {};
    },

  },
  extraReducers: {
    [createAssessmentTemplate.pending]: (state, action) => {
      state.loading = true;
    },
    [createAssessmentTemplate.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createAssessmentTemplate.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [createAssessmentTemplateByPreDefined.pending]: (state, action) => {
      state.loading = true;
    },
    [createAssessmentTemplateByPreDefined.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createAssessmentTemplateByPreDefined.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [getAssessmentTemplate.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentTemplate.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentTemplateData = action?.payload?.data;
    },
    [getAssessmentTemplate.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getArchivedAssessmentTemplate.pending]: (state, action) => {
      state.loading = true;
    },
    [getArchivedAssessmentTemplate.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentTemplateData = action?.payload?.data;
    },
    [getArchivedAssessmentTemplate.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getReportListById.pending]: (state, action) => {
      state.loading = true;
    },
    [getReportListById.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentTemplateData = action?.payload?.data;
    },
    [getReportListById.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

   
    [getAssessmentTemplateBycustomerId.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentTemplateBycustomerId.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentTemplateData = action?.payload?.data;
    },
    [getAssessmentTemplateBycustomerId.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getAssessmentTemplateByAccountId.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentTemplateByAccountId.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentTemplateData = action?.payload?.data;
    },
    [getAssessmentTemplateByAccountId.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },


    [updateAssessmentTemplate.pending]: (state, action) => {
      state.loading = true;
    },
    [updateAssessmentTemplate.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateAssessmentTemplate.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [updateArchivedAssessmentTemplate.pending]: (state, action) => {
      state.loading = true;
    },
    [updateArchivedAssessmentTemplate.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateArchivedAssessmentTemplate.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },



    [deleteAssessmentTemplate.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteAssessmentTemplate.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteAssessmentTemplate.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [copyAssessmentTemplate.pending]: (state, action) => {
      state.loading = true;
    },
    [copyAssessmentTemplate.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [copyAssessmentTemplate.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [forceDeleteAssessmentTemplate.pending]: (state, action) => {
      state.loading = true;
    },
    [forceDeleteAssessmentTemplate.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [forceDeleteAssessmentTemplate.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect, rowSelectEmpty } =
  prepareAssessmentSlice.actions;
export default prepareAssessmentSlice.reducer;
