import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_ASSESSMENT_TEMPLATE_SECTION_CONFIG, GET_ASSESSMENT_TEMPLATE_SECTION_CONFIG_BY_TEMPLATE_ID } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createAssessmentTemplateSectionConfig = createAsyncThunk(
  "assessmentTemplateSectionConfig/createAssessmentTemplateSectionConfig",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_ASSESSMENT_TEMPLATE_SECTION_CONFIG, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAssessmentTemplateSectionConfig = createAsyncThunk(
  "assessmentTemplateSectionConfig/getAssessmentTemplateSectionConfig",
  async (data, {rejectWithValue}) => {
    try {
      const response = await axios.post(GET_ASSESSMENT_TEMPLATE_SECTION_CONFIG_BY_TEMPLATE_ID, data, { withCredentials: true });
      return response
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
);

export const updateAssessmentTemplateSectionConfig = createAsyncThunk(
  "assessmentTemplateSectionConfig/updateAssessmentTemplateSectionConfig",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_ASSESSMENT_TEMPLATE_SECTION_CONFIG, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAssessmentTemplateSectionConfig = createAsyncThunk(
  "assessmentTemplateSectionConfig/deleteAssessmentTemplateSectionConfig",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_ASSESSMENT_TEMPLATE_SECTION_CONFIG, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const assessmentTemplateSectionConfigSlice = createSlice({
  name: "assessmentTemplateSectionConfig",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    assessmentTemplateSectionConfigData: [],
    selectedData: {},
    selectedSectionData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
    sectionRowSelect: (state, action) => {
      state.selectedSectionData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createAssessmentTemplateSectionConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [createAssessmentTemplateSectionConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createAssessmentTemplateSectionConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getAssessmentTemplateSectionConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentTemplateSectionConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentTemplateSectionConfigData = action?.payload?.data;
    },
    [getAssessmentTemplateSectionConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateAssessmentTemplateSectionConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [updateAssessmentTemplateSectionConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateAssessmentTemplateSectionConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteAssessmentTemplateSectionConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteAssessmentTemplateSectionConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteAssessmentTemplateSectionConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect,sectionRowSelect } =
  assessmentTemplateSectionConfigSlice.actions;
export default assessmentTemplateSectionConfigSlice.reducer;
