import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { countries, continents } from "countries-list";
import { Link, useLocation } from "react-router-dom";
import { ASSESSMENT_BASE_URL } from "../../utils/config";
// material ui
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
  Autocomplete,
  Stack,
  TextField,
  Input,
  IconButton,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

// components
import Layout from "../../layout/Layout";
import {
  MuiDataGrid,
  MuiAutocomplete,
  geographyArr,
  GridCellExpand,
  ModalPopUp,
  FormSubmitButton,
  FormTextField as MyTextInput,
  Notifier,
  ErrorNotifier,
  ModalCloseBtn,
  ConfirmDialog,
  MuiTooltip,
  CommonCurdPopUp
} from "../../components";
// redux
import {
  createMapAssessor,
  getMapAssessorsByTrackingId,
  getNotMappedAssessors,
  updateMapAssessor,
  deleteMapAssessor,
  rowSelect,
  resetMessage,
  resetErrMessage,
  batchUploadAssessor,
} from "../../features/config_and_publish/mapAssessorSlice";
import { getRelationshipType } from "../../features/config/relationshipTypeSlice";

// ------------------------------------------------------------------------------

const validationSchema = Yup.object({
  client: Yup.object().required("Assessor Name is required"),
  relationship: Yup.object().required("Relationship is required"),
  comments: Yup.string(),
});

const editValidationSchema = Yup.object({
  client: Yup.string().required("Assessor Name is required"),
  relationship: Yup.string().required("Relationship is required"),
  comments: Yup.string().nullable(),
});

// -------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ------------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  rootbox: {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: "56.25rem !important",
    [theme.breakpoints.down("sm")]: {
      width: "17.75rem !important",
      height: "44% !important",
      // display:'block !important',
      // overflow:'scroll !important',
      marginTop: "-8.25rem",
      marginLeft: "-0.938rem",
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '33.375rem !important',
      height: '49% !important',
      display: 'block !important',
      overflow: 'scroll !important',
      // margin: '-3.188rem',
      marginTop: '-3.75rem  !important',
      marginLeft: '-3.563rem',
      padding: '2.125rem !important',
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: "1.5rem !important",
    paddingTop: "1rem !important",
    paddingLeft: "2rem !important",
    paddingRight: "2rem !important",
    paddingBottom: "1.5rem",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },

  responsive_text: {
    width: "50rem",
    [theme.breakpoints.down("md")]: {
      width: "13rem",
    },
  },
  responsive_upload: {
    marginTop: "1.25rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "9rem",
      marginRight: "-13.125rem",
    },
  },
  responsive_close: {
    marginTop: "1.75rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "7.375rem",
      marginRight: "-4.25rem",
    },
  },
}));
const MapAssessors = (props) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const dispatch = useDispatch();

  const location = useLocation();

  const {
    message,
    errMessage,
    mapAssessorsData,
    notMappedAssessorsData,
    selectedData,
  } = useSelector((state) => ({ ...state.mapAssessors }));

  const { relationshipTypeData } = useSelector((state) => ({
    ...state.relationshipType,
  }));

  const { custUserData } = useSelector((state) => ({ ...state.custUser }));

  const showAddModel = () => {
    setShowAdd(!showAdd);
  };

  const closeModel = () => {
    setShowAdd(false);
    formik.resetForm({
      values: {
        client: "",
        relationship: "",
        comments: "",
      },
    });
    dispatch(
      getNotMappedAssessors({
        assessmentTrackingId: location?.state?.trackingSelectedData?.id,
        assessmentCustomerId:
          location?.state?.trackingSelectedData?.assessmentCustomerUser?.
          assessmentCustomerAccount?.assessmentCustomer?.id,
      })
    );
  };

  const showEditModal = () => {
    setShowEdit(true);
  };

  const closeEditModal = () => {
    setShowEdit(false);
    dispatch(
      getNotMappedAssessors({
        assessmentTrackingId: location?.state?.trackingSelectedData?.id,
        assessmentCustomerId:
        location?.state?.trackingSelectedData?.assessmentCustomerUser?.
        assessmentCustomerAccount?.assessmentCustomer?.id,
      })
    );
  };

  const showDelete = () =>
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => handleDeleteCustomer(),
    });

  const handleDeleteCustomer = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deleteMapAssessor(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(
            getMapAssessorsByTrackingId({
              assessmentTrackingId: location?.state?.trackingSelectedData?.id,
            })
          );
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  function getClient(params) {
    return `${params.row.assessmentCustomerUser?.assessmentCustomerUserDetail
      ?.firstName || ""
      } ${params.row.assessmentCustomerUser?.assessmentCustomerUserDetail
        ?.lastName || ""
      }`;
  }

  function getClientEmail(params) {
    return (
      <GridCellExpand
        value={params?.row?.assessmentCustomerUser?.email || ""}
        width={params?.colDef?.computedWidth}
      />
    );
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const data = {
    rows: mapAssessorsData,
    columns: [
      {
        field: "client",
        headerName: "Assessor Name",
        width: 250,
        valueGetter: getClient,
      },
      {
        field: "email",
        headerName: "Assessor E-Mail",
        width: 250,
        renderCell: getClientEmail,
        valueGetter: (params) => params?.row?.assessmentCustomerUser?.email,
      },
      { field: "relationshipType", headerName: "Relationship", width: 150 },

      {
        field: "comments",
        headerName: "Comments",
        width: 350,
        renderCell: renderCellExpand,
      },
    ],
  };

  const formik = useFormik({
    reset: true,
    initialValues: {
      client: "",
      relationship: "",
      comments: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values, onSubmitProps) => {
      dispatch(
        createMapAssessor({
          assessmentTrackingId: location?.state?.trackingSelectedData?.id,
          assessmentCustomerUserId: values.client?.id,
          relationshipType: values?.relationship?.relationshipTypeName,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeModel();
            dispatch(
              getMapAssessorsByTrackingId({
                assessmentTrackingId: location?.state?.trackingSelectedData?.id,
              })
            );
            onSubmitProps.resetForm();
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      client: selectedData?.assessmentCustomerUser?.id,
      relationship: selectedData?.relationshipType,
      comments: selectedData?.comments,
    },

    validationSchema: editValidationSchema,
    onSubmit: (values) => {
      dispatch(
        updateMapAssessor({
          id: selectedData?.id,
          assessmentTrackingId: location?.state?.trackingSelectedData?.id,
          assessmentCustomerUserId: values?.client,
          relationshipType: values?.relationship,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeEditModal();
            dispatch(
              getMapAssessorsByTrackingId({
                assessmentTrackingId: location?.state?.trackingSelectedData?.id,
              })
            );
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  const handleFile = (e) => {
    let formdata = new FormData();

    formdata.append(
      "assessmentTrackingId",
      location?.state?.trackingSelectedData?.id
    );
    formdata.append("file", e.target.files[0]);

    dispatch(batchUploadAssessor(formdata)).then((res) => {
      // if (res?.payload?.data?.ok) {
      setTimeout(() => {
        dispatch(resetMessage());
        dispatch(
          getMapAssessorsByTrackingId({
            assessmentTrackingId: location?.state?.trackingSelectedData?.id,
          })
        );
      }, 2000);
      // }
      // if (res?.payload?.error) {
      //   setTimeout(() => {
      //     dispatch(resetErrMessage());
      //   }, 3000);
      // }
    }).catch((err) => {
      setTimeout(() => {
        dispatch(resetErrMessage())
      })
    });
    e.target.value = null;
  };

  let options = Object.values(countries);



  useEffect(() => {
    dispatch(
      getMapAssessorsByTrackingId({
        assessmentTrackingId: location?.state?.trackingSelectedData?.id,
        // assessmentCustomerId:
        //   location?.state?.trackingSelectedData?.assessmentCustomerUser
        //     ?.assessmentCustomerAccount?.id,

      })
    );
    dispatch(
      getNotMappedAssessors({
        assessmentTrackingId: location?.state?.trackingSelectedData?.id,
        assessmentCustomerId:
          location?.state?.trackingSelectedData?.assessmentCustomerUser.assessmentCustomerAccount?.assessmentCustomer
            ?.id,
      })
    );
    dispatch(getRelationshipType());
  }, []);



  const classes = useStyles();

  console.log( location?.state?.trackingSelectedData)

  return (
    <Layout>
      <>
        <PageHeader variant="pageHeader">
          Assessors for{" "}
          {
            location?.state?.trackingSelectedData?.assessmentCustomerUser
              ?.assessmentCustomerUserDetail?.firstName
          }{" "}
          {
            location?.state?.trackingSelectedData?.assessmentCustomerUser
              ?.assessmentCustomerUserDetail?.lastName
          }
          ,{" "}
          {
            location?.state?.trackingSelectedData?.assessmentTemplate
              ?.assessmentTemplateName
          }
        </PageHeader>

        <Stack sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>


          {location?.state?.permissions?.find(
            (e) =>
              e.applicationService?.name?.split(".")[
              e?.applicationService?.name?.split(".")?.length - 1
              ] === "uploadAssessorUsers"
          ) && (
              <label
                style={{ height: "2rem", marginTop: "-0.5rem" }}
                htmlFor="icon-button-file"
              >
                <Input
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  id="icon-button-file"
                  type="file"
                  sx={{ display: "none" }}
                  name="l1MenuIcon"
                  onChange={handleFile}
                  required={true}
                />
                <MuiTooltip title="Upload Assessors" placement="bottom">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <CloudUploadIcon />
                  </IconButton>
                </MuiTooltip>
              </label>)}

          {location?.state?.permissions?.find(
            (e) =>
              e.applicationService?.name?.split(".")[
              e?.applicationService?.name?.split(".")?.length - 1
              ] === "uploadAssessorUsers"
          ) && (<label style={{ height: "2rem", marginTop: "-0.5rem", marginRight: "0.5rem" }}>
            <MuiTooltip title="Download Template" placement="bottom">
              <a href={`${ASSESSMENT_BASE_URL}/v1/api/excel-parser/template/batch-upload-assessor`} target="_blank">
                <IconButton color="primary" component="span">
                  <FileDownloadIcon />
                </IconButton>
              </a>
            </MuiTooltip>
          </label>)}
          <label>

            <Link
              to="/exco/publish"
              state={{ permissions: location?.state?.permissions }}
            >
              <CancelIcon color="red" />
            </Link>
          </label>

        </Stack>








        <MuiDataGrid
          setShowAdd={
            location?.state?.permissions?.find(
              (e) =>
                e.applicationService?.name?.split(".")[
                e?.applicationService?.name?.split(".")?.length - 1
                ] === "createAssrMap"
            ) && showAddModel
          }
          setShowEdit={
            location?.state?.permissions?.find(
              (e) =>
                e.applicationService?.name?.split(".")[
                e?.applicationService?.name?.split(".")?.length - 1
                ] === "updateAssrMap"
            ) && showEditModal
          }
          setShowDelete={
            location?.state?.permissions?.find(
              (e) =>
                e.applicationService?.name?.split(".")[
                e?.applicationService?.name?.split(".")?.length - 1
                ] === "deleteAssrMap"
            ) && showDelete
          }
          // setShowAdd={showAddModel}
          // setShowEdit={showEditModal}
          // setShowDelete={showDelete
          // }

          toolbarColumn={true}
          // toolbarFilter={true}
          toolbarDensity={true}
          toolbarExport={true}
          data={data}
          select={(data) => dispatch(rowSelect(data))}
        />
      </>

      {showAdd && <CommonCurdPopUp
        heading={`Create Assessor`}
        mediumScreen={true}
        close={
          closeModel
        }
      >
        <Grid container >
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11}>
            {/* <MuiAutocomplete
                  name="client"
                  options={notMappedAssessorsData}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.client}
                  innerValue={true}
                  optionValue="assessmentCustomerUserDeatil"
                  optionSecValue="firstName"
                  optionThirdValue="lastName"
                  label="Assessor"
                  required={true}
                  error={
                    formik.touched.client && Boolean(formik.errors.client)
                  }
                  helperText={formik.touched.client && formik.errors.client}
                /> */}
            <Autocomplete
              name="client"
              options={
                notMappedAssessorsData?.length > 0
                  ? notMappedAssessorsData
                  : []
              }
              getOptionLabel={(option) =>
                option &&
                `${option?.assessmentCustomerUserDetail
                  ?.firstName} ${option?.assessmentCustomerUserDetail
                    ?.lastName}-${option?.email}`
              }
              value={formik.values.client}
              onChange={(event, newValue) =>
                formik.setFieldValue("client", newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Assessor"
                  required={true}
                  error={
                    formik.touched.client && Boolean(formik.errors.client)
                  }
                  helperText={formik.touched.client && formik.errors.client}
                />
              )}
              sx={{ marginTop: "1rem" }}
            />
            <MuiAutocomplete
              name="relationship"
              options={relationshipTypeData}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.relationship}
              optionValue="relationshipTypeName"
              label="Relationship"
              required={true}
              error={
                formik.touched.relationship &&
                Boolean(formik.errors.relationship)
              }
              helperText={
                formik.touched.relationship && formik.errors.relationship
              }
            />
            <MyTextInput
              label="Comments"
              type="comments"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formik.values.comments}
              onChange={formik.handleChange}
              error={
                formik.touched.comments && Boolean(formik.errors.comments)
              }
              helperText={formik.touched.comments && formik.errors.comments}
            />
          </Grid>
        </Grid>
        <Box style={{ width: "100%", textAlign: "center" }} >

          <FormSubmitButton onClick={formik?.handleSubmit} type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>


      </CommonCurdPopUp>}


      {/* <ModalPopUp
        open={showAdd}
        handleClose={closeModel}
        closeModel={closeModel}
      >
        <Box
         
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={closeModel} />
          <div className={classes.responsive_text}> */}

      {/* </div>

          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box >
      </ModalPopUp > */}
      {/* <ModalPopUp
        open={showEdit}
        handleClose={() => setShowEdit(false)}
        closeModel={() => setShowEdit(false)}
      >
        <Box
        
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formikEdit.handleSubmit}
        >
          <ModalCloseBtn close={() => setShowEdit(false)} />
          <div className={classes.responsive_text}> */}
      {showEdit && <CommonCurdPopUp
        heading={`Update Assessor`}
        mediumScreen={true}
        close={
          () => setShowEdit(false)
        }
      >
        <Grid container >
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11} >
            <FormControl sx={{ marginTop: "1rem" }} fullWidth>
              <InputLabel id="demo-simple-select-label">
                Assessor *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="client"
                defaultValue={formikEdit.values.client}
                value={formikEdit.values.client}
                label="Assessor"
                onChange={formikEdit.handleChange}
                error={
                  formikEdit.touched.assessor &&
                  Boolean(formikEdit.errors.assessor)
                }
                disabled
              >
                {custUserData.length > 0 &&
                  custUserData.map((option) => {
                    return (
                      <MenuItem key={option.id} value={option.id}>
                        {option?.assessmentCustomerUserDetail?.firstName}{" "}
                        {option?.assessmentCustomerUserDetail?.lastName}-
                        {option?.email}
                      </MenuItem>
                    );
                  })}
              </Select>
              <FormHelperText error={true} sx={{ color: "red" }}>
                {formikEdit.touched.client && formikEdit.errors.client}
              </FormHelperText>
            </FormControl>
            <FormControl sx={{ marginTop: "1rem" }} fullWidth>
              <InputLabel id="demo-simple-select-label">
                Relationship *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="relationship"
                defaultValue={formikEdit.values.relationship}
                value={formikEdit.values.relationship}
                label="Relationship"
                onChange={formikEdit.handleChange}
                error={
                  formikEdit.touched.relationship &&
                  Boolean(formikEdit.errors.relationship)
                }
              >
                {relationshipTypeData.map((option) => {
                  return (
                    <MenuItem
                      key={option.id}
                      value={option.relationshipTypeName}
                    >
                      {option?.relationshipTypeName}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error={true} sx={{ color: "red" }}>
                {formikEdit.touched.relationship &&
                  formikEdit.errors.relationship}
              </FormHelperText>
            </FormControl>
            <MyTextInput
              label="Comments"
              type="comments"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formikEdit.values.comments}
              onChange={formikEdit.handleChange}
              error={
                formikEdit.touched.comments &&
                Boolean(formikEdit.errors.comments)
              }
              helperText={
                formikEdit.touched.comments && formikEdit.errors.comments
              }
            />
          </Grid>
        </Grid>
        <Box style={{ width: "100%", textAlign: "center" }} >

          <FormSubmitButton onClick={formikEdit?.handleSubmit} type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>

      </CommonCurdPopUp>}



      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />

      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout >
  );
};

export default MapAssessors;
