import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_RELATIONSHIP_TYPE } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createRelationshipType = createAsyncThunk(
  "relationshipType/createRelationshipType",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_RELATIONSHIP_TYPE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getRelationshipType = createAsyncThunk(
  "relationshipType/getRelationshipType",
  async (obj, {rejectWithValue}) => {
    try{
      const response = await axios.get(CURD_RELATIONSHIP_TYPE, { withCredentials: true });
      return response;
    } catch (err) {
      return rejectWithValue(err.response) 
    }
  }
);

export const updateRelationshipType = createAsyncThunk(
  "relationshipType/updateRelationshipType",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_RELATIONSHIP_TYPE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteRelationshipType = createAsyncThunk(
  "relationshipType/deleteRelationshipType",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_RELATIONSHIP_TYPE, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const relationshipTypeSlice = createSlice({
  name: "relationshipType",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    relationshipTypeData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createRelationshipType.pending]: (state, action) => {
      state.loading = true;
    },
    [createRelationshipType.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createRelationshipType.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getRelationshipType.pending]: (state, action) => {
      state.loading = true;
    },
    [getRelationshipType.fulfilled]: (state, action) => {
      state.loading = false;
      state.relationshipTypeData = action?.payload?.data;
    },
    [getRelationshipType.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateRelationshipType.pending]: (state, action) => {
      state.loading = true;
    },
    [updateRelationshipType.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateRelationshipType.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteRelationshipType.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteRelationshipType.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteRelationshipType.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  relationshipTypeSlice.actions;
export default relationshipTypeSlice.reducer;
