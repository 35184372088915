import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";

import { Link, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
// material ui
import {

  IconButton,
  FormControlLabel,
  Stack,
  RadioGroup,
  Radio
} from "@mui/material";

// icons
import DescriptionIcon from "@mui/icons-material/Description";
import CancelIcon from "@mui/icons-material/Cancel";
import EmailIcon from "@mui/icons-material/Email";
import moment from "moment";
import { DateTime } from "luxon";
import { toPng } from 'html-to-image';
import PreviewIcon from '@mui/icons-material/Preview';
import format from "date-fns/format";
// components
import Layout from "../../layout/Layout";
import {
  MuiDataGrid,

  GridCellExpand,

  FormSubmitButton,
  FormTextField as MyTextInput,
  Notifier,
  ErrorNotifier,
  CommonCurdPopUp,
  MuiTooltip,
  ConfirmDialog
} from "../../components";
// redux
import {
  getAssessorsResponseList,
  getHistoryRemainderResponseList,
  sendReminderMail,
  rowSelect,
  resetMessage,
  resetErrMessage,
  updateAssessorStatus,
  emptyRow
} from "../../features/analyze_and_report/assessmentResponseSlice";
import { getPreviewReminderById } from "../../features/config_and_publish/publishAssessmentSlice";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// -------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  close_btn: {
    position: "absolute",
    right: 30,
    zIndex: 1,
    [theme.breakpoints.down("md")]: {
      marginRight: "-33px !important",
    },
  },
}));
const AssessmentReport = (props) => {

  const {
    message,
    errMessage,
    assessorsResponseList,
    remainderHistoryData,
    selectedData,
  } = useSelector((state) => ({ ...state.assessmentResponse }));

  const [open, setOpen] = React.useState(false);
  const [errors, setErrors] = React.useState("")
  const [comments, setComments] = React.useState("")
  const [statusOpen, setStatusOpen] = React.useState(false);
  const [updateStatus, setUpdateStatus] = useState("");
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [previewEmail, setPreviewEmail] = useState(false)
  const htmlRef = useRef(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const location = useLocation();


  const { previewRimnderData } = useSelector((state) => ({ ...state.publishAssessment }))

  function getClientName(params) {
    return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
  }

  function getReminderSentDate(params) {

    if (params?.row?.lastReminderSent) {
      let date = params?.row?.lastReminderSent;
      const dateResult = DateTime.fromISO(date).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      let localIsoString = dateResult.toISO();
      localIsoString = format(new Date(localIsoString), "MM-dd-yyyy")
      // date = date.substring(0, 10).split("-");
      // date = date[1] + "-" + date[2] + "-" + date[0];
      return localIsoString
    }
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const showReminderModel = (data) =>
    setConfirmDialog({
      isOpen: true,
      title: "Attention!",
      subTitle: "Clicking this button will send an email to the designated recipients. Please Preview the information before proceeding. Once sent, emails cannot be retrieved.",
      onConfirm: () =>
        dispatch(
          sendReminderMail({
            assessmentTrackingId: data?.assessmentTrackingId,
            assessmentCustomerUserId: data?.id,
          })
        ).then((res) => {
          if (res?.payload?.data?.ok) {
            setTimeout(() => {
              dispatch(resetMessage());
              dispatch(
                getAssessorsResponseList({
                  id: location?.state?.trackingSelectedData?.id,
                })
              );
              setConfirmDialog({
                ...confirmDialog,
                isOpen: false,
              });
            }, 2000);
          }
          if (res?.payload?.error) {
            setTimeout(() => {
              dispatch(resetErrMessage());
              setConfirmDialog({
                ...confirmDialog,
                isOpen: false,
              });
            }, 3000);
          }
        })
    })

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const data = {
    rows: assessorsResponseList,
    columns: [
      {
        field: "clientName",
        headerName: "Assessor Name",
        width: 150,
        renderCell: renderCellExpand,
        valueGetter: getClientName,
      },
      {
        field: "email",
        headerName: "Assessor E-Mail",
        width: 150,
        renderCell: renderCellExpand,
      },
      {
        field: "responseStatus",
        headerName: "Response Status",
        width: 150,
        renderCell: (params) => {
          return (
            <MuiTooltip
              title={params?.row?.selfAssessmentStatus}
              placement="bottom"
            >
              <div
                style={{
                  backgroundColor:
                    params?.row?.selfAssessmentStatus === "Pending"
                      ? "#f50a0a"
                      : "#1f4904",
                  width: "20px",
                  height: "20px",
                  border: `1px solid ${params?.row?.selfAssessmentStatus === "Pending"
                    ? "#f50a0a"
                    : "#1f4904"
                    }`,
                  borderRadius: "100%",
                }}
              ></div>
            </MuiTooltip>
          );
        },
      },
      {
        field: 'Preview',
        // headerName: 'Clone',
        type: 'preview',
        width: 70,
        renderCell: (params) => {
          if (params?.row?.selfAssessmentStatus !== "Completed") {
            return (
              // location?.state?.permissions && location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "forceDelCustUsr") && 
              <MuiTooltip title="Reminder Email" placement="right">
                <IconButton onClick={() => handlePreviewClick({
                  trackingId: location?.state?.trackingSelectedData?.id, customerId: params?.row?.assessmentCustomerUserId
                })} color="primary" >
                  <PreviewIcon />
                </IconButton>
              </MuiTooltip>
            );
          }
        }
      },
      {
        field: "reminder",
        headerName: "Reminder To Assessor",
        type: "actions",
        width: 200,
        renderCell: (params) => {
          if (params?.row?.selfAssessmentStatus !== "Completed") {
            return (
              <MuiTooltip title="Reminder Mail" placement="bottom">
                <IconButton
                  onClick={
                    () => showReminderModel(params.row)
                    // () =>
                    // dispatch(
                    //   sendReminderMail({
                    //     assessmentTrackingId: params?.row?.assessmentTrackingId,
                    //     assessmentCustomerUserId: params?.row?.id,
                    //   })
                    // ).then((res) => {
                    //   if (res?.payload?.data?.ok) {
                    //     setTimeout(() => {
                    //       dispatch(resetMessage());
                    //       dispatch(
                    //         getAssessorsResponseList({
                    //           id: location?.state?.trackingSelectedData?.id,
                    //         })
                    //       );
                    //     }, 2000);
                    //   }
                    //   if (res?.payload?.error) {
                    //     setTimeout(() => {
                    //       dispatch(resetErrMessage());
                    //     }, 3000);
                    //   }
                    // })
                  }
                >
                  <EmailIcon style={{ color: "#808080" }} />
                </IconButton>
              </MuiTooltip>
            );
          }
        },
      },
      {
        field: "reminderDate",
        headerName: "Reminder Sent Date",
        width: 150,
        valueGetter: getReminderSentDate,
      },

      {
        field: "remainder",
        headerName: "Reminder History",
        type: "actions",
        width: 150,
        renderCell: (params) => {
          return (
            <div>
              <MuiTooltip title="Reminder History" placement="bottom">
                <IconButton>
                  <DescriptionIcon
                    onClick={() => {
                      handleClickOpen();
                      dispatch(
                        getHistoryRemainderResponseList({
                          assessmentCustomerTrackingId:
                            params?.row?.assessmentTrackingId,
                          assessmentCustomerUserId: params?.row?.id,
                        })
                      ).then((res) => {
                        if (res?.payload?.data?.ok) {
                          setTimeout(() => {
                            dispatch(resetMessage());
                          }, 2000);
                        }
                        if (res?.payload?.error) {
                          setTimeout(() => {
                            dispatch(resetErrMessage());
                          }, 3000);
                        }
                      });
                    }}
                    style={{ color: "#808080" }}
                  />
                </IconButton>
              </MuiTooltip>
            </div>
          );
        },
      },
      {
        field: "customer",
        headerName: "Customer Name",
        width: 200,
        valueGetter: (params) => params?.row?.customerName,

        renderCell: renderCellExpand,
      },
      {
        field: "account",
        headerName: "Account Name",
        width: 200,
        valueGetter: (params) => params?.row?.accountName,

        renderCell: renderCellExpand,
      },
      {
        field: "responseStatuscomments",
        headerName: "Response Status Comments",
        width: 200,
        valueGetter: (params) => params?.row?.responseStatuscomments,

        renderCell: renderCellExpand,
      },
    ],
  };

  useEffect(() => {
    dispatch(
      getAssessorsResponseList({
        id: location?.state?.trackingSelectedData?.id,
      })
    );
  }, []);


  const classes = useStyles();
  const handleUpdateStatusChange = (event) => {
    setUpdateStatus(event.target.value);
  };

  const handleUpdateClickOpen = () => {
    setStatusOpen(true);
  };

  const handleUpdateClose = () => {
    setStatusOpen(false);
  };

  const handleSubmitClick = () => {
    if (validationCheck(comments)) {
      dispatch(updateAssessorStatus({
        assessmentTrackingId: selectedData?.assessmentTrackingId,
        assessmentCustomerUserId: selectedData?.id,
        status: updateStatus ? updateStatus : selectedData?.selfAssessmentStatu,
        responseStatuscomments: comments
      }))
        .then((res) => {

          if (res?.payload?.data?.ok) {
            setTimeout(() => {
              dispatch(resetMessage());
              dispatch(
                getAssessorsResponseList({
                  id: selectedData?.assessmentTrackingId,
                })
              );
              handleUpdateClose()
              dispatch(emptyRow())
            }, 2000);
          }
          if (res?.error) {
            setTimeout(() => {
              dispatch(resetErrMessage());
            }, 3000);
          }
        })
        .catch((err) => {

          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 2000);
        });

    }


  }

  const handlePreviewClick = async (data) => {

    setPreviewEmail(true)
    dispatch(getPreviewReminderById({ assessmentTrackingId: data?.trackingId, assessmentCustomerUserId: data?.customerId }))
    if (htmlRef.current) {
      // Convert HTML content to image
      await toPng(htmlRef.current);
    }
  }

  const validationCheck = (data) => {
    if (!data?.length) {
      setErrors("This Field is Mandatory")
      return false
    }
    else {
      setErrors("")
      return true
    }
  }

  const handleCommentChange = (e) => {

    validationCheck(e.target.value)
    setComments(e.target.value)
  }

  console.log(errors)

  return (
    <Layout>
      <>
        <Link
          to="/exco/assessment/report"
          state={{
            permissions: location?.state?.permissions,
            trackingSelectedData: location?.state?.assessmentData,
          }}
        >
          <CancelIcon color="red" sx={{}} className={classes.close_btn} />
        </Link>
        <PageHeader variant="pageHeader">Assessors List</PageHeader>
        <MuiDataGrid
          toolbarColumn={true}
          // toolbarFilter={true}
          toolbarDensity={true}
          toolbarExport={true}
          data={data}
          setShowEditButton={selectedData?.selfAssessmentStatus === "Completed" || selectedData?.selfAssessmentStatus === "Pending" ?
            true : false}
          handleUpdateClickOpen={handleUpdateClickOpen}
          select={(data) => dispatch(rowSelect(data))}
        />
      </>

      {previewEmail && <CommonCurdPopUp
        heading="Email Preview"
        mediumScreen={true}
        close={
          () => setPreviewEmail(false)
        }
      >

        <div ref={htmlRef} dangerouslySetInnerHTML={{ __html: previewRimnderData }} style={{}} />


      </CommonCurdPopUp>}

      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Reminder History"}
        </DialogTitle>
        <DialogContent>
          {remainderHistoryData.length > 0 &&
            remainderHistoryData.map((data) => {
              const formattedDate = moment(data?.lastReminderSent).local().format('DD MMM YYYY HH:mm:ss (UTCZ)');
              const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

              return (
                <DialogContentText sx={{ display: "block" }}>
                  {`${formattedDate} - ${timeZone} `}
                </DialogContentText>
              );
            })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog

        open={statusOpen}
        onClose={handleUpdateClose}
        aria-labelledby="responsive-dialog-title"
      >
        <IconButton
          aria-label="close"
          onClick={handleUpdateClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,

          }}
        >
          <CloseIcon />
        </IconButton>

        {/* <DialogTitle id="responsive-dialog-title">
          {"Change Status"}
        </DialogTitle> */}
        <DialogContent>
          <Stack sx={{ width: "30rem" }}>

            <RadioGroup

              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={updateStatus ? updateStatus : selectedData?.selfAssessmentStatus}
              onChange={handleUpdateStatusChange}
            >

              <FormControlLabel
                style={{ color: "black", }}
                value="Completed"
                control={<Radio />}
                label="Completed"
              />
              <FormControlLabel
                style={{ color: "black", margin: "1rem" }}
                value="Pending"
                control={<Radio />}
                label="Pending"
              />
            </RadioGroup>

            <MyTextInput
              tabIndex="5"
              label="Response Status Comments"
              type="text"
              name="comment"
              fullWidth={true}
              required={true}
              margin="normal"
              id="executiveSponsor"
              value={comments}
              onChange={handleCommentChange}
              error={
                errors &&
                errors
              }
              helperText={
                errors &&
                errors
              }

            />


          </Stack>
          <Stack sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <FormSubmitButton onClick={handleSubmitClick} type="submit" variant="contained">
              Submit
            </FormSubmitButton>
          </Stack>
        </DialogContent>

      </Dialog>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />

      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default AssessmentReport;