import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_RESPONSE_STATUS } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createResponseStatus = createAsyncThunk(
  "responseStatus/createResponseStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_RESPONSE_STATUS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getResponseStatus = createAsyncThunk(
  "responseStatus/getResponseStatus",
  async (obj, {rejectWithValue}) => {
    try {
      const response = await axios.get(CURD_RESPONSE_STATUS, { withCredentials: true });
      return response;
    } catch (err) {
      return rejectWithValue(err.response) 
    }
  }
);

export const updateResponseStatus = createAsyncThunk(
  "responseStatus/updateResponseStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_RESPONSE_STATUS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteResponseStatus = createAsyncThunk(
  "responseStatus/deleteResponseStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_RESPONSE_STATUS, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const responseStatusSlice = createSlice({
  name: "responseStatus",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    responseStatusData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createResponseStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [createResponseStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createResponseStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getResponseStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [getResponseStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.responseStatusData = action?.payload?.data;
    },
    [getResponseStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateResponseStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [updateResponseStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateResponseStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteResponseStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteResponseStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteResponseStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  responseStatusSlice.actions;
export default responseStatusSlice.reducer;
