import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {
  CURD_ACCOUNTS,
  GET_ALL_USERS_EXCEL_DOWNLOAD,
  GET_ALL_STATUS_EXCEL_DOWNLOAD,
  GET_STATUS_DETAILS_CUSTOMERS,
  GET_STATUS_ALL_CUSTOMERS_ACCOUNTS,
  GET_CLIENTS_DATA_CUSTOMERS_DETAILS_ACCOUNTS_GRAPH,
  GET_ACCOUNT_BY_ID,
  GET_CLIENTS_DATA_CUSTOMERS_ACCOUNTS_GRAPH,
  GET_ALL_STATUS_ALL_CUSTOMERS,
  GET_ALL_STATUS_ALL_CUSTOMERS_TWO,
} from "../../utils/routes";

// ---------------------------------------------------------------------

export const createAccount = createAsyncThunk(
  "account/createAccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_ACCOUNTS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getDownLoadAssessmentPie = createAsyncThunk(
  "account/getDowloadDetailsPie",
  async (data) => {
    return axios
    .post(GET_ALL_STATUS_EXCEL_DOWNLOAD, data, {
      withCredentials: true,
      responseType: "blob",
    })
    .then((res) => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
  
      link.href = url;
      link.setAttribute("download", "status_report.xlsx"); // Set filename
      document.body.appendChild(link);
      link.click();
      
      // Cleanup
      link.remove();
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {
      console.error("Download error:", err);
    });
  
  }
);

export const getDownLoadAssessmentBar = createAsyncThunk(
  "account/getDowloadDetailsBar",
  async (data) => {
    return axios
    .post(GET_ALL_USERS_EXCEL_DOWNLOAD, data, {
      withCredentials: true,
      responseType: "blob",
    })
    .then((res) => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
  
      link.href = url;
      link.setAttribute("download", "Assessment_progress.xlsx"); // Set filename
      document.body.appendChild(link);
      link.click();
      
      // Cleanup
      link.remove();
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {
      console.error("Download error:", err);
    });
  }
);

export const getAllStatusForAllCustomers = createAsyncThunk(
  "customers/getAllStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(GET_ALL_STATUS_ALL_CUSTOMERS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStatusDetailsForCustomer = createAsyncThunk(
  "customers/getStatusDetails",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(GET_STATUS_DETAILS_CUSTOMERS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllStatusForAllCustomersGraph = createAsyncThunk(
  "customers/getAllStatusGraph",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        GET_ALL_STATUS_ALL_CUSTOMERS_TWO,
        data,
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllUserDetailsForAllCustomersGraph = createAsyncThunk(
  "customersDetails/getAllUserDetailsGraph",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        GET_CLIENTS_DATA_CUSTOMERS_DETAILS_ACCOUNTS_GRAPH,
        data,
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStatusForCustomerAccounts = createAsyncThunk(
  "customersAccounts/getAllStatusCustAccounts",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        GET_STATUS_ALL_CUSTOMERS_ACCOUNTS,
        data,
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getClientDataForCustomerAccounts = createAsyncThunk(
  "customerr/clientsData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        GET_CLIENTS_DATA_CUSTOMERS_ACCOUNTS_GRAPH,
        data,
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAccount = createAsyncThunk(
  "account/getAccount",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await axios.get(CURD_ACCOUNTS, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const getCustAccount = createAsyncThunk(
  "account/getCustAccount",
  async (id) => {
    return axios.get(`${CURD_ACCOUNTS}/${id}}`, {
      withCredentials: true,
    });
  }
);

export const updateAccount = createAsyncThunk(
  "account/updateAccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_ACCOUNTS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAccount = createAsyncThunk(
  "account/deleteAccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_ACCOUNTS, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    customerStatusData: [],
    customerCountData: [],

    customerClientDetails: [],
    customerStatusDetails: [],

    accountData: [],
    custAccountData: [],
    accountIdData: [],
    selectedAccountData: {},
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },

    getselectedAccount: (state, action) => {
      state.selectedAccountData = action?.payload;
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [createAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createAccount.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [getAllStatusForAllCustomers.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllStatusForAllCustomers.fulfilled]: (state, action) => {
      state.loading = false;
      state.customerStatusData = action?.payload?.data;
    },
    [getAllStatusForAllCustomers.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getStatusDetailsForCustomer.pending]: (state, action) => {
      state.loading = true;
    },
    [getStatusDetailsForCustomer.fulfilled]: (state, action) => {
      state.loading = false;
      state.customerStatusDetails = action?.payload?.data;
    },
    [getStatusDetailsForCustomer.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getStatusForCustomerAccounts.pending]: (state, action) => {
      state.loading = true;
    },
    [getStatusForCustomerAccounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.customerStatusData = action?.payload?.data;
    },
    [getStatusForCustomerAccounts.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getAllStatusForAllCustomersGraph.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllStatusForAllCustomersGraph.fulfilled]: (state, action) => {
      state.loading = false;
      state.customerCountData = action?.payload?.data;
    },
    [getAllStatusForAllCustomersGraph.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getAllUserDetailsForAllCustomersGraph.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllUserDetailsForAllCustomersGraph.fulfilled]: (state, action) => {
      state.loading = false;
      state.customerClientDetails = action?.payload?.data;
    },
    [getAllUserDetailsForAllCustomersGraph.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getClientDataForCustomerAccounts.pending]: (state, action) => {
      state.loading = true;
    },
    [getClientDataForCustomerAccounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.customerCountData = action?.payload?.data;
    },
    [getClientDataForCustomerAccounts.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [getAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.accountData = action?.payload?.data;
      state.accountIdData = [];
    },
    [getAccount.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getCustAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [getCustAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.custAccountData = action?.payload?.data;
    },
    [getCustAccount.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [updateAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateAccount.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteAccount.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteAccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteAccount.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect, getselectedAccount } =
  dashboardSlice.actions;
export default dashboardSlice.reducer;
