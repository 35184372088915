import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useLocation } from "react-router-dom";
// material ui
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
  IconButton,
} from "@mui/material";
// icons
import SettingsIcon from "@mui/icons-material/Settings";
import HelpIcon from "@mui/icons-material/Help";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DeleteIcon from "@mui/icons-material/Delete";
// components
import Layout from "../../../../layout/Layout";
import {
  MuiDataGrid,
  GridCellExpand,
  ModalPopUp,
  FormSubmitButton,
  MyTextInput,
  Notifier,
  MuiAutocomplete,
  ErrorNotifier,
  ModalCloseBtn,
  ConfirmDialog,
  MuiTooltip,
  PageHeader,
} from "../../../../components";

import { Stack } from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";

// redux
import {
  createAssessmentTemplateConfig,
  getAssessmentTemplateConfig,
  updateAssessmentTemplateConfig,
  deleteAssessmentTemplateConfig,
  forceDeleteAssessmentTemplateConfig,
  copyAssessmentTemplateConfig,
  rowSelect,
  resetMessage,
  resetErrMessage,
} from "../../../../features/config/assessmentTemplateConfigSlice";
import { getPlatformStatus } from "../../../../features/config/platformStatusSlice";
import { getTemplateType } from "../../../../features/config/templateType";
// import { ConfirmDialog } from "../../../../components";
import MicrositeConfirmDialog from "../../../customer_management/microsites/MicrositeConfirmDialog";
import { TurnLeftOutlined } from "@mui/icons-material";
import { textAreaChecker, textChecker } from "../../../../components/common/form_validation/regularExpressions";
import { textAreaFieldErr, textFieldErr } from "../../../../components/common/form_validation/validationErrMessages";

// ------------------------------------------------------------------------------

const validationSchema = Yup.object({
  templateDisplayName: Yup.string().required(
    "Template Display Name is required"
  ).matches(textChecker, textFieldErr),
  templateName: Yup.string().required("Assessment Name is required").matches(textChecker, textFieldErr),
  description: Yup.string()
    .max(500, "Description must be at most 500 characters")
    .required("Description is required").matches(textAreaChecker, textAreaFieldErr),
  // templateType: Yup.object().nullable().required("Template Type is required"),
});

const editValidationSchema = Yup.object({
  templateDisplayName: Yup.string().required(
    "Template Display Name is required"
  ).matches(textChecker, textFieldErr),
  templateName: Yup.string().required("Assessment Name is required").matches(textChecker, textFieldErr),
  status: Yup.string().required("Status is required"),
  description: Yup.string()
    .max(500, "Description must be at most 500 characters")
    .required("Description is required").matches(textAreaChecker, textAreaFieldErr),
  // templateType: Yup.object().nullable().required("Template Type is required"),
});

// -------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const useStyles = makeStyles((theme) => ({
  rootbox: {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: "56.25rem !important",
    [theme.breakpoints.down("sm")]: {
      width: "17.75rem !important",
      height: "44% !important",
      // display:'block !important',
      // overflow:'scroll !important',
      marginTop: "-8.25rem",
      marginLeft: "-0.938rem",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "24.375rem  !important",
      height: "34% !important",
      display: "block !important",
      overflow: "scroll !important",
      // margin: '-3.188rem',
      marginTop: "-3.75rem  !important",
      marginLeft: "-3.563rem",
      padding: "2.125rem !important",
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: "1.5rem !important",
    paddingTop: "1rem !important",
    paddingLeft: "2rem !important",
    paddingRight: "2rem !important",
    paddingBottom: "1.5rem",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },

  responsive_text: {
    width: "50rem",
    [theme.breakpoints.down("md")]: {
      width: "13rem",
    },
  },
}));
// ------------------------------------------------------------------------

const AssessmentTemplateConfig = (props) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showAdd2, setShowAdd2] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [forceDelConfirmDialog, setForceDelConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [copyLoading, setCopyLoading] = useState(false);

  const dispatch = useDispatch();

  const location = useLocation();

  const { message, errMessage, assessmentTemplateConfigData, selectedData } =
    useSelector((state) => ({ ...state.assessmentTemplateConfig }));

  const { assessmentTemplateStatusData } = useSelector((state) => ({
    ...state.assessmentTemplateStatus,
  }));

  const { TemplateTypeData } = useSelector((state) => ({
    ...state.assessmentTemplateType,
  }));

  const { permissionsData } = useSelector((state) => ({
    ...state.permissions,
  }));



  const showAddModel = () => {
    setShowAdd2(!showAdd);
  };

  const closeModel = () => {
    setShowAdd2(false);
    formik.resetForm({
      values: {
        templateName: "",
        description: "",
        comments: "",
      },
    });
  };
  // function getTemplateTypeName(params) {

  //   return params?.row?.templateTypeName &&
  //     params?.row?.templateTypeName.toLowerCase() == "standard"
  //     ? "Standard" : params?.row?.templateTypeName.toLowerCase() == "talent" ? "Client"
  //     : params?.row?.templateTypeName;
  // }

  function getTemplateTypeName(params) {
    const templateTypeName = params?.row?.assessmentType;

    return templateTypeName;
  }

  const showEditModal = () => {
    setShowEdit(true);
  };

  const closeEditModal = () => {
    setShowEdit(false);
  };

  const showDelete = () =>
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => handleDeleteCustomer(),
    });

  const handleDeleteCustomer = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deleteAssessmentTemplateConfig(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(getAssessmentTemplateConfig());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const showForceDelete = (dataId) =>
    setForceDelConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: (pass) => handleForceDeleteAssessmentTemplate(pass, dataId),
    });

  const handleForceDeleteAssessmentTemplate = (pass, dataId) => {
    let data = {
      id: dataId?.id,
      password: pass,
    };
    dispatch(forceDeleteAssessmentTemplateConfig(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setForceDelConfirmDialog({
            ...forceDelConfirmDialog,
            isOpen: false,
          });
          dispatch(getAssessmentTemplateConfig());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 2000);
      }
    });
  };

  const handleSetForceDelConfirmDialog = (obj) => {
    setForceDelConfirmDialog({
      ...forceDelConfirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const classes = useStyles();

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params?.value || ""}
        width={params?.colDef.computedWidth}
      />
    );
  }



  const data = {
    rows: assessmentTemplateConfigData?.length > 0 ? assessmentTemplateConfigData : [],
    columns: [
      {
        field: "assessmentTemplateName",
        headerName: "Assessment Template Name",
        width: 250,
        renderCell: renderCellExpand,
      },
      {
        field: "assessmentTemplateDisplayName",
        headerName: "Template Display Name",
        width: 250,
        renderCell: renderCellExpand,
      },

      {
        field: "status",
        headerName: "Template Status",
        width: 150,
        renderCell: renderCellExpand,
      },

      {
        field: "config",

        type: "actions",
        width: 50,
        renderCell: (params) => {
          return (
            <MuiTooltip title="Sections & Dimensions" placement="right">

              {location?.state?.
                permissions?.find(
                  (e) =>
                    e.applicationService?.name?.split(".")[
                    e?.applicationService?.name?.split(".")?.length - 1
                    ] === "getAssessSecByTempId"
                ) && (
                  <Link
                    to="/exco/config/assessment-template/section-dimension"
                    state={{
                      templateSelectedData: params?.row,
                      permissions: location?.state?.permissions,
                      l3Menu:location?.state?.l3Menu,
                      templateNameDisplay: params?.row?.assessmentTemplateName
                        ? params?.row?.assessmentTemplateName
                        : "",

                    }}

                  >
                    <IconButton sx={{ p: 0 }}>
                      <SettingsIcon style={{ color: "#808080" }} />
                    </IconButton>
                  </Link>
                )}
            </MuiTooltip>
          );
        },
      },
      {
        field: "prepareQuestion",

        type: "actions",
        width: 50,
        renderCell: (params) => {
          return (

            <MuiTooltip title="Questions" placement="right">
              {location?.state?.
                permissions?.find(
                  (e) =>
                    e.applicationService?.name?.split(".")[
                    e?.applicationService?.name?.split(".")?.length - 1
                    ] === "getAssessQnByTempId"
                ) && (
                  <Link
                    to="/exco/config/assessment-template/prepare-questions"
                    state={{
                      templateSelectedData: params?.row,
                      l3Menu:location?.state?.l3Menu,
                      permissions: location?.state?.permissions,
                      moduleName: location.moduleName,
                      data: props?.location?.data,
                    }}


                  >
                    <IconButton sx={{ p: 0 }}>
                      <HelpIcon style={{ color: "#808080" }} />
                    </IconButton>
                  </Link>
                )}
            </MuiTooltip>

          );
        },
      },


      {
        field: "copyAssessment",
        headerName:
          location?.state?.
            permissions?.find(
              (e) =>
                e.applicationService?.name?.split(".")[
                e?.applicationService?.name?.split(".")?.length - 1
                ] === "assessTempClone"
            )
            ? "Clone"
            : "",
        type: "actions",

        width: 70,
        renderCell: (params) => {
          return (
            <MuiTooltip title="Clone Template" placement="right">

              {location?.state?.
                permissions?.find(
                  (e) =>
                    e.applicationService?.name?.split(".")[
                    e?.applicationService?.name?.split(".")?.length - 1
                    ] === "assessTempClone"
                ) && (
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={() =>
                      dispatch(
                        copyAssessmentTemplateConfig({ id: params?.row?.id })
                      ).then((res) => {
                        if (res?.payload?.data?.ok) {
                          setCopyLoading(true);
                          setTimeout(() => {
                            dispatch(resetMessage());
                            dispatch(getAssessmentTemplateConfig());
                            setCopyLoading(false);
                          }, 2000);
                        }
                        if (res?.payload?.error) {
                          setTimeout(() => {
                            dispatch(resetErrMessage());
                          }, 3000);
                        }
                      })
                    }
                    disabled={copyLoading}
                  >
                    <FileCopyIcon style={{ color: "#808080" }} />
                  </IconButton>
                )}

            </MuiTooltip>
          );
        },
      },


      {
        field: "forceDelete",

        type: "actions",
        width: 70,
        renderCell: (params) => {
          return (
            <MuiTooltip title="Force Delete" placement="right">

              {location?.state?.
                permissions?.find(
                  (e) =>
                    e.applicationService?.name?.split(".")[
                    e?.applicationService?.name?.split(".")?.length - 1
                    ] === "assessTempforceDel"
                ) && (
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={() => showForceDelete(params?.row)}
                  >
                    <DeleteIcon style={{ color: "#FF0000" }} />
                  </IconButton>
                )}

            </MuiTooltip>
          );
        },
      },

      {
        field: "detailedDescription",
        headerName: "Description",
        width: 450,
        renderCell: renderCellExpand,
      },
    ],
  };

  // download template function
  // const handleDownloadTempleteConfig = () => {
  //   dispatch(downloadAssessmentTemplete()).then((res) => {
  //     if (res?.payload?.data?.error) {
  //       setTimeout(() => {
  //         dispatch(resetErrMessage());
  //       }, 2000);
  //     }
  //   });
  // };

  const formik = useFormik({
    reset: true,
    initialValues: {
      templateName: "",
      templateDisplayName: "",
      templateType: "example one",
      description: "",
      comments: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values, onSubmitProps) => {
      dispatch(
        createAssessmentTemplateConfig({
          assessmentTemplateName: values.templateName,
          assessmentTemplateDisplayName: values.templateDisplayName,
          assessmentType: values?.templateType?.assessmentType,
          detailedDescription: values.description,
          status: "New",
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeModel();
            dispatch(getAssessmentTemplateConfig());
            onSubmitProps.resetForm();
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      templateName: selectedData?.assessmentTemplateName,
      templateDisplayName: selectedData?.assessmentTemplateDisplayName,
      templateType: TemplateTypeData?.find(
        (data) => data?.assessmentType === selectedData?.assessmentType
      ),
      description: selectedData?.detailedDescription,
      status: selectedData?.status,
      comments: selectedData?.comments,
    },

    validationSchema: editValidationSchema,
    onSubmit: (values) => {
      dispatch(
        updateAssessmentTemplateConfig({
          id: selectedData?.id,
          assessmentTemplateName: values.templateName,
          assessmentTemplateDisplayName: values.templateDisplayName,
          detailedDescription: values.description,
          assessmentType: values?.templateType?.assessmentType,
          status: values?.status,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeEditModal();
            dispatch(getAssessmentTemplateConfig());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getAssessmentTemplateConfig());
    dispatch(getPlatformStatus());
    dispatch(getTemplateType());
  }, []);

  let shrink = props.sidebar ? "scale" : "no-scale";

  console.log(location)

  return (
    <Layout>
      <>
        <Stack flexDirection="row" justifyContent="space-between">
          <Box sx={{ width: "100%" }}>
            <PageHeader variant="pageHeader">
             Manage Master Templates
            </PageHeader>
          </Box>
          {/* <Link
            to="/exco/platform/config"
            state={{
              l3Menu: location?.state?.l3Menu,
              permissions:location?.state?.permissions,

            }}
          >
            <CancelIcon color="red" />
          </Link> */}
        </Stack>

        {/* <PageHeader
          variant="pageHeader"
          pathname="/exco/platform/config"
          moduleName={props?.location?.moduleName}
          data={props?.location?.data}
        >
          Assessment Template
        </PageHeader> */}
        <MuiDataGrid
          setShowAdd={
            location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "createAssessTemp") &&
            showAddModel
          }
          setShowEdit={
            location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "updateAssessTemp") &&
            showEditModal
          }
          setShowDelete={
            location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "deleteAssessTemp") &&
            showDelete
          }
          // setShowAdd={
          //   location?.serviceAccessData?.userRoleL3ServicePermission?.length >
          //     0 &&
          //   location?.serviceAccessData?.userRoleL3ServicePermission?.find(
          //     (e) =>
          //       e.applicationService?.name?.split(".")[
          //         e?.applicationService?.name?.split(".")?.length - 1
          //       ] === "createAssessTemp"
          //   ) &&
          //   showAddModel
          // }
          // setShowEdit={
          //   location?.serviceAccessData?.userRoleL3ServicePermission?.length >
          //     0 &&
          //   location?.serviceAccessData?.userRoleL3ServicePermission?.find(
          //     (e) =>
          //       e.applicationService?.name?.split(".")[
          //         e?.applicationService?.name?.split(".")?.length - 1
          //       ] === "updateAssessTemp"
          //   ) &&
          //   showEditModal
          // }
          // setShowDelete={
          //   location?.serviceAccessData?.userRoleL3ServicePermission?.length >
          //     0 &&
          //   location?.serviceAccessData?.userRoleL3ServicePermission?.find(
          //     (e) =>
          //       e.applicationService?.name?.split(".")[
          //         e?.applicationService?.name?.split(".")?.length - 1
          //       ] === "deleteAssessTemp"
          //   ) &&
          //   showDelete
          // }
          toolbarColumn={true}
          // toolbarFilter={true}
          toolbarDensity={true}
          toolbarExport={true}
          toolbarUpload={true}
          // handleUpload={handleFile}
          toolbarPageObjectDownload={true}
          // handlePageObjectDownload={handleDownloadTempleteConfig}
          data={data}
          select={(data) => dispatch(rowSelect(data))}
          projCheck={false}
        />
      </>
      <ModalPopUp
        open={showAdd2}
        handleClose={closeModel}
        closeModel={closeModel}
      >
        <Box
          // sx={{ ...style, width: 900 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <PageHeader>Add Master Templates</PageHeader>
          <ModalCloseBtn close={closeModel} />
          <div className={classes.responsive_text}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={10} margin="auto">
                <MyTextInput
                  label="Template Name"
                  type="text"
                  name="templateName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="templateName"
                  value={formik.values.templateName}
                  onChange={formik.handleChange}
                  tabIndex={1}
                  error={
                    formik.touched.templateName &&
                    Boolean(formik.errors.templateName)
                  }
                  helperText={
                    formik.touched.templateName && formik.errors.templateName
                  }
                />
                <MyTextInput
                  label="Display Name"
                  type="text"
                  name="templateDisplayName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="templateDisplayName"
                  value={formik.values.templateDisplayName}
                  onChange={formik.handleChange}
                  tabIndex={1}
                  error={
                    formik.touched.templateDisplayName &&
                    Boolean(formik.errors.templateDisplayName)
                  }
                  helperText={
                    formik.touched.templateDisplayName &&
                    formik.errors.templateDisplayName
                  }
                />

                <MyTextInput
                  label="Detailed description"
                  type="text"
                  name="description"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  multiline={true}
                  rows={4}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </Grid>
            </Grid>
          </div>

          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>
      <ModalPopUp
        open={showEdit}
        handleClose={() => setShowEdit(false)}
        closeModel={() => setShowEdit(false)}
      >
        <Box
          // sx={{ ...style, width: 900 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formikEdit.handleSubmit}
        >
          <PageHeader>Update Master Templates</PageHeader>
          <ModalCloseBtn close={() => setShowEdit(false)} />
          <div className={classes.responsive_text}>
            <Grid container>
              <Grid item xs={12} sm={12} md={10} margin="auto">
                <MyTextInput
                  label="Template Name"
                  type="text"
                  name="templateName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="templateName"
                  defaultValue={formikEdit.values.templateName}
                  value={formikEdit.values.templateName}
                  onChange={formikEdit.handleChange}
                  error={
                    formikEdit.touched.templateName &&
                    Boolean(formikEdit.errors.templateName)
                  }
                  helperText={
                    formikEdit.touched.templateName &&
                    formikEdit.errors.templateName
                  }
                />

                <MyTextInput
                  label="Display Name"
                  type="text"
                  name="templateDisplayName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="templateDisplayName"
                  defaultValue={formikEdit.values.templateDisplayName}
                  value={formikEdit.values.templateDisplayName}
                  onChange={formikEdit.handleChange}
                  error={
                    formikEdit.touched.templateDisplayName &&
                    Boolean(formikEdit.errors.templateDisplayName)
                  }
                  helperText={
                    formikEdit.touched.templateDisplayName &&
                    formikEdit.errors.templateDisplayName
                  }
                />
                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Template Status *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="status"
                    defaultValue={formikEdit.values.status}
                    value={formikEdit.values.status}
                    label="Template Status"
                    onChange={formikEdit.handleChange}
                    error={
                      formikEdit.touched.status &&
                      Boolean(formikEdit.errors.status)
                    }
                  >
                    {assessmentTemplateStatusData.map((option) => {
                      return (
                        <MenuItem key={option.id} value={option.
                          assessmentTemplateStatus}>
                          {option.
                            assessmentTemplateStatus}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {formikEdit.touched.status && formikEdit.errors.status}
                  </FormHelperText>
                </FormControl>


                <MyTextInput
                  label="Detailed description"
                  type="text"
                  name="description"
                  fullWidth={true}
                  required={true}
                  margin="normal"
                  id="description"
                  value={formikEdit.values.description}
                  onChange={formikEdit.handleChange}
                  multiline={true}
                  rows={4}
                  error={
                    formikEdit.touched.description &&
                    Boolean(formikEdit.errors.description)
                  }
                  helperText={
                    formikEdit.touched.description &&
                    formikEdit.errors.description
                  }
                />
              </Grid>
            </Grid>
          </div>

          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />

      < MicrositeConfirmDialog
        confirmDialog={forceDelConfirmDialog}
        setConfirmDialog={(obj) => handleSetForceDelConfirmDialog(obj)}
      />
      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default AssessmentTemplateConfig;
