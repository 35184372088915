import React from "react";
import ReactDOM from "react-dom";
// redux
import { Provider } from "react-redux";
import { store } from "./app/store";
// fonts
import "./fonts/din/DIN-Bold.ttf";
import { createRoot } from "react-dom/client";
// css
import "./index.css";
// material ui
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme/Theme";
// components
import App from "./App";

// ------------------------------------------------------------

const root = createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>,
 
);
