import React from "react";
import { PageHeader, MyTextInput } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
// material ui
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Box,
  CardContent,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import { FormSubmitButton,CommonCurdPopUp } from "../../../../components";
import Notifier from "../../../../components/common/Notifier";
import ErrorNotifier from "../../../../components/common/ErrorNotifier";
// redux
import {
  updateUserGroups,
  getUserGroups,
  resetMessage,
  resetErrMessage,
} from "../../../../features/iam/role_management/userGroupsSlice";

// --------------------------------------------------------------

const validationSchema = Yup.object({
  groupName: Yup.string().required("Group Name is required"),
  groupDesc: Yup.string().required("Group Description is required"),
});

//   ------------------------------------------------------------------------------------------

const UpdateUserGroups = (props) => {
  const { message, errMessage, selectedData } = useSelector(
    (state) => ({ ...state.userGroups })
  );

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      groupName: selectedData?.groupName,
      groupDesc: selectedData?.groupDescription,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        updateUserGroups({
          id: selectedData.id,
          groupName: values?.groupName,
          groupDescription: values?.groupDesc,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.close();
            dispatch(getUserGroups());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  return (
    <>

      {props?.showEdit && <CommonCurdPopUp
        heading={`Update User Groups`}
        mediumScreen={true}
        close={props.close}
      >
        <Grid container >
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11} >
            <MyTextInput
              label="Group Name"
              type="text"
              name="groupName"
              required={true}
              fullWidth={true}
              margin="normal"
              id="groupName"
              value={formik.values.groupName}
              onChange={formik.handleChange}
              error={
                formik.touched.groupName && Boolean(formik.errors.groupName)
              }
              helperText={formik.touched.groupName && formik.errors.groupName}
            />
            <MyTextInput
              label="Group Description"
              type="text"
              name="groupDesc"
              fullWidth={true}
              margin="normal"
              id="groupDesc"
              value={formik.values.groupDesc}
              onChange={formik.handleChange}
              required={true}
              error={
                formik.touched.groupDesc && Boolean(formik.errors.groupDesc)
              }
              helperText={formik.touched.groupDesc && formik.errors.groupDesc}
            />
            <Box style={{ width: "100%", textAlign: "center" }} mb={10}>

              <FormSubmitButton onClick={formik?.handleSubmit} type="submit" variant="contained">
                Submit
              </FormSubmitButton>
            </Box>
          </Grid>
        </Grid>



      </CommonCurdPopUp>}
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}

    </>
  );
};

export default UpdateUserGroups;
