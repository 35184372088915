import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import FileSaver from 'file-saver';

// routes
import { CURD_PUBLISH_ASSESSMENT,GET_ARCHIVED_PUBLISH_ASSESSMENT, PREVIEW_REPORT_REMMINDER, PUBLISH_ASSESSMENT,PREVIEW_PUBLISH_REMMINDER, BULK_UPLOAD_CUSTOMER_USER,PUBLISH_ASSESSMENT_BY_ACCOUNT_ID ,PUBLISH_ASSESSMENT_BY_CUSTOMER_ID   } from "../../utils/routes";


// ---------------------------------------------------------------------

export const createPublishAssessment = createAsyncThunk(
  "publishAssessment/createPublishAssessment",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_PUBLISH_ASSESSMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const publishAssessment = createAsyncThunk(
  "publishAssessment/publishAssessment",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(PUBLISH_ASSESSMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPublishAssessmentById = createAsyncThunk(
  "publishAssessment/publishAssessmentById",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(PUBLISH_ASSESSMENT_BY_ACCOUNT_ID , data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getArchivedPublishAssessment = createAsyncThunk(
  "publishAssessment/archivedPublishAssessmentById",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(GET_ARCHIVED_PUBLISH_ASSESSMENT ,data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateArchivedPublishAssessment = createAsyncThunk(
  "publishAssessment/updateArchivedPublishAssessment",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(GET_ARCHIVED_PUBLISH_ASSESSMENT , data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);




export const getPreviewReminderById = createAsyncThunk(
  "publishAssessment/getPreviewReminderById ",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(PREVIEW_REPORT_REMMINDER , data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPublishPreviewReminderById = createAsyncThunk(
  "publishAssessment/getPublishPreviewReminderById ",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(PREVIEW_PUBLISH_REMMINDER , data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPublishAssessmentByCustomerId = createAsyncThunk(
  "publishAssessment/publishAssessmentByCustomerId",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(PUBLISH_ASSESSMENT_BY_CUSTOMER_ID , data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);




export const getPublishAssessment = createAsyncThunk(
  "publishAssessment/getPublishAssessment",
  async (obj, {rejectWithValue}) => {
    try {
      const response = await axios.get(CURD_PUBLISH_ASSESSMENT, { withCredentials: true });
      return response;
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
);

export const updatePublishAssessment = createAsyncThunk(
  "publishAssessment/updatePublishAssessment",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_PUBLISH_ASSESSMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const batchUploadCustomerUser = createAsyncThunk(
  "Assessment/bulkUploadCustomerUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(BULK_UPLOAD_CUSTOMER_USER , data, {
        withCredentials: true,
        responseType: 'blob'
      });
      FileSaver.saveAs(new Blob([response.data]), 'data.xlsx');
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deletePublishAssessment = createAsyncThunk(
  "publishAssessment/deletePublishAssessment",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_PUBLISH_ASSESSMENT, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const publishAssessmentSlice = createSlice({
  name:"publishAssessment",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    publishAssessmentData: [],
    previewRimnderData : null,
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createPublishAssessment.pending]: (state, action) => {
      state.loading = true;
    },
    [createPublishAssessment.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createPublishAssessment.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [publishAssessment.pending]: (state, action) => {
      state.loading = true;
    },
    [publishAssessment.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [publishAssessment.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [getPublishAssessmentById.pending]: (state, action) => {
      state.loading = true;
    },
    [getPublishAssessmentById.fulfilled]: (state, action) => {
      state.loading = false;
      state.publishAssessmentData = action?.payload?.data;
    },
    [getPublishAssessmentById.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getArchivedPublishAssessment.pending]: (state, action) => {
      state.loading = true;
    },
    [getArchivedPublishAssessment.fulfilled]: (state, action) => {
      state.loading = false;
      state.publishAssessmentData = action?.payload?.data;
    },
    [getArchivedPublishAssessment.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getPreviewReminderById.pending]: (state, action) => {
      state.loading = true;
    },
    [getPreviewReminderById.fulfilled]: (state, action) => {
      state.loading = false;
      state.previewRimnderData  = action?.payload?.data;
    },
    [getPreviewReminderById.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getPublishPreviewReminderById.pending]: (state, action) => {
      state.loading = true;
    },
    [getPublishPreviewReminderById.fulfilled]: (state, action) => {
      state.loading = false;
      state.previewRimnderData  = action?.payload?.data;
    },
    [getPublishPreviewReminderById.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getPublishAssessmentByCustomerId.pending]: (state, action) => {
      state.loading = true;
    },
    [getPublishAssessmentByCustomerId.fulfilled]: (state, action) => {
      state.loading = false;
      state.publishAssessmentData = action?.payload?.data;
    },
    [getPublishAssessmentByCustomerId.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getPublishAssessment.pending]: (state, action) => {
      state.loading = true;
    },
    [getPublishAssessment.fulfilled]: (state, action) => {
      state.loading = false;
      state.publishAssessmentData = action?.payload?.data;
    },
    [getPublishAssessment.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updatePublishAssessment.pending]: (state, action) => {
      state.loading = true;
    },
    [updatePublishAssessment.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updatePublishAssessment.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [updateArchivedPublishAssessment.pending]: (state, action) => {
      state.loading = true;
    },
    [updateArchivedPublishAssessment.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateArchivedPublishAssessment.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },


    [deletePublishAssessment.pending]: (state, action) => {
      state.loading = true;
    },
    [deletePublishAssessment.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deletePublishAssessment.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [batchUploadCustomerUser.pending]: (state, action) => {
      state.loading = true;
    },
    [batchUploadCustomerUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = "Bulk Users Uploaded Successfully";
    },
    [batchUploadCustomerUser.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = "Bulk users upload failed";
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  publishAssessmentSlice.actions;
export default publishAssessmentSlice.reducer;
