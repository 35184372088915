import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useLocation } from "react-router-dom";

// material ui
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
  IconButton
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import Layout from "../../layout/Layout";
import {
  MuiDataGrid,
  GridCellExpand,
  ModalPopUp,
  FormSubmitButton,
  FormTextField as MyTextInput,
  Notifier,
  ErrorNotifier,
  ModalCloseBtn,
  ConfirmDialog,
  MuiAutocomplete,
  CommonCurdPopUp,
  MuiTooltip
} from "../../components";
// redux
import {
  createAssessmentTemplateQuestionMain,
  getAssessmentTemplateQuestionMain,
  updateAssessmentTemplateQuestionMain,
  deleteAssessmentTemplateQuestionMain,

  createAssessmentTemplateQuestion,
  getAssessmentTemplateQuestion,
  updateAssessmentTemplateQuestion,
  deleteAssessmentTemplateQuestion,
  rowSelect,
  resetMessage,
  resetErrMessage,
} from "../../features/prepare_assessments/prepareQuestionSlice";
import { getAssessmentTemplateSection } from "../../features/prepare_assessments/assessmentTemplateSectionSlice";
import { getAssessmentTemplateDimension } from "../../features/prepare_assessments/assessmentTemplateDimensionSlice";
import { getQuestionType } from "../../features/config/questionTypeSlice";

// ------------------------------------------------------------------------------

const validationSchema = Yup.object({
  // section: Yup.object().required("Section Name is required"),
  // dimension: Yup.object().required("Dimension is required"),
  questionNo: Yup.number().required("Question Number is required"),
  questionType: Yup.object().required("Question Type is required"),
  question: Yup.string().required("Assessor Question is required"),
  clientQuestion: Yup.string().required("Client Question is required")
});

const editValidationSchema = Yup.object({
  // section: Yup.string().required("Section Name is required"),
  // dimension: Yup.string().required("Dimension Name is required"),
  questionNo: Yup.number().required("Question Number is required"),
  questionType: Yup.string().required("Question Type is required"),
  question: Yup.string().required("Question is required"),
  clientQuestion: Yup.string().required("Client Question is required")
});

// -------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ------------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  rootbox: {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: '56.25rem !important',
    [theme.breakpoints.down('sm')]: {
      width: '20rem !important',
      height: '60% !important',
      display: 'block !important',
      overflow: 'scroll !important',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '33.375rem !important',
      height: '49% !important',
      display: 'block !important',
      overflow: 'scroll !important',
      // margin: '-3.188rem',
      marginTop: '-3.75rem  !important',
      marginLeft: '-3.563rem',
      padding: '2.125rem !important',
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: '1.5rem !important',
    paddingTop: '1rem !important',
    paddingLeft: '2rem !important',
    paddingRight: '2rem !important',
    paddingBottom: '1.5rem',
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },

  iconResponsive: {
    [theme.breakpoints.down('md')]: {
      marginRight: '-4.5rem'
    },
  },
  text_responsive: {
    width: '40rem',
    [theme.breakpoints.down('md')]: {
      width: '17rem',

    },
  },
  responsive_close: {
    [theme.breakpoints.down("md")]: {
      marginTop: "2.063rem",
      marginRight: "-3.688rem",
    },
  },
}))





const PrepareQuestions = (props) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const dispatch = useDispatch();

  const location = useLocation();

  const { message, errMessage, assessmentTemplateQuestionData, selectedData } =
    useSelector((state) => ({ ...state.assessmentTemplateQuestion }));

  const { assessmentTemplateSectionData } = useSelector((state) => ({
    ...state.assessmentTemplateSection,
  }));
  const { assessmentTemplateDimensionData } = useSelector((state) => ({
    ...state.assessmentTemplateDimension,
  }));
  const { questionTypeData } = useSelector((state) => ({
    ...state.questionType,
  }));

  const showAddModel = () => {
    setShowAdd(!showAdd);
  };

  const closeModel = () => {
    setShowAdd(false);
    formik.resetForm({
      values: {
        questionNo: "",
        questionType: "",
        question: "",
        clientQuestion: "",
        isRequiredForClientOrNot: true
      },
    });
  };

  const showEditModal = () => {
    setShowEdit(true);
  };

  const closeEditModal = () => {
    setShowEdit(false);
    formikEdit.resetForm({
      values: {
        questionNo: "",
        questionType: "",
        question: "",
        clientQuestion: "",
        isRequiredForClientOrNot: true
      },
    });
  };

  const showDelete = () =>
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => handleDeleteCustomer(),
    });

  const handleDeleteCustomer = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deleteAssessmentTemplateQuestionMain(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(
            getAssessmentTemplateQuestionMain({
              assessmentTemplateId: location?.state?.templateSelectedData?.id,
            })
          );
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  function getSectionName(params) {
    return params?.row?.assessmentSection?.sectionName;
  }

  function getDimensionName(params) {
    return params?.row?.assessmentDimension?.dimensionName;
  }

  function getQuestionNo(params) {
    if (
      params?.row?.assessmentSection?.sectionNo &&
      params?.row?.assessmentDimension?.dimensionNo
    ) {
      return `${params?.row?.assessmentSection?.sectionNo}.${params?.row?.assessmentDimension?.dimensionNo}.${params?.row?.questionNo}`;
    } else if (params?.row?.assessmentSection?.sectionNo) {
      return `${params?.row?.assessmentSection?.sectionNo}.${params?.row?.questionNo}`;
    } else if (params?.row?.assessmentDimension?.dimensionNo) {
      return `${params?.row?.assessmentDimension?.dimensionNo}.${params?.row?.questionNo}`;
    } else {
      return params?.row?.questionNo;
    }
  }

  function getIsRequiredForClientOrNot(params) {
    return params.row.isRequiredForClientOrNot ? "Yes" : "No"
  }

  function getDimensionName(params) {
    return params.row?.assessmentDimension?.dimensionName

  }
  function getSectionName(params) {
    return params.row?.assessmentSection?.sectionName

  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const data = {
    rows: assessmentTemplateQuestionData,
    columns: [
      // {
      //   field: "section",
      //   headerName: "Section",
      //   width: 150,
      //   valueGetter: getSectionName,
      //   renderCell: (params) => (
      //     <GridCellExpand value={params?.row?.assessmentSection?.sectionName || ""} width={params.colDef.computedWidth} />
      //   )
      // },
      // {
      //   field: "dimension",
      //   headerName: "Dimension",
      //   width: 150,
      //   valueGetter: getDimensionName,
      //   renderCell: (params) => (
      //     <GridCellExpand
      //       value={params?.row?.assessmentDimension?.dimensionName || ""}
      //       width={params.colDef.computedWidth}
      //     />
      //   ),
      // },
      {
        field: "questionNo",
        headerName: "Question No",
        width: 90,
        valueGetter: getQuestionNo,
      },
      {
        field: "section",
        headerName: "Section",
        width: 200,
        valueGetter: getSectionName,
      },
      {
        field: "dimension",
        headerName: "Dimension",
        width: 200,
        valueGetter: getDimensionName,
      },
      {
        field: "questionName",
        headerName: "Assessor Question",
        width: 200,
        renderCell: renderCellExpand,
      },
      {
        field: "clientQuestion",
        headerName: "Client Question",
        width: 350,
        renderCell: renderCellExpand,
      },
      {
        field: "questionType",
        headerName: "Question Type",
        width: 120,
        renderCell: renderCellExpand,
      },
      {
        field: "isRequiredForClientOrNot",
        headerName: "Required For Client",
        width: 140,
        valueGetter: getIsRequiredForClientOrNot,
      },

    ],
  };

  const formik = useFormik({
    enableReinitialize : true,
    initialValues: {
      section: "",
      dimension: "",
      questionNo: "",
      questionType: "",
      question: "",
      clientQuestion: '',
      isRequiredForClientOrNot: true,
    },

    validationSchema: validationSchema,
    onSubmit: (values, onSubmitProps) => {
      dispatch(
        createAssessmentTemplateQuestionMain({
          assessmentTemplateId: location?.state?.templateSelectedData?.id,
          assessmentSectionId: values?.section?.id,
          assessmentDimensionId: values?.dimension?.id,
          questionNo: values.questionNo,
          questionType: values?.questionType?.questionTypeName,
          questionName: values.question,
          clientQuestion: values.clientQuestion,
          isRequiredForClientOrNot: values.isRequiredForClientOrNot
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeModel();
            dispatch(
              getAssessmentTemplateQuestionMain({
                assessmentTemplateId: location?.state?.templateSelectedData?.id,
              })
            );
            onSubmitProps.resetForm();
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      section: selectedData?.assessmentSection?.id,
      dimension: selectedData?.assessmentDimension?.id,
      questionNo: selectedData?.questionNo,
      questionType: selectedData?.questionType,
      question: selectedData?.questionName,
      clientQuestion: selectedData?.clientQuestion,
      isRequiredForClientOrNot: selectedData?.isRequiredForClientOrNot
    },

    validationSchema: editValidationSchema,
    onSubmit: (values) => {
      dispatch(
        updateAssessmentTemplateQuestionMain({
          id: selectedData?.id,
          assessmentTemplateId: location?.state?.templateSelectedData?.id,
          assessmentSectionId: values?.section,
          assessmentDimensionId: values?.dimension,
          questionNo: values?.questionNo,
          questionType: values?.questionType,
          questionName: values?.question,
          clientQuestion: values?.clientQuestion,
          isRequiredForClientOrNot: values?.isRequiredForClientOrNot
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeEditModal();
            dispatch(
              getAssessmentTemplateQuestionMain({
                assessmentTemplateId: location?.state?.templateSelectedData?.id,
              })
            );
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    let data = {
      assessmentTemplateId: location?.state?.templateSelectedData?.id ? location?.state?.templateSelectedData?.id : localStorage.getItem("state"),
    };
    dispatch(getAssessmentTemplateSection(data));
    dispatch(getAssessmentTemplateDimension(data));
    dispatch(getAssessmentTemplateQuestionMain(data));
    dispatch(getQuestionType());
  }, []);

  useEffect(() => {
    if (!formik?.values?.section) {
      formik.setFieldValue("dimension", "")
    }
  }, [formik?.values?.section])

  useEffect(() => {
    if (selectedData?.assessmentSection?.id !== formikEdit?.values?.section) {
      formikEdit.setFieldValue("dimension", null);
    }
  }, [formikEdit?.values?.section]);

  const classes = useStyles();

  console.log(formik?.errors)

  return (
    <Layout>
      <Link
        to="/exco/prepare"
        state={{
          l3Menu: location?.state?.l3Menu,
          permissions: location?.state?.permissions,
        }}
      >
        <CancelIcon
          color="red"
          sx={{ position: "absolute", right: 80, zIndex: 1 }}
          className={classes.responsive_close}
        />
      </Link>
      {/* {location?.state?.permissions?.find(
          (e) =>
            e.applicationService?.name?.split(".")[
            e?.applicationService?.name?.split(".")?.length - 1
            ] === "uploadClientUsers"
        ) && ( */}




      {/* )} */}

      <>
        <PageHeader variant="pageHeader">
          Prepare Questions For{" "}
          {location?.state?.templateSelectedData?.assessmentTemplateName}
        </PageHeader>
        <MuiDataGrid
          isPreview={true}
          permissions={location?.state?.permissions}
          template={location?.state?.templateSelectedData}
          setShowAdd={
            location?.state?.permissions?.find(
              (e) =>
                e.applicationService?.name?.split(".")[
                e?.applicationService?.name?.split(".")?.length - 1
                ] === "createAssesQn"
            ) && showAddModel
          }
          setShowEdit={
            location?.state?.permissions?.find(
              (e) =>
                e.applicationService?.name?.split(".")[
                e?.applicationService?.name?.split(".")?.length - 1
                ] === "updateAssesQn"
            ) && showEditModal
          }
          setShowDelete={
            location?.state?.permissions?.find(
              (e) =>
                e.applicationService?.name?.split(".")[
                e?.applicationService?.name?.split(".")?.length - 1
                ] === "deleteAssesQn"
            ) && showDelete
          }
          // setShowAdd={showAddModel}
          // setShowEdit={showEditModal}
          // setShowDelete={showDelete
          // }

          toolbarColumn={true}
          // toolbarFilter={true}
          toolbarDensity={true}
          toolbarExport={true}
          data={data}
          select={(data) => dispatch(rowSelect(data))}
        />
      </>


      {showAdd && <CommonCurdPopUp
        heading="Add Questions"
        mediumScreen={true}
        close={
          closeModel
        }
      >

        <Grid container >
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11} container spacing={1}  >

            <Grid item xs={12} sm={12} md={6}>
              {/* <MuiAutocomplete
                  name="section"
                  options={assessmentTemplateSectionData}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.section}
                  optionValue="sectionName"
                  label="Section"
                  // required={true}
                  error={
                    formik.touched.section && Boolean(formik.errors.section)
                  }
                  helperText={formik.touched.section && formik.errors.section}
                /> */}
              <MyTextInput
                label="Question No."
                type="number"
                name="questionNo"
                required={true}
                fullWidth={true}
                margin="normal"
                id="questionNo"
                value={formik.values.questionNo}
                onChange={formik.handleChange}
                error={formik.touched.questionNo && Boolean(formik.errors.questionNo)}
                helperText={formik.touched.questionNo && formik.errors.questionNo}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              {/* <MuiAutocomplete
                  name="dimension"
                  options={assessmentTemplateDimensionData.filter(
                    (dimension) =>
                      dimension?.assessmentSection?.id ===
                      formik?.values?.section?.id
                  )}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.dimension}
                  optionValue="dimensionName"
                  label="Dimension"
                  // required={true}
                  error={Boolean(formik.errors.dimension)}
                  helperText={
                    formik.touched.dimension && formik.errors.dimension
                  }
                /> */}
              <MuiAutocomplete
                name="questionType"
                options={questionTypeData}
                setValue={(name, newValue) =>
                  formik.setFieldValue(name, newValue)
                }
                value={formik.values.questionType}
                optionValue="questionTypeName"
                optionSecValue="comments"
                label="Question Type"
                required={true}
                error={formik.touched.questionType && Boolean(formik.errors.questionType)}
                helperText={formik.touched.questionType && formik.errors.questionType}
              />
            </Grid>
            <Grid item xs={12}>
              <MyTextInput
                label="Assessor Question"
                type="text"
                name="question"
                required={true}
                fullWidth={true}
                margin="normal"
                id="question"
                value={formik.values.question}
                onChange={formik.handleChange}
                error={formik.touched.question && Boolean(formik.errors.question)}
                helperText={formik.touched.question && formik.errors.question}
              />
            </Grid>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={12} sm={12} md={9}>
                <MyTextInput
                  label="Client Question"
                  type="text"
                  name="clientQuestion"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="clientQuestion"
                  value={formik.values.clientQuestion}
                  onChange={formik.handleChange}
                  // onFocus={() => formik.setFieldValue("clientQuestion", formik?.values?.question)}
                  error={formik.touched.clientQuestion && Boolean(formik.errors.clientQuestion)}
                  helperText={formik.touched.clientQuestion && formik.errors.clientQuestion}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">Required For Client</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="isRequiredForClientOrNot"
                    value={formik.values.isRequiredForClientOrNot}
                    label="Required For Client"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.isRequiredForClientOrNot &&
                      Boolean(formik.errors.isRequiredForClientOrNot)
                    }
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {formik.touched.isRequiredForClientOrNot && formik.errors.isRequiredForClientOrNot}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Box style={{ width: "100%", textAlign: "center" }} mt={2}>

                <FormSubmitButton onClick={formik?.handleSubmit} type="submit" variant="contained">
                  Submit
                </FormSubmitButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>



      </CommonCurdPopUp>}

      {showEdit && <CommonCurdPopUp
        heading="Update Questions"
        mediumScreen={true}
        close={
         closeEditModal
        }
      >

        <Grid container >
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11} container spacing={1}  >
            <Grid item xs={12} sm={12} md={6}>
              {/* <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">Section</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="section"
                    defaultValue={formikEdit.values.section}
                    value={formikEdit.values.section}
                    label="Section"
                    onChange={formikEdit.handleChange}
                    error={
                      formikEdit.touched.section &&
                      Boolean(formikEdit.errors.section)
                    }
                    disabled={location?.state?.templateSelectedData?.assessmentTracking && location?.state?.templateSelectedData?.assessmentTracking?.assessmentStatus === "Published"}
                  >
                    <MenuItem value={null}>None</MenuItem>
                    {assessmentTemplateSectionData.map((option) => {
                      return (
                        <MenuItem key={option.id} value={option.id}>
                          {option.sectionName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {formikEdit.touched.section && formikEdit.errors.section}
                  </FormHelperText>
                </FormControl> */}
              <MyTextInput
                label="Question No *"
                type="number"
                name="questionNo"
                fullWidth={true}
                margin="normal"
                id="questionNo"
                value={formikEdit.values.questionNo}
                onChange={formikEdit.handleChange}
                disabled={location?.state?.templateSelectedData?.assessmentTracking && location?.state?.templateSelectedData?.assessmentTracking?.assessmentStatus === "Published"}
                error={
                  formikEdit.touched.questionNo &&
                  Boolean(formikEdit.errors.questionNo)
                }
                helperText={
                  formikEdit.touched.questionNo &&
                  formikEdit.errors.questionNo
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              {/* <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Dimension
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="dimension"
                    defaultValue={formikEdit.values.dimension}
                    value={formikEdit.values.dimension}
                    label="Dimension"
                    onChange={formikEdit.handleChange}
                    disabled={location?.state?.templateSelectedData?.assessmentTracking && location?.state?.templateSelectedData?.assessmentTracking?.assessmentStatus === "Published"}
                    error={
                      formikEdit.touched.dimension &&
                      Boolean(formikEdit.errors.dimension)
                    }
                  >
                    <MenuItem value={null}>None</MenuItem>
                    {assessmentTemplateDimensionData.map((option) => {
                      if (
                        option?.assessmentSection?.id ===
                        formikEdit?.values?.section
                      ) {
                        return (
                          <MenuItem key={option.id} value={option.id}>
                            {option.dimensionName}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {formikEdit.touched.dimension &&
                      formikEdit.errors.dimension}
                  </FormHelperText>
                </FormControl> */}
              <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Question Type *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="questionType"
                  defaultValue={formikEdit.values.questionType}
                  value={formikEdit.values.questionType}
                  label="Question Type"
                  onChange={formikEdit.handleChange}
                  disabled={location?.state?.templateSelectedData?.assessmentTracking && location?.state?.templateSelectedData?.assessmentTracking?.assessmentStatus === "Published"}
                  error={
                    formikEdit.touched.questionType &&
                    Boolean(formikEdit.errors.questionType)
                  }
                >
                  {questionTypeData.map((option) => {
                    return (
                      <MenuItem
                        key={option.id}
                        value={option.questionTypeName}
                      >
                        {option.questionTypeName}-{option.comments}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formikEdit.touched.questionType &&
                    formikEdit.errors.questionType}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <MyTextInput
                label="Assessor Question *"
                type="text"
                name="question"
                fullWidth={true}
                margin="normal"
                id="question"
                value={formikEdit.values.question}
                onChange={formikEdit.handleChange}
                error={
                  formikEdit.touched.question &&
                  Boolean(formikEdit.errors.question)
                }
                helperText={
                  formikEdit.touched.question && formikEdit.errors.question
                }
              />
            </Grid>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={12} sm={12} md={9}>
                <MyTextInput
                  label="Client Question"
                  type="text"
                  name="clientQuestion"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="clientQuestion"
                  value={formikEdit.values.clientQuestion}
                  onChange={formikEdit.handleChange}
                  error={Boolean(formikEdit.errors.clientQuestion)}
                  helperText={formikEdit.errors.clientQuestion}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">Required For Client</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="isRequiredForClientOrNot"
                    defaultValue={formikEdit.values.isRequiredForClientOrNot}
                    value={formikEdit.values.isRequiredForClientOrNot}
                    label="Required For Client"
                    onChange={formikEdit.handleChange}
                    error={
                      formikEdit.touched.isRequiredForClientOrNot &&
                      Boolean(formikEdit.errors.isRequiredForClientOrNot)
                    }
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {formikEdit.touched.isRequiredForClientOrNot && formikEdit.errors.isRequiredForClientOrNot}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Box style={{ width: "100%", textAlign: "center" }} mt={2}>

                <FormSubmitButton onClick={formikEdit?.handleSubmit} type="submit" variant="contained">
                  Submit
                </FormSubmitButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>


      </CommonCurdPopUp>}












      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />

      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default PrepareQuestions;
