// import React, { useState } from "react";
// import { makeStyles } from "@mui/styles";
// import {
//     Typography,
//     Slider
// } from '@mui/material'

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: 200,
//   },
//   margin: {
//     height: theme.spacing(8)
//   },
//   thumb: {
//     // width: '0px !important'
//   },
//   mark: {
//     background: "black"
//   },
//   rail: {
//     background: "linear-gradient(to right, red, orange, yellow, green);"
//   },
//   track: {
//     background: "orange"
//   },
//   valueLabel: {
//     "&>*": {
//       background: "black"
//     }
//   }
// }));

// export default function MuiSlider() {
//   const classes = useStyles();
//   const [value, setValue] = useState([2, 4]);

//   const onChange = (e, value) => {
//     // const [min, max] = value;
//     // if (max >= 50 && min <= 50 && max !== min) {
//       setValue(value);
//     // }
//   };

//   return (
//     <div className={classes.root}>
//       <Typography id="discrete-slider" gutterBottom>
//         Temperature
//       </Typography>
//       <Slider
//         classes={{
//           thumb: classes.thumb,
//           rail: classes.rail,
//           track: classes.track,
//           valueLabel: classes.valueLabel,
//           mark: classes.mark
//         }}
//         valueLabelDisplay="auto"
//         // value={value}
//         defaultValue={[2,2,4,4]}
//         step={10}
//         marks={[1, 2, 3, 4, 5].map(i => ({
//           label: i,
//           value: i
//         }))}
//         min={1}
//         max={5}
//         // onChange={onChange}
//       />
//     </div>
//   );
// }


import { responsiveFontSizes, Slider } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100% !important',
  //  "& :nth-child(14)": {
  //   width: '0.2rem !important',
  //   height: '2rem !important',
  //   borderRadius: '0px !important',
  //   backgroundColor: '#000080 !important'
  //  },
  //  "& :nth-child(15)": {
  //   width: '0.2rem !important',
  //   height: '2rem !important',
  //   borderRadius: '0px !important',
  //   backgroundColor: '#fff !important',
  //   border: '1px solid #000080'
  //  }
  },
  mark: {
    width: '8px !important',
    height: '30px !important',
    backgroundColor: '#000',
    marginTop: '-3px',
   
  },
  label: {
    color: 'red',
  },
  rail: {
        background: "linear-gradient(to right, red, orange, yellow, green);"
      },
  thumb: {
    display: 'none !important'
    // width: "0px !important",
    // border: 'none !important',
  },
  track: {
    backgroundColor: 'transparent !important',
    height: '0.7rem !important'
  },
  [theme.breakpoints.down('md')]: {
  responsive_slide : {
    width:'62.5rem'
  }
}
}))

function MuiSlider(props) {
  const classes = useStyles();

  let value = []
  value.push(props.report.min_column2)
  value.push(props.report.max_column2)
  // value.push(Number(props.report.self_rating))
  // value.push(parseFloat(props.report.avg_stakeholder))

  // const value = [{
  //   minMax: [props.report.min_column2, props.report.max_column2],
  //   selfRating: Number(props.report.self_rating),
  //   avgStakeholder: parseFloat(props.report.avg_stakeholder)
  // }];

  return (
    <div className="App">      
    <Slider        
      value={value}
        min={1}
        max={5}
        step={1}
        marks={[
          { value: 1, label: 1 },
          { value: 2, label: 2 },
          {value: 3, label: 3},
          {value: 4, label: 4},
          {value: 5, label: 5},
          {value: Number(props.report.self_rating), label: <span style={{
            display: "block",
            width: '0.35rem ',
            height: '2rem',
            borderRadius: '0px',
            backgroundColor: '#000080',
            position: 'absolute',
            top: '-2rem'
          }}/>},
          {value: parseFloat(props.report.avg_stakeholder), label: <span style={{
            display: "block ",
            width: '0.2rem ',
              height: '2rem',
              borderRadius: '0px',
              backgroundColor: '#fff',
              border: '1px solid #ADD8E6',
              position: "absolute",
              top: '-2rem'
          }}/>},
          
        ]}
        classes={{  rail: classes.rail, thumb: classes.thumb, track: classes.track, root: classes.root }}
      />    
      </div>  );
}
export default MuiSlider;