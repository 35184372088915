import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// material ui
import { CssBaseline } from "@mui/material";
// components
import {
  SignIn,
  ActivateAccount,
  ForgotPassword,
  ForgotPasswordReset,
  PageNotFound,
  Sidebar,
  Dashboard,
  ApplicationManagement,
  MenuManagement,
  ApiManagement,
  RoleManagement,
  UserGroups,
  Roles,
  AccessPermissions,
  MenuAccessPermissions,
  UserOnboarding,
  MyProfile,
  CustomerManagement,
  Customers,
  Accounts,
  CustUserOnbarding,
  AssessmentConfig,
  QuestionType,
  QuestionRatingValue,
  AssessmentProgress,
  AssessmentStatus,
  ResponseStatus,
  IndividualResponseStatus,
  RelationshipType,
  PrepareAssessments,
  ConfigureAssessmentTemplate,
  AssessmentFramework,
  ConfigureAssessmentFrameworkConfig,
  GraphReports,

  PrepareQuestions,
  ConfigAndPublish,
  MapAssessors,
  PublishAssessment,
  AnalyzeAndReport,
  AssessmentReport,
  AssessorsList,
  PlatformStatus,
  OnboardingCustomers,
  Report,
  ReportView,
  AssessmentTemplateConfig,
  SectionConfigQuestions,
  ConfigureAssessmentTemplateConfig,
  PrepareQuestionsConfig,
  DimensionConfigQuestions,

  PrepareDimensionQuestions,
  PrepareSectionQuestions,


  StartPage,
  ClientRating,
  Preview
} from "./pages";

function App() {
  return (
    <>
      <CssBaseline />

      <Router>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/activation" element={<ActivateAccount />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/forgot-password/reset"
            element={<ForgotPasswordReset />}
          />

         <Route path="/survey_start" element={<StartPage/>} />
         <Route path="/rating" element={< ClientRating />} />
         <Route path="/preview" element={<Preview />} />
          <Route path="/exco" element={<Sidebar />}>
         
            <Route path="dashboard" element={<Dashboard />} />
            <Route
              path="iam/app-services"
              element={<ApplicationManagement />}
            />
            <Route path="iam/menu-management" element={<MenuManagement />} />
            <Route path="iam/api-management" element={<ApiManagement />} />
            <Route path="iam/roles" element={<RoleManagement />} />
            <Route path="iam/user-groups" element={<UserGroups />} />
            <Route path="iam/role-management" element={<Roles />} />
            <Route
              path="iam/access-permissions"
              element={<AccessPermissions />}
            />
            <Route
              path="iam/menu-access-permissions"
              element={<MenuAccessPermissions />}
            />
            <Route path="iam/users" element={<UserOnboarding />} />
            <Route path="my-profile" element={<MyProfile />} />
            <Route
              path="user-management/customer-management"
              element={<CustomerManagement />}
            />
             <Route
              path="assessment-framework"
              element={<AssessmentFramework />}
            />
            <Route path="business/customers" element={<Customers />} />
            <Route path="customers/accounts" element={<Accounts />} />
            <Route
              path="customers/customer-management"
              element={<OnboardingCustomers />}
            />
            <Route path="customers/users" element={<CustUserOnbarding />} />
            <Route path="platform/config" element={<AssessmentConfig />} />
            <Route path="config/question-type" element={<QuestionType />} />
            <Route
              path="config/assessment-progress"
              element={<AssessmentProgress />}
            />
            <Route
              path="config/question-rating-value"
              element={<QuestionRatingValue />}
            />
            <Route
              path="config/assessment-progress"
              element={<AssessmentProgress />}
            />
            <Route
              path="config/assessment-template"
              element={<AssessmentTemplateConfig />}
            />
             <Route
              path="config/assessment-framework"
              element={<AssessmentFramework />}
            />
             <Route
              path="config/assessment-framework/spider-report"
              element={<GraphReports />}
            />
            <Route
              path="config/assessment-template/section-dimension"
              element={<ConfigureAssessmentTemplateConfig />}
            />
             <Route
              path="config/assessment-framework/section-dimension"
              element={<ConfigureAssessmentFrameworkConfig  />}
            />


            <Route
              path="config/assessment-template/prepare-questions"
              element={<PrepareQuestionsConfig />}
            />

            <Route
              path="config/assessment-template/section-dimension/dimension-questions"
              element={<DimensionConfigQuestions />}
            />

            <Route
              path="config/assessment-template/section-dimension/section-questions"
              element={<SectionConfigQuestions />}
            />

            <Route
              path="config/assessment-status"
              element={<AssessmentStatus />}
            />
            <Route path="config/template-status" element={<PlatformStatus />} />
            <Route
              path="config/individual-response-status"
              element={<IndividualResponseStatus />}
            />
            <Route path="config/response-status" element={<ResponseStatus />} />
            <Route
              path="config/relationship-type"
              element={<RelationshipType />}
            />
            <Route path="prepare" element={<PrepareAssessments />} />
         

            <Route
              path="prepare/section-dimension"
              element={<ConfigureAssessmentTemplate />}
            />
             <Route
              path="prepare/prepare-dimension-questions"
              element={<PrepareDimensionQuestions />}
            />
             <Route
              path="prepare/prepare-section-questions"
              element={< PrepareSectionQuestions />}
            />



            <Route
              path="prepare/prepare-questions"
              element={<PrepareQuestions />}
            />
           



            <Route path="publish" element={<ConfigAndPublish />} />
            <Route path="publish/map-assessors" element={<MapAssessors />} />

            <Route path="publish/assessment" element={<PublishAssessment />} />
            <Route path="report" element={<AnalyzeAndReport />} />
            <Route path="assessment/report" element={<AssessmentReport />} />
            <Route path="report/assessors/list" element={<AssessorsList />} />
            <Route path="report/preview" element={<Report />} />
            <Route path="report/preview-report" element={<Report />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
