import React from "react";
import { PageHeader, MyTextInput } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
// material ui
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Box,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import { FormSubmitButton } from "../../../../components";
import Notifier from "../../../../components/common/Notifier";
import ErrorNotifier from "../../../../components/common/ErrorNotifier";
// redux
import {
  updateAssessmentProgress,
  getAssessmentProgress,
  resetMessage,
  resetErrMessage,
} from "../../../../features/config/assessmentProgressSlice";

// --------------------------------------------------------------

const validationSchema = Yup.object({
  progress: Yup.string().required("Progress is required"),
  comments: Yup.string(),
});

//   ------------------------------------------------------------------------------------------

const UpdateAssessmentProgress = (props) => {
  const {  message, errMessage, selectedData } = useSelector(
    (state) => ({ ...state.assessmentProgress })
  );

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      progress: selectedData?.assessmentProgress,
      comments: selectedData?.comments,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        updateAssessmentProgress({
          id: selectedData.id,
          assessmentProgress: values.progress,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.close();
            dispatch(getAssessmentProgress());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  return (
    <Card sx={{ marginTop: "0.5rem" }}>
      <CardHeader
        title={
          <PageHeader variant="pageHeader">
            Update Assessment Progress
          </PageHeader>
        }
        action={
          <IconButton aria-label="cancel" onClick={() => props.close()}>
            <CancelIcon color="red" />
          </IconButton>
        }
      />
      <CardContent>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} sm={12} md={2}></Grid>
            <Grid item xs={12} sm={12} md={8}>
            <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Assessment User *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="userGroup"
                  // value={formik.values.userGroup}
                  // error={
                  //   formik.touched.userGroup && Boolean(formik.errors.userGroup)
                  // }
                  label="userGroup"
                  onChange={formik.handleChange}
                >
                  {/* {userGroupsData.length > 0 &&
                    userGroupsData.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.groupName}
                      </MenuItem>
                    ))} */}
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {/* {formik.touched.userGroup && formik.errors.userGroup} */}
                </FormHelperText>
              </FormControl>
              <MyTextInput
                label="Progress"
                type="text"
                name="progress"
                required={true}
                fullWidth={true}
                margin="normal"
                id="progress"
                value={formik.values.progress}
                onChange={formik.handleChange}
                error={
                  formik.touched.progress &&
                  Boolean(formik.errors.progress)
                }
                helperText={
                  formik.touched.progress &&
                  formik.errors.progress
                }
              />
              <MyTextInput
                label="Comments"
                type="text"
                name="comments"
                fullWidth={true}
                margin="normal"
                id="comments"
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={
                  formik.touched.comments && Boolean(formik.errors.comments)
                }
                helperText={formik.touched.comments && formik.errors.comments}
              />
              <Box textAlign="center">
                <FormSubmitButton
                  type="submit"
                  variant="contained"
                >
                  Submit
                </FormSubmitButton>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={2}></Grid>
          </Grid>
        </form>
      </CardContent>
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </Card>
  );
};

export default UpdateAssessmentProgress;
