// import { customUseEffect } from "../../../../../../common";
import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { CommonCurdPopUp, ConfirmDialog, MuiDataGrid } from "../../components";
import { GET_ALL_USERS_EXCEL_DOWNLOAD } from "../../utils/routes";
import { getDownLoadAssessmentBar } from "../../features/dashboard/dashboardSlice";
// import CommonErrorMessage from "../../../../../../common/error-message/CommonErrorMessage";
// import { deleteProjectGoal, getProjectGoalsByStatus } from "../../../../../../../features/project/projectConfig/projectGoalSlice";
// import DateFns from 'date-fns';
// import ViewGoalDetails from "../../../../viewComponents/project/ViewGoalDetails";
// -------------------------------------------------------------------------------
const ViewAssessmentUserDetails = ({ ...props }) => {
  // try {
  const [showDetail, setShowDetail] = useState(false);
  const [showDetailData, setShowDetailData] = useState(false);
  const [flattenedArray, setFlattenedArray] = useState([])
  const dispatch = useDispatch();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const { customerClientDetails } = useSelector((state) => ({ ...state?.dashboard }));
  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  useEffect(() => {
    // dispatch(getProjectGoalsByStatus(props?.data));
  }, []);
  const getFunction = () => {
    // dispatch(getProjectGoalsByStatus(prop?.data));
  };

  function goalDate(params) {
    try {
      // return DateFns.format(params?.row?.goalDate, "DD-MMM-YYYY");
    } catch (e) {
      console.log(e);
      return "";
    }
  }

  useEffect(() => {

    let flattenedArray = [];

    // Loop through each item in the original array
    let count = 0;
    customerClientDetails?.length > 0 && customerClientDetails?.forEach(item => {
      // Loop through each user in the 'users' array of each item
      // item.users.forEach(user => {
      count++
      // Create a new object by combining the properties from the item and the user
      let flattenedObject = {
        id: count,
        customerName: item.customerName,
        templateName: item.templateName,
        clientName: item.clientFirstName ? `${item?.clientFirstName} ${item?.clientLastName}` : `${item?.
          assessorFirstName
          } ${item?.assessorLastName}`,
        accountName: item.accountName,
        total: item.total,
        firstName: item.clientFirstName && "Client" || item.assessorFirstName && "Assessor" || 'N/A',
        lastName: item.clientLastName || item.assessorLastName || 'N/A',
        email: item.clientEmail || item.assessorEmail || 'N/A',
        status: item.selfAssessmentStatus
      };
      // Push the new object into the flattenedArray
      flattenedArray.push(flattenedObject);
      //   }

      // );
    }

    );



    setFlattenedArray(flattenedArray)

  }, [customerClientDetails])




  const data = {
    rows: flattenedArray?.length > 0 ? flattenedArray : [],


    columns: [
      {
        field: "customerName",
        headerName: "Customer",
        renderHeader: () => <strong>Customer</strong>,
        width: 200,
      },
      {
        field: "accountName",
        headerName: "Account",
        renderHeader: () => <strong>Account</strong>,

        width: 200,
      },
      {
        field: "templateName",
        headerName: "Assessment Name",
        renderHeader: () => <strong>Assessment Name</strong>,

        width: 230,
      },
      {
        field: "clientName",
        headerName: "User Name",
        renderHeader: () => <strong>User Name</strong>,
        // valueGetter: (params) => `${params?.row?.firstName} ${params?.row?.lastName}`,

        width: 250,
      },
      {
        field: "email",
        headerName: "Email",
        renderHeader: () => <strong>Email</strong>,


        width: 250,
      },
      {
        field: "firstName",
        headerName: "User Name",
        renderHeader: () => <strong>Client/Assessor</strong>,

        width: 250,
      },
      {
        field: "status",
        headerName: "Status",
        renderHeader: () => <strong>Status</strong>,

        width: 100,
      },

    ],
  };
  const handleDelete = () => {
    try {
      let data = {
        id: showDetailData[0]?.id,
      };
      // dispatch(deleteProjectGoal(data)).then((res) => {
      //   if (res?.payload?.data?.ok) {
      //     setTimeout(() => {
      //       getFunction();
      //       handleCloseConfirmDialog();
      //       setShowDetailData(false);
      //       props?.close(false);
      //     }, 2000);
      //   }
      // });
    } catch (e) {
      console.log(e);
    }
  };
  const showDeleteFunction = () => {

    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => handleDelete(),
    });
  }



  const downLoadFunc = () => {

    let body = {
      fromDate: props?.downloadData?.fromDate,
      endDate: props?.downloadData?.endDate,
      accountId: props?.downloadData?.accountId,
      customerId: props?.downloadData?.customerId,
      templateId: props?.downloadData?.trackingId
    }

    dispatch(getDownLoadAssessmentBar(body))
  }



  // try {
  return (
    <>
      {!showDetail && <CommonCurdPopUp
        isDownload={true}
        TARGET_URL={GET_ALL_USERS_EXCEL_DOWNLOAD}
        targetId={props?.trackingId
        }
        downloadFunc={downLoadFunc}
        heading={`Details for ${customerClientDetails[0]?.templateName} `}
        bigScreen={true}
        close={() => props.close()}
      >
        <div
          style={{ marginLeft: "20px", marginRight: "20px" }}
          className="res_tcTestData"
        >
          <MuiDataGrid

            data={data}
            select={(item) => {
              if (item.length > 0) {
                setShowDetailData(item);
                // setShowDetail(true);
              }
            }}
          />
        </div>
      </ CommonCurdPopUp>}

      {/* {
          showDetail && (
            <ViewGoalDetails
              open={showDetail}
              close={() => setShowDetail(false)}
              data={showDetailData[0]}
              showDeleteFunction={showDeleteFunction}
              getFunction={getFunction}
            />
          )} */}



      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />
    </>
  );
  // } 

  //   catch (err) {
  //     console.log(err);
  //     return <CommonErrorMessage />;
  //   }
  // } 

  // catch (err) {
  //   console.log(err);
  //   return <CommonErrorMessage />;
  // }
};

const mapStateToProps = (state) => ({
  projectGoal: state?.projectGoal,
  myProject: state?.myProject,
});

export default connect(mapStateToProps, {
})(ViewAssessmentUserDetails);
