import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_ASSESSMENT_FRAMEWORK_DIMENSION, 
  GET_ASSESSMENT_FRAMEWORK_DIMENSION_BY_FRAMEWORK_ID,
  GET_ASSESSMENT_FRAMEWORK_DIMENSION_BY_SECTION_ID
} from "../../utils/routes";

// ---------------------------------------------------------------------

export const createAssessmentFrameworkDimensionConfig = createAsyncThunk(
  "assessmentFrameworkDimensionConfig/createAssessmentFrameworkDimensionConfig",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_ASSESSMENT_FRAMEWORK_DIMENSION, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAssessmentFrameworkDimensionConfig = createAsyncThunk(
  "assessmentFrameworkDimensionConfig/getAssessmentFrameworkDimensionConfig",
  async (data, {rejectWithValue}) => {
    try {
      const response = await axios.post(GET_ASSESSMENT_FRAMEWORK_DIMENSION_BY_FRAMEWORK_ID, data, { withCredentials: true });
      return response
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
);
export const getAssessmentFrameworkDimensionConfigBySectionId = createAsyncThunk(
  "assessmentFrameworkDimensionConfig/getAssessmentFrameworkDimensionConfigBySectionId",
  async (data, {rejectWithValue}) => {


    try {
      const response = await axios.post(GET_ASSESSMENT_FRAMEWORK_DIMENSION_BY_SECTION_ID, data, { withCredentials: true });
      return response
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
);

export const updateAssessmentFrameworkDimensionConfig = createAsyncThunk(
  "assessmentFrameworkDimensionConfig/updateAssessmentFrameworkDimensionConfig",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_ASSESSMENT_FRAMEWORK_DIMENSION, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAssessmentFrameworkDimensionConfig = createAsyncThunk(
  "assessmentFrameworkDimensionConfig/deleteAssessmentFrameworkDimensionConfig",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_ASSESSMENT_FRAMEWORK_DIMENSION, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const assessmentFrameworkDimensionConfigSlice = createSlice({
  name: "assessmentFrameworkDimensionConfig",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    assessmentFrameworkDimensionData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createAssessmentFrameworkDimensionConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [createAssessmentFrameworkDimensionConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createAssessmentFrameworkDimensionConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getAssessmentFrameworkDimensionConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentFrameworkDimensionConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentFrameworkDimensionData = action?.payload?.data;
    },
    [getAssessmentFrameworkDimensionConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getAssessmentFrameworkDimensionConfigBySectionId.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentFrameworkDimensionConfigBySectionId.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentFrameworkDimensionConfigData = action?.payload?.data;
    },
    [getAssessmentFrameworkDimensionConfigBySectionId.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateAssessmentFrameworkDimensionConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [updateAssessmentFrameworkDimensionConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateAssessmentFrameworkDimensionConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteAssessmentFrameworkDimensionConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteAssessmentFrameworkDimensionConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteAssessmentFrameworkDimensionConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  assessmentFrameworkDimensionConfigSlice.actions;
export default assessmentFrameworkDimensionConfigSlice.reducer;
