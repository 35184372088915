import React, { useEffect } from "react";
import { PageHeader, MyTextInput } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
// material ui
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Box,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import { FormSubmitButton,CommonCurdPopUp } from "../../../../components";
import Notifier from "../../../../components/common/Notifier";
import ErrorNotifier from "../../../../components/common/ErrorNotifier";
// redux
import {
  updateRoles,
  getRoles,
  resetMessage,
  resetErrMessage,
} from "../../../../features/iam/role_management/rolesSlice";
import { getUserGroups } from "../../../../features/iam/role_management/userGroupsSlice";

// --------------------------------------------------------------

const validationSchema = Yup.object({
  userGroup: Yup.string().uuid().required("User Group is required"),
  roleCode: Yup.string().required("Role Code is required"),
  roleName: Yup.string().required("Role Name is required"),
  securityLevel: Yup.number().required("Security Level is required"),
  roleDesc: Yup.string().required("Role Description is required"),
});

//   ------------------------------------------------------------------------------------------

const UpdateRoles = (props) => {
  const { message, errMessage, selectedData } = useSelector(
    (state) => ({ ...state.roles })
  );
  const { userGroupsData } = useSelector((state) => ({ ...state.userGroups }));

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      userGroup: selectedData?.userGroup?.id,
      roleCode: selectedData?.roleCode,
      roleName: selectedData?.roleName,
      securityLevel: selectedData?.securityLevel,
      roleDesc: selectedData?.roleDescription,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        updateRoles({
          id: selectedData.id,
          userGroupId: values?.userGroup,
          roleCode: values?.roleCode,
          roleName: values.roleName,
          securityLevel: values?.securityLevel,
          roleDescription: values.roleDesc,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.close();
            dispatch(getRoles());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getUserGroups())
  }, [])

  return (
    <>

      {props?.showEdit && <CommonCurdPopUp
        heading={`Update Role`}
        mediumScreen={true}
        close={props.close}
      >
        <Grid container >
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11} >
           
                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    User Group *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="userGroup"
                    value={formik.values.userGroup}
                    error={
                      formik.touched.userGroup && Boolean(formik.errors.userGroup)
                    }
                    label="userGroup"
                    onChange={formik.handleChange}
                  >
                    {userGroupsData.length > 0 &&
                      userGroupsData.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.groupName}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {formik.touched.userGroup && formik.errors.userGroup}
                  </FormHelperText>
                </FormControl>
                <MyTextInput
                  label="Role Code"
                  type="text"
                  name="roleCode"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="roleCode"
                  value={formik.values.roleCode}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.roleCode && Boolean(formik.errors.roleCode)
                  }
                  helperText={formik.touched.roleCode && formik.errors.roleCode}
                />
                <MyTextInput
                  label="Role Name"
                  type="text"
                  name="roleName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="roleName"
                  value={formik.values.roleName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.roleName && Boolean(formik.errors.roleName)
                  }
                  helperText={formik.touched.roleName && formik.errors.roleName}
                />
                <MyTextInput
                  label="Security Level"
                  type="number"
                  name="securityLevel"
                  fullWidth={true}
                  required={true}
                  margin="normal"
                  id="securityLevel"
                  value={formik.values.securityLevel}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.securityLevel && Boolean(formik.errors.securityLevel)
                  }
                  helperText={formik.touched.securityLevel && formik.errors.securityLevel}
                />
                <MyTextInput
                  label="Role Description"
                  type="text"
                  name="roleDesc"
                  fullWidth={true}
                  margin="normal"
                  id="roleDesc"
                  value={formik.values.roleDesc}
                  onChange={formik.handleChange}
                  required={true}
                  error={
                    formik.touched.roleDesc && Boolean(formik.errors.roleDesc)
                  }
                  helperText={formik.touched.roleDesc && formik.errors.roleDesc}
                />
                <Box style={{ width: "100%", textAlign: "center" }} mb={10}>

                  <FormSubmitButton onClick={formik?.handleSubmit} type="submit" variant="contained">
                    Submit
                  </FormSubmitButton>
                </Box>
              </Grid>
            </Grid>



          </CommonCurdPopUp>}
          {message && <Notifier message={message} />}
          {errMessage && <ErrorNotifier message={errMessage} />}

        </>
        );
};

        export default UpdateRoles;
