// import { customUseEffect } from "../../../common";
import React, { useRef,useEffect } from 'react';
import Chart from 'chart.js/auto';
import { ChartLabel, getChartLabelPlugin } from 'chart.js-plugin-labels-dv';
import ReactDOM from "react-dom";
// --------------------------------------------------------------------------------------------------------------------------------
const GraphDimension = (props) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  // const level2Labels = [
  //   'LevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevel',
  //   'Level2 2',
  //   'Level2 3',
  //   'Level2 4',
  //   'Level2 5',
  // ];
  // const level2Tooltip = [
  //   'Level2 1',
  //   'Level2 2',
  //   'Level2 3',
  //   'Level2 4',
  //   'Level2 5',
  // ];
  // const level2Data = [
  //   1000
  // ];



  const level1Data =
    props?.sectionStore.length > 0
      ? props?.sectionStore?.map((item) => item.value)
      : [];

  const level1Labels =
    props?.sectionStore.length > 0
      ? props?.sectionStore?.map((item) => item.name)
      : [];

  const level2Data =
    props?.dimensionStore.length > 0
      ? props?.dimensionStore.map((item) => item.value)
      : [];
  const level2Labels =
    props?.dimensionStore.length > 0
      ? props?.dimensionStore.map((item) => item.name)
      : [];
  const level2Tooltip =
    props?.dimensionStore.length > 0
      ? props?.dimensionStore.map((item) => item.name)
      : [];


  useEffect(() => {
    const canvas = chartRef.current;
    const ctx = canvas.getContext('2d');

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const labelsPlugin = getChartLabelPlugin(ChartLabel, ctx);
    // console.log("Labels Plugin:", labelsPlugin); // Check if labels plugin is correctly loaded

    chartInstance.current = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: level2Labels,
        datasets: [
          {
            data: level2Data,
            backgroundColor: ['#8bcaf4'],
            borderColor: ["white", "white", "white", "white"],
            borderWidth: 2,
          },
        ],
      },
      options: {
        cutout: "80%",
        plugins: {
          tooltip: {
            enabled: false, // Disable default tooltip
            // external: (context) => {
            //   // Custom tooltip logic
            //   // console.log("coming ",context)
            //   const tooltipEl = document.getElementById('custom-tooltip');
            //   if (tooltipEl && context.tooltip && context.tooltip.caretX && context.tooltip.caretY) {
            //     const { chart, tooltip } = context;
            //     const x= context.tooltip.caretY;
            //     const y = context.tooltip.caretY;
            //     // tooltipEl.innerHTML = `<span>${chart.data.labels[tooltip.dataPoints[0].index]}</span>: <span>${chart.data.datasets[0].data[tooltip.dataPoints[0].index]}</span>`;
            //     tooltipEl.innerHTML = `<span>${chart.
            //       tooltip.title[0]}</span>
            //       `;
            //       // : <span>${chart.data.datasets[0].data[tooltip.dataPoints[0].index]}</span>
            //     tooltipEl.style.display = 'block';
            //     tooltipEl.style.left = `${x}px`;
            //     tooltipEl.style.top = `${y}px`;

            //     setTimeout(()=>{
            //       tooltipEl.style.display = 'none';
            //     },2000);
            //   } else {
            //     // Hide the tooltip if no caret is available
            //     tooltipEl.style.display = 'none';
            //   }
            // }
          },
          legend: {
            display: false,
          },
          labels: {
            render: "label",
            fontColor: "black",
            fontSize: 18,
            arc: true,
            position: "inside",
            outsidePadding: 4,
            textMargin: 8,
            overlap: true,
            showActualPercentages: true,
            precision: 0,
            formatter: (value, ctx) => {
              // Check if formatter is called
              // Add your formatter logic here
              // For example, truncating labels
              if (value.length > 10) {
                return value.slice(0, 10) + '...';
              }
              return value;
            },
          },
        },
        legend: {
          display: false,
        },
      },
      plugins: [labelsPlugin],
    });


    // Intercept hover events on chart labels
    chartRef.current.onmousemove = function (evt) {

      // console.log("evt ",evt)
      const activePoints = chartInstance.current.getElementsAtEventForMode(
        evt,
        "nearest",
        {
          intersect: true,
        }
      );

      // console.log("Active points ", activePoints)
      if (activePoints.length) {
        const firstPoint = activePoints[0];
        const { x, y } = firstPoint.element.tooltipPosition();
        // const label =
        //   firstPoint?.element?.$datalabels[0]?.$context?.dataset?.labels[
        //     firstPoint?.index
        //   ];
        // const label="Label to display"
        const label = level2Tooltip[firstPoint?.index];
        const value = 300;

        // console.log("level2Labels[firstPoint?.index]; ",
        // level2Labels[firstPoint?.index], level2Labels)
        const tooltipContent = (
          // <MuiTooltip title={label} arrow>
          <div
            style={{
              backgroundColor: "#f5f5f5",
              zIndex: 9999,
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              // width: '50rem', // Set maximum width as needed
            }}
            title="label"
          >
            <span style={{ fontWeight: "bold" }}></span> {label}
            <br />
          </div>
          // </MuiTooltip>
        );

        const tooltipDiv = document.getElementById("custom-tooltip");
        ReactDOM.render(tooltipContent, tooltipDiv);

        // Show tooltip and set position
        tooltipDiv.style.display = "block";
        tooltipDiv.style.position = "absolute";
        tooltipDiv.style.top = y + "px";
        tooltipDiv.style.left = x + "px";
        // tooltipDiv.style.width = '100px';
      } else {
        // Hide tooltip when not hovering over a label
        document.getElementById("custom-tooltip").style.display = "none";
      }
    };

    // Hide tooltip when mouse leaves the canvas
    chartRef.current.onmouseleave = function () {
      document.getElementById("custom-tooltip").style.display = "none";
    };

    // Calculate the width of the curve
    // const innerRadius = chartInstance.current.config.options.cutout ? (chartInstance.current.config.options.cutout.replace('%', '') / 100) * Math.min(chartRef.current.clientWidth, chartRef.current.clientHeight) / 2 : 0;
    // const outerRadius = Math.min(chartRef.current.clientWidth, chartRef.current.clientHeight) / 2;
    // const curveWidth = outerRadius - innerRadius;


    const innerRadius = chartInstance.current.config.options.cutout ?
      (parseFloat(chartInstance.current.config.options.cutout) / 100) * Math.min(chartRef.current.clientWidth, chartRef.current.clientHeight) / 2 : 0;
    const outerRadius = Math.min(chartRef.current.clientWidth, chartRef.current.clientHeight) / 2;
    const curveWidth = outerRadius - innerRadius;

    // Calculate the maximum width for each label within the curved space
    const maxLabelWidth = curveWidth / 2;

    const data = chartInstance.current.config.data;
    const labels = data.labels;

    // console.log("Before modification:", labels);

    // const sectionWidth = 210 / level1Data?.length;
    // const dimensionWidth = sectionWidth / (level2Data?.length/level1Data?.length);

    const sectionWidth = (175 - level2Data?.length) / level1Data?.length;
    const labelWidth = sectionWidth / (level2Data?.length / level1Data?.length);

    labels.forEach((label, index) => {
      if (label.length > (labelWidth - 1)) {

        data.labels[index] = label.slice(0, (labelWidth -9 )) + '...';
      }
    });

    // labels.forEach((label, index) => {
    //   if (label.length > maxLabelWidth) {

    //     data.labels[index] = label.slice(0, maxLabelWidth) + '...';
    //   }
    // });

    // console.log("After modification:", data.labels);

    // Update the chart to reflect label modifications
    chartInstance.current.update();

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [level2Labels, level2Data, chartRef]);

  return (
    <div style={{  height: '442px', width: '442px', position: 'relative' }}>
      <canvas ref={chartRef} id="mainPieChart" width="400" height="400"></canvas>
      <div
        id="custom-tooltip"
        style={{
          display: "none",
          maxWidth: "50rem",
          zIndex: 9999,
          pointerEvents: "none",
          position: "absolute",
        }}
      />
    </div>
  );
};

export default GraphDimension;
