import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_ASSESSMENT_FRAMEWORK,CURD_ASSESSMENT_FRAMEWORK_COMPLETED, COPY_ASSESSMENT_FRAMEWORK,VIEW_ASSESSMENT_FRAMEWORK_RADAR_GRAPH , FORCE_DELETE_ASSESSMENT_FRAMEWORK } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createAssessmentFrameworkConfig = createAsyncThunk(
  "assessmentFrameworkConfig/createAssessmentFrameworkConfig",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_ASSESSMENT_FRAMEWORK, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const viewAssessmentFrameworkRador= createAsyncThunk(
  "assessmentFrameworkRadarGraph/viewRadar",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(VIEW_ASSESSMENT_FRAMEWORK_RADAR_GRAPH , data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAssessmentFrameworkConfig = createAsyncThunk(
  "assessmentFrameworkConfig/getAssessmentFrameworkConfig",
  async (obj, {rejectWithValue}) => {
    try {
      const response = await axios.get(CURD_ASSESSMENT_FRAMEWORK, { withCredentials: true });
      return response;
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
);

export const getAssessmentCompletedFramework = createAsyncThunk(
  "assessmentFrameworK/getAssessmentFrameworkCompleted",
  async (obj, {rejectWithValue}) => {
    try {
      const response = await axios.get(CURD_ASSESSMENT_FRAMEWORK_COMPLETED, { withCredentials: true });
      return response;
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
);

export const updateAssessmentFrameworkConfig = createAsyncThunk(
  "assessmentFrameworkConfig/updateAssessmentFrameworkConfig",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_ASSESSMENT_FRAMEWORK, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAssessmentFrameworkConfig = createAsyncThunk(
  "assessmentFrameworkConfig/deleteAssessmentFrameworkConfig",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_ASSESSMENT_FRAMEWORK, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const copyAssessmentFrameworkConfig = createAsyncThunk(
  "assessmentFrameworkConfig/copyAssessmentFrameworkConfig",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(COPY_ASSESSMENT_FRAMEWORK, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const forceDeleteAssessmentFrameworkConfig = createAsyncThunk(
  "assessmentFrameworkConfig/forceDeleteAssessmentFrameworkConfig",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(FORCE_DELETE_ASSESSMENT_FRAMEWORK, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const assessmentFrameworkConfigSlice = createSlice({
  name: "assessmentFrameworkConfig",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    assessmentFrameworkConfigData: [],
    assessmentFrameworkCompletedData : [],
    reportsData : [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createAssessmentFrameworkConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [createAssessmentFrameworkConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createAssessmentFrameworkConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [viewAssessmentFrameworkRador.pending]: (state, action) => {
      state.loading = true;
    },
    [viewAssessmentFrameworkRador.fulfilled]: (state, action) => {
      state.loading = false;
      state.reportsData = action?.payload?.data;
    },
    [viewAssessmentFrameworkRador.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    
    [getAssessmentCompletedFramework.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentCompletedFramework.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentFrameworkCompletedData = action?.payload?.data;
    },
    [getAssessmentCompletedFramework.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },



    [getAssessmentFrameworkConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentFrameworkConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentFrameworkConfigData = action?.payload?.data;
    },
    [getAssessmentFrameworkConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },


    [updateAssessmentFrameworkConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [updateAssessmentFrameworkConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateAssessmentFrameworkConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteAssessmentFrameworkConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteAssessmentFrameworkConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteAssessmentFrameworkConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [copyAssessmentFrameworkConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [copyAssessmentFrameworkConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [copyAssessmentFrameworkConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [forceDeleteAssessmentFrameworkConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [forceDeleteAssessmentFrameworkConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [forceDeleteAssessmentFrameworkConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  assessmentFrameworkConfigSlice.actions;
export default assessmentFrameworkConfigSlice.reducer;
