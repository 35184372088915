import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CRUD_SECTION_CONFIG_QUESTIONS, GET_DIMENSION_CONFIG_QUESTIONS, GET_SECTION_CONFIG_QUESTIONS } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createSectionConfigQuestion = createAsyncThunk(
  "sectionConfigQuestion/createSectionConfigQuestion",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CRUD_SECTION_CONFIG_QUESTIONS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSectionConfigQuestion = createAsyncThunk(
  "sectionConfigQuestion/getSectionConfigQuestion",
  async (data, { rejectWithValue }) => {
    try {
    //   const response = await axios.post(GET_SECTION_CONFIG_QUESTIONS, data, {
      const response = await axios.post(GET_SECTION_CONFIG_QUESTIONS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
// export const getDimensionConfigQuestion = createAsyncThunk(
//   "sectionConfigQuestion/getDimensionConfigQuestion",
//   async (data, { rejectWithValue }) => {
//     try {
//     //   const response = await axios.post(GET_SECTION_CONFIG_QUESTIONS, data, {
//       const response = await axios.post(GET_DIMENSION_CONFIG_QUESTIONS, data, {
//         withCredentials: true,
//       });
//       return response;
//     } catch (err) {
//       return rejectWithValue(err.response);
//     }
//   }
// );


export const updateSectionConfigQuestion = createAsyncThunk(
  "sectionConfigQuestion/updateSectionConfigQuestion",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CRUD_SECTION_CONFIG_QUESTIONS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSectionConfigQuestion = createAsyncThunk(
  "sectionConfigQuestion/deleteSectionConfigQuestion",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CRUD_SECTION_CONFIG_QUESTIONS, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const sectionConfigQuestionSlice = createSlice({
  name: "sectionConfigQuestion",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    sectionConfigQuestionData: [],
    dimensionConfigQuestionData: [],
    selectedData: {},
    selectedSectionData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
    sectionRowSelect: (state, action) => {
      state.selectedSectionData =
        action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createSectionConfigQuestion.pending]: (state, action) => {
      state.loading = true;
    },
    [createSectionConfigQuestion.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createSectionConfigQuestion.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getSectionConfigQuestion.pending]: (state, action) => {
      state.loading = true;
    },
    [getSectionConfigQuestion.fulfilled]: (state, action) => {
      state.loading = false;
      state.sectionConfigQuestionData = action?.payload?.data;
    },
    [getSectionConfigQuestion.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [updateSectionConfigQuestion.pending]: (state, action) => {
      state.loading = true;
    },
    [updateSectionConfigQuestion.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateSectionConfigQuestion.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteSectionConfigQuestion.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteSectionConfigQuestion.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteSectionConfigQuestion.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect, sectionRowSelect } =
  sectionConfigQuestionSlice.actions;
export default sectionConfigQuestionSlice.reducer;
