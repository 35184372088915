import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { countries, continents } from "countries-list";
import { Link, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { DateTime } from "luxon";
// material ui
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
  IconButton,
  Autocomplete,
  TextField,
  Input,
  Stack
} from "@mui/material";
// icons
import SettingsIcon from "@mui/icons-material/Settings";
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import UploadIcon from "@mui/icons-material/Upload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ViewCozyIcon from '@mui/icons-material/ViewCozy';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
// components
import Layout from "../../layout/Layout";
import { ASSESSMENT_BASE_URL } from "../../utils/config";
import {
  MuiDataGrid,
  MuiAutocomplete,
  geographyArr,
  GridCellExpand,
  ModalPopUp,
  FormSubmitButton,
  FormTextField as MyTextInput,
  Notifier,
  ErrorNotifier,
  ModalCloseBtn,
  ConfirmDialog,
  MuiTooltip,
  CommonCurdPopUp
} from "../../components";
// redux
import {
  createPublishAssessment,
  getPublishAssessment,
  getPublishAssessmentById,
  getPublishAssessmentByCustomerId,
  updatePublishAssessment,
  deletePublishAssessment,
  batchUploadCustomerUser,
  getArchivedPublishAssessment,
  updateArchivedPublishAssessment,
  rowSelect,
  resetMessage,
  resetErrMessage,
} from "../../features/config_and_publish/publishAssessmentSlice";
import { getAssessmentTemplate } from "../../features/prepare_assessments/prepareAssessmentSlice";
import { getAccount, getAccountById } from "../../features/customer/accountSlice";
import { getCustUser } from "../../features/customer/custUserSlice";
import { getAssessmentStatus } from "../../features/config/assessmentStatusSlice";


import { getCustomer } from "../../features/customer/customerSlice";
import CommonCrudPopup from "../../components/form_components/CommonCrudPopup";
// ------------------------------------------------------------------------------

const validationSchema = Yup.object({
  assessment: Yup.object().nullable().required("Assessment Template Name is required"),
  templateDisplayName: Yup.string().nullable().required("Template Display Name is required"),
  account: Yup.object().nullable().required("Account Name is required"),
  client: Yup.object().nullable().required("Client is required"),
  comments: Yup.string(),
  custName: Yup.object().nullable().required("Customer is required")
});

const editValidationSchema = Yup.object({
  assessment: Yup.object().required("Assessment Template Name is required"),
  // account: Yup.object().required("Account is required"),
  // custName: Yup.string().required("Customer is required"),
  account: Yup.object().required("Account Name is required"),
  custName: Yup.object().required("Customer is required"),
  client: Yup.string().required("Client is required"),
  status: Yup.string().required("Status is required"),
  comments: Yup.string().nullable(),
});


// -------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  rootbox: {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: "56.25rem !important",
    [theme.breakpoints.down("sm")]: {
      width: "20rem !important",
      height: "60% !important",
      display: "block !important",
      overflow: "scroll !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "33.375rem !important",
      height: "49% !important",
      display: "block !important",
      overflow: "scroll !important",
      // margin: '-3.188rem',
      marginTop: "-3.75rem  !important",
      marginLeft: "-3.563rem",
      padding: "2.125rem !important",
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: "1.5rem !important",
    paddingTop: "1rem !important",
    paddingLeft: "2rem !important",
    paddingRight: "2rem !important",
    paddingBottom: "1.5rem",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },

  text_responsive: {
    width: "50rem",
    [theme.breakpoints.down("md")]: {
      width: "17rem",
    },
  },

  cutoff_box: {
    [theme.breakpoints.down("sm")]: {
      width: "16.875rem !important",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      width: "20.875rem !important",
      marginLeft: "-7.313rem",
      marginTop: "-11.313rem",
    },
  },
  responsive_field: {
    [theme.breakpoints.down("sm")]: {
      width: "15rem !important",
    },
  },
  responsive_field: {
    [theme.breakpoints.down("md")]: {
      width: "15rem !important",
    },
  },
  iconResponsive: {
    [theme.breakpoints.down("md")]: {
      marginRight: "-7rem",
    },
  },
}));

const ConfigAndPublish = (props) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [viewArchived, setViewArchived] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [showCutoffDate, setShowCutoffDate] = useState(false);
  const [cutoffDateSelectedData, setCutoffDateSelectedData] = useState({});
  const [showReminderFreq, setShowReminderFreq] = useState(false);
  const [reminderFreqSelectedData, setReminderFreqSelectedData] = useState({});
  const [selectedCustValue, setSelectedCustValue] = useState(null)
  const [selectedAccountValue, setSelectedAccountValue] = useState(null)

  const [dateErr, setDateErr] = useState("");

  const dispatch = useDispatch();

  const location = useLocation();

  const { message, errMessage, publishAssessmentData, selectedData } =
    useSelector((state) => ({ ...state.publishAssessment }));

  const { assessmentTemplateData } = useSelector((state) => ({
    ...state.prepareAssessment,
  }));

  const { accountData, accountIdData } = useSelector((state) => ({ ...state?.account }));
  const { customersData } =
    useSelector((state) => ({ ...state.customer }));

  const { custUserData } = useSelector((state) => ({ ...state?.custUser }));

  const { assessmentStatusData } = useSelector((state) => ({
    ...state?.assessmentStatus,
  }));

  const showAddModel = () => {
    setShowAdd(!showAdd);

  };

  const closeModel = () => {
    setShowAdd(false);
    const { account, custName } = formik?.values
    formik.resetForm({
      values: {
        account,
        custName,
        assessment: "",
        client: "",
        status: "",
        comments: "",
      },
    });
    // dispatch(getAccount())
  };

  const showEditModal = () => {
    setShowEdit(true);
    // const { account, custName } = formikEdit?.values
    // formikEdit.resetForm({
    //   values: {
    //     account,
    //     custName,
    //     assessment: "",
    //     client: "",
    //     status: "",
    //     comments: "",
    //   },
    // });
  };

  const closeEditModal = () => {
    setShowEdit(false);

  };

  const showCutoffDateModal = (data) => {
    console.log(data)
    setShowCutoffDate(true);
    setCutoffDateSelectedData(Object.keys(data)?.length > 0 ? data : {});
  };

  const closeCutoffDateModal = () => {
    setShowCutoffDate(false);
  };

  const showReminderFreqModal = (data) => {
    setShowReminderFreq(true);
    setReminderFreqSelectedData(data);
  };

  const closeReminderFreqModal = () => {
    setShowReminderFreq(false);
  };

  const showDelete = () =>
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => handleDeleteCustomer(),
    });

  const handleDeleteCustomer = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deletePublishAssessment(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(getPublishAssessment());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  function getAssessment(params) {
    return params?.row?.assessmentTemplate?.assessmentTemplateDisplayName;
  }

  function getAssessmentTemplateMui(params) {
    return params?.row?.assessmentTemplate?.assessmentTemplateName;
  }

  function getClient(params) {
    return `${params.row.assessmentCustomerUser?.assessmentCustomerUserDetail
      ?.firstName || ""
      } ${params.row.assessmentCustomerUser?.assessmentCustomerUserDetail
        ?.lastName || ""
      }`;
  }

  function getClientEmail(params) {
    return (
      <GridCellExpand
        value={params?.row?.assessmentCustomerUser?.email}
        width={params?.colDef?.computedWidth}
      />
    );
  }

  function getAccountName(params) {
    return (
      <GridCellExpand
        value={`${params?.row?.assessmentCustomerUser?.assessmentCustomer?.customerName}-${params?.row?.assessmentCustomerUser?.assessmentCustomer?.accountName}`}
        width={params?.colDef?.computedWidth}
      />
    );
  }

  function getCutoffDate(params) {
    const utcTimestamp = params;

    // Convert to local time
    const date = new Date(utcTimestamp);

    // Get local date string
    const localDateString = date.toLocaleString();

    // Display in the div
    // document.getElementById('localTime').textContent = localDateString;

    // If you want more control over the format, you can use options:
    
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    };

    const formattedDate = date.toLocaleString(undefined, options);
    return formattedDate ? formattedDate?.split(",")[0] : ""
    // if (params?.row?.cutOffDate) {
    //   let date = params?.row?.cutOffDate;
    //   date = date.substring(0, 10).split("-");
    //   date = date[1] + "-" + date[2] + "-" + date[0];

    //   return params?.row?.cutOffDate && date;
    // }
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const data = {
    rows: publishAssessmentData,
    columns: [

      {
        field: "assessment",
        headerName: "Assessment Name",
        width: 150,
        renderCell: renderCellExpand,
        valueGetter: getAssessment,
      },
      {
        field: "client",
        headerName: "Client Name",
        width: 150,
        valueGetter: getClient,
      },
      {
        field: "Email",
        headerName: "Client E-Mail",
        width: 150,
        renderCell: getClientEmail,
        valueGetter: (params) => params?.row?.assessmentCustomerUser?.email,
      },
      // {
      //   field: "account",
      //   headerName: "Account",
      //   width: 150,
      //   renderCell: getAccountName,
      //   valueGetter: (params) =>
      //     `${params?.row?.assessmentCustomerUser?.assessmentCustomer?.customerName}-${params?.row?.assessmentCustomerUser?.assessmentCustomer?.accountName}`,
      // },
      {
        field: "cutOffDate",
        headerName: "Cut Off Date",
        width: 130,
        valueGetter: (params) => params?.row?.cutOffDate ?  getCutoffDate(params?.row?.cutOffDate) :"",
      },



      {
        field: "config",
        hide: viewArchived ? true : false,
        // headerName: 'Actions',
        type: "actions",
        width: 50,
        renderCell: (params) => {
          return (
            <MuiTooltip title="Config Assessors" placement="bottom">
              {!params?.row?.isArchived && <Link
                to="/exco/publish/map-assessors"
                state={{
                  trackingSelectedData: params?.row,
                  permissions: location?.state?.permissions,
                }}
              >
                <SettingsIcon style={{ color: "#808080" }} />
              </Link>}
            </MuiTooltip>
          );
        },
      },
      {
        field: "Date",
        hide: viewArchived ? true : false,
        // headerName: 'Actions',
        type: "actions",
        width: 50,
        renderCell: (params) => {
          return (
            <MuiTooltip title="Set Cut Off Date" placement="bottom">
              <Link
                onClick={() => showCutoffDateModal(params?.row)}
                state={{ permissions: location?.state?.permissions }}
              >
                {location?.state?.permissions?.find(
                  (e) =>
                    e.applicationService?.name?.split(".")[
                    e?.applicationService?.name?.split(".")?.length - 1
                    ] === "updateAssessTrack"
                ) && !params?.row?.isArchived && <CalendarMonthIcon style={{ color: "#808080" }} />}
              </Link>
            </MuiTooltip>
          );
        },
      },
      // {
      //   field:'Frequency',
      //   // headerName: 'Actions',
      //   type: 'actions',
      //   width: 50,
      //   renderCell: (params) => {
      //     return (
      //       <MuiTooltip title="Set Reminder Frequency" placement="bottom">
      //       <Link onClick={() => showReminderFreqModal(params?.row)} state={{permissions: location?.state?.permissions}} >
      //         <QueryBuilderIcon style={{color: '#808080'}} />
      //       </Link>
      //       </MuiTooltip>
      //     );
      //   },
      // },
      {
        field: "publish",
        hide: viewArchived ? true : false,
        // headerName: 'Actions',
        type: "actions",
        width: 50,
        renderCell: (params) => {
          return (
            <MuiTooltip title="Publish" placement="bottom">
              <Link
                to="/exco/publish/assessment"
                state={{
                  trackingSelectedData: params?.row,
                  permissions: location?.state?.permissions,
                }}
              >
                {location?.state?.permissions?.find(
                  (e) =>
                    e.applicationService?.name?.split(".")[
                    e?.applicationService?.name?.split(".")?.length - 1
                    ] === "publishAssessment"
                ) && !params?.row?.isArchived && <UploadIcon style={{ color: "#808080" }} />}
              </Link>
            </MuiTooltip>
          );
        },
      },
      {
        field: "archived",
        // headerName: 'Actions',
        type: "actions",
        width: 50,
        renderCell: (params) => {
          return (
            <MuiTooltip title={!params?.row?.isArchived ? "Archive" : "Un Archive"} placement="right">
              {!params?.row?.isArchived ? <IconButton
                onClick={() => dispatch(
                  updateArchivedPublishAssessment({

                    id: params?.row?.id,
                    isArchived: true

                  })
                ).then((res) => {
                  if (res?.payload?.data?.ok) {
                    // setCopyLoading(true);
                    setTimeout(() => {
                      dispatch(resetMessage());
                      dispatch(getPublishAssessment())
                      // setCopyLoading(false);
                    }, 2000);
                  }
                  if (res?.payload?.error) {
                    setTimeout(() => {
                      dispatch(resetErrMessage());
                    }, 3000);
                  }
                })}
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <ArchiveIcon className={classes.iconResponsive} />
              </IconButton> :
                <IconButton
                  onClick={() => dispatch(
                    updateArchivedPublishAssessment({

                      id: params?.row?.id,
                      isArchived: false

                    })
                  ).then((res) => {
                    if (res?.payload?.data?.ok) {
                      // setCopyLoading(true);
                      setTimeout(() => {
                        dispatch(resetMessage());
                        dispatch(getArchivedPublishAssessment({ assessmentCustomerId: formik.values.custName?.id, assessmentCustomerAccountId: formik?.values?.accountName?.id }));
                        // setCopyLoading(false);
                      }, 2000);
                    }
                    if (res?.payload?.error) {
                      setTimeout(() => {
                        dispatch(resetErrMessage());
                      }, 3000);
                    }
                  })}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <UnarchiveIcon className={classes.iconResponsive} />
                </IconButton>


              }
            </MuiTooltip>
          );
        },
      },
      {
        field: "colorCode",
        headerName: "Status",
        // type: "actions",
        width: 100,
        renderCell: (params) => {
          return (
            <MuiTooltip
              title={params?.row?.assessmentStatus}
              placement="bottom"
            >
              <div
                style={{
                  backgroundColor: assessmentStatusData
                    .filter(
                      (value) =>
                        value?.assessmentStatus ===
                        params?.row?.assessmentStatus
                    )
                    .map((option) => option?.colorCode),
                  width: "20px",
                  height: "20px",
                  border: `1px solid ${assessmentStatusData
                    .filter(
                      (value) =>
                        value?.assessmentStatus ===
                        params?.row?.assessmentStatus
                    )
                    .map((option) => option?.colorCode)}`,
                  borderRadius: "100%",
                }}
              ></div>
            </MuiTooltip>
          );
        },
      },
      {
        field: "AssessmentTemplateName",
        headerName: "Assessment Template Name",
        width: 220,
        renderCell: renderCellExpand,
        valueGetter: getAssessmentTemplateMui,
      },
      // {field: "reminderFrequency", headerName: "Reminder Frequency", width: 150},
      {
        field: "comments",
        headerName: "Comments",
        width: 250,
        renderCell: renderCellExpand,
      },
      {
        field: "customer",
        headerName: "Customer Name",
        width: 200,
        valueGetter: (params) => params?.row?.assessmentCustomerUser?.assessmentCustomerAccount?.assessmentCustomer?.
          customerName,

        renderCell: renderCellExpand,
      },
      {
        field: "account",
        headerName: "Account Name",
        width: 200,
        valueGetter: (params) => params?.row?.assessmentCustomerUser?.assessmentCustomerAccount?.accountName,

        renderCell: renderCellExpand,
      },
    ],
  };

  const formik = useFormik({
    reset: true,
    initialValues: {
      assessment: "",
      templateDisplayName: "",
      customerSelectedData: "",
      accountSelectedData: "",
      account: "",
      custName: "",
      client: "",
      comments: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values, onSubmitProps) => {
      dispatch(
        createPublishAssessment({
          assessmentTemplateId: values.assessment?.id,
          assessmentCustomerUserId: values.client?.id,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeModel();
            selectedCustValue && !selectedAccountValue ?
              dispatch(getPublishAssessmentByCustomerId({ assessmentCustomerId: selectedCustValue?.id }))
              : selectedAccountValue
                ? dispatch(getPublishAssessmentById({ assessmentCustomerAccountId: selectedAccountValue?.id }))
                : dispatch(getPublishAssessment());


            onSubmitProps.resetForm();
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });



  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      custName: "",
      account: "",
      customerSelectedData: "",
      accountSelectedData: "",
      assessment: selectedData?.assessmentTemplate,
      templateDisplayName:
        selectedData?.assessmentTemplate?.assessmentTemplateDisplayName,

      client: selectedData?.assessmentCustomerUser?.id,
      status: selectedData?.assessmentStatus,
      cutoffDate:
        Object.keys(cutoffDateSelectedData)?.length > 0 && cutoffDateSelectedData?.cutOffDate ?
          (cutoffDateSelectedData?.cutOffDate)?.substring(0, 10).split("-")[0] +
          "-" +
          (cutoffDateSelectedData?.cutOffDate)?.substring(0, 10).split("-")[1] +
          "-" +
          (cutoffDateSelectedData?.cutOffDate)?.substring(0, 10).split("-")[2] : "",
      reminderFreq: reminderFreqSelectedData?.reminderFrequency,
      comments: selectedData?.comments,
    },

    validationSchema: showEdit && editValidationSchema,
    onSubmit: (values) => {
      const dateResult = DateTime.fromISO(values?.cutoffDate).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      let localIsoString = dateResult.toISO();


      if (values?.status === "Completed") {
        console.log(values?.client)
        setConfirmDialog({
          isOpen: true,
          title: `The Assessment  will be closed for ${selectedData?.
            assessmentCustomerUser
            ?.assessmentCustomerUserDetail
            ?.firstName} ${selectedData?.
              assessmentCustomerUser
              ?.assessmentCustomerUserDetail
              ?.lastName} ..You will not be able to modify the status further. Please confirm.`,
          subTitle: "You can't undo this operation",
          onConfirm: () => handleUpdateIsCompletedCustomer(),
        });
      } else {
        dispatch(
          updatePublishAssessment(
            // showCutoffDate ? {
            //   id: cutoffDateSelectedData?.id,
            //   cutOffDate: values?.cutoffDate,
            //   assessmentTemplateId:cutoffDateSelectedData?.assessmentTemplate?.id,
            //   assessmentCustomerUserId: cutoffDateSelectedData?.assessmentCustomerUser?.id,
            //   assessmentStatus: cutoffDateSelectedData?.assessmentStatus,
            // } : showReminderFreq ? {
            //   id: reminderFreqSelectedData?.id,
            //   reminderFrequency: values?.reminderFreq.toString(),
            //   assessmentTemplateId:reminderFreqSelectedData?.assessmentTemplate?.id,
            //   assessmentCustomerUserId: reminderFreqSelectedData?.assessmentCustomerUser?.id,
            //   assessmentStatus: reminderFreqSelectedData?.assessmentStatus,
            // } :
            {
              id: selectedData?.id,
              assessmentTemplateId: values.assessment?.id,
              assessmentCustomerUserId: values.client,
              assessmentStatus: values?.status,
              // cutOffDate: localIsoString,
              reminderFrequency: values?.reminderFreq,
              comments: values?.comments,
            }
          )
        ).then((res) => {
          if (res?.payload?.data?.ok) {
            setTimeout(() => {
              dispatch(resetMessage());
              closeEditModal();
              closeCutoffDateModal();
              closeReminderFreqModal();
              selectedCustValue && !selectedAccountValue ?
                dispatch(getPublishAssessmentByCustomerId({ assessmentCustomerId: selectedCustValue?.id }))
                : selectedAccountValue
                  ? dispatch(getPublishAssessmentById({ assessmentCustomerAccountId: selectedAccountValue?.id }))
                  : dispatch(getPublishAssessment());
            }, 2000);
          }
          if (res?.payload?.error) {
            setTimeout(() => {
              dispatch(resetErrMessage());
            }, 3000);
          }
        });
      }
      // if(!dateErr) {

      // }
    },
  });

  const handleUpdateIsCompletedCustomer = () => {
    const dateResult = DateTime.fromISO(formikEdit?.values?.cutoffDate).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    let localIsoString = dateResult.toISO();

    let data = {
      id: selectedData?.id,
      assessmentTemplateId: formikEdit?.values.assessment?.id,
      assessmentCustomerUserId: formikEdit?.values.client?.id,

      assessmentStatus: formikEdit?.values?.status,
      cutOffDate: localIsoString,
      reminderFrequency: formikEdit?.values?.reminderFreq,
      comments: formikEdit?.values?.comments,
    };
    dispatch(updatePublishAssessment(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          closeEditModal();
          closeCutoffDateModal();
          closeReminderFreqModal();
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          selectedCustValue && !selectedAccountValue ?
            dispatch(getPublishAssessmentByCustomerId({ assessmentCustomerId: selectedCustValue?.id }))
            : selectedAccountValue
              ? dispatch(getPublishAssessmentById({ assessmentCustomerAccountId: selectedAccountValue?.id }))
              : dispatch(getPublishAssessment());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const formikCutoffDateEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      assessment: selectedData?.assessmentTemplate,
      templateDisplayName: "",
      account: selectedData?.assessmentCustomerUser?.assessmentCustomer,
      client: selectedData?.assessmentCustomerUser?.id,
      status: selectedData?.assessmentStatus,
      cutoffDate:
        Object.keys(cutoffDateSelectedData)?.length > 0 ? cutoffDateSelectedData?.cutOffDate &&
          (getCutoffDate(cutoffDateSelectedData?.cutOffDate).split(",")[0])?.split("/")[2] +
          "-" +
          (getCutoffDate(cutoffDateSelectedData?.cutOffDate).split(",")[0])?.split("/")[0] +
          "-" +
          (getCutoffDate(cutoffDateSelectedData?.cutOffDate).split(",")[0])?.split("/")[1] : "",
      reminderFreq: reminderFreqSelectedData?.reminderFrequency,
      comments: selectedData?.comments,
    },

    validationSchema: showEdit && editValidationSchema,
    onSubmit: (values) => {
      const dateResult = DateTime.fromISO(values?.cutoffDate).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      let localIsoString = dateResult.toISO();
      if (!dateErr) {
        dispatch(
          updatePublishAssessment(
            // showCutoffDate ? {
            {
              id: cutoffDateSelectedData?.id,
              cutOffDate: localIsoString,
              assessmentTemplateId:
                cutoffDateSelectedData?.assessmentTemplate?.id,
              assessmentCustomerUserId:
                cutoffDateSelectedData?.assessmentCustomerUser?.id,
              assessmentStatus: cutoffDateSelectedData?.assessmentStatus,
            }
            //   } : showReminderFreq ? {
            //     id: reminderFreqSelectedData?.id,
            //     reminderFrequency: values?.reminderFreq.toString(),
            //     assessmentTemplateId:reminderFreqSelectedData?.assessmentTemplate?.id,
            //     assessmentCustomerUserId: reminderFreqSelectedData?.assessmentCustomerUser?.id,
            //     assessmentStatus: reminderFreqSelectedData?.assessmentStatus,
            //   } :
            //     {
            //   id: selectedData?.id,
            //   assessmentTemplateId: values.assessment?.id,
            //   assessmentCustomerUserId: values.client?.id,
            //   assessmentStatus: values?.status,
            //   cutOffDate: values?.cutoffDate,
            //   reminderFrequency: values?.reminderFreq,
            //   comments: values?.comments,
            // }
          )
        ).then((res) => {
          if (res?.payload?.data?.ok) {
            setTimeout(() => {
              dispatch(resetMessage());
              closeEditModal();
              closeCutoffDateModal();
              closeReminderFreqModal();
              selectedCustValue && !selectedAccountValue ?
                dispatch(getPublishAssessmentByCustomerId({ assessmentCustomerId: selectedCustValue?.id }))
                : selectedAccountValue
                  ? dispatch(getPublishAssessmentById({ assessmentCustomerAccountId: selectedAccountValue?.id }))
                  : dispatch(getPublishAssessment());
            }, 2000);
          }
          if (res?.payload?.error) {
            setTimeout(() => {
              dispatch(resetErrMessage());
            }, 3000);
          }
        });
      }
    },
  });

  let options = Object.values(countries);

  useEffect(() => {
    dispatch(getPublishAssessment());
    dispatch(getAssessmentTemplate());
    dispatch(getCustUser());
    dispatch(getAccount())
    dispatch(getCustomer())
    dispatch(getAssessmentStatus());
  }, []);

  useEffect(() => {
    if (!formik?.values?.account) {
      formik.setFieldValue("client", "");
    }
  }, [formik?.values?.account]);

  useEffect(() => {
    // templateDisplayName
    formik.setFieldValue(
      "templateDisplayName",
      formik.values.assessment?.assessmentTemplateDisplayName
        ? formik.values.assessment?.assessmentTemplateDisplayName
        : ""
    );
  }, [formik.values.assessment]);

  useEffect(() => {
    // templateDisplayName
    formikEdit.setFieldValue(
      "templateDisplayName",
      formikEdit.values.assessment?.assessmentTemplateDisplayName
        ? formikEdit.values.assessment?.assessmentTemplateDisplayName
        : ""
    );
  }, [formikEdit.values.assessment]);



  // useEffect(() => {
  //   if (
  //     formikEdit?.values?.account?.id !==
  //     selectedData?.assessmentCustomerUser?.assessmentCustomer?.id
  //   ) {
  //     formikEdit.setFieldValue("client", "");
  //   }
  // }, [formikEdit?.values?.account]);

  useEffect(() => {
    if (
      formikCutoffDateEdit?.values?.cutoffDate &&
      (formikCutoffDateEdit?.values?.cutoffDate)
        .substring(0, 10)
        .split("-")[0] +
      "-" +
      (formikCutoffDateEdit?.values?.cutoffDate)
        .substring(0, 10)
        .split("-")[1] +
      "-" +
      (formikCutoffDateEdit?.values?.cutoffDate)
        .substring(0, 10)
        .split("-")[2] <
      format(new Date(), "yyyy-MM-dd")
    ) {
      setDateErr(
        "Cut Off Date should be greater than or equal to current date"
      );
    } else if (!formikCutoffDateEdit?.values?.cutoffDate) {
      setDateErr("Please enter cut off date");
    } else {
      setDateErr("");
    }
  }, [formikCutoffDateEdit.values.cutoffDate]);

  const handleFile = (e) => {

    let formdata = new FormData();
    formdata.append("file", e.target.files[0]);


    dispatch(batchUploadCustomerUser(formdata))
      .then((res) => {

        // if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());

          selectedCustValue && !selectedAccountValue ?
            dispatch(getPublishAssessmentByCustomerId({ assessmentCustomerId: selectedCustValue?.id }))
            : selectedAccountValue
              ? dispatch(getPublishAssessmentById({ assessmentCustomerAccountId: selectedAccountValue?.id }))
              : dispatch(getPublishAssessment());
        }, 2000);
        // }
        if (res?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      })
      .catch((err) => {

        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 2000);
      });

    e.target.value = null;
  };
  useEffect(() => {
    if (formik.values?.custName?.id) {
      dispatch(getAccountById({ assessmentCustomerId: formik.values?.custName?.id }))
    }

  }, [formik.values?.custName])

  // useEffect(() => {
  //   if (!formik?.values?.account) {
  //     dispatch(getPublishAssessment());
  //   }
  //   else {
  //     dispatch(getPublishAssessmentById({ assessmentCustomerAccountId: formik.values?.account?.id }))
  //   }
  // }, [formik.values?.account])

  // useEffect(() => {
  //   if (formikEdit.values?.custName?.id) {
  //     dispatch(getAccountById({ assessmentCustomerId: formikEdit.values?.custName?.id }))
  //   }

  // }, [formikEdit.values?.custName])




  // useEffect(() => {

  //   formik.setFieldValue("account", accountData.find((data) => data?.id === formik?.values?.assessment?.assessmentCustomerAccount?.id)
  //   )
  //   formik.setFieldValue("custName", customersData.find((data) => data?.id === formik?.values?.assessment?.assessmentCustomerAccount?.assessmentCustomer?.id)
  //   )

  // }, [formik.values.assessment])



  useEffect(() => {

    let dataAccounts = accountData?.length > 0 ? accountData : accountIdData

    formikEdit.setFieldValue("account", dataAccounts.find((data) => data?.id === selectedData?.assessmentCustomerUser?.assessmentCustomerAccount?.id)
    )
    formikEdit.setFieldValue("custName", customersData.find((data) => data?.id === selectedData?.assessmentCustomerUser?.assessmentCustomerAccount?.assessmentCustomer?.id)
    )
    // formikEdit.setFieldValue("client", custUserData.find((data) => data?.id === selectedData?.assessmentCustomerUser?.assessmentCustomerAccount?.id)?.id
    // )
  }, [selectedData])

  useEffect(() => {

    let dataAccounts = accountData?.length > 0 ? accountData : accountIdData

    formikEdit.setFieldValue("account", dataAccounts.find((data) => data?.id === selectedData?.assessmentCustomerUser?.assessmentCustomerAccount?.id)
    )
    formikEdit.setFieldValue("custName", customersData.find((data) => data?.id === selectedData?.assessmentCustomerUser?.assessmentCustomerAccount?.assessmentCustomer?.id)
    )
    // formikEdit.setFieldValue("client", custUserData.find((data) => data?.id === selectedData?.assessmentCustomerUser?.assessmentCustomerAccount?.id)?.id
    // )
  }, [cutoffDateSelectedData])



  // useEffect(() => {
  //   if (!formikEdit?.values?.assessment) {
  //     formikEdit.setFieldValue("account", "")
  //     formikEdit.setFieldValue("custName", "")
  //   }

  //   formikEdit.setFieldValue("account", accountData.find((data) => data?.id === formikEdit?.values?.assessment?.assessmentCustomerAccount?.id)
  //   )
  //   formikEdit.setFieldValue("custName", customersData.find((data) => data?.id === formikEdit?.values?.assessment?.assessmentCustomerAccount?.assessmentCustomer?.id)
  //   )

  // }, [formik?.values?.assessment])


  const classes = useStyles();

  const getCustomerSelectedValue = (data) => {
    formik.setFieldValue("custName", data)
    setSelectedCustValue(data)
  }

  const getAccountSelectedValue = (data) => {
    formik.setFieldValue("account", data)
    setSelectedAccountValue(data)
  }

  const handleArchiveClick = () => {
    setViewArchived(!viewArchived);
    if (viewArchived === false) {
      dispatch(getArchivedPublishAssessment({ assessmentCustomerId: formik.values.custName?.id, assessmentCustomerAccountId: formik?.values?.accountName?.id }));
    }
    // else {
    //   dispatch(getPublishAssessment())
    // }

    else {
      if (formik?.values?.custName && !formik?.values?.accountName) {
        dispatch(getPublishAssessmentByCustomerId({ assessmentCustomerId: formik?.values?.custName?.id }))
      }
      if (formik?.values?.custName && formik?.values?.accountName) {
        dispatch(getPublishAssessmentById({ assessmentCustomerAccountId: formik?.values?.account?.id }))
      }
      else {
        dispatch(getPublishAssessment())
      }
    }

  }

  console.log(Object.keys(cutoffDateSelectedData)?.length > 0 ? cutoffDateSelectedData?.cutOffDate &&
    (cutoffDateSelectedData?.cutOffDate).substring(0, 10).split("-")[0] +
    "-" +
    (cutoffDateSelectedData?.cutOffDate).substring(0, 10).split("-")[1] +
    "-" +
    (cutoffDateSelectedData?.cutOffDate).substring(0, 10).split("-")[2] : "")




  return (

    <Layout>
      <>
        {/* {location?.state?.permissions?.find(
          (e) =>
            e.applicationService?.name?.split(".")[
            e?.applicationService?.name?.split(".")?.length - 1
            ] === "uploadClientUsers"
        ) && ( */}
        <label style={{ position: "absolute", right: 140, zIndex: 3 }}>
          <MuiTooltip title={!viewArchived ? "View Archived Records" : "View All"} placement="bottom">

            {!viewArchived ? <IconButton
              onClick={handleArchiveClick}
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <ArchiveIcon className={classes.iconResponsive} />
            </IconButton> :
              <IconButton
                onClick={handleArchiveClick}
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <ViewCozyIcon className={classes.iconResponsive} />
              </IconButton>


            }

          </MuiTooltip>
        </label>
        {/* )} */}
        {location?.state?.permissions?.find(
          (e) =>
            e.applicationService?.name?.split(".")[
            e?.applicationService?.name?.split(".")?.length - 1
            ] === "uploadClientUsers"
        ) && (
            <label
              htmlFor="icon-button-file"
              style={{ position: "absolute", right: 100, zIndex: 1 }}
            >
              <Input
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                id="icon-button-file"
                type="file"
                sx={{ display: "none" }}
                name="l1MenuIcon"
                onChange={handleFile}
                required={true}
              />
              <MuiTooltip title="Bulk Upload" placement="bottom">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <CloudUploadIcon className={classes.iconResponsive} />
                </IconButton>
              </MuiTooltip>
            </label>
          )}

        {location?.state?.permissions?.find(
          (e) =>
            e.applicationService?.name?.split(".")[
            e?.applicationService?.name?.split(".")?.length - 1
            ] === "uploadClientUsers"
        ) && (
            <label style={{ position: "absolute", right: 60, zIndex: 3 }}>
              <MuiTooltip title="Download Template" placement="bottom">
                <a
                  href={`${ASSESSMENT_BASE_URL}/v1/api/excel-parser/template/batch-upload-assessment`}
                  target="_blank"
                >
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <FileDownloadIcon className={classes.iconResponsive} />
                  </IconButton>
                </a>
              </MuiTooltip>
            </label>
          )}



        <PageHeader variant="pageHeader">
          Create & Publish Assessment
        </PageHeader>

        {/* <Stack
          flexDirection="row"
          justifyContent="space-around"
          alignItems="center"
          sx={{ marginBottom: "1rem" }}
        >
          <Grid spacing={1} container style={{ display: 'flex', alignItems: 'center', justifyContent: "flex-end" }}>
            <Grid item xs={12} sm={12} md={3}>
              <MuiAutocomplete
                name="custName"
                options={customersData}
                setValue={(name, newValue) => {

                  formik.setFieldValue(name, newValue)
                  formik.setFieldValue("account", "")
                }
                }
                value={formik.values.custName}
                optionValue="customerName"
                label="Customer"
               
                error={formik.touched.custName && Boolean(formik.errors.custName)}
                helperText={formik.touched.custName && formik.errors.custName}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
            
              <MuiAutocomplete
                name="account"
                options={accountIdData}
                setValue={(name, newValue) =>
                  formik.setFieldValue(name, newValue)
                }
                value={formik.values.account}
                optionValue="accountName"
              
                label="Account Name"
               
                error={
                  formik.touched.account && Boolean(formik.errors.account)
                }
                helperText={formik.touched.account && formik.errors.account}
              />

            
            </Grid>
          </Grid>
        </Stack> */}

        <MuiDataGrid
          setShowAdd={
            location?.state?.permissions?.find(
              (e) =>
                e.applicationService?.name?.split(".")[
                e?.applicationService?.name?.split(".")?.length - 1
                ] === "createAssessTrack"
            ) && !viewArchived && showAddModel
          }
          setShowEdit={
            location?.state?.permissions?.find(
              (e) =>
                e.applicationService?.name?.split(".")[
                e?.applicationService?.name?.split(".")?.length - 1
                ] === "updateAssessTrack"
            ) && !viewArchived && showEditModal
          }
          setShowDelete={
            location?.state?.permissions?.find(
              (e) =>
                e.applicationService?.name?.split(".")[
                e?.applicationService?.name?.split(".")?.length - 1
                ] === "deleteAssessTrack"
            ) && !viewArchived && showDelete
          }

          isAccount={true}
          isCustomer={true}
          getAccountSelectedValue={(data) => getAccountSelectedValue(data)}
          getCustomerSelectedValue={(data) => getCustomerSelectedValue(data)}
          getCallBackCustomer={(data) =>
            viewArchived ? getArchivedPublishAssessment({ assessmentCustomerId: data, assessmentCustomerAccountId: formik?.values?.account?.id })
              : getPublishAssessmentByCustomerId({ assessmentCustomerId: data })}

          getCallBackOne={!viewArchived ? getPublishAssessment : getArchivedPublishAssessment}

          getCallBackTwo={(data) => viewArchived ?
            getArchivedPublishAssessment({ assessmentCustomerId: formik.values.custName?.id, assessmentCustomerAccountId: data }) :
            getPublishAssessmentById({ assessmentCustomerAccountId: data })}

          // getCallBack={(data)=>getPublishAssessmentById({assessmentCustomerAccountId : data})}
          // setShowAdd={ showAddModel}
          // setShowEdit={showEditModal}
          // setShowDelete={showDelete
          // }

          toolbarColumn={true}
          // toolbarFilter={true}
          toolbarDensity={true}
          toolbarExport={true}
          data={data}
          select={(data) => dispatch(rowSelect(data))}
        />
      </>


      {/* <ModalPopUp
        open={showAdd}
        handleClose={closeModel}
        closeModel={closeModel}
      >
        <Box
       
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={closeModel} />
          <div className={classes.text_responsive}> */}

      {showAdd && <CommonCurdPopUp
        heading={`Create Assessment`}
        mediumScreen={true}
        close={
          closeModel
        }
      >
        <Grid container >
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11} >




            <MuiAutocomplete
              name="custName"
              options={customersData}
              setValue={(name, newValue) => {

                formik.setFieldValue(name, newValue)
                formik.setFieldValue("account", "")
                formik.setFieldValue("assessment", "")
                formik.setFieldValue("templateDisplayName", "")
                formik.setFieldValue("client", "");
              }
              }

              value={formik.values.custName}
              optionValue="customerName"
              label="Customer"
              required={true}
              error={formik.touched.custName && Boolean(formik.errors.custName)}
              helperText={formik.touched.custName && formik.errors.custName}
            />

            <MuiAutocomplete
              name="account"
              options={accountIdData}
              setValue={(name, newValue) => {
                formik.setFieldValue(name, newValue)
                formik.setFieldValue("assessment", "");
                formik.setFieldValue("templateDisplayName", "");
                formik.setFieldValue("client", "");
              }

              }

              value={formik.values.account}
              optionValue="accountName"
              // optionSecValue="accountName"
              label="Account Name"
              required={true}
              error={
                formik.touched.account && Boolean(formik.errors.account)
              }
              helperText={formik.touched.account && formik.errors.account}
            />

            <MuiAutocomplete
              name="assessment"
              options={
                assessmentTemplateData?.length > 0 ?
                  assessmentTemplateData?.filter((data) => data?.
                    assessmentCustomerAccount?.id === formik?.values.account?.id
                  ) : []

              }
              setValue={(name, newValue) => {
                formik.setFieldValue(name, newValue);

              }}
              value={formik.values.assessment}
              optionValue="assessmentTemplateName"
              label="Assessment Template Name"
              required={true}
              error={
                formik.touched.assessment &&
                Boolean(formik.errors.assessment)
              }
              helperText={
                formik.touched.assessment && formik.errors.assessment
              }

            />




            <MyTextInput
              InputLabelProps={{ shrink: true }}
              label="Template Display Name *"
              type="text"
              name="templateDisplayName"
              fullWidth={true}
              margin="normal"
              id="templateDisplayName"
              value={formik.values.templateDisplayName}
              // onChange={formik.handleChange}
              error={
                formik.touched.templateDisplayName &&
                Boolean(formik.errors.templateDisplayName)
              }
              helperText={
                formik.touched.templateDisplayName &&
                formik.errors.templateDisplayName
              }
              disabled={formik.values.templateDisplayName ? "" : "disabled"}
            />


            {/* <MuiAutocomplete
                  name="client"
                  options={custUserData?.length > 0 ? custUserData.filter(item => item?.assessmentCustomer?.id === formik?.values?.account?.id) : []}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.client}
                  innerValue={true}
              optionValue="assessmentCustomerUserDetail"
              optionSecValue="firstName"
              optionThirdValue="lastName"
                  label="Client"
                  required={true}
                  error={
                    formik.touched.client && Boolean(formik.errors.client)
                  }
                  helperText={formik.touched.client && formik.errors.client}
                />  */}
            <Autocomplete
              name="client"
              options={
                custUserData?.length > 0
                  ? custUserData.filter(
                    (item) =>
                      item?.assessmentCustomerAccount?.id ===
                      formik?.values?.account?.id
                  )
                  : []
              }
              getOptionLabel={(option) =>
                option &&
                `${option?.assessmentCustomerUserDetail?.firstName} ${option?.assessmentCustomerUserDetail?.lastName}-${option?.email}`
              }
              value={formik.values.client}
              onChange={(event, newValue) =>
                formik.setFieldValue("client", newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Client"
                  required={true}
                  error={
                    formik.touched.client && Boolean(formik.errors.client)
                  }
                  helperText={formik.touched.client && formik.errors.client}
                />
              )}
              sx={{ marginTop: "1rem" }}
              disabled={!formik?.values?.account}
            />
            {/* <MyTextInput
                  label="Email"
                  type="email"
                  name="email"
                  fullWidth={true}
                  margin="normal"
                  id="email"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.client?.email}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.email && Boolean(formik.errors.email)
                  }
                  helperText={formik.touched.email && formik.errors.email}
                  disabled
                /> */}
            <MyTextInput
              label="Comments"
              type="comments"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formik.values.comments}
              onChange={formik.handleChange}
              error={
                formik.touched.comments && Boolean(formik.errors.comments)
              }
              helperText={formik.touched.comments && formik.errors.comments}
            />
            <Box style={{ width: "100%", textAlign: "center" }} mb={10}>

              <FormSubmitButton onClick={formik?.handleSubmit} type="submit" variant="contained">
                Submit
              </FormSubmitButton>
            </Box>
          </Grid>
        </Grid>

      </CommonCurdPopUp>
      }


      {showEdit && <CommonCurdPopUp
        heading={`Update Assessment`}
        mediumScreen={true}
        close={
          () => setShowEdit(false)
        }
      >
        <Grid container >
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11} >




            <MuiAutocomplete
              name="custName"
              options={customersData}
              setValue={(name, newValue) => {

                formikEdit.setFieldValue(name, newValue)
                formikEdit.setFieldValue("account", "")
                formikEdit.setFieldValue("assessment", "")
                formikEdit.setFieldValue("templateDisplayName", "")

              }
              }
              disabled
              value={formikEdit.values.custName}
              optionValue="customerName"
              label="Customer"
              required={true}
              error={formikEdit.touched.custName && Boolean(formikEdit.errors.custName)}
              helperText={formikEdit.touched.custName && formikEdit.errors.custName}
            />

            <MuiAutocomplete
              name="account"
              options={accountData.length > 0 ? accountData : accountIdData}
              setValue={(name, newValue) => {
                formikEdit.setFieldValue(name, newValue)
                formikEdit.setFieldValue("assessment", "");
                formikEdit.setFieldValue("templateDisplayName", "");
                formikEdit.setFieldValue("client", "");
              }

              }
              disabled
              value={formikEdit.values.account}
              optionValue="accountName"
              // optionSecValue="accountName"
              label="Account Name"
              required={true}
              error={
                formikEdit.touched.account && Boolean(formikEdit.errors.account)
              }
              helperText={formikEdit.touched.account && formikEdit.errors.account}
            />

            <MuiAutocomplete
              name="assessment"
              options={
                assessmentTemplateData?.length > 0 && formikEdit?.values?.account?.id ?
                  assessmentTemplateData?.filter((data) => data?.
                    assessmentCustomerAccount?.id === formikEdit?.values?.account?.id
                  ) : []}
              setValue={(name, newValue) =>
                formikEdit.setFieldValue(name, newValue)
              }
              value={formikEdit.values.assessment}
              // defaultValue={formikEdit?.values?.assessment}
              optionValue="assessmentTemplateName"
              label="Assessment Template Name"
              required={true}
              error={
                formikEdit.touched.assessment &&
                Boolean(formikEdit.errors.assessment)
              }
              helperText={
                formikEdit.touched.assessment &&
                formikEdit.errors.assessment
              }
              disabled={
                formikEdit.values.status === "Published" ||
                  formikEdit.values.status === "Completed"
                  ? "disabled"
                  : ""
              }
            />

            <MyTextInput
              InputLabelProps={{ shrink: true }}
              label="Template Display Name"
              type="text"
              name="templateDisplayName"
              fullWidth={true}
              margin="normal"
              id="templateDisplayName"
              value={formikEdit.values.templateDisplayName}
              // onChange={formikEdit.handleChange}
              error={
                formikEdit.touched.templateDisplayName &&
                Boolean(formikEdit.errors.templateDisplayName)
              }
              helperText={
                formikEdit.touched.templateDisplayName &&
                formikEdit.errors.templateDisplayName
              }
              disabled={
                (formik.values.templateDisplayName ? "" : "disabled") ||
                (formikEdit.values.status === "Published" ||
                  formikEdit.values.status === "Completed"
                  ? "disabled"
                  : "")
              }
            />
            {/* <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Customer *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="custName"
                    defaultValue={formikEdit.values.custName}
                    value={formikEdit.values.custName}
                    label="Customer"
                    onChange={formikEdit.handleChange}
                    error={
                      formikEdit.touched.custName &&
                      Boolean(formikEdit.errors.custName)
                    }
                  >
                    {customersData.map((option) => {
                      return (
                        <MenuItem
                          key={option.id}
                          value={option.id}
                        >
                          {option.customerName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {formikEdit.touched.custName && formikEdit.errors.custName}
                  </FormHelperText>
                </FormControl>

                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Account Name *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="account"
                    defaultValue={formikEdit?.values?.account}
                    value={formikEdit.values.account}
                    error={
                      formikEdit.touched.account &&
                      Boolean(formikEdit.errors.account)
                    }
                    label="Account Name *"
                    onChange={formikEdit.handleChange}
                    disabled={true}
                  >
                    {accountIdData.length > 0 &&
                      accountIdData.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item?.assessmentCustomer?.customerName}-
                          {item.accountName}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {formikEdit.touched.account && formikEdit.errors.account}
                  </FormHelperText>
                </FormControl> */}


            {/* <MuiAutocomplete
                  name="account"
                  options={accountData}
                  setValue={(name, newValue) =>
                    formikEdit.setFieldValue(name, newValue)
                  }
                  value={formikEdit.values.account}
                  defaultValue={formikEdit?.values?.account}
                  optionValue="customerName"
                  optionSecValue="accountName"
                  label="Account Name"
                  required={true}
                  error={
                    formikEdit.touched.account &&
                    Boolean(formikEdit.errors.account)
                  }
                  helperText={
                    formikEdit.touched.account && formikEdit.errors.account
                  }
                  disabled={
                    formikEdit.values.status === "Published" ||
                    formikEdit.values.status === "Completed"
                      ? "disabled"
                      : ""
                  }
                />  */}


            {/* <MuiAutocomplete
                  name="client"
                  options={custUserData?.length > 0 && custUserData?.filter(value => value?.assessmentCustomer?.id === formikEdit?.values?.account?.id)}
                  setValue={(name, newValue) =>
                    formikEdit.setFieldValue(name, newValue)
                  }
                  defaultValue={formikEdit?.values?.client}
                  value={formikEdit.values.client}
                  innerValue={true}
                  optionValue="assessmentCustomerUserDetail"
                  optionSecValue="firstName"
                  optionThirdValue="lastName"
                  label="Client"
                  required={true}
                  error={
                    formikEdit.touched.client && Boolean(formikEdit.errors.client)
                  }
                  helperText={formikEdit.touched.client && formikEdit.errors.client}
                /> */}
            {/* <Autocomplete
                  name="client"
                  options={
                    custUserData?.length > 0
                      ? custUserData
                        .filter(
                          (item) =>
                            item?.assessmentCustomerAccount?.id ===
                            formikEdit?.values?.account?.id
                        )
                      : []
                  }
                  getOptionLabel={(option) =>
                    option &&
                    `${option?.assessmentCustomerUserDetail?.firstName} ${option?.assessmentCustomerUserDetail?.lastName}-${option?.email}`
                  }
                 
                  value={formikEdit.values.client}
                  onChange={(event, newValue) =>
                    formikEdit.setFieldValue("client", newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Client"
                      required={true}
                      error={
                        formikEdit.touched.client &&
                        Boolean(formikEdit.errors.client)
                      }
                      helperText={
                        formikEdit.touched.client && formikEdit.errors.client
                      }
                    />
                  )}
                  sx={{ marginTop: "1rem" }}
                  disabled={
                    !formikEdit?.values?.account ||
                      formikEdit.values.status === "Published" ||
                      formikEdit.values.status === "Completed"
                      ? "disabled"
                      : ""
                  }
                /> */}
            <FormControl sx={{ marginTop: "1rem" }} fullWidth>
              <InputLabel id="demo-simple-select-label">
                Client *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="client"
                value={formikEdit.values.client}
                error={
                  formikEdit.touched.client &&
                  Boolean(formikEdit.errors.client)
                }
                defaultValue={formikEdit.values.client}
                label="client"
                onChange={formikEdit.handleChange}
                disabled={
                  !formikEdit?.values?.account ||
                    formikEdit.values.status === "Published" ||
                    formikEdit.values.status === "Completed"
                    ? "disabled"
                    : ""
                }
              >
                {
                  custUserData?.length > 0
                  && custUserData
                    .filter(
                      (item) =>
                        item?.assessmentCustomerAccount?.id ===
                        formikEdit?.values?.account?.id
                    ).map((option) => {

                      return (
                        <MenuItem
                          key={option.id}
                          value={option.id}
                        >
                          {option?.assessmentCustomerUserDetail?.firstName} {option?.assessmentCustomerUserDetail?.lastName}-{option?.email}
                        </MenuItem>
                      );
                    })}
              </Select>
              {formikEdit?.errors?.client && (
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formikEdit.touched.client && formikEdit.errors.client}
                </FormHelperText>
              )}
            </FormControl>
            {/* <MyTextInput
                  label="Email"
                  type="email"
                  name="email"
                  fullWidth={true}
                  margin="normal"
                  id="email"
                  value={formikEdit.values.client?.email}
                  onChange={formikEdit.handleChange}
                  error={
                    formikEdit.touched.comments &&
                    Boolean(formikEdit.errors.comments)
                  }
                  helperText={
                    formikEdit.touched.comments && formikEdit.errors.comments
                  }
                  disabled
                /> */}
            <FormControl sx={{ marginTop: "1rem" }} fullWidth>
              <InputLabel id="demo-simple-select-label">
                Assessment Status *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={formikEdit.values.status}
                disabled={selectedData?.assessmentStatus === "Completed"}
                error={
                  formikEdit.touched.status &&
                  Boolean(formikEdit.errors.status)
                }
                label="Assessment Status"
                onChange={formikEdit.handleChange}
              >
                {assessmentStatusData.length > 0 &&
                  (selectedData?.assessmentStatus === "Published" ||
                    selectedData?.assessmentStatus === "Completed")
                  ? assessmentStatusData
                    .filter(
                      (data) =>
                        data?.assessmentStatus === "Completed" ||
                        data?.assessmentStatus === "Published"
                    )
                    .map((item) => {
                      return (
                        <MenuItem
                          key={item.id}
                          value={item.assessmentStatus}
                        >
                          {item.assessmentStatus}
                        </MenuItem>
                      );
                    })
                  : assessmentStatusData
                    .filter(
                      (data) =>
                        data?.assessmentStatus === "New" ||
                        data?.assessmentStatus === "Pending" ||
                        data?.assessmentStatus === "Hold" ||
                        data?.assessmentStatus === "Archived"
                    )
                    .map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.assessmentStatus}
                      >
                        {item.assessmentStatus}
                      </MenuItem>
                    ))}
              </Select>
              {formikEdit?.errors?.status && (
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formikEdit.touched.status && formikEdit.errors.status}
                </FormHelperText>
              )}
            </FormControl>
            <MyTextInput
              label="Comments"
              type="comments"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formikEdit.values.comments}
              onChange={formikEdit.handleChange}
              error={
                formikEdit.touched.comments &&
                Boolean(formikEdit.errors.comments)
              }
              helperText={
                formikEdit.touched.comments && formikEdit.errors.comments
              }
            />
            <Box style={{ width: "100%", textAlign: "center" }} mb={10}>

              <FormSubmitButton onClick={formikEdit?.handleSubmit} type="submit" variant="contained">
                Submit
              </FormSubmitButton>
            </Box>
          </Grid>
        </Grid>


      </CommonCurdPopUp>}

      {/* <ModalPopUp
        open={showEdit}
        handleClose={() => setShowEdit(false)}
        closeModel={() => setShowEdit(false)}
      >
        <Box
         
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formikEdit.handleSubmit}
        >
          <ModalCloseBtn close={() => setShowEdit(false)} />
          <div className={classes.text_responsive}> */}

      {/* </div> */}

      {/* <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp> */}

      {showCutoffDate && <CommonCurdPopUp
        heading={`Cutt Off Date`}
        mediumScreen={true}
        close={
          () => setShowCutoffDate(false)
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8} margin="auto">
            <MyTextInput
              label="Cut Off Date"
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: format(new Date(), "yyyy-MM-dd") }}
              type="date"
              name="cutoffDate"
              fullWidth={true}
              margin="normal"
              id="cutoffDate"
              value={formikCutoffDateEdit.values.cutoffDate}
              onChange={(e) =>
                formikCutoffDateEdit.setFieldValue(
                  "cutoffDate",
                  e.target.value
                )
              }
              required={true}
              error={
                formikCutoffDateEdit.touched.cutoffDate && dateErr
                  ? Boolean(dateErr)
                  : Boolean(formikCutoffDateEdit.errors.cutoffDate)
              }
              helperText={
                formikCutoffDateEdit.touched.cutoffDate && dateErr
                  ? dateErr
                  : formikCutoffDateEdit.errors.cutoffDate
              }
            />
          </Grid>
        </Grid>
        <Box style={{ width: "100%", textAlign: "center" }} >

          <FormSubmitButton onClick={formikCutoffDateEdit?.handleSubmit} type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>

      </CommonCurdPopUp>}
      {/* <ModalPopUp
        open={showCutoffDate}
        handleClose={() => setShowCutoffDate(false)}
        closeModel={() => setShowCutoffDate(false)}
      >
        <Box
          sx={{ ...style, width: 900 }}
          className={classes.cutoff_box}
          component="form"
          noValidate
          onSubmit={formikCutoffDateEdit.handleSubmit}
        >
          <ModalCloseBtn
            close={() => {
              setShowCutoffDate(false);
              formikCutoffDateEdit.setFieldValue("cutoffDate", "");
            }}
          />
          <div style={{ width: "50rem" }} className={classes.responsive_field}> */}


      <ModalPopUp
        open={showReminderFreq}
        handleClose={() => setShowReminderFreq(false)}
        closeModel={() => setShowReminderFreq(false)}
      >
        <Box
          sx={{ ...style, width: 900 }}
          component="form"
          noValidate
          onSubmit={formikEdit.handleSubmit}
        >
          <ModalCloseBtn close={() => setShowReminderFreq(false)} />
          <div style={{ width: "50rem" }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={8} margin="auto">
                <MyTextInput
                  label="Reminder Frequency(days)"
                  type="number"
                  name="reminderFreq"
                  fullWidth={true}
                  margin="normal"
                  id="reminderFreq"
                  value={formikEdit.values.reminderFreq}
                  onChange={formikEdit.handleChange}
                  error={
                    formikEdit.touched.reminderFreq &&
                    Boolean(formikEdit.errors.reminderFreq)
                  }
                  helperText={
                    formikEdit.touched.reminderFreq &&
                    formikEdit.errors.reminderFreq
                  }
                />
              </Grid>
            </Grid>
          </div>

          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />

      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>

  );
};

export default ConfigAndPublish
