import React from 'react'
import Report from './Report'

function ReportView() {
  return (
    <>
        <iframe srcDoc={<Report />} title="testPdf" height="100%" width="100%" />
    </>
  )
}

export default ReportView