
// This error message for name text field which should accepts only alphanumeric, dash, underScore and spaces
// export const textFieldErr = "Invalid Attempt, only alphanumeric, dash , underscore and space are allowed."
// ------------
// export const textFieldErr = "Invalid Attempt. Only alphanumeric characters, spaces, commas, colons, Hyphen, and ampersands are allowed.";
// -------------------
export const textFieldErr = "Invalid characters, only alphanumeric and few symbols (& , : _ - ( ) + = @ . ! * $ % [ ] { } / \ | ? \" ; :) are allowed";
                                                                                      
// ----------------
// This error message for comments and description textarea field which should accepts only alphanumeric, special characters are $-_.+!*'(), " and spaces
export const textAreaFieldErr = "Invalid characters, only alphanumeric and few symbols (& , : _ - ( ) + = @ . ! * $ % [ ] { } / \ | ? \" ; :) are allowed";
                                                                                        

// export const textAreaFieldErr = `Invalid Attempt, only alphanumeric, $ - _ . + ! * () / : " ' , and space are allowed.`
export const textAreaFieldErrWithoutQuotes = "Invalid Attempt, only alphanumeric, $ - _ . + ! * () , and space are allowed."
export const bigTextAreaFieldErr = `Invalid Attempt, only alphanumeric, $ - _ . + ! * () '  : / ", and space are allowed.`
 
// This error message for url field in menuManagement,
export const urlFieldErr = "Invalid Attempt, only alphanumerid, dash, underscore, forward slash and space are allowed"

export const numberFieldErr = "Invalid Attempt, only numbers are allowed."