import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_QUESTION_TYPE } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createQuestionType = createAsyncThunk(
  "questionType/createQuestionType",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_QUESTION_TYPE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getQuestionType = createAsyncThunk(
  "questionType/getQuestionType",
  async (obj, {rejectWithValue}) => {
    try {
      const response = await axios.get(CURD_QUESTION_TYPE, { withCredentials: true });
      return response;
    } catch(err) {
      return rejectWithValue(err.response)
    }
  }
);

export const updateQuestionType = createAsyncThunk(
  "questionType/updateQuestionType",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_QUESTION_TYPE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteQuestionType = createAsyncThunk(
  "questionType/deleteQuestionType",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_QUESTION_TYPE, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const questionTypeSlice = createSlice({
  name: "questionType",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    questionTypeData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createQuestionType.pending]: (state, action) => {
      state.loading = true;
    },
    [createQuestionType.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createQuestionType.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getQuestionType.pending]: (state, action) => {
      state.loading = true;
    },
    [getQuestionType.fulfilled]: (state, action) => {
      state.loading = false;
      state.questionTypeData = action?.payload?.data;
    },
    [getQuestionType.rejected]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateQuestionType.pending]: (state, action) => {
      state.loading = true;
    },
    [updateQuestionType.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateQuestionType.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteQuestionType.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteQuestionType.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteQuestionType.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  questionTypeSlice.actions;
export default questionTypeSlice.reducer;
