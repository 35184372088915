import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { Link, useLocation } from "react-router-dom";
// material ui
import { Stack, Box, Grid, Paper, Fab, TextField, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";

// icons
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
// components
import { MuiDataGrid, GridCellExpand, MuiTooltip } from "../../../../components";
import ConfirmDialog from "../../../../components/common/ConfirmDialog";
import Layout from "../../../../layout/Layout";
import AddL1Menu from "./AddL1Menu";
import EditL1Menu from "./EditL1Menu";
import AddL2Menu from './AddL2Menu';
import EditL2Menu from "./EditL2Menu";
import AddL3Menu from './AddL3Menu'
import EditL3Menu from "./EditL3Menu";
import { STATIC_IMAGE } from "../../../../utils/config";
// redux 
import { getL1Menu, rowSelect as l1RowSelect, deleteL1Menu, resetMessage as l1ResetMessage, resetErrMessage as l1ResetErrMessage } from "../../../../features/iam/application_management/menu_management/l1MenuSlice"
import { getL2Menu, getL2MenuByL1Menu, rowSelect as l2RowSelect, deleteL2Menu, resetMessage as l2ResetMessage, resetErrMessage as l2ResetErrMessage } from '../../../../features/iam/application_management/menu_management/l2MenuSlice'
import { getL3Menu, getL3MenuByL2Menu, rowSelect as l3RowSelect, deleteL3Menu, resetMessage as l3ResetMessage, resetErrMessage as l3ResetErrMessage } from '../../../../features/iam/application_management/menu_management/l3MenuSlice'

// ------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "0.5rem",
    borderRadius: "1rem !important",
  },
}));

// ----------------------------------------------------------------------------------

const MenuManagement = () => {
  const [showL1Add, setShowL1Add] = useState(false);
  const [showL1Edit, setShowL1Edit] = useState(false);
  const [l1ConfirmDialog, setL1ConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [showL2Add, setShowL2Add] = useState(false);
  const [showL2Edit, setShowL2Edit] = useState(false);
  const [l2ConfirmDialog, setL2ConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [showL3Add, setShowL3Add] = useState(false);
  const [showL3Edit, setShowL3Edit] = useState(false);
  const [l3ConfirmDialog, setL3ConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [search, setSearch] = useState("");

  const classes = useStyles();
  const location = useLocation()
  const dispatch = useDispatch();

  const { l1MenuData, l1SelectedData, } = useSelector((state) => ({ ...state.l1Menus }))
  const { l2MenuData, l2SelectedData } = useSelector((state) => ({ ...state.l2Menus }))
  const { l3MenuData, l3SelectedData } = useSelector((state) => ({ ...state.l3Menus }))

  useEffect(() => {
    dispatch(getL1Menu());
    dispatch(getL2Menu());
    dispatch(getL3Menu());
  }, [])

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const l1Data = {
    rows: l1MenuData,
    columns: [
      { field: "l1MenuName", headerName: "Menu Name", width: 150, renderCell: renderCellExpand },

      { field: "l1MenuGroupId", headerName: "Group Id", width: 70 },
      { field: "l1PageLink", headerName: "PageLink", width: 150, renderCell: renderCellExpand },
      {
        field: "l1MenuIcon",
        headerName: "Icon",
        width: 50,
        renderCell: (params) => {
          return (
            <Avatar
              alt="Icon"
              src={`${STATIC_IMAGE}/${params?.row?.l1MenuIcon}`}
              sx={{ width: 30, height: 30 }}
            />
          );
        },
      },
    ],
  };

  const handleSetL1ConfirmDialog = (obj) => {
    setL1ConfirmDialog({
      ...l1ConfirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleDeleteL1Menu = () => {
    let data = {
      id: l1SelectedData?.id,
    };
    dispatch(deleteL1Menu(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(l1ResetMessage());
          setL1ConfirmDialog({
            ...l1ConfirmDialog,
            isOpen: false,
          });
          dispatch(getL1Menu());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(l1ResetErrMessage());
        }, 2000);
      }
    });
  };

  const l2Data = {
    rows: l1SelectedData?.id ?  l2MenuData : [],
    columns: [
      { field: "l2MenuName", headerName: "Menu Name", width: 150, renderCell: renderCellExpand },

      { field: "l2MenuGroupId", headerName: "Group Id", width: 70 },
      { field: "l2PageLink", headerName: "PageLink", width: 150, renderCell: renderCellExpand },
      {
        field: "l2MenuIcon",
        headerName: "Icon",
        width: 50,
        renderCell: (params) => {
          return (
            <Avatar
              alt="Icon"
              src={`${STATIC_IMAGE}/${params?.row?.l2MenuIcon}`}
              sx={{ width: 30, height: 30 }}
            />
          );
        },
      },
    ],
  };

  const handleSetL2ConfirmDialog = (obj) => {
    setL2ConfirmDialog({
      ...l2ConfirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleDeleteL2Menu = () => {
    let data = {
      id: l2SelectedData?.id,
    };
    dispatch(deleteL2Menu(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(l2ResetMessage());
          setL2ConfirmDialog({
            ...l2ConfirmDialog,
            isOpen: false,
          });
          dispatch(getL2Menu());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(l2ResetErrMessage());
        }, 2000);
      }
    });
  };



  const l3Data = {
    rows:  l2SelectedData?.id ?  l3MenuData : [],
    columns: [
      { field: "l3MenuName", headerName: "Menu Name", width: 150, renderCell: renderCellExpand },

      { field: "l3MenuGroupId", headerName: "Group Id", width: 70 },
      { field: "l3PageLink", headerName: "PageLink", width: 150, renderCell: renderCellExpand },
      {
        field: "l3MenuIcon",
        headerName: "Icon",
        width: 50,
        renderCell: (params) => {
          return (
            <Avatar
              alt="Icon"
              src={`${STATIC_IMAGE}/${params?.row?.l3MenuIcon}`}
              sx={{ width: 30, height: 30 }}
            />
          );
        },
      },
    ],
  };

  const handleSetL3ConfirmDialog = (obj) => {
    setL3ConfirmDialog({
      ...l3ConfirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleDeleteL3Menu = () => {
    let data = {
      id: l3SelectedData?.id,
    };
    dispatch(deleteL3Menu(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(l3ResetMessage());
          setL3ConfirmDialog({
            ...l3ConfirmDialog,
            isOpen: false,
          });
          dispatch(getL3Menu());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(l3ResetErrMessage());
        }, 2000);
      }
    });
  };

  return (
    <Layout>
      <Link to="/exco/iam/app-services" state={{ l3Menu: location?.state?.l3Menu }}>
        <CancelIcon color="red" sx={{ position: "absolute", right: 15, zIndex: 1 }} />
      </Link>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={4}>
          <Paper elevation={4} className={classes.paper}>
            <PageHeader variant="pageSecHeader">L1 Menus</PageHeader>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              textAlign="center"
              position="relative"
              mb={1}
            >
              <TextField
                hiddenLabel
                type="text"
                id="filled-hidden-label-small"
                name="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                variant="filled"
                size="small"
                placeholder="Search L1 Menus..."
                sx={{
                  width: 250,
                }}
              />
              {location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "l1MenuCreate") &&
                <MuiTooltip title="Add" placement="bottom">
                  <Fab
                    aria-label="add"
                    onClick={() => setShowL1Add(true)}
                    color="primary"
                    size="small"
                  >
                    <AddIcon />
                  </Fab>
                </MuiTooltip>
              }
            </Stack>
            <MuiDataGrid
              setShowEdit={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "l1MenuUpdate") ? () => setShowL1Edit(true) : null}
              setShowDelete={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "l1MenuDelete") ? () =>
                setL1ConfirmDialog({
                  isOpen: true,
                  title: "Are you sure to delete this record?",
                  subTitle: "You can't undo this operation",
                  onConfirm: () => handleDeleteL1Menu(),
                }) : null
              }
              // setShowEdit={ () => setShowL1Edit(true) }
              // setShowDelete={ () =>
              //   setL1ConfirmDialog({
              //     isOpen: true,
              //     title: "Are you sure to delete this record?",
              //     subTitle: "You can't undo this operation",
              //     onConfirm: () => handleDeleteL1Menu(),
              //   })
              // }
              toolbarFilter={true}
              data={l1Data}
              select={(data) => {
                dispatch(l1RowSelect(data))
                dispatch(getL2MenuByL1Menu(data[0]?.id))
              }}
            />
            <AddL1Menu showAdd={showL1Add} setShowAdd={setShowL1Add} />
            <EditL1Menu showEdit={showL1Edit} setShowEdit={setShowL1Edit} />
            <ConfirmDialog
              confirmDialog={l1ConfirmDialog}
              setConfirmDialog={(obj) => handleSetL1ConfirmDialog(obj)}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Paper elevation={4} className={classes.paper}>
            <PageHeader variant="pageSecHeader">L2 Menus</PageHeader>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              textAlign="center"
              position="relative"
              mb={1}
            >
              <TextField
                hiddenLabel
                type="text"
                id="filled-hidden-label-small"
                name="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                variant="filled"
                size="small"
                placeholder="Search L2 Menus..."
                sx={{
                  width: 250,
                }}
              />
              {location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "l2MenuCreate") &&
                <MuiTooltip title="Add" placement="bottom">
                  <Fab
                    aria-label="add"
                    onClick={() => setShowL2Add(true)}
                    color="primary"
                    size="small"
                  >
                    <AddIcon />
                  </Fab>
                </MuiTooltip>
              }
            </Stack>
            <MuiDataGrid
              setShowEdit={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "l2MenuUpdate") ? () => setShowL2Edit(true) : null}
              setShowDelete={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "l2MenuDelete") ? () =>
                setL2ConfirmDialog({
                  isOpen: true,
                  title: "Are you sure to delete this record?",
                  subTitle: "You can't undo this operation",
                  onConfirm: () => handleDeleteL2Menu(),
                }) : null
              }
              // setShowEdit={ () => setShowL2Edit(true) }
              // setShowDelete={ () =>
              //   setL2ConfirmDialog({
              //     isOpen: true,
              //     title: "Are you sure to delete this record?",
              //     subTitle: "You can't undo this operation",
              //     onConfirm: () => handleDeleteL2Menu(),
              //   }) 
              // }
              toolbarFilter={true}
              data={l2Data}
              select={(data) => {
                dispatch(l2RowSelect(data))
                dispatch(getL3MenuByL2Menu(data[0]?.id))
              }}
            />
            <AddL2Menu showAdd={showL2Add} setShowAdd={setShowL2Add} />
            <EditL2Menu showEdit={showL2Edit} setShowEdit={setShowL2Edit} />
            <ConfirmDialog
              confirmDialog={l2ConfirmDialog}
              setConfirmDialog={(obj) => handleSetL2ConfirmDialog(obj)} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Paper elevation={4} className={classes.paper}>
            <PageHeader variant="pageSecHeader">L3 Menus</PageHeader>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              textAlign="center"
              position="relative"
              mb={1}
            >
              <TextField
                hiddenLabel
                type="text"
                id="filled-hidden-label-small"
                name="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                variant="filled"
                size="small"
                placeholder="Search L3 Menus..."
                sx={{
                  width: 250,
                }}
              />
              {location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "l3MenuCreate") &&
                <MuiTooltip title="Add" placement="bottom">
                  <Fab
                    aria-label="add"
                    onClick={() => setShowL3Add(true)}
                    color="primary"
                    size="small"
                  >
                    <AddIcon />
                  </Fab>
                </MuiTooltip>
              }
            </Stack>
            <MuiDataGrid
              setShowEdit={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "l3MenuUpdate") ? () => setShowL3Edit(true) : null}
              setShowDelete={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "l3MenuDelete") ? () =>
                setL3ConfirmDialog({
                  isOpen: true,
                  title: "Are you sure to delete this record?",
                  subTitle: "You can't undo this operation",
                  onConfirm: () => handleDeleteL3Menu(),
                }) : null
              }
              //  setShowEdit={() =>  setShowL3Edit(true)}
              //  setShowDelete={ () =>
              //    setL3ConfirmDialog({
              //      isOpen: true,
              //      title: "Are you sure to delete this record?",
              //      subTitle: "You can't undo this operation",
              //      onConfirm: () => handleDeleteL3Menu(),
              //    }) 
              //  }
              toolbarFilter={true}
              data={l3Data}
              select={(data) => {
                dispatch(l3RowSelect(data))
              }}
            />
            <AddL3Menu showAdd={showL3Add} setShowAdd={setShowL3Add} />
            <EditL3Menu showEdit={showL3Edit} setShowEdit={setShowL3Edit} />
            <ConfirmDialog
              confirmDialog={l3ConfirmDialog}
              setConfirmDialog={(obj) => handleSetL3ConfirmDialog(obj)} />
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default MenuManagement;
