import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_ASSESSMENT_TEMPLATE_CONFIG, COPY_ASSESSMENT_TEMPLATE_CONFIG, FORCE_DELETE_ASSESSMENT_TEMPLATE_CONFIG } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createAssessmentTemplateConfig = createAsyncThunk(
  "assessmentTemplateConfig/createAssessmentTemplateConfig",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_ASSESSMENT_TEMPLATE_CONFIG, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAssessmentTemplateConfig = createAsyncThunk(
  "assessmentTemplateConfig/getAssessmentTemplateConfig",
  async (obj, {rejectWithValue}) => {
    try {
      const response = await axios.get(CURD_ASSESSMENT_TEMPLATE_CONFIG, { withCredentials: true });
      return response;
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
);

export const updateAssessmentTemplateConfig = createAsyncThunk(
  "assessmentTemplateConfig/updateAssessmentTemplateConfig",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_ASSESSMENT_TEMPLATE_CONFIG, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAssessmentTemplateConfig = createAsyncThunk(
  "assessmentTemplateConfig/deleteAssessmentTemplateConfig",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_ASSESSMENT_TEMPLATE_CONFIG, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const copyAssessmentTemplateConfig = createAsyncThunk(
  "assessmentTemplateConfig/copyAssessmentTemplateConfig",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(COPY_ASSESSMENT_TEMPLATE_CONFIG, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const forceDeleteAssessmentTemplateConfig = createAsyncThunk(
  "assessmentTemplateConfig/forceDeleteAssessmentTemplateConfig",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(FORCE_DELETE_ASSESSMENT_TEMPLATE_CONFIG, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const assessmentTemplateConfigSlice = createSlice({
  name: "assessmentTemplateConfig",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    assessmentTemplateConfigData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createAssessmentTemplateConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [createAssessmentTemplateConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createAssessmentTemplateConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getAssessmentTemplateConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentTemplateConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentTemplateConfigData = action?.payload?.data;
    },
    [getAssessmentTemplateConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateAssessmentTemplateConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [updateAssessmentTemplateConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateAssessmentTemplateConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteAssessmentTemplateConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteAssessmentTemplateConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteAssessmentTemplateConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [copyAssessmentTemplateConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [copyAssessmentTemplateConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [copyAssessmentTemplateConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [forceDeleteAssessmentTemplateConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [forceDeleteAssessmentTemplateConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [forceDeleteAssessmentTemplateConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  assessmentTemplateConfigSlice.actions;
export default assessmentTemplateConfigSlice.reducer;
