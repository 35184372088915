import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {
  CURD_ASSESSMENT_TEMPLATE_QUESTION,
  GET_ASSESSMENT_TEMPLATE_QUESTION_BY_TEMPLATE_ID,
  GET_ASSESSMENT_TEMPLATE_QUESTION_BY_SECTION_ID,
  GET_ASSESSMENT_TEMPLATE_QUESTION_BY_DIMENSION_ID,
  UPLOAD_PREPARE_QUESTIONS_TEMPLATE,
  DOWNLOAD_PREPARE_QUESTIONS_TEMPLATE,

  CURD_ASSESSMENT_TEMPLATE_QUESTION_MAIN,
  GET_ASSESSMENT_TEMPLATE_QUESTION_BY_TEMPLATE_ID_MAIN,

  GET_ASSESSMENT_TEMPLATE_QUESTION_BY_SECTION_ID_MAIN,
GET_ASSESSMENT_TEMPLATE_QUESTION_BY_DIMENSION_ID_MAIN,
GET_PREVIEW_BY_TEMPLATE_ID_CLIENT,
GET_PREVIEW_BY_TEMPLATE_ID_ASSESSOR
} from "../../utils/routes";

// ---------------------------------------------------------------------

export const createAssessmentTemplateQuestionMain = createAsyncThunk(
  "assessmentTemplateQuestion/createAssessmentTemplateQuestionMain",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        CURD_ASSESSMENT_TEMPLATE_QUESTION_MAIN,
        data,
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAssessmentTemplateQuestionMain = createAsyncThunk(
  "assessmentTemplateQuestion/getAssessmentTemplateQuestionMain",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        GET_ASSESSMENT_TEMPLATE_QUESTION_BY_TEMPLATE_ID_MAIN,
        data,
        { withCredentials: true }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPreviewByTemplateIdForClients = createAsyncThunk(
  "assessmentTemplate/getPreviewByTemplateIdForClients",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        GET_PREVIEW_BY_TEMPLATE_ID_CLIENT,
        data,
        { withCredentials: true }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPreviewByTemplateIdForAssessor = createAsyncThunk(
  "assessmentTemplate/getPreviewByTemplateIdForAssesor",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        GET_PREVIEW_BY_TEMPLATE_ID_ASSESSOR,
        data,
        { withCredentials: true }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAssessmentTemplateQuestionBySectionIdMain = createAsyncThunk(
  "assessmentTemplateQuestion/getAssessmentTemplateQuestionBySectionIdMain",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        GET_ASSESSMENT_TEMPLATE_QUESTION_BY_SECTION_ID_MAIN,
        data,
        { withCredentials: true }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAssessmentTemplateQuestionByDimensionIdMain = createAsyncThunk(
  "assessmentTemplateQuestion/getAssessmentTemplateQuestionByDimensionIdMain",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        GET_ASSESSMENT_TEMPLATE_QUESTION_BY_DIMENSION_ID_MAIN,
        data,
        { withCredentials: true }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAssessmentTemplateQuestionMain = createAsyncThunk(
  "assessmentTemplateQuestion/updateAssessmentTemplateQuestionMain",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        CURD_ASSESSMENT_TEMPLATE_QUESTION_MAIN,
        data,
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAssessmentTemplateQuestionMain = createAsyncThunk(
  "assessmentTemplateQuestion/deleteAssessmentTemplateQuestionMain",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_ASSESSMENT_TEMPLATE_QUESTION_MAIN, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// ----------------------------------------------------------------------------------------------
export const createAssessmentTemplateQuestion = createAsyncThunk(
  "assessmentTemplateQuestion/createAssessmentTemplateQuestion",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        CURD_ASSESSMENT_TEMPLATE_QUESTION,
        data,
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAssessmentTemplateQuestion = createAsyncThunk(
  "assessmentTemplateQuestion/getAssessmentTemplateQuestion",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        GET_ASSESSMENT_TEMPLATE_QUESTION_BY_TEMPLATE_ID,
        data,
        { withCredentials: true }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAssessmentTemplateQuestionBySectionId = createAsyncThunk(
  "assessmentTemplateQuestion/getAssessmentTemplateQuestionBySectionId",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        GET_ASSESSMENT_TEMPLATE_QUESTION_BY_SECTION_ID,
        data,
        { withCredentials: true }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getAssessmentTemplateQuestionByDimensionId = createAsyncThunk(
  "assessmentTemplateQuestion/getAssessmentTemplateQuestionByDimensionId",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        GET_ASSESSMENT_TEMPLATE_QUESTION_BY_DIMENSION_ID,
        data,
        { withCredentials: true }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);



export const updateAssessmentTemplateQuestion = createAsyncThunk(
  "assessmentTemplateQuestion/updateAssessmentTemplateQuestion",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        CURD_ASSESSMENT_TEMPLATE_QUESTION,
        data,
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAssessmentTemplateQuestion = createAsyncThunk(
  "assessmentTemplateQuestion/deleteAssessmentTemplateQuestion",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_ASSESSMENT_TEMPLATE_QUESTION, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const downloadPrepareQuestionsTemplate = createAsyncThunk(
  "assessmentTemplateQuestion/downloadPrepareQuestionsTemplete",
  async (data, { rejectWithValue }) => {
    try {
      await axios
        .get(DOWNLOAD_PREPARE_QUESTIONS_TEMPLATE, {
          // params: data,
          withCredentials: true,
          responseType: "blob",
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));

          const link = document.createElement("a");

          link.href = url;

          link.setAttribute("download", "AssessmentTemplate.xlsx");

          document.body.appendChild(link);

          link.click();
        });
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const uploadPrepareQuestionsTemplate = createAsyncThunk(
  "assessmentTemplateQuestion/uploadPrepareQuestionsTemplate",
  async (data, { rejectWithValue }) => {
    try {
      // console.log("upload assessment template slice");
      await axios
        .post(UPLOAD_PREPARE_QUESTIONS_TEMPLATE, data, {
          params: {
            projectInfoId: data.get("projectInfoId"),
            appInfoId: data.get("appInfoId"),
            moduleInfoId: data.get("moduleInfoId"),
          },
          withCredentials: true,
          responseType: "arraybuffer",
        })
        .then((res) => {
          if (res && res.data) {
            const blob = new Blob([res.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            const url = window.URL.createObjectURL(blob);

            const link = document.createElement("a");

            link.href = url;

            link.setAttribute(
              "download",
              "Assessment Template Upload AssessmentTemplate.xlsx"
            );

            document.body.appendChild(link);

            link.click();
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const prepareQuestionSlice = createSlice({
  name: "assessmentTemplateQuestion",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    assessmentTemplateQuestionData: [],
    assessmentTemplateData : [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createAssessmentTemplateQuestion.pending]: (state, action) => {
      state.loading = true;
    },
    [createAssessmentTemplateQuestion.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createAssessmentTemplateQuestion.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [createAssessmentTemplateQuestionMain.pending]: (state, action) => {
      state.loading = true;
    },
    [createAssessmentTemplateQuestionMain.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createAssessmentTemplateQuestionMain.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },


    [getAssessmentTemplateQuestion.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentTemplateQuestion.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentTemplateQuestionData = action?.payload?.data;
    },
    [getAssessmentTemplateQuestion.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getPreviewByTemplateIdForAssessor.pending]: (state, action) => {
      state.loading = true;
    },
    [getPreviewByTemplateIdForAssessor.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentTemplateData = action?.payload?.data;
    },
    [getPreviewByTemplateIdForAssessor.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },


    [getPreviewByTemplateIdForClients.pending]: (state, action) => {
      state.loading = true;
    },
    [getPreviewByTemplateIdForClients.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentTemplateData = action?.payload?.data;
    },
    [getPreviewByTemplateIdForClients.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },



    [getAssessmentTemplateQuestionMain.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentTemplateQuestionMain.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentTemplateQuestionData = action?.payload?.data;
    },
    [getAssessmentTemplateQuestionMain.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getAssessmentTemplateQuestionBySectionId.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentTemplateQuestionBySectionId.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentTemplateQuestionData = action?.payload?.data;
    },
    [getAssessmentTemplateQuestionBySectionId.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getAssessmentTemplateQuestionByDimensionId.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentTemplateQuestionByDimensionId.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentTemplateQuestionData = action?.payload?.data;
    },
    [getAssessmentTemplateQuestionByDimensionId.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getAssessmentTemplateQuestionBySectionIdMain.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentTemplateQuestionBySectionIdMain.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentTemplateQuestionData = action?.payload?.data;
    },
    [getAssessmentTemplateQuestionBySectionIdMain.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getAssessmentTemplateQuestionByDimensionIdMain.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentTemplateQuestionByDimensionIdMain.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentTemplateQuestionData = action?.payload?.data;
    },
    [getAssessmentTemplateQuestionByDimensionIdMain.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateAssessmentTemplateQuestion.pending]: (state, action) => {
      state.loading = true;
    },
    [updateAssessmentTemplateQuestion.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateAssessmentTemplateQuestion.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [updateAssessmentTemplateQuestionMain.pending]: (state, action) => {
      state.loading = true;
    },
    [updateAssessmentTemplateQuestionMain.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateAssessmentTemplateQuestionMain.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteAssessmentTemplateQuestion.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteAssessmentTemplateQuestion.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteAssessmentTemplateQuestion.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteAssessmentTemplateQuestionMain.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteAssessmentTemplateQuestionMain.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteAssessmentTemplateQuestionMain.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [uploadPrepareQuestionsTemplate.pending]: (state, action) => {
      state.loading = true;
    },
    [uploadPrepareQuestionsTemplate.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [uploadPrepareQuestionsTemplate.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [downloadPrepareQuestionsTemplate.pending]: (state, action) => {
      state.loading = true;
    },
    [downloadPrepareQuestionsTemplate.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [downloadPrepareQuestionsTemplate.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  prepareQuestionSlice.actions;
export default prepareQuestionSlice.reducer;
