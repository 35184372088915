
// regular expression for name text field it accepts
// alphabets, numbers, underScore( _ ), dash( - ) and spaces
// export const textChecker = RegExp(/^[a-z\d\-_\s]+$/i);

// export const textChecker = /^[a-zA-Z\d\s,&:_\-]+$/;

// export const textChecker = /^[a-zA-Z\d\s,&:_\-\(\)\+\-=@![]%]+$/
// export const textChecker = /^[a-zA-Z\d\s,&:_\-\(\)\+\-=@!\[\]%{}\/\\|?]+$/;
export const textChecker = /^[a-zA-Z\d\s,&:_\-\(\)\+\-=@.!*$%[\]{}\/\\|?";:$*']+$/;

// regular expression for comments, description textarea field it accepts
// alphabets, numbers, dash ( - ), underScore ( _ ), dollar ( $ ), fullStop ( . ), plus ( + ), single quote ( ' ), parenthesis (),
// comma ( , ), double quote ( "" ) and white space.
// export const textAreaChecker = RegExp(/^[a-z\d\-_$.+!*'(),/:&"\s]+$/i)
// export const textAreaChecker = /^[a-zA-Z\d\s,&:_\-.+$!*'():\/"&]+$/;

export const textAreaChecker = /^[a-zA-Z\d\s,&:_\-\(\)\+\-=@.!*$%[\]{}\/\\|?";:$*']+$/;

// export const textAreaCheckerWithoutQuotes = RegExp(/^[a-z\d\-_$.+!*(),&\s]+$/i)
export const textAreaCheckerWithoutQuotes = /^[a-z\d\-_$.+!*(),&\s?[\]@%+-=\\|]+$/i;

// export const bigTextAreaChecker = RegExp(/^[a-z\d\-_$.+!*'():/,&"\s]+$/i)
export const bigTextAreaChecker = /^[a-zA-Z\d\s,&:_\-\(\)\+\-=@.!\[\]%{}\/\\|?";:$*']+$/;

// export const textAreaChecker = /^[a-z\d\-_$.+!*'(),"\s]{0,255}$/i;

// regular expression for url field in menumanagement
// alphabets, numbers, dash ( - ), underScore ( _ ), forward slash ( / ) and white space
export const urlChecker = RegExp(/^[a-z\d\-_:./\s]+$/i)

export const numberChecker = /^[0-9].*\.*$/;
