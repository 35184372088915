import React from "react";
import { PageHeader, MyTextInput } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
// material ui
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Box,
  CardContent,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import { FormSubmitButton,CommonCurdPopUp } from "../../../../components";
import Notifier from "../../../../components/common/Notifier";
import ErrorNotifier from "../../../../components/common/ErrorNotifier";
// redux
import {
  updateQuestionRatingValue,
  getQuestionRatingValue,
  resetMessage,
  resetErrMessage,
} from "../../../../features/config/questionRatingValueSlice";

// --------------------------------------------------------------

const validationSchema = Yup.object({
  ratingName: Yup.string().required("Rating Name is required"),
  ratingValue: Yup.string().required("Rating Value is required"),
  comments: Yup.string(),
});

//   ------------------------------------------------------------------------------------------

const UpdateQuestionRatingValue = (props) => {
  const {  message, errMessage, selectedData } = useSelector(
    (state) => ({ ...state.questionRatingValue })
  );

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      ratingName: selectedData?.ratingName,
      ratingValue: selectedData?.ratingValue,
      comments: selectedData?.comments,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        updateQuestionRatingValue({
          id: selectedData.id,
          ratingName: values.ratingName,
          ratingValue: Number(values.ratingValue),
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.close();
            dispatch(getQuestionRatingValue());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  return (
    <>

{props?.showEdit && <CommonCurdPopUp
        heading={`Update Rating Value`}
        mediumScreen={true}
        close={props.close}
      >
        <Grid container >
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11} >
              <MyTextInput
                label="Rating Name"
                type="text"
                name="ratingName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="ratingName"
                value={formik.values.ratingName}
                onChange={formik.handleChange}
                error={
                  formik.touched.ratingName &&
                  Boolean(formik.errors.ratingName)
                }
                helperText={
                  formik.touched.ratingName &&
                  formik.errors.ratingName
                }
              />
              <MyTextInput
                label="Rating Value"
                type="text"
                name="ratingValue"
                required={true}
                fullWidth={true}
                margin="normal"
                id="ratingValue"
                value={formik.values.ratingValue}
                onChange={formik.handleChange}
                error={
                  formik.touched.ratingValue &&
                  Boolean(formik.errors.ratingValue)
                }
                helperText={
                  formik.touched.ratingValue &&
                  formik.errors.ratingValue
                }
              />
              <MyTextInput
                label="Comments"
                type="text"
                name="comments"
                fullWidth={true}
                margin="normal"
                id="comments"
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={
                  formik.touched.comments && Boolean(formik.errors.comments)
                }
                helperText={formik.touched.comments && formik.errors.comments}
              />
               </Grid>
          <Box style={{ width: "100%", textAlign: "center" }} >

            <FormSubmitButton onClick={formik?.handleSubmit} type="submit" variant="contained">
              Submit
            </FormSubmitButton>
          </Box>
        </Grid>

      </CommonCurdPopUp>}
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    
    </>
  );
};

export default UpdateQuestionRatingValue;
