import { Box, Card, CardContent, CardHeader, IconButton } from "@mui/material";
import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Cancel, Delete } from "@mui/icons-material";
import { MuiTooltip } from "..";
import { ModalPopUp } from "..";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { ASSESSMENT_BASE_URL } from "../../utils/config";
// -------------------------------------------------------------------------------------
const CommonCrudPopup = ({
  bigScreen,
  mediumScreen,
  smallScreen,
  largeScreen,
  customWidth,
  heading,
  close,
  children,
  veryBigScreen,
  aiScreen,
  isDownload,
  TARGET_URL,
  targetId,
  downloadFunc,
  ...props
}) => {
  console.log(targetId)
  return (
    <ModalPopUp open={true}>
      <Card
        style={{
          width: largeScreen
            ? "76.5vw"
            : mediumScreen
              ? "60vw"
              : smallScreen
                ? "45vw"
                : customWidth || "76.5vw",
          marginTop: "16.5vh",
          marginLeft: smallScreen ? "32vw" : mediumScreen ? "27vw" : "15vw",
          position: "relative",
        }}
        className="res_update_uc"
      >
        {(heading || close) && (
          <CardHeader
            title={
              heading && (
                <h5
                  style={
                    aiScreen
                      ? {
                        backgroundColor: "cadetblue",
                        color: "white",
                        // paddingBottom: "0.5rem",
                        // marginTop: "0.6rem",

                      }
                      : {}
                  }
                  className="center bold pt-2 header-font-size"
                >
                  {heading}
                </h5>
              )
            }
            action={
              close && (
                <>

                  <div
                    style={{
                      position: "absolute",
                      top: "-0.3rem",
                      right: "0rem",
                    }}
                  >
                    <MuiTooltip title="close">
                      <IconButton aria-label="cancel" onClick={() => close()}>
                        <CancelIcon color="red" />
                      </IconButton>
                    </MuiTooltip>
                  </div>

                  {isDownload && <div
                    style={{
                      position: "absolute",
                      top: "2rem",
                      right: "3rem",
                    }}
                  >
                    <MuiTooltip title="Download">
                      {/* <a
                        href={`${TARGET_URL}/${targetId}`}
                        target="_blank"
                      > */}
                      <IconButton aria-label="cancel" onClick={downloadFunc}>
                        <CloudDownloadIcon />
                      </IconButton>
                      {/* </a> */}
                    </MuiTooltip>
                  </div>}
                </>
              )
            }
            sx={{
              paddingBottom: "0px!important",
            }}
          />

        )}


        <CardContent style={{ maxHeight: "85vh", marginTop: "-2rem", overflow: "auto", }}>
          {children}
        </CardContent>
      </Card>
    </ModalPopUp>
  );
};

export default CommonCrudPopup;
