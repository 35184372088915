import React, {  useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PageHeader } from "@excoleadershipui/pionweblib";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// material ui 
import {Stack, Box} from '@mui/material'
// components
import Layout from '../../../../layout/Layout'
import { MuiDataGrid, GridCellExpand } from "../../../../components";

// redux 
import { getRoles, rowSelect } from '../../../../features/iam/role_management/rolesSlice';

// --------------------------------------------------------------------------------
const AccessPermissions = () => {
const location = useLocation()
  const dispatch = useDispatch();
  const {  rolesData} = useSelector(
    (state) => ({ ...state.roles })
  );
  let Navigate = useNavigate()

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const data = {
    rows: rolesData,
    columns: [
      { field: "roleCode", headerName: "Role Code", width: 100 },
      {
        field: "roleName",
        headerName: "Role Name",
        width: 250,
       renderCell: renderCellExpand
      },
      {
        field: "roleDescription",
        headerName: "Role Description",
        width: 600,
       renderCell: renderCellExpand
      },
    ],
  };

  useEffect(() => {
    dispatch(getRoles());
  }, []);

  return (
    <Layout>

<Stack flexDirection="row" justifyContent="space-between">
            <Box sx={{ width: "100%" }}>
              <PageHeader variant="pageHeader">Roles</PageHeader>
            </Box>
            <Link to="/exco/iam/roles" state={{l3Menu: location?.state?.l3Menu, permissions: location?.state?.permissions}}>
              <CancelIcon color="red" />
            </Link>
          </Stack>
          <MuiDataGrid
            
            toolbarFilter = {true}
           
            data={data}
            select={(data) => {
              dispatch(rowSelect(data))
              Navigate("/exco/iam/menu-access-permissions", {state: {l3Menu: location?.state?.l3Menu, permissions: location?.state?.permissions}})
            }}
          />
    </Layout>
  )
}

export default AccessPermissions