import React, { useState, useEffect } from "react";
// components
import Layout from "../../layout/Layout";
//mui components
import { Grid, Typography, TextField, IconButton } from "@mui/material";
//common Components
import { FormSubmitButton, MuiAutocomplete, MuiTooltip } from "../../components";
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell } from "recharts";

//mui Icons
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { ImSearch } from "react-icons/im";

import { useFormik } from "formik";
import * as Yup from "yup";

// import Funnel from "./funnel";
import NewBarGraph from "./NewBarGraph";
import ViewAssessmentStatusDetails from "./ViewAssessmentStatusDetails";

//redux
import { useDispatch, useSelector } from "react-redux";
import { getCustomer } from "../../features/customer/customerSlice";
import { getAccountById } from "../../features/customer/accountSlice";
import { getAllStatusForAllCustomers, getStatusForCustomerAccounts, getStatusDetailsForCustomer, getClientDataForCustomerAccounts, getAllStatusForAllCustomersGraph } from "../../features/dashboard/dashboardSlice";
import { CoPresentOutlined } from "@mui/icons-material";

import generatePDF from "./GeneratePdf";


// ----------------------------------------------------

const Dashboard = () => {

  const [showStatusDetail, setShowStatusDetails] = useState(false);
  const [showStatusData, setShowsStatusData] = useState(false);
  const [custName, setCustName] = useState("")
  const [accountName, setAccountName] = useState("")
  const [data01, setData01] = useState([]);
  const [statusTracking, setStatusTracking] = useState("")
  const dispatch = useDispatch()
  const { customersData, } =
    useSelector((state) => ({ ...state.customer }));
  const { accountIdData } = useSelector((state) => ({ ...state?.account }));
  const { customerStatusData } = useSelector((state) => ({ ...state?.dashboard }));

  const dataArrangementFunction = (data, statusKey, countKey, colorKey, idKey) => {

    return data.length > 0
      ? data.map((item) => {
        return {
          name: item[statusKey],
          value: Number(item[countKey]),
          backgroundColor: item[colorKey],
          id: item[idKey] ?? "",
        };
      })
      : [];
  };


  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    value,
    ...props
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {/* {`${(percent * 100).toFixed(0)}%`} */}
        {value <= 0 ? "" : value}
      </text>
    );
  };


  const PieChartWrapper = ({ label, show, showData, fill }) => {

    // let data = [
    //   { name: "New", value: 3 },
    //   { name: "Completed", value: 4 },
    //   { name: "Pending", value: 5 },
    //   { name: "Hold", value: 6 },
    // ];


    // Function to generate gradient IDs based on index
    const createGradientId = (index) => `gradient3D-${index}`;

    return (
      // <div className="graphChild-col-1 box_width" style={{ width: "100%" }}>
      //   <div style={{ display: "flex", justifyContent: "center" }}>
      //     <span style={{ alignSelf: "flex-start", fontSize: "0.80rem", marginTop: "0.5rem", fontWeight: "bold" }}>
      //       {label}
      //     </span>
      //   </div>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart aria-label={label}>
          {/* <defs>
            {data.map((entry, index) => (
               <linearGradient key={index} id={createGradientId(index)} x1="0%" y1="0%" x2="100%" y2="100%">
               <stop offset="0%" stopColor={entry?.backgroundColor ?? "#FF4500"} stopOpacity={0.9} />
               <stop offset="100%" stopColor={ entry?.backgroundColor ?? "#FF4500"} stopOpacity={0.9} />
             </linearGradient>
            ))}
          </defs> */}
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={data01}
            cx="50%"
            cy="42%"
            outerRadius={150}
            fill="#8884d8"
            labelLine={false}
            label={renderCustomizedLabel}
          >
            {data01.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                onClick={() => {
                  setStatusTracking(entry?.name)
                  dispatch(getStatusDetailsForCustomer({
                    status: entry?.name, customerId: custName?.id,
                    accountId: accountName?.id,
                    fromDate: formik?.values?.startDate,
                    endDate: formik?.values?.endDate
                  }))
                  setShowsStatusData(entry?.id ? entry?.id : entry?.name);
                  setShowStatusDetails(true);
                }}
                style={{
                  // filter: 'drop-shadow(1px 1px 1px rgba(0,0,0,0.5))', // Shadow effect
                }}
                // fill={entry?.backgroundColor ?? "#FDD017"}
                fill={entry?.backgroundColor ?? "#FDD017"}
              // fill={`url(#${createGradientId(index)})`}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
      // </div>
    )
  };

  // const PieChartContainer = () => (
  //   <>
  //     {[
  //       { label: "Assessment Status", show: setShowMilestone, showData: setShowMilestoneData, fill: "milestoneStatusColor" },

  //     ].map((chart, index) => (
  //       <Grid item md={2.666666} xs={12} xl={2.666666} lg={2.666666} sm={12} key={index}>



  //       </Grid>
  //     ))}
  //   </>
  // );

  const today = new Date();


  // Calculate the date three months before today
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(today.getMonth() - 3);


  const formik = useFormik({

    initialValues: {
      startDate: threeMonthsAgo.toISOString().split('T')[0],
      endDate: today.toISOString().split('T')[0],
    },
    enableReinitialize: true,
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!custName && !accountName) {
        dispatch(getAllStatusForAllCustomers({ fromDate: values?.startDate, endDate: values?.endDate }));
        dispatch(getAllStatusForAllCustomersGraph({ fromDate: values?.startDate, endDate: values?.endDate }))
      }
      else {
        dispatch(getStatusForCustomerAccounts(
          {
            fromDate: values?.startDate,
            endDate: values?.endDate,
            customerId: custName ? custName?.id : null,
            accountId: accountName ? accountName?.id : null
          }))
        dispatch(getClientDataForCustomerAccounts(
          {
            fromDate: values?.startDate,
            endDate: values?.endDate,
            customerId: custName ? custName?.id : null,
            accountId: accountName ? accountName?.id : null
          }))
      }



    },
  });

  useEffect(() => {
    dispatch(getCustomer())
    dispatch(getAllStatusForAllCustomers())
    dispatch(getAllStatusForAllCustomersGraph())
  }, [])

  useEffect(() => {

    setData01(
      dataArrangementFunction(
        customerStatusData,
        "assessmentStatusName",
        "assessmentStatusCount",
        "assessmentStatusColor",
      )
    );
    return () => { };
  }, [customerStatusData]);






  const onChangeCustomerHandle = (value) => {
    setCustName(value)

  }
  const onChangeAccountHandle = (value) => {
    setAccountName(value)

  }

  const handleResetDate = () => {
    formik.resetForm()

    if (custName || accountName) {
      dispatch(
        getStatusForCustomerAccounts({
          customerId: custName?.id,
          accountId: accountName?.id,
        })
      );
      dispatch(
        getClientDataForCustomerAccounts({
          customerId: custName?.id,
          accountId: accountName?.id,
        })
      );
    }
    else {
      dispatch(getAllStatusForAllCustomers());
      dispatch(getAllStatusForAllCustomersGraph());
    }


  }



  return (
    <Layout>
      <Grid container spacing={2} alignItems="center" mt={1}>
        {/* Left Section: Customers and Accounts */}
        <Grid item xs={12} sm={12} md={6} container spacing={2} justifyContent="flex-start">
          {/* Customer Autocomplete */}
          <Grid item xs={12} sm={6} md={5}>
            <MuiAutocomplete
              name="customerName"
              options={customersData?.length > 0 ? customersData : []}
              value={custName}
              setValue={(name, newValue) => {
                onChangeCustomerHandle(newValue);
                setAccountName("")
                dispatch(getAccountById({ assessmentCustomerId: newValue?.id }));
                dispatch(getStatusForCustomerAccounts({ customerId: newValue?.id }));
                dispatch(getClientDataForCustomerAccounts({ customerId: newValue?.id }));

                if (!newValue) {
                  dispatch(getAllStatusForAllCustomers());
                  dispatch(getAllStatusForAllCustomersGraph());
                }
              }}
              optionValue="customerName"
              label="Customers"
              size="small"
              sx={{ width: "100%" }} // Full width for responsiveness
            />
          </Grid>

          {/* Account Autocomplete */}
          <Grid item xs={12} sm={6} md={5}>
            <MuiAutocomplete
              name="accountName"
              options={custName ? (accountIdData?.length > 0 ? accountIdData : []) : []}
              value={accountName}
              setValue={(name, newValue) => {
                onChangeAccountHandle(newValue);
                dispatch(
                  getStatusForCustomerAccounts({
                    customerId: custName?.id,
                    accountId: newValue?.id,
                  })
                );
                dispatch(
                  getClientDataForCustomerAccounts({
                    customerId: custName?.id,
                    accountId: newValue?.id,
                  })
                );
              }}
              optionValue="accountName"
              label="Accounts"
              size="small"
              sx={{ width: "100%" }} // Full width for responsiveness
            />
          </Grid>
        </Grid>

        {/* Right Section: Date Pickers and Icons */}
        <Grid item xs={12} sm={12} md={6} container alignItems="center" justifyContent="flex-end" spacing={2}>
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              label="From Date"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              type="date"
              name="startDate"
              value={formik?.values?.startDate}
              onChange={formik.handleChange("startDate")}
              sx={{ width: "100%" }}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <TextField
              label="To Date"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              type="date"
              name="endDate"
              value={formik?.values?.endDate}
              onChange={formik.handleChange("endDate")}
              sx={{ width: "100%" }}
            />
          </Grid>

          {/* Search, Download, and Reset Icons */}
          <Grid item xs={12} sm={4} md={3} container justifyContent="flex-start" alignItems="center"   >
            <MuiTooltip title="Search By Date">

              <IconButton
                // data-placement="top"
                onClick={formik.handleSubmit}
                style={{
                  color: "#0056b3",
                  cursor: "pointer",
                  fontSize: "1rem",


                }}  >



                <ImSearch />
              </IconButton>

            </MuiTooltip>

            {/* <MuiTooltip title="Download">
              <IconButton onClick={generatePDF} style={{



              }}  >
                <CloudDownloadIcon />
              </IconButton>
            </MuiTooltip> */}

            <MuiTooltip title="Reset Date">
              <IconButton style={{




              }} color="primary" onClick={handleResetDate} >
                <RestartAltIcon />
              </IconButton>
            </MuiTooltip>
          </Grid>
        </Grid>
      </Grid>


      <Grid mt={2} container>
        <Grid sx={{ height: "25rem" }} item md={6}>
          <Grid item md={12} lg={12} sm={12} >
            <Typography sx={{ textAlign: "center", fontFamily: "dinBold sans-serif", fontSize: "1.5rem" }}>Assessment Status</Typography>
          </Grid>

          <PieChartWrapper />
          {showStatusDetail && (
            <ViewAssessmentStatusDetails
              downloadData={{
                fromDate: formik?.values?.startDate,
                endDate: formik?.values?.endDate,
                customerId: custName?.id,
                accountId: accountName?.id,
                status: showStatusData
              }
              }
              open={showStatusDetail}
              statusTracking={statusTracking}
              data={{
                status: showStatusData,
                // from_date: formatDate(startDate),
                // to_date: formatDate(endDate),
              }}
              close={() => setShowStatusDetails(false)}
            />
          )}

        </Grid>

        <Grid container sx={{ height: "25rem" }} item md={6}>
          <Grid item md={12} lg={12} sm={12} >
            <Typography sx={{ textAlign: "center", fontFamily: "dinBold sans-serif", fontSize: "1.5rem" }}>Assessment Progress</Typography>
          </Grid>
          <NewBarGraph
            custName={custName}
            accountName={accountName}
            date={{ fromdate: formik.values?.startDate, toDate: formik?.values?.endDate }} />

        </Grid>

      </Grid>






    </Layout>

  );
};

export default Dashboard;





