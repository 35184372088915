import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { Link, useLocation } from "react-router-dom";

// material ui

// icons
import SummarizeIcon from "@mui/icons-material/Summarize";
// components
import Layout from "../../layout/Layout";
import {
  MuiDataGrid,
  GridCellExpand,
  FormTextField as MyTextInput,
  Notifier,
  ErrorNotifier,
  MuiTooltip,
} from "../../components";
// redux
import {
  getAssessmentTemplate,
  getReportListById,
  
} from "../../features/prepare_assessments/prepareAssessmentSlice";
import { getCustomer } from "../../features/customer/customerSlice";



// -------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ------------------------------------------------------------------------

const AnalyzeAndReport = (props) => {
  const dispatch = useDispatch();

  const location = useLocation();

  const { message, errMessage, assessmentTemplateData } = useSelector(
    (state) => ({ ...state.prepareAssessment })
  );

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const data = {
    rows:
      assessmentTemplateData.length > 0 &&
      assessmentTemplateData.filter((value) => value.status === "Completed"),
    columns: [
      {
        field: "assessmentTemplateName",
        headerName: "Assessment Template Name",
        width: 250,
        renderCell: renderCellExpand,
      },

      {
        field: "assessmentTemplateDisplayName",
        headerName: "Template Display Name",
        width: 250,
        renderCell: renderCellExpand,
      },

      {
        field: "config",
        headerName: "View Reports",
        type: "actions",
        width: 130,
        renderCell: (params) => {
          return (
            params?.row?.assessmentTracking &&
            (params?.row?.assessmentTracking?.assessmentStatus ===
              "Published" ||
              params?.row?.assessmentTracking?.assessmentStatus ===
                "Completed") && (
              <MuiTooltip title="View Report" placement="bottom">
                <Link
                  to="/exco/assessment/report"
                  state={{
                    trackingSelectedData: params?.row,
                    permissions: location?.state?.permissions,
                  }}
                >
                  <SummarizeIcon style={{ color: "#808080" }} />
                </Link>
              </MuiTooltip>
            )
          );
        },
      },

      {
        field: "detailedDescription",
        headerName: "Description",
        width: 350,
        renderCell: renderCellExpand,
      },
     
    ],
  };

  useEffect(() => {
    dispatch(getAssessmentTemplate());
    dispatch(getCustomer())
  
  }, []);

  return (
    <Layout>
      <>
        <PageHeader variant="pageHeader">Assessment Response Report</PageHeader>
        <MuiDataGrid
         getCallBackCustomer={(data)=> getReportListById({assessmentCustomerId : data})}
          getCallBackOne={getAssessmentTemplate}
          getCallBackTwo={(data)=> getReportListById({assessmentCustomerAccountId: data})}
          // getCallBackargs = {defaultCallBack}

         isAccount = {true}
         isCustomer = {true}
            
          toolbarColumn={true}
          // toolbarFilter={true}
          toolbarDensity={true}
          toolbarExport={true}
          data={data}
        />
      </>

      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default AnalyzeAndReport;
