import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useLocation } from "react-router-dom";
// material ui
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
  IconButton,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
// components
import {
  MuiDataGrid,
  GridCellExpand,
  ModalPopUp,
  FormSubmitButton,
  FormTextField as MyTextInput,
  Notifier,
  ErrorNotifier,
  ModalCloseBtn,
  ConfirmDialog,
  MuiTooltip,
  PageHeader,
  CommonCurdPopUp
} from "../../../../../components";
// redux
// import {
//   createAssessmentFrameworkSectionConfig,
//   getAssessmentFrameworkSectionConfig,
//    updateAssessmentFrameworkSectionConfig,
//   deleteAssessmentFrameworkSectionConfig,
//   rowSelect,
//   sectionRowSelect,
//   resetMessage,
//   resetErrMessage,
// } from "../../../../../features/config/assessmentTemplateSectionConfigSlice";

import {
  createAssessmentFrameworkSectionConfig,
  getAssessmentFrameworkSectionConfig,
  updateAssessmentFrameworkSectionConfig,
  deleteAssessmentFrameworkSectionConfig,
  rowSelect,
  sectionRowSelect,
  resetMessage,
  resetErrMessage,


} from "../../../../../features/config/assessmentFrameworkSectionConfigSlice";


import { textAreaChecker, textChecker } from "../../../../../components/common/form_validation/regularExpressions";
import { textAreaFieldErr, textFieldErr } from "../../../../../components/common/form_validation/validationErrMessages";

// ------------------------------------------------------------------------------

const validationSchema = Yup.object({
  sectionName: Yup.string().required("Section Name is required").matches(textChecker, textFieldErr),
  sectionNo: Yup.number().required("Section Number is required"),
  comments: Yup.string().matches(textAreaChecker, textAreaFieldErr),
});

const editValidationSchema = Yup.object({
  sectionName: Yup.string().required("Section Name is required").matches(textChecker, textFieldErr),
  sectionNo: Yup.number().required("Section Number is required"),
  comments: Yup.string().nullable().matches(textAreaChecker, textAreaFieldErr),
});

// -------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  rootbox: {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: "56.25rem !important",
    [theme.breakpoints.down("sm")]: {
      width: "20rem !important",
      height: "60% !important",
      display: "block !important",
      overflow: "scroll !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "24.375rem  !important",
      height: "34% !important",
      display: "block !important",
      overflow: "scroll !important",
      margin: "-3.188rem",
      marginTop: "-3.75rem  !important",
      marginLeft: "-3.563rem",
      padding: "2.125rem !important",
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: "1.5rem !important",
    paddingTop: "1rem !important",
    paddingLeft: "2rem !important",
    paddingRight: "2rem !important",
    paddingBottom: "1.5rem",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },

  text_responsive: {
    width: "50rem",
    [theme.breakpoints.down("md")]: {
      width: "17rem",
    },
  },

  cutoff_box: {
    [theme.breakpoints.down("sm")]: {
      width: "16.875rem !important",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      width: "20.875rem !important",
      marginLeft: "-7.313rem",
      marginTop: "-11.313rem",
    },
  },
  responsive_field: {
    [theme.breakpoints.down("sm")]: {
      width: "15rem !important",
    },
  },
  responsive_field: {
    [theme.breakpoints.down("md")]: {
      width: "15rem !important",
    },
  },
}));

const AssessmentTemplateSectionConfig = (props) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const dispatch = useDispatch();

  const location = useLocation();

  const {
    assessmentFrameworkSectionConfigData,
    message,
    errMessage,
    selectedData,
  } = useSelector((state) => ({ ...state.assessmentFrameworkSectionConfig }));

  const { permissionsData } = useSelector((state) => ({
    ...state.permissions,
  }));

  const showAddModel = () => {
    setShowAdd(!showAdd);
  };

  const closeModel = () => {
    setShowAdd(false);
    formik.resetForm({
      values: {
        sectionName: "",
        sectionNo: "",
        comments: "",
      },
    });
  };

  const showEditModal = () => {
    setShowEdit(true);
  };

  const closeEditModal = () => {
    setShowEdit(false);
  };

  const showDelete = () =>
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => handleDeleteCustomer(),
    });

  const handleDeleteCustomer = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deleteAssessmentFrameworkSectionConfig(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(
            getAssessmentFrameworkSectionConfig({
              frameworkTemplateId: location?.state?.templateSelectedData?.id,
            })
          );
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const classes = useStyles();
  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }
  const [sortModel, setSortModel] = useState([
    { field: "sectionNo", sort: "asc" },
  ]);

  const data = {
    rows: assessmentFrameworkSectionConfigData.length > 0 ? assessmentFrameworkSectionConfigData : [],
    columns: [
      {
        field: "sectionName",
        headerName: "Section",
        width: 250,
        renderCell: renderCellExpand,
      },

      {
        field: "sectionNo",
        headerName: "Section No.",
        width: 100,
        renderCell: renderCellExpand,
        sortable: true,
      },
      // {
      //   field: "prepareQuestion",

      //   type: "actions",
      //   width: 50,
      //   renderCell: (params) => {
      //     return (
      //       <MuiTooltip title="Questions" placement="right">
      //         { location?.state?.
      //     permissions?.find(
      //           (e) =>
      //             e.applicationService?.name?.split(".")[
      //               e?.applicationService?.name?.split(".")?.length - 1
      //             ] === "getAssessQnBySecId"
      //         ) && (

      //         <Link
      //          to="/exco/config/assessment-template/section-dimension/section-questions"
      //          state={{
      //           templateSelectedData: location?.state?.templateSelectedData,
      //           permissions:location?.state?.permissions,
      //           data: location.data,
      //           sectionSelectedData: params?.row,
      //           l3Menu: location?.state?.l3Menu,

      //          }}

      //         >
      //           <IconButton sx={{ p: 0 }}>
      //             <HelpIcon style={{ color: "#808080" }} />
      //           </IconButton>

      //         </Link>
      //          )}
      //       </MuiTooltip>
      //     );
      //   },
      // },
      {
        field: "comments",
        headerName: "Comments",
        width: 350,
        renderCell: renderCellExpand,
      },
    ],
  };



  const formik = useFormik({
    reset: true,
    initialValues: {
      sectionName: "",
      sectionNo: "",
      comments: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values, onSubmitProps) => {
      dispatch(
        createAssessmentFrameworkSectionConfig({
          // frameworkTemplateId: location?.templateSelectedData?.id,
          frameworkTemplateId: location?.state?.templateSelectedData?.id,
          sectionName: values?.sectionName,
          sectionNo: values?.sectionNo,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeModel();
            dispatch(
              getAssessmentFrameworkSectionConfig({
                frameworkTemplateId: location?.state?.templateSelectedData?.id,
              })
            );
            onSubmitProps.resetForm();
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      sectionName: selectedData?.sectionName,
      sectionNo: selectedData?.sectionNo,
      comments: selectedData?.comments,
    },

    validationSchema: editValidationSchema,
    onSubmit: (values) => {
      dispatch(
        updateAssessmentFrameworkSectionConfig({
          id: selectedData?.id,
          frameworkTemplateId: location?.state?.templateSelectedData?.id,
          sectionName: values?.sectionName,
          sectionNo: values?.sectionNo,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeEditModal();
            dispatch(
              getAssessmentFrameworkSectionConfig({
                frameworkTemplateId: location?.state?.templateSelectedData?.id,
              })
            );
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    let data = {
      frameworkTemplateId: location?.state?.templateSelectedData?.id,
    };

    if (Boolean(data.frameworkTemplateId)) {
      dispatch(getAssessmentFrameworkSectionConfig(data)).then((res) => {
        if (res.payload.data?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    }
  }, []);

  console.log(location?.state?.permissions)

  return (
    <>
      <PageHeader variant="pageThirdHeader">Sections</PageHeader>
      <>
        <MuiDataGrid
          isMuidatagridSmall={true}
          setShowAdd={
            // location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "createAssessSec") &&
            showAddModel
          }
          setShowEdit={
            // location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "updateAssessSec") &&
            showEditModal
          }
          setShowDelete={
            // location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "deleteAssessSec") &&
            showDelete
          }
          // setShowAdd={
          //   location?.serviceAccessData?.userRoleL3ServicePermission?.find(
          //     (e) =>
          //       e.applicationService?.name?.split(".")[
          //         e?.applicationService?.name?.split(".")?.length - 1
          //       ] === "createAssesSec"
          //   ) && showAddModel
          // }
          // setShowEdit={
          //   location?.serviceAccessData?.userRoleL3ServicePermission?.find(
          //     (e) =>
          //       e.applicationService?.name?.split(".")[
          //         e?.applicationService?.name?.split(".")?.length - 1
          //       ] === "updateAssesSec"
          //   ) && showEditModal
          // }
          // setShowDelete={
          //   location?.serviceAccessData?.userRoleL3ServicePermission?.find(
          //     (e) =>
          //       e.applicationService?.name?.split(".")[
          //         e?.applicationService?.name?.split(".")?.length - 1
          //       ] === "deleteAssesSec"
          //   ) && showDelete
          // }
          // setShowAdd={showAddModel}
          // setShowEdit={showEditModal}
          // setShowDelete={showDelete
          // }

          toolbarColumn={true}
          // toolbarFilter={true}
          // toolbarDensity = {true}
          toolbarExport={true}
          data={data}
          select={(data) => {
            dispatch(rowSelect(data));
            dispatch(sectionRowSelect(data));
          }}
          // For Sorting
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        />
      </>


      {/* <ModalPopUp
        open={showAdd}
        handleClose={closeModel}
        closeModel={closeModel}
      >
        <Box
         
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <PageHeader>Add Section</PageHeader>
          <ModalCloseBtn close={closeModel} />
          <div className={classes.text_responsive}> */}

      {showAdd && <CommonCurdPopUp
        heading="Add Section"
        mediumScreen={true}
        close={
          closeModel
        }
      >
        <Grid container >
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={11}
            // margin="auto"
            container
           
          >
            <Grid xs={12} sm={12} md={9} >
              <MyTextInput
                label="Section Name"
                type="text"
                name="sectionName"
                required={true}

                fullWidth={true}
                margin="normal"
                id="sectionName"
                value={formik.values.sectionName}
                onChange={formik.handleChange}
                tabIndex={1}
                error={
                  formik.touched.sectionName &&
                  Boolean(formik.errors.sectionName)
                }
                helperText={
                  formik.touched.sectionName && formik.errors.sectionName
                }
              />
            </Grid>
            <Grid xs={12} sm={12} md={3} sx={{paddingLeft:"0.5rem"}}>
              <MyTextInput
                label="Section No."
                type="number"
                name="sectionNo"
                required={true}
                fullWidth={true}
                margin="normal"
                id="sectionNo"
                value={formik.values.sectionNo}
                // onChange={formik.handleChange}
                onChange={(e) => {
                  const inputValue = e.target.value;

                  // Use a regular expression to check if the input is a number
                  if (/^\d*$/.test(inputValue)) {
                    // If it's a number, update the field value
                    formik.handleChange(e);
                  }
                  // You can also handle invalid input here if needed
                }}
                tabIndex={1}
                error={
                  formik.touched.sectionNo &&
                  Boolean(formik.errors.sectionNo)
                }
                helperText={
                  formik.touched.sectionNo && formik.errors.sectionNo
                }
              />
            </Grid>

            <MyTextInput
              label="Comments"
              type="text"
              name="comments"
              fullWidth={true}
              multiline={true}
              maxRows={5}
              onBlur={formik?.handleBlur}
              margin="normal"
              id="comments"
              value={formik.values.comments}
              onChange={formik.handleChange}
              error={
                formik.touched.comments && Boolean(formik.errors.comments)
              }
              helperText={formik.touched.comments && formik.errors.comments}
            />
            <Box style={{ width: "100%", textAlign: "center" }} mt={2}>

              <FormSubmitButton onClick={formik?.handleSubmit} type="submit" variant="contained">
                Submit
              </FormSubmitButton>
            </Box>
          </Grid>
        </Grid>

      </CommonCurdPopUp>}

      {/* </div> */}

      {/* <FormSubmitButton type="submit" variant="contained">
        Submit
      </FormSubmitButton> */}
      {/* </Box>
      </ModalPopUp> */}

      {showEdit && <CommonCurdPopUp
        heading="Update Section"
        mediumScreen={true}
        close={
          () => setShowEdit(false)
        }
      >
        <Grid container>
        <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11} container>
            <Grid xs={12} sm={12} md={9} >
              <MyTextInput
                label="Section Name"
                type="text"
                name="sectionName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="sectionName"
                defaultValue={formikEdit.values.sectionName}
                value={formikEdit.values.sectionName}
                onChange={formikEdit.handleChange}
                error={
                  formikEdit.touched.sectionName &&
                  Boolean(formikEdit.errors.sectionName)
                }
                helperText={
                  formikEdit.touched.sectionName &&
                  formikEdit.errors.sectionName
                }
              />
            </Grid>
            <Grid xs={12} sm={12} md={3} sx={{paddingLeft:"0.5rem"}}>
              <MyTextInput
                label="Section No."
                type="number"
                name="sectionNo"
                required={true}
                fullWidth={true}
                margin="normal"
                id="sectionNo"
                defaultValue={formikEdit.values.sectionNo}
                value={formikEdit.values.sectionNo}
                // onChange={formikEdit.handleChange}
                onChange={(e) => {
                  const inputValue = e.target.value;

                  // Use a regular expression to check if the input is a number
                  if (/^\d*$/.test(inputValue)) {
                    // If it's a number, update the field value
                    formikEdit.handleChange(e);
                  }
                  // You can also handle invalid input here if needed
                }}
                error={
                  formikEdit.touched.sectionNo &&
                  Boolean(formikEdit.errors.sectionNo)
                }
                helperText={
                  formikEdit.touched.sectionNo &&
                  formikEdit.errors.sectionNo
                }
              />
            </Grid>

            <MyTextInput
              label="Comments"
              type="comments"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              multiline={true}
              maxRows={5}
              value={formikEdit.values.comments}
              onChange={formikEdit.handleChange}
              error={
                formikEdit.touched.comments &&
                Boolean(formikEdit.errors.comments)
              }
              helperText={
                formikEdit.touched.comments && formikEdit.errors.comments
              }
            />

            <Box style={{ width: "100%", textAlign: "center" }} mt={2}>

              <FormSubmitButton onClick={formikEdit?.handleSubmit} type="submit" variant="contained">
                Submit
              </FormSubmitButton>
            </Box>
          </Grid>
        </Grid>


      </CommonCurdPopUp>}


      {/* <ModalPopUp
        open={showEdit}
        handleClose={() => setShowEdit(false)}
        closeModel={() => setShowEdit(false)}
      >
        <Box
         
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formikEdit.handleSubmit}
        >
          <PageHeader>Update Section</PageHeader>
          <ModalCloseBtn close={() => setShowEdit(false)} />
          <div className={classes.text_responsive}> */}


      {/* </div> */}

      {/* <FormSubmitButton type="submit" variant="contained">
        Submit
      </FormSubmitButton>
    </Box >
      </ModalPopUp > */}

  <ConfirmDialog
    confirmDialog={confirmDialog}
    setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
  />

{ errMessage && <ErrorNotifier message={errMessage} /> }
{ message && <Notifier message={message} /> }
    </>
  );
};

export default AssessmentTemplateSectionConfig;
