import React, { useEffect, useState } from 'react'
import { PageHeader } from '@excoleadershipui/pionweblib'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { continents } from "countries-list";
// material ui
import {Stack, Box, Grid, Paper, Input, IconButton, Avatar, ListItem, ListItemText, } from '@mui/material'
import { makeStyles } from "@mui/styles";
// components
import Layout from '../../../layout/Layout'
import { Notifier, ErrorNotifier } from '../../../components';
// icons 
import CancelIcon from "@mui/icons-material/Cancel";
import PhotoCamera from '@mui/icons-material/PhotoCamera';
// redux
import {uploadProfilePic, resetMessage, resetErrMessage, getCurrentUser,} from '../../../features/auth/authSlice'
import { ASSESSMENT_BASE_URL } from '../../../utils/config';

// ------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: "0.5rem",
      borderRadius: "1rem !important",
    },
    muiList: {
      padding: "0 !important",
    },
    muiListHeadText: {
      width: "3.4rem",
    },
    muiListDataText: {
      width: "3rem",
    },
  }));
  
// ---------------------------------------------------------------



// --------------------------------------------------------------------------------------------------------------

const MyProfile = () => {
  const [geo, setGeo] = useState(" ")
  const dispatch = useDispatch()
  const {message, errMessage, loginData} = useSelector(state => ({...state.auth}))
  const classes = useStyles();

  const handleFile = (e) => {
    let formdata = new FormData()
    
    formdata.append("profilePic", e.target.files[0]);
    
    dispatch(uploadProfilePic(formdata)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          dispatch(getCurrentUser());

        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const ProfilePic = () => {
    return (
  <Grid container spacing={2} sx={{justifyContent: 'center'}}>
            <Grid item xs={12} sm={12} md={6}>
            <Paper elevation={4} className={classes.paper} sx={{textAlign: 'center'}}> 
            <Avatar
  alt="Remy Sharp"
  src={`${ASSESSMENT_BASE_URL}/v1/api/assessment-user/profile-pic`}
  sx={{ width: 100, height: 100, margin: 'auto'}}
  />
            <label htmlFor="icon-button-file">
        <Input accept="image/*" id="icon-button-file" type="file" sx={{display: "none"}} name="l1MenuIcon"
         onChange={handleFile}
         required={true}
        />
        <IconButton color="primary" aria-label="upload picture" component="span">
          <PhotoCamera />
        </IconButton>
      </label>
            </Paper>
            </Grid>
          </Grid>
    )
  }

  // useEffect(() => {
  //  return <ProfilePic />
  // }, [loginData])

  useEffect(() => {
    Object.keys(continents).map(item => {
      if(item === loginData?.data?.getAssessmentUser?.assessmentUserDetail?.geoName) {
        setGeo(continents[item])
      }
    } )
  },[])

  return (
    <Layout>

<Stack flexDirection="row" justifyContent="space-between">
            <Box sx={{ width: "100%" }}>
              <PageHeader variant="pageHeader">My Profile</PageHeader>
            </Box>
            <Link to="/exco/dashboard">
              <CancelIcon color="red" />
            </Link>
          </Stack>
          <ProfilePic  />
          <Grid container sx={{justifyContent: 'center', mt: 2}}>
            <Grid item xs={12} sm={12} md={6}>
              <Paper elevation={4} className={classes.paper}>
              <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    User Name
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {loginData?.data?.getAssessmentUser?.assessmentUserDetail?.firstName}&nbsp;{loginData?.data?.getAssessmentUser?.assessmentUserDetail?.lastName}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Email
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText style={{wordWrap:'break-word'}} className={classes.muiListDataText}>
                    {loginData?.data?.getAssessmentUser?.email}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Geography
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {geo}({loginData?.data?.getAssessmentUser?.assessmentUserDetail?.geoName})
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Country
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {loginData?.data?.getAssessmentUser?.assessmentUserDetail?.country}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Contact Number
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {loginData?.data?.getAssessmentUser?.assessmentUserDetail?.dialingCode}-{loginData?.data?.getAssessmentUser?.assessmentUserDetail?.contactNumber}
                  </ListItemText>
                </ListItem>
                
              </Paper>
            </Grid>
          </Grid>
          {message && <Notifier message={message} />}
          {errMessage && <ErrorNotifier message={errMessage} />}
    </Layout>
  )
}

export default MyProfile