import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { MyTextInput, PageHeader } from "@excoleadershipui/pionweblib";
import { useSelector, useDispatch } from "react-redux";
import Notifier from "../../components/common/Notifier";
import ErrorNotifier from "../../components/common/ErrorNotifier";
import { ADMIN_VERSION } from "../../utils/config";
// material ui
import { Typography, Container, Box, Link, Paper, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
// icons
import logo from "../../assets/images/exco-nav-logo.svg";
// components
import { FormSubmitButton } from "../../components";
// redux
import {
  activateAccount,
  activateAccountMailSend,
  resetErrMessage,
  resetMessage,
  resetBaseString
} from "../../features/auth/authSlice";
import { useEffect } from "react";
// -----------------------------------------------------

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.excoleadership.com/">
        The ExCo Group LLC
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

//   -----------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  btnSubmit: {
    marginTop: "1rem !important",
  },
}));

// ---------------------------------------------------------------------------------------------

const validationSchema = Yup.object({
  newPassword: Yup.string()
    .required("New Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character  (!@#$%^&*)"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm Password is required"),
  secret: Yup.string().required("Secret is required"),
});

// ---------------------------------------------------------------------------------------------

const ActivateAccount = (props) => {
  let navigate = useNavigate();

  const classes = useStyles();

  const dispatch = useDispatch();
  const { loading, baseString, message, errMessage } = useSelector((state) => ({
    ...state.auth,
  }));
  const queryParams = new URLSearchParams(window.location.search);
  const result1 = queryParams.get("aue");
  const queryParamsTwo = new URLSearchParams(window.location.search);
  const result2 = queryParamsTwo.get("auec");

  useEffect(() => {
    dispatch(resetBaseString())
  }, [])
  
  const sendActivationMail = () => {
    dispatch(
      activateAccountMailSend({
        aue: result1,
        auec: result2,
      })).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 2000);
        }
      })
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 4 }}>
        <PageHeader sx={{ marginTop: 4 }}>Activate Account</PageHeader>
      </Box>

      <Paper
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "2rem",
          position: "relative",
        }}
        elevation={2}
      >
        <img alt="logo" src={logo} width="80rem" height="40rem" />

        <Formik
          validationSchema={validationSchema}
          initialValues={{
            secret: "",
            newPassword: "",
            confirmPassword: "",
          }}
          onSubmit={(values) => {
            let body = {
              aue: result1,
              auec: result2,
              token: values.secret.toString(),
              password: values.newPassword,
              // confirmPassword: values.confirmPassword,
            };
            dispatch(activateAccount(body)).then((res) => {
              if (res?.payload?.data?.ok) {
                setTimeout(() => {
                  dispatch(resetMessage());
                  navigate("/");
                }, 2000);
              }
              if (res?.payload?.error) {
                setTimeout(() => {
                  dispatch(resetErrMessage());
                }, 2000);
              }
            });
          }}
        >
          {({ errors, touched, handleSubmit, handleChange, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div style={{ display: "flex" }}>
                <div style={{ width: "25%", paddingRight: "0.5rem" }}>
                  <MyTextInput
                    // label="Dialing Code"
                    type="string"
                    name="baseString"
                    fullWidth={true}
                    margin="normal"
                    id="baseString"
                    value={baseString}
                    disabled
                  />
                </div>
                <div style={{ width: "75%" }}>
              <MyTextInput
                label="Activation Code Received Through E-mail"
                type="number"
                name="secret"
                required={true}
                fullWidth={true}
                margin="normal"
                id="newPassword"
                onChange={handleChange("secret")}
                value={values?.secret}
                error={touched.secret && Boolean(errors.secret)}
                helperText={touched.secret && errors.secret}
                sx={{ marginBottom: "0px" }}
              />
              </div>
              </div>
              <Button
                variant="text"
                onClick={sendActivationMail}
                disabled={loading}
              >
                Send Activation Code
              </Button>
              <MyTextInput
                label="New Password"
                type="password"
                name="newPassword"
                required={true}
                fullWidth={true}
                margin="normal"
                id="newPassword"
                onChange={handleChange("newPassword")}
                value={values?.newPassword}
                autoComplete="off"
                error={touched.newPassword && Boolean(errors.newPassword)}
                helperText={touched.newPassword && errors.newPassword}
              />
              <MyTextInput
                label="Confirm Password"
                type="password"
                name="confirmPassword"
                required={true}
                fullWidth={true}
                margin="normal"
                id="confirmPassword"
                onChange={handleChange("confirmPassword")}
                value={values?.confirmPassword}
                error={
                  touched.confirmPassword && Boolean(errors.confirmPassword)
                }
                helperText={touched.confirmPassword && errors.confirmPassword}
              />

              <FormSubmitButton
                type="submit"
                fullWidth={true}
                variant="contained"
                className={classes.btnSubmit}
              >
                Activate Account
              </FormSubmitButton>
            </form>
          )}
        </Formik>
      </Paper>
      <Copyright sx={{ mt: 8, mb: 4 }} />
      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Container>
  );
};

export default ActivateAccount;
