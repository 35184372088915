import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// material ui
import { Grid } from "@mui/material";
// material icon
import CancelIcon from "@mui/icons-material/Cancel";
// components
import AssessmentTemplateDimensionConfig from "./AssessmentTemplateDimensionConfig";
import AssessmentTemplateSectionConfig from "./AssessmentTemplateSectionConfig";
import Layout from "../../../../../layout/Layout";
import { useDispatch } from "react-redux";
// import { sectionRowSelect } from "../../../../../../../features/config/assessmentTemplateSectionConfigSlice";
import { AppPageHeader, PageHeader } from "../../../../../components";
import {
  rowSelect,
  sectionRowSelect,
} from "../../../../../features/config/assessmentTemplateSectionConfigSlice";
const ConfigureAssessmentTemplateConfig = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  let shrink = props.sidebar ? "scale" : "no-scale";
 

  useEffect(() => {
    try {
     
        dispatch(rowSelect({}));
        dispatch(sectionRowSelect({}));
    
    } catch (err) {
    
    }
  }, []);

  return (
    <Layout>
      {/* <Link
        to={{
          pathname: "/piedap/assessment360/config/assessment_template",
          serviceAccessData : location?.serviceAccessData
        }}
      >
        <CancelIcon
          color="red"
          sx={{ position: "absolute", right: 80, zIndex: 1 }}
          onClick={() => dispatch(sectionRowSelect(""))}
        />
      </Link> */}
      <AppPageHeader
        variant="pageHeader"
        pathname="/exco/config/assessment-template"
        permissions={location?.state?.permissions}
        l3Menu={location?.state?.l3Menu}
        data={location?.state?.data}
        moduleName={location?.state?.moduleName}
      >
        {location?.state?.templateSelectedData?.assessmentTemplateName
          ? location?.state?.templateSelectedData?.assessmentTemplateName
          : ""}
      </AppPageHeader>
      <Grid container spacing={2} >
        <Grid item xs={12} sm={12} md={6}>
          <AssessmentTemplateSectionConfig
            fromMain={location.fromMain}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <AssessmentTemplateDimensionConfig />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ConfigureAssessmentTemplateConfig;
