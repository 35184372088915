import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";

import { ASSESSMENT_BASE_URL } from "../../utils/config";
import { Link, useLocation } from "react-router-dom";
import { toPng } from 'html-to-image';
// material ui
import { makeStyles } from "@mui/styles";
import {

  IconButton,
  FormControlLabel,
  Stack,
  RadioGroup,
  Radio,
} from "@mui/material";
// icons
import DescriptionIcon from "@mui/icons-material/Description";
import CancelIcon from "@mui/icons-material/Cancel";
import EmailIcon from "@mui/icons-material/Email";
import PreviewIcon from '@mui/icons-material/Preview';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import moment from "moment";
import { DateTime } from "luxon";
import format from "date-fns/format";
// components
import Layout from "../../layout/Layout";
import {
  MuiDataGrid,

  GridCellExpand,

  FormSubmitButton,
  FormTextField as MyTextInput,
  Notifier,
  ErrorNotifier,
  CommonCurdPopUp,
  MuiTooltip,
  ConfirmDialog
} from "../../components";
// redux
import {
  getAssessmentResponse,

  getHistoryRemainderResponseList,
  sendReminderMail,
  rowSelect,
  resetMessage,
  resetErrMessage,
  updateClientStatus,
  emptyRow
} from "../../features/analyze_and_report/assessmentResponseSlice";
import { getPreviewReminderById } from "../../features/config_and_publish/publishAssessmentSlice";

import { getCustomer } from "../../features/customer/customerSlice";

import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// -------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  responsive_close: {
    // marginTop: "1.75rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "9.563rem",
      marginRight: "-2.688rem",
    },
  },
}));

const AssessmentReport = (props) => {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [statusOpen, setStatusOpen] = React.useState(false);
  const [previewEmail, setPreviewEmail] = useState(false)
  const [updateStatus, setUpdateStatus] = useState("Completed");
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const htmlRef = useRef(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateClickOpen = () => {
    setStatusOpen(true);
  };

  const handleUpdateClose = () => {
    setStatusOpen(false);
  };

  const location = useLocation();

  const {
    message,
    errMessage,
    assessmentResponseData,
    remainderHistoryData,
    selectedData,
  } = useSelector((state) => ({ ...state.assessmentResponse }));
  const { previewRimnderData } = useSelector((state) => ({ ...state.publishAssessment }))

  function getClientName(params) {
    return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
  }

  function getReminderSentDate(params) {
    if (params?.row?.lastReminderSent) {
      let date = params?.row?.lastReminderSent;
      const dateResult = DateTime.fromISO(date).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      let localIsoString = dateResult.toISO();
      localIsoString = format(new Date(localIsoString), "MM-dd-yyyy");
      // date = date.substring(0, 10).split("-");
      // date = date[1] + "-" + date[2] + "-" + date[0];
      return localIsoString;
    }
  }

  const showReminderModel = (data) =>
    setConfirmDialog({
      isOpen: true,
      title: "Attention!",
      subTitle: "Clicking this button will send an email to the designated recipients. Please Preview the information before proceeding. Once sent, emails cannot be retrieved.",
      onConfirm: () => dispatch(
        sendReminderMail({
          assessmentTrackingId: data?.id,
          assessmentCustomerUserId:
           data?.assessmentCustomerUserId,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            dispatch(
              getAssessmentResponse({
                assessmentTemplateId:
                  location?.state?.trackingSelectedData?.id,
              })
             
            );
            setConfirmDialog({
              ...confirmDialog,
              isOpen: false,
            });
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
            setConfirmDialog({
              ...confirmDialog,
              isOpen: false,
            });
          }, 3000);
        }
      })
    })

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  function renderCellExpandMaping(params) {
    let result = remainderHistoryData
      .filter(
        (data) =>
          data?.assessmentCustomerUser?.id ===
          params?.row?.assessmentCustomerUserId
      )
      ?.map((result) =>
        format(new Date(result.lastReminderSent), "MM/dd/yyyy")
      );

    let final = result.join(" ");
    return (
      <GridCellExpand value={final || ""} width={params.colDef.computedWidth} />
    );
  }

  // useEffect(() => {
  //   dispatch(
  //     getHistoryRemainderResponseList({
  //       assessmentCustomerTrackingId:
  //         location.state.trackingSelectedData?.assessmentTracking?.id,
  //       assessmentCustomerUserId:
  //         location.state.trackingSelectedData?.assessmentCustomerUser?.id,
  //     })
  //   );
  // }, []);

  const data = {
    rows: assessmentResponseData,
    columns: [
      {
        field: "clientName",
        headerName: "Client Name",
        width: 150,
        renderCell: renderCellExpand,
        valueGetter: getClientName,
      },
      {
        field: "email",
        headerName: "Client E-Mail",
        width: 150,
        renderCell: renderCellExpand,
      },
      {
        field: "selfAssessmentStatus",
        headerName: "Self Response",
        width: 150,
        renderCell: (params) => {
          return (
            <MuiTooltip
              title={params?.row?.selfAssessmentStatus}
              placement="bottom"
            >
              <div
                style={{
                  backgroundColor:
                    params?.row?.selfAssessmentStatus === "Pending"
                      ? "#f50a0a"
                      : "#1f4904",
                  width: "20px",
                  height: "20px",
                  border: `1px solid ${params?.row?.selfAssessmentStatus === "Pending"
                    ? "#f50a0a"
                    : "#1f4904"
                    }`,
                  borderRadius: "100%",
                }}
              ></div>
            </MuiTooltip>
          );
        },
      },
      {
        field: "assessorStatus",
        headerName: "Assessor Response",
        width: 150,
        renderCell: (params) => {
          return (
            <Link
              to="/exco/report/assessors/list"
              state={{
                trackingSelectedData: params?.row,
                assessmentData: location?.state?.trackingSelectedData,
                permissions: location?.state?.permissions,
              }}
            >
              {params?.row?.all_completed_assessor}/{params?.row?.all_assessor}
            </Link>
          );
        },
      },
      {
        field: "previewReport",
        headerName: "Report",
        type: "actions",
        width: 100,
        renderCell: (params) => {
          return (
            <div>
              <MuiTooltip title="View Report" placement="bottom">
                <Link
                  to="/exco/report/preview"
                  state={{ clientSelectedData: params?.row }}
                >
                  {/* <a href={`${ASSESSMENT_BASE_URL}/v1/api/excel-parser/report?assessmentTrackingId=${params?.row?.id}`} target="_blank"> */}
                  <IconButton>
                    <DescriptionIcon style={{ color: "#808080" }} />
                  </IconButton>
                  {/* </a> */}
                </Link>
              </MuiTooltip>

              <MuiTooltip title="View Detailed Report" placement="bottom">
                {/* <Link to="/exco/report/preview" state={{clientSelectedData: params?.row}}> */}
                <a
                  href={`${ASSESSMENT_BASE_URL}/v1/api/excel-parser/full-report?assessmentTrackingId=${params?.row?.id}`}
                  target="_blank"
                >
                  <IconButton>
                    <FileDownloadIcon style={{ color: "#808080" }} />
                  </IconButton>
                </a>
                {/* </Link> */}
              </MuiTooltip>

              <MuiTooltip title="View Report" placement="bottom">
                {/* <Link to="/exco/report/preview" state={{clientSelectedData: params?.row}}> */}
                <a
                  href={`${ASSESSMENT_BASE_URL}/v1/api/excel-parser/full-report-v2?assessmentTrackingId=${params?.row?.id}`}
                  target="_blank"
                >
                  <IconButton>
                    <FileDownloadIcon style={{ color: "#808080" }} />
                  </IconButton>
                </a>
                {/* </Link> */}
              </MuiTooltip>
            </div>
          );
        },
      },
      {
        field: 'Preview',
        // headerName: 'Clone',
        type: 'preview',
        width: 70,
        renderCell: (params) => {
          if (params?.row?.selfAssessmentStatus !== "Completed") {
            return (
              // location?.state?.permissions && location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "forceDelCustUsr") && 
              <MuiTooltip title="Client Preview Email" placement="right">
                <IconButton onClick={() => handlePreviewClick({
                  trackingId: location?.state?.trackingSelectedData?.id, customerId: params?.row?.
                    assessmentCustomerUserId

                })} color="primary" >
                  <PreviewIcon />
                </IconButton>
              </MuiTooltip>
            );
          }
        }
      },
      {
        field: "reminder",
        headerName: "Reminder To Client",
        type: "actions",
        width: 200,
        renderCell: (params) => {
          if (params?.row?.selfAssessmentStatus !== "Completed") {
            return (
              <MuiTooltip title="Reminder Mail" placement="bottom">
                <IconButton
                  onClick={
                    () => showReminderModel(params.row)
                  }
                >
                  <EmailIcon style={{ color: "#808080" }} />
                </IconButton>
              </MuiTooltip>
            );
          }
        },
      },

      {
        field: "reminderDate",
        headerName: "Last Reminder Date",
        width: 150,
        valueGetter: getReminderSentDate,
      },

      {
        field: "remainder",
        headerName: "Reminder History",
        type: "actions",
        width: 150,
        renderCell: (params) => {
          return (
            <div>
              <MuiTooltip title="Reminder History" placement="bottom">
                <IconButton>
                  <DescriptionIcon
                    onClick={() => {
                      handleClickOpen();
                      dispatch(
                        getHistoryRemainderResponseList({
                          assessmentCustomerTrackingId: params?.row?.id,
                          assessmentCustomerUserId:
                            params?.row?.assessmentCustomerUserId,
                        })
                      ).then((res) => {
                        if (res?.payload?.data?.ok) {
                          setTimeout(() => {
                            dispatch(resetMessage());
                          }, 2000);
                        }
                        if (res?.payload?.error) {
                          setTimeout(() => {
                            dispatch(resetErrMessage());
                          }, 3000);
                        }
                      });
                    }}
                    style={{ color: "#808080" }}
                  />
                </IconButton>
              </MuiTooltip>
            </div>
          );
        },
      },
      {
        field: "customer",
        headerName: "Customer Name",
        width: 200,
        valueGetter: (params) => params?.row?.customerName,

        renderCell: renderCellExpand,
      },
      {
        field: "account",
        headerName: "Account Name",
        width: 200,
        valueGetter: (params) => params?.row?.accountName
        ,

        renderCell: renderCellExpand,
      },

      // {
      //   field: "reminderHistory",
      //   headerName: "Remainder History",
      //   width: 150,

      //   renderCell: renderCellExpandMaping,
      // },
    ],
  };

  useEffect(() => {
    dispatch(
      getAssessmentResponse({
        assessmentTemplateId: location?.state?.trackingSelectedData?.id,
      })
    );
    dispatch(getCustomer())
  }, []);

  const handlePreviewClick = async (data) => {

    setPreviewEmail(true)
    dispatch(getPreviewReminderById({ assessmentTrackingId: data?.trackingId, assessmentCustomerUserId: data?.customerId }))
    if (htmlRef.current) {
      // Convert HTML content to image
      await toPng(htmlRef.current);
    }
  }





  const classes = useStyles();

  const handleUpdateStatusChange = (event) => {
    setUpdateStatus(event.target.value);
  };

  const defaultCallBack = () => {
    dispatch(
      getAssessmentResponse({
        assessmentTemplateId: location?.state?.trackingSelectedData?.id,
      })
    );
  }

  const handleSubmitClick = () => {
    dispatch(
      updateClientStatus({
        assessmentTrackingId: selectedData?.id,
        assessmentCustomerUserId: selectedData?.assessmentCustomerUserId,
        status: updateStatus,
      })
    )
      .then((res) => {

        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            dispatch(
              getAssessmentResponse({
                assessmentTemplateId: location?.state?.trackingSelectedData?.id,
              })
            );
            handleUpdateClose();
            dispatch(emptyRow())
          }, 2000);
        }
        if (res?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      })
      .catch((err) => {

        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 2000);
      });
  };


  return (
    <Layout>
      {/* <> */}
        <Link
          to="/exco/report"
          state={{ permissions: location?.state?.permissions }}
        >
          <CancelIcon
            color="red"
            sx={{ position: "absolute", right: 30, zIndex: 1 }}
            className={classes.responsive_close}
          />
        </Link>

        <PageHeader variant="pageHeader">
          Assessment Response Report for{" "}
          {location?.state?.trackingSelectedData?.assessmentTemplateName}
        </PageHeader>
        <MuiDataGrid


          toolbarColumn={true}
          // toolbarFilter={true}
          toolbarDensity={true}
          toolbarExport={true}
          data={data}
          select={(data) => dispatch(rowSelect(data))}
          setShowEditButton={
            selectedData?.selfAssessmentStatus === "Completed" ? true : false
          }
          handleUpdateClickOpen={handleUpdateClickOpen}
        />
      {/* </> */}

      {previewEmail && <CommonCurdPopUp
        heading="Email Preview"
        mediumScreen={true}
        close={
          () => setPreviewEmail(false)
        }
      >

        <div ref={htmlRef} dangerouslySetInnerHTML={{ __html: previewRimnderData }} style={{}} />


      </CommonCurdPopUp>}

      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Reminder History"}
        </DialogTitle>
        <DialogContent>
          {remainderHistoryData.length > 0 &&
            remainderHistoryData.map((data) => {
              const formattedDate = moment(data?.lastReminderSent)
                .local()
                .format("DD MMM YYYY HH:mm:ss (UTCZ)");
              const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

              return (
                <DialogContentText sx={{ display: "block" }}>
                  {`${formattedDate} - ${timeZone} `}
                </DialogContentText>
              );
            })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog

        open={statusOpen}
        onClose={handleUpdateClose}
        aria-labelledby="responsive-dialog-title"
      >
        <IconButton
          aria-label="close"
          onClick={handleUpdateClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* <DialogTitle id="responsive-dialog-title">
          {"Change Status"}
        </DialogTitle> */}
        <DialogContent>
          <Stack sx={{ width: "100%" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={updateStatus}
              onChange={handleUpdateStatusChange}
            >

              <FormControlLabel
                style={{ color: "black", }}
                value="Completed"
                control={<Radio />}
                label="Completed"
              />
              <FormControlLabel
                style={{ color: "black", margin: "1rem" }}
                value="Pending"
                control={<Radio />}
                label="Pending"
              />
            </RadioGroup>
          </Stack>
          <Stack sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <FormSubmitButton
              onClick={handleSubmitClick}
              type="submit"
              variant="contained"
            >
              Submit
            </FormSubmitButton>

          </Stack>

        </DialogContent>
      </Dialog>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />

      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default AssessmentReport;
