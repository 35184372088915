import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_TEMPLATE_TYPE } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createTemplateType = createAsyncThunk(
  "TemplateType/createTemplateType",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_TEMPLATE_TYPE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTemplateType = createAsyncThunk(
  "TemplateType/getTemplateType",
  async (obj, {rejectWithValue}) => {
    try{
      const response = await axios.get(CURD_TEMPLATE_TYPE, { withCredentials: true });
      return response;
    } catch (err) {
      return rejectWithValue(err.response) 
    }
  }
);

export const updateTemplateType = createAsyncThunk(
  "TemplateType/updateTemplateType",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_TEMPLATE_TYPE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteTemplateType = createAsyncThunk(
  "TemplateType/deleteTemplateType",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_TEMPLATE_TYPE, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const TemplateTypeSlice = createSlice({
  name: "TemplateType",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    TemplateTypeData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createTemplateType.pending]: (state, action) => {
      state.loading = true;
    },
    [createTemplateType.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createTemplateType.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getTemplateType.pending]: (state, action) => {
      state.loading = true;
    },
    [getTemplateType.fulfilled]: (state, action) => {
      state.loading = false;
      state.TemplateTypeData = action?.payload?.data;
    },
    [getTemplateType.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateTemplateType.pending]: (state, action) => {
      state.loading = true;
    },
    [updateTemplateType.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateTemplateType.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteTemplateType.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteTemplateType.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteTemplateType.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  TemplateTypeSlice.actions;
export default TemplateTypeSlice.reducer;
