import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import FileSaver from 'file-saver';

// routes
import { CURD_MAP_ASSESSOR, GET_MAP_ASSESSORS_BY_TRACKING_ID, GET_NOT_MAPPED_ASSESSORS, BATCH_UPLOAD_ASSESSOR } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createMapAssessor = createAsyncThunk(
  "mapAssessor/createMapAssessor",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_MAP_ASSESSOR, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const batchUploadAssessor = createAsyncThunk(
  "mapAssessor/batchUploadAssessor",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(BATCH_UPLOAD_ASSESSOR, data, {
        withCredentials: true,
        responseType: 'blob'
      });
      FileSaver.saveAs(new Blob([response.data]), 'data.xlsx');
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getMapAssessors = createAsyncThunk(
  "mapAssessor/getMapAssessors",
  async (obj, {rejectWithValue}) => {
    try {
      const response = await axios.get(CURD_MAP_ASSESSOR, { withCredentials: true });
      return response;
    } catch (err) {
      return rejectWithValue(err.response) 
    }
  }
);



export const getMapAssessorsByTrackingId = createAsyncThunk(
    "mapAssessor/getMapAssessorsByTrackingId",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axios.post(GET_MAP_ASSESSORS_BY_TRACKING_ID, data, {
          withCredentials: true,
        });
        return response;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  );

export const getNotMappedAssessors = createAsyncThunk(
    "mapAssessor/getNotMappedAssessors",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axios.post(GET_NOT_MAPPED_ASSESSORS, data, {
          withCredentials: true,
        });
        return response;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  );

export const updateMapAssessor = createAsyncThunk(
  "mapAssessor/updateMapAssessor",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_MAP_ASSESSOR, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteMapAssessor = createAsyncThunk(
  "mapAssessor/deleteMapAssessor",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_MAP_ASSESSOR, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const  mapAssessorSlice = createSlice({
  name:"mapAssessor",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    mapAssessorsData: [],
    notMappedAssessorsData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createMapAssessor.pending]: (state, action) => {
      state.loading = true;
    },
    [createMapAssessor.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createMapAssessor.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getMapAssessors.pending]: (state, action) => {
      state.loading = true;
    },
    [getMapAssessors.fulfilled]: (state, action) => {
      state.loading = false;
      state.mapAssessorsData = action?.payload?.data;
    },
    [getMapAssessors.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getMapAssessorsByTrackingId.pending]: (state, action) => {
      state.loading = true;
    },
    [getMapAssessorsByTrackingId.fulfilled]: (state, action) => {
      state.loading = false;
      state.mapAssessorsData = action?.payload?.data;
    },
    [getMapAssessorsByTrackingId.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getNotMappedAssessors.pending]: (state, action) => {
      state.loading = true;
    },
    [getNotMappedAssessors.fulfilled]: (state, action) => {
      state.loading = false;
      state.notMappedAssessorsData = action?.payload?.data;
    },
    [getNotMappedAssessors.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateMapAssessor.pending]: (state, action) => {
      state.loading = true;
    },
    [updateMapAssessor.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateMapAssessor.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteMapAssessor.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteMapAssessor.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteMapAssessor.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [batchUploadAssessor.pending]: (state, action) => {
      state.loading = true;
    },
    [batchUploadAssessor.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = "Assessors uploaded successfully ";
    },
    [batchUploadAssessor.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = "Assessors upload failed";
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  mapAssessorSlice.actions;
export default mapAssessorSlice.reducer;
