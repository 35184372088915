import React, { useEffect } from 'react'
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
// material ui 
import { makeStyles } from "@mui/styles";
import { Grid, Box, FormGroup, InputLabel, } from '@mui/material'
// components
import { ModalPopUp, FormSubmitButton,CommonCurdPopUp, ModalCloseBtn, FormTextField as MyTextInput, Notifier, ErrorNotifier } from '../../../../components'
// redux
import { createL1Menu, getL1Menu, resetMessage, resetErrMessage } from '../../../../features/iam/application_management/menu_management/l1MenuSlice'

// ----------------------------------------------------------------------------------
const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ------------------------------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({

  rootbox: {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: '56.25rem !important',
    [theme.breakpoints.down('md')]: {
      width: '18.375rem !important',
      height: '60% !important',
      display: 'block !important',
      overflow: 'scroll !important',
      marginTop: '-7.75rem'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '33.375rem !important',
      height: '49% !important',
      display: 'block !important',
      overflow: 'scroll !important',
      // margin: '-3.188rem',
      marginTop: '-3.75rem  !important',
      marginLeft: '-3.563rem',
      padding: '2.125rem !important',
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: '1.5rem !important',
    paddingTop: '1rem !important',
    paddingLeft: '2rem !important',
    paddingRight: '2rem !important',
    paddingBottom: '1.5rem',
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },

  responsiveText: {
    width: '50rem',
    [theme.breakpoints.down('md')]: {
      width: '20rem'

    }

  }
}))


const validationSchema = Yup.object({
  l1MenuName: Yup.string().required("L1 Menu Name is required"),
  l1MenuGroupId: Yup.string().required("L1 Menu Group Id is required"),
  l1PageLink: Yup.string().required("L1 Page Link is required"),
  l1MenuIcon: Yup.string().required("L1 Menu Icon is required"),
});

//   -------------------------------------------------------------------------------------------

const AddL1Menu = ({ showAdd, setShowAdd }) => {

  const dispatch = useDispatch();
  const { message, errMessage } = useSelector((state) => ({
    ...state.l1Menus
  }))

  const handleFile = (e) => {
    formik.setFieldValue("l1MenuIcon", e.target.files[0]);
  };

  const formRefresh = () => {
    formik.resetForm({
      values: {
        l1MenuName: "",
        l1MenuGroupId: "",
        l1PageLink: "",
        l1MenuIcon: ""
      },
    });
  }

  const formik = useFormik({
    reset: true,
    initialValues: {
      l1MenuName: "",
      l1MenuGroupId: "",
      l1PageLink: "",
      l1MenuIcon: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      let formdata = new FormData();
      formdata.append("l1MenuName", values.l1MenuName);
      formdata.append("l1MenuGroupId", values.l1MenuGroupId.toString());
      formdata.append("l1PageLink", values.l1PageLink);
      formdata.append("l1MenuIcon", values.l1MenuIcon);
      dispatch(createL1Menu(formdata)).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            dispatch(getL1Menu());
            formRefresh()
            setShowAdd(false);

          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });
  const classes = useStyles()
  return (
    <>

      {showAdd && <CommonCurdPopUp
        heading={`Create L1 Menu`}
        mediumScreen={true}
        close={() => {
          setShowAdd(false)
          formRefresh()
        }}
      >
        <Grid container >
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11} >
            <MyTextInput
              className={classes.responsiveText}
              label="L1 Menu Name"
              type="text"
              name="l1MenuName"
              required={true}
              fullWidth={true}
              margin="normal"
              id="l1MenuName"
              value={formik.values.l1MenuName}
              onChange={formik.handleChange}
              tabIndex={1}
              error={
                formik.touched.l1MenuName &&
                Boolean(formik.errors.l1MenuName)
              }
              helperText={
                formik.touched.l1MenuName && formik.errors.l1MenuName
              }
            />
            <MyTextInput
              label="L1 Menu Group Id"
              type="text"
              name="l1MenuGroupId"
              required={true}
              fullWidth={true}
              margin="normal"
              id="l1MenuGroupId"
              value={formik.values.l1MenuGroupId}
              onChange={formik.handleChange}
              tabIndex={1}
              error={
                formik.touched.l1MenuGroupId &&
                Boolean(formik.errors.l1MenuGroupId)
              }
              helperText={
                formik.touched.l1MenuGroupId && formik.errors.l1MenuGroupId
              }
            />
            <MyTextInput
              label="L1 Page Link"
              type="text"
              name="l1PageLink"
              required={true}
              fullWidth={true}
              margin="normal"
              id="l1PageLink"
              value={formik.values.l1PageLink}
              onChange={formik.handleChange}
              tabIndex={1}
              error={
                formik.touched.l1PageLink &&
                Boolean(formik.errors.l1PageLink)
              }
              helperText={
                formik.touched.l1PageLink && formik.errors.l1PageLink
              }
            />
            <Grid container spacing={1} sx={{ marginTop: "0.5rem" }} >
              <Grid item xs={12} sm={12} md={12}>
                <FormGroup className="file-display mr-1">
                  <InputLabel className="bold label-sm">Icon *</InputLabel>
                  <MyTextInput
                    disabled
                    type="text"
                    name="l1MenuIcon"
                    value={formik?.values?.l1MenuIcon?.name || ""}
                    className="form-control-sm "
                    required={true}
                    size="small"
                    error={
                      formik.touched.l1MenuIcon &&
                      Boolean(formik.errors.l1MenuIcon)
                    }
                    helperText={
                      formik.touched.l1MenuIcon &&
                      formik.errors.l1MenuIcon
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormGroup className="file-browse-btn-div">
                  <label className="btn label-sm file-browse-btn">
                    Browse
                    <MyTextInput
                      type="file"
                      name="l1MenuIcon"
                      style={{
                        display: "none",
                        marginTop: "23px",
                        marginLeft: "-28px",
                      }}
                      className="form-control-sm file-input"
                      onChange={handleFile}
                      required={true}
                      size="small"
                      error={
                        formik.touched.l1MenuIcon &&
                        Boolean(formik.errors.l1MenuIcon)
                      }
                      helperText={
                        formik.touched.l1MenuIcon &&
                        formik.errors.l1MenuIcon
                      }
                    />
                  </label>
                </FormGroup>
              </Grid>
              <Box style={{ width: "100%", textAlign: "center" }} mb={10}>

                <FormSubmitButton onClick={formik?.handleSubmit} type="submit" variant="contained">
                  Submit
                </FormSubmitButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>


      </CommonCurdPopUp>}




      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </>
  )
}

export default AddL1Menu