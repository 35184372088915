


// 
// ---------------------------------
import React, { useRef,useEffect } from 'react';
import ReactDOM from "react-dom";
import Chart from 'chart.js/auto';
import { ChartLabel, getChartLabelPlugin } from 'chart.js-plugin-labels-dv';
import ChartDataLabels from "chartjs-plugin-datalabels";


import "chartjs-plugin-labels";

// =================================================================================================================================
const Graph = (props) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  // const level1Labels = [
  //   'LevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevel',
  //   'Level1  2',
  //   'Level1  3',
  //   'Level1  4',
  // ];
  // const level1Tooltip = [
  //   'LevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevelLevel',
  //   'Level1  2',
  //   'Level1  3',
  //   'Level1  4',
  // ];
  // const level1Data = [1000];
  // const level2Labels = [
  //   'Level2  1',
  //   'Level2  2',
  //   'Level2  3',
  //   'Level2  4',
  //   'Level2  5',
  // ];
  // const level2Data = [
  //   50, 50, 50, 50,50,
  // ];

  const level1Data =
    props?.sectionStore?.length > 0
      ? props?.sectionStore?.map((item) => item.value)
      : [];

  const level1Labels =
    props?.sectionStore?.length > 0
      ? props?.sectionStore?.map((item) => item.name)
      : [];
  const level1Tooltip =
    props?.sectionStore?.length > 0
      ? props?.sectionStore?.map((item) => item.name)
      : [];

  const level2Data =
    props?.dimensionStore?.length > 0
      ? props?.dimensionStore.map((item) => item.value)
      : [];
  const level2Labels =
    props?.dimensionStore?.length > 0
      ? props?.dimensionStore.map((item) => item.name)
      : [];


  useEffect(() => {
    const canvas = chartRef.current;
    const ctx = canvas.getContext('2d');

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    // Chart.register(ChartLabel); // Register ChartLabel
    const labelsPlugin = getChartLabelPlugin(ChartLabel, ctx);

    chartInstance.current = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: level1Labels,
        datasets: [
          {
            data: level1Data,
            backgroundColor: ["#003399"],
            borderColor: ["white", "white", "white", "white"],
            borderWidth: 2,
            datalabels: {
              display: true,
              color: "#FFFFFF",
              font: {
                // size: 22,
                color: 'white',
              },
              formatter: (value, ctx) => {

                return Boolean(ctx.chart.data.labels[ctx.dataIndex])
                  ? ctx.chart.data.labels[ctx.dataIndex].length > 17
                    ? ctx.chart.data.labels[ctx.dataIndex].slice(0, 17) + "..."
                    : ctx.chart.data.labels[ctx.dataIndex]
                  : "";
              },
              align: 'start', // Align labels to the start of the arc
              anchor: 'start', // Anchor labels to the start of the arc
            },
          },
        ],
      },
      options: {
        cutout: "80%",
        plugins: {
          tooltip: {
            enabled: false, // Disable default tooltip
            // external: (context) => {
            //   // Custom tooltip logic
            //   // console.log("coming ",context)
            //   const tooltipEl = document.getElementById('custom-tooltip');
            //   if (tooltipEl && context.tooltip && context.tooltip.caretX && context.tooltip.caretY) {
            //     const { chart, tooltip } = context;
            //     const x= context.tooltip.caretY;
            //     const y = context.tooltip.caretY;
            //     // tooltipEl.innerHTML = `<span>${chart.data.labels[tooltip.dataPoints[0].index]}</span>: <span>${chart.data.datasets[0].data[tooltip.dataPoints[0].index]}</span>`;
            //     tooltipEl.innerHTML = `<span>${chart.
            //       tooltip.title[0]}</span>
            //       `;
            //       // : <span>${chart.data.datasets[0].data[tooltip.dataPoints[0].index]}</span>
            //     tooltipEl.style.display = 'block';
            //     tooltipEl.style.left = `${x}px`;
            //     tooltipEl.style.top = `${y}px`;

            //     setTimeout(()=>{
            //       tooltipEl.style.display = 'none';
            //     },2000);
            //   } else {
            //     // Hide the tooltip if no caret is available
            //     tooltipEl.style.display = 'none';
            //   }
            // }
          },
          legend: {
            display: false,
          },
          labels: {
            render: "label",
            fontColor: "white",
            fontSize: 18,
            arc: true,
            position: "inside",
            outsidePadding: 4,
            textMargin: 8,
            overlap: true,
            showActualPercentages: true,
            precision: 0,
          },
        },
        legend: {
          display: false,
        },
      },
      plugins: [
        labelsPlugin,
        // ChartDataLabels
      ],
    });

    // Calculate the width of the curve
    const innerRadius = chartInstance.current.config.options.cutout ? (chartInstance.current.config.options.cutout.replace('%', '') / 100) * Math.min(chartRef.current.clientWidth, chartRef.current.clientHeight) / 2 : 0;
    const outerRadius = Math.min(chartRef.current.clientWidth, chartRef.current.clientHeight) / 2;
    const curveWidth = outerRadius - innerRadius;

    // console.log('innerRadius ',innerRadius)
    // console.log('outerRadius ',outerRadius)
    // console.log('curveWidth ',curveWidth)





    const data = chartInstance.current.config.data;
    const labels = data.labels;

    // console.log("Before modification:", labels);


    // const sectionWidth = 210 / level1Data?.length;
    
    const labelWidth = (215 - level1Data?.length) / level1Data?.length;

    labels.forEach((label, index) => {
      if (label.length > labelWidth) {

        data.labels[index] = label.slice(0, labelWidth) + '...';
      }
    });

    // console.log("After modification:", data.labels);













    // Intercept hover events on chart labels
    chartRef.current.onmousemove = function (evt) {
      const activePoints = chartInstance.current.getElementsAtEventForMode(
        evt,
        "nearest",
        {
          intersect: true,
        }
      );

      // console.log("Active points ", activePoints)
      if (activePoints.length) {
        const firstPoint = activePoints[0];
        const { x, y } = firstPoint.element.tooltipPosition();
        // const label =
        //   firstPoint?.element?.$datalabels[0]?.$context?.dataset?.labels[
        //     firstPoint?.index
        //   ];
        // const label="Label to display"
        const label = level1Tooltip[firstPoint?.index];
        const value = 300;

        const tooltipContent = (
          // <MuiTooltip title={label} arrow>
          <div
            style={{
              backgroundColor: "#f5f5f5",
              zIndex: 9999,
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              // width: '50rem', // Set maximum width as needed
            }}
            title="label"
          >
            <span style={{ fontWeight: "bold" }}></span> {label}
            <br />
          </div>
          // </MuiTooltip>
        );

        const tooltipDiv = document.getElementById("custom-tooltip");
        ReactDOM.render(tooltipContent, tooltipDiv);

        // Show tooltip and set position
        tooltipDiv.style.display = "block";
        tooltipDiv.style.position = "absolute";
        tooltipDiv.style.top = y + "px";
        tooltipDiv.style.left = x + "px";
        // tooltipDiv.style.width = '100px';
      } else {
        // Hide tooltip when not hovering over a label
        document.getElementById("custom-tooltip").style.display = "none";
      }
    };

    // Hide tooltip when mouse leaves the canvas
    chartRef.current.onmouseleave = function () {
      document.getElementById("custom-tooltip").style.display = "none";
    };



    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [level1Labels, level1Data, chartRef]);



  return (
    <div style={{ height: '572px', width: '572px', position: 'relative', marginBottom:"6.2rem" }}>
      <canvas ref={chartRef} id="mainPieChart" width="400" height="400"></canvas>
      <div
        id="custom-tooltip"
        style={{
          display: "none",
          maxWidth: "50rem",
          zIndex: 9999,
          pointerEvents: "none",
          position: "absolute",
        }}
      />
    </div>
  );
};

export default Graph;
