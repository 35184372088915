import { PageHeader } from "@excoleadershipui/pionweblib";
import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";
import { PDFExport } from "@progress/kendo-react-pdf";
// material ui
import { Box, Button, Grid, Slider, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import Layout from "../../layout/Layout";
import logo from "../../assets/images/exco-nav-logo.svg";
import { MuiSlider } from "../../components";
import { exportElement } from "./export-element";
// redux
import {
  getReport,
  getOptionalCommentsReport,
  getShortResponseReport,
  getLongResponseReport,
} from "../../features/analyze_and_report/assessmentResponseSlice";
import { ASSESSMENT_BASE_URL } from "../../utils/config";

// ----------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100% !important",
    "& :nth-child(14)": {
      width: "0.2rem !important",
      height: "2rem !important",
      borderRadius: "0px !important",
      backgroundColor: "#000080 !important",
    },
    "& :nth-child(15)": {
      width: "0.2rem !important",
      height: "2rem !important",
      borderRadius: "0px !important",
      backgroundColor: "#fff !important",
      border: "1px solid #000080",
    },
  },
  mark: {
    width: "8px !important",
    height: "30px !important",
    backgroundColor: "#000",
    marginTop: "-3px",
  },
  label: {
    color: "red",
  },
  rail: {
    background: "linear-gradient(to right, red, orange, yellow, green);",
  },
  thumb: {
    width: "0px !important",
    border: "none !important",
  },
  track: {
    backgroundColor: "transparent !important",
    height: "0.7rem !important",
  },
  [theme.breakpoints.down("md")]: {
    responsive_slide: {
      width: "62.5rem",
    },
  },
}));

// --------------------------------------------------------------------------------------------

function Report() {
  const [custReportsData, setCustReportsData] = useState({});

  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const pdfExportComponent = React.useRef(null);

  const { reportsData, shortResponseData, longResponseData } = useSelector(
    (state) => ({
      ...state.assessmentResponse,
    })
  );

  useEffect(() => {
    dispatch(getReport({ id: location?.state?.clientSelectedData?.id }));
    dispatch(
      getOptionalCommentsReport({ id: location?.state?.clientSelectedData?.id })
    );
    dispatch(
      getShortResponseReport({ id: location?.state?.clientSelectedData?.id })
    );
    dispatch(
      getLongResponseReport({ id: location?.state?.clientSelectedData?.id })
    );
  }, []);

  // let customizedReportData = [
  //   {
  //     sectionName: "SHAPES STRATEGY",
  //     dimension: [
  //       {
  //         dimensionName: "Creates Clarity",
  //         questions: reportsData.filter(section => section.sectionName === "Strategys" && section.dimensionName === "Creates Clarity")
  //       },
  //       {
  //         dimensionName: "Balances Short & Long-Term Thinking",
  //         questions: reportsData.filter(section => section.sectionName === "Strategys" && section.dimensionName === "Balances Short & Long-Term Thinking")
  //       },
  //       {
  //         dimensionName: "Balances Risk & Innovation",
  //         questions: reportsData.filter(section => section.sectionName === "Strategys" && section.dimensionName === "Balances Risk & Innovation")
  //       },
  //     ],
  //   },
  // ];

  // Reorder the data
  useEffect(() => {
    const template = [];
    let templateObj = {};
    let sectionObj = {};
    let dimension = [];
    let dimensionObj = {};
    let currentSectionName = "";

    for (const item of reportsData) {
      // if (!item.sectionName) {
      //   if ("question" in templateObj) {
      //     templateObj.question.push(item);
      //   } else {
      //     templateObj.question = [item];
      //   }
      // }

      if (!("section" in templateObj)) templateObj.section = [];
      // find if section exists
      const sectionIndex = templateObj.section.findIndex(
        (section) => section.sectionName === item.sectionName
      );

      if (sectionIndex === -1) {
        // section does not exist
        sectionObj = {
          sectionName: item.sectionName,
          dimension: [],
        };
        templateObj.section.push(sectionObj);
      }

      const dimensionIndex = sectionObj.dimension.findIndex(
        (dimension) => dimension.dimensionName === item.dimensionName
      );

      if (dimensionIndex === -1) {
        dimensionObj = {
          dimensionName: item.dimensionName,
          question: [],
        };
        sectionObj.dimension.push(dimensionObj);
      } else {
        dimensionObj = sectionObj.dimension[dimensionIndex];
      }

      dimensionObj.question.push(item);
    }

    setCustReportsData(templateObj);
  }, [reportsData]);

  const onButtonClick = async () => {
    let doc = new jsPDF();

    // Source HTMLElement or a string containing HTML.
    let elementHTML = document.querySelector("#report-content");

    doc.html(elementHTML, {
      callback: function (doc) {
        // Save the PDF
        doc.save("sample-document.pdf");
      },
      x: 15,
      y: 15,
      width: 170, //target width in the PDF document
      windowWidth: 650, //window width in CSS pixels
    });
  };

  function Export2Word(element, filename = "") {
    var preHtml =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    var postHtml = "</body></html>";
    var html = preHtml + document.getElementById(element).innerHTML + postHtml;

    var blob = new Blob(["\ufeff", html], {
      type: "application/msword",
    });

    // Specify link url
    var url =
      "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);

    // Specify file name
    filename = filename ? filename + ".doc" : "document.doc";

    // Create download link element
    var downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  }

  return (
    <Layout>
      <>
        <Link
          to="/exco/assessment/report"
          state={{ permissions: location?.state?.permissions }}
        >
          <CancelIcon
            color="red"
            sx={{ position: "absolute", right: 80, zIndex: 1 }}
          />
        </Link>
        <PDFExport
          keepTogether="p"
          paperSize="A2"
          margin="2cm"
          ref={pdfExportComponent}
        >
          <div
            // ref={(div) => {
            //   _element = div;
            // }}
            // style={{ fontSize: "10px" }}
            id="report-content"
          >
            <PageHeader variant="pageHeader">
              <img src={logo} alt="nav logo" width="120" height="52" />
            </PageHeader>
            <PageHeader variant="pageHeader">
              ELI Response Overview for{" "}
              {location?.state?.clientSelectedData?.firstName}{" "}
              {location?.state?.clientSelectedData?.lastName}
            </PageHeader>
            {Object.keys(custReportsData).length > 0 &&
              custReportsData.section?.length > 0 &&
              custReportsData.section.map((item) => {
                return (
                  <>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={12} sm={12} md={8}>
                        <h2 style={{ textTransform: "uppercase" }}>
                          {item.sectionName}
                        </h2>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <div
                          style={{
                            backgroundColor: "#D3D3D3",
                            padding: "0.2rem",
                            marginLeft: "2.8rem",
                          }}
                        >
                          <Grid container justifyContent="center">
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={3}
                              sx={{ paddingLeft: "0px !important" }}
                            >
                              <Slider
                                value={[2, 4]}
                                min={1}
                                max={5}
                                step={1}
                                marks={[
                                  { value: 1 },
                                  { value: 2 },
                                  { value: 3 },
                                  { value: 4 },
                                  { value: 5 },
                                ]}
                                classes={{
                                  rail: classes.rail,
                                  thumb: classes.thumb,
                                  track: classes.track,
                                  root: classes.root,
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={2}
                              sx={{ margin: "0 5px 0 5px !important" }}
                            >
                              Range
                            </Grid>
                            <Grid item xs={12} sm={12} md={2}>
                              <div
                                style={{
                                  width: "0.3rem",
                                  height: "2rem",
                                  backgroundColor: "#000080",
                                }}
                              >
                                &nbsp;&nbsp;Self
                              </div>
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={2}>Self</Grid> */}
                            <Grid item xs={12} sm={12} md={4}>
                              <div
                                style={{
                                  width: "0.3rem",
                                  height: "2rem",
                                  backgroundColor: "#fff",
                                  border: "1px solid #ADD8E6",
                                }}
                              >
                                &nbsp;&nbsp;Assessor
                              </div>
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={3}>Assessor</Grid> */}
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                    {item.dimension.map((dimension) => {
                      return (
                        <>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={1.5}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={
                                  {
                                    // transform:"rotate(-90deg)"
                                  }
                                }
                              >
                                <h5
                                  style={{
                                    transform: "rotate(-90deg)",
                                    // writingMode: "horizontal-bt"
                                    // writingMode: "vertical-rl",
                                    // textOrientation: "sideways-right"
                                    // "-webkit-writingMode": "vertical-rl",
                                    // "-ms-writingMode": "vertical-rl",
                                    // filter: "flipH() flipV()",
                                    // margin: "0 auto"
                                  }}
                                >
                                  {dimension.dimensionName}
                                </h5>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={10.5}
                              container
                              style={{ minHeight: "25vh" }}
                            >
                              {dimension.question.map((question) => {
                                return (
                                  <>
                                    <Grid item xs={12} sm={12} md={8}>
                                      <p style={{ margin: 0 }}>
                                        {question.questionName}
                                      </p>
                                      <Grid container>
                                        <Grid item xs={6} sm={6} md={4}>
                                          <b>Self</b>:&nbsp;
                                          {question.self_rating &&
                                          Number(question.self_rating) === 0
                                            ? "N"
                                            : question.self_rating &&
                                              Number(question.self_rating)}
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={4}>
                                          <b>Assessor</b>:&nbsp;
                                          {question.avg_stakeholder &&
                                            ("%. 2f",
                                            parseFloat(
                                              question.avg_stakeholder
                                            ))}
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={4}>
                                          <b>Range</b>:&nbsp;
                                          {question.min_column2 &&
                                            Number(question.min_column2)}
                                          -
                                          {question.max_column2 &&
                                            Number(question.max_column2)}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                      <MuiSlider report={question} />
                                    </Grid>
                                  </>
                                );
                              })}
                            </Grid>
                          </Grid>
                          <hr />
                        </>
                      );
                    })}
                    <h3 style={{ textTransform: "uppercase" }}>
                      {shortResponseData.length > 0 &&
                        shortResponseData.filter(
                          (sr) => sr.sectionName === item.sectionName
                        ).length > 0 &&
                        shortResponseData.filter(
                          (sr) => sr.sectionName === item.sectionName
                        )[0]?.sectionName}
                      {shortResponseData.length > 0 &&
                        shortResponseData.filter(
                          (sr) => sr.sectionName === item.sectionName
                        ).length > 0 &&
                        "-"}
                      Comments
                    </h3>
                    <ul>
                      {shortResponseData.length > 0 &&
                        shortResponseData
                          .filter((sr) => sr.sectionName === item.sectionName)
                          .map(
                            (sr) =>
                              sr.shortResponse && <li>{sr.shortResponse}</li>
                          )}
                    </ul>
                    <hr />
                    <hr />
                  </>
                );
              })}
            <h3>WHAT YOUR ASSESSORS WANT YOU TO KNOW</h3>
            <ul>
              {longResponseData.length > 0 &&
                longResponseData.map(
                  (lr) => lr.longResponse && <li>{lr.longResponse}</li>
                )}
            </ul>
          </div>
        </PDFExport>
        <Box textAlign="center">
          <Button
            variant="contained"
            sx={{ margin: "1rem" }}
            onClick={() => {
              if (pdfExportComponent.current) {
                pdfExportComponent.current.save();
              }
            }}
            // onClick={() => onButtonClick()}
          >
            Download PDF
          </Button>
          {/* <a
            href={`${ASSESSMENT_BASE_URL}/v1/api/excel-parser/full-report?assessmentTrackingId=${location?.state?.clientSelectedData?.id}`}
            target="_blank"
            style={{
              textDecoration: 'none'
            }}
          > */}
          {/* <Button variant="contained" sx={{ margin: "1rem" }} onClick={() => Export2Word('report-content') }>
              Download DOCX
            </Button> */}
          {/* </a> */}
        </Box>
      </>
    </Layout>
  );
}

export default Report;
