import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_ASSESSMENT_TEMPLATE_DIMENSION_CONFIG, 
  GET_ASSESSMENT_TEMPLATE_DIMENSION_CONFIG_BY_TEMPLATE_ID,
  GET_ASSESSMENT_TEMPLATE_DIMENSION_CONFIG_BY_SECTION_ID
} from "../../utils/routes";

// ---------------------------------------------------------------------

export const createAssessmentTemplateDimensionConfig = createAsyncThunk(
  "assessmentTemplateDimensionConfig/createAssessmentTemplateDimensionConfig",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_ASSESSMENT_TEMPLATE_DIMENSION_CONFIG, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAssessmentTemplateDimensionConfig = createAsyncThunk(
  "assessmentTemplateDimensionConfig/getAssessmentTemplateDimensionConfig",
  async (data, {rejectWithValue}) => {
    try {
      const response = await axios.post(GET_ASSESSMENT_TEMPLATE_DIMENSION_CONFIG_BY_TEMPLATE_ID, data, { withCredentials: true });
      return response
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
);
export const getAssessmentTemplateDimensionConfigBySectionId = createAsyncThunk(
  "assessmentTemplateDimensionConfig/getAssessmentTemplateDimensionConfigBySectionId",
  async (data, {rejectWithValue}) => {


    try {
      const response = await axios.post(GET_ASSESSMENT_TEMPLATE_DIMENSION_CONFIG_BY_SECTION_ID, data, { withCredentials: true });
      return response
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
);

export const updateAssessmentTemplateDimensionConfig = createAsyncThunk(
  "assessmentTemplateDimensionConfig/updateAssessmentTemplateDimensionConfig",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_ASSESSMENT_TEMPLATE_DIMENSION_CONFIG, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAssessmentTemplateDimensionConfig = createAsyncThunk(
  "assessmentTemplateDimensionConfig/deleteAssessmentTemplateDimensionConfig",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_ASSESSMENT_TEMPLATE_DIMENSION_CONFIG, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const assessmentTemplateDimensionConfigSlice = createSlice({
  name: "assessmentTemplateDimensionConfig",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    assessmentTemplateDimensionConfigData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createAssessmentTemplateDimensionConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [createAssessmentTemplateDimensionConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createAssessmentTemplateDimensionConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getAssessmentTemplateDimensionConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentTemplateDimensionConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentTemplateDimensionData = action?.payload?.data;
    },
    [getAssessmentTemplateDimensionConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getAssessmentTemplateDimensionConfigBySectionId.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentTemplateDimensionConfigBySectionId.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentTemplateDimensionConfigData = action?.payload?.data;
    },
    [getAssessmentTemplateDimensionConfigBySectionId.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateAssessmentTemplateDimensionConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [updateAssessmentTemplateDimensionConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateAssessmentTemplateDimensionConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteAssessmentTemplateDimensionConfig.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteAssessmentTemplateDimensionConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteAssessmentTemplateDimensionConfig.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  assessmentTemplateDimensionConfigSlice.actions;
export default assessmentTemplateDimensionConfigSlice.reducer;
