import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_ASSESSMENT_PROGRESS } from "../../utils/routes";

// ---------------------------------------------------------------------

export const createAssessmentProgress = createAsyncThunk(
  "assessmentProgress/createAssessmentProgress",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_ASSESSMENT_PROGRESS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAssessmentProgress = createAsyncThunk(
  "assessmentProgress/getAssessmentProgress",
  async (obj, {rejectWithValue}) => {
    try {
      const response = await axios.get(CURD_ASSESSMENT_PROGRESS, { withCredentials: true });
      return response
    } catch (err) {
      return rejectWithValue(err.response) 
    }
  }
);

export const updateAssessmentProgress = createAsyncThunk(
  "assessmentProgress/updateAssessmentProgress",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_ASSESSMENT_PROGRESS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAssessmentProgress = createAsyncThunk(
  "assessmentProgress/deleteAssessmentProgress",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(CURD_ASSESSMENT_PROGRESS, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const assessmentProgressSlice = createSlice({
  name: "assessmentProgress",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    assessmentProgressData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createAssessmentProgress.pending]: (state, action) => {
      state.loading = true;
    },
    [createAssessmentProgress.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createAssessmentProgress.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getAssessmentProgress.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentProgress.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentProgressData = action?.payload?.data;
    },
    [getAssessmentProgress.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateAssessmentProgress.pending]: (state, action) => {
      state.loading = true;
    },
    [updateAssessmentProgress.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateAssessmentProgress.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteAssessmentProgress.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteAssessmentProgress.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteAssessmentProgress.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  assessmentProgressSlice.actions;
export default assessmentProgressSlice.reducer;
