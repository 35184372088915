import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import { CURD_ASSESSMENT_RESPONSE,UPDATE_ASSESSOR_CLIENT_REPORT, CURD_ASSESSORS_RESPONSE_LIST,CURD_REMAINDER_RESPONSE_LIST, SEND_REMINDER_MAIL, GET_REPORT, GET_OPTIONAL_COMMENTS_REPORT, GET_SHORT_RESPONSE_REPORT, GET_LONG_RESPONSE_REPORT, } from "../../utils/routes";

// ---------------------------------------------------------------------

export const getAssessmentResponse = createAsyncThunk(
  "assessmentResponse/getAssessmentResponse",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_ASSESSMENT_RESPONSE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAssessorsResponseList = createAsyncThunk(
  "assessmentResponse/getAssessorsResponseList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_ASSESSORS_RESPONSE_LIST, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);






export const sendReminderMail = createAsyncThunk(
  "assessmentResponse/sendReminderMail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(SEND_REMINDER_MAIL, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateClientStatus = createAsyncThunk(
  "assessmentResponse/clientStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_ASSESSOR_CLIENT_REPORT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAssessorStatus = createAsyncThunk(
  "assessmentResponse/assessorStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_ASSESSOR_CLIENT_REPORT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getReport = createAsyncThunk(
  "assessmentResponse/getReport",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(GET_REPORT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);



export const getOptionalCommentsReport = createAsyncThunk(
  "assessmentResponse/getOptionalCommentsReport",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(GET_OPTIONAL_COMMENTS_REPORT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getShortResponseReport = createAsyncThunk(
  "assessmentResponse/getShortResponseReport",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(GET_SHORT_RESPONSE_REPORT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getLongResponseReport = createAsyncThunk(
  "assessmentResponse/getLongResponseReport",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(GET_LONG_RESPONSE_REPORT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const getHistoryRemainderResponseList = createAsyncThunk(
  "assessmentResponse/getHistoryRemainderList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CURD_REMAINDER_RESPONSE_LIST, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);





const assessmentResponseSlice = createSlice({
  name:"assessmentResponse",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    assessmentResponseData: [],
    assessorsResponseList: [],
    reportsData: [],
    optionalCommentsData: [],
    remainderHistoryData:[],
    shortResponseData: [],
    longResponseData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
    emptyRow : (state,action) =>{
      state.selectedData = {}
    }
  },
  extraReducers: {
    [getHistoryRemainderResponseList.pending]: (state, action) => {
      state.loading = true;
    },
    [getHistoryRemainderResponseList.fulfilled]: (state, action) => {
      state.loading = false;
      state.remainderHistoryData = action?.payload?.data;
    },
    [getHistoryRemainderResponseList.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getAssessmentResponse.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentResponse.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentResponseData = action?.payload?.data;
    },
    [getAssessmentResponse.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

   


    [getAssessorsResponseList.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessorsResponseList.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessorsResponseList = action?.payload?.data;
    },
    [getAssessorsResponseList.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

   

    [getReport.pending]: (state, action) => {
      state.loading = true;
    },
    [getReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.reportsData = action?.payload?.data;
    },


    [getReport.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getOptionalCommentsReport.pending]: (state, action) => {
      state.loading = true;
    },
    [getOptionalCommentsReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.optionalCommentsData = action?.payload?.data;
    },
    [getOptionalCommentsReport.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getShortResponseReport.pending]: (state, action) => {
      state.loading = true;
    },
    [getShortResponseReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.shortResponseData = action?.payload?.data;
    },
    [getShortResponseReport.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getLongResponseReport.pending]: (state, action) => {
      state.loading = true;
    },
    [getLongResponseReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.longResponseData = action?.payload?.data;
    },
    [getLongResponseReport.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },


    [sendReminderMail.pending]: (state, action) => {
      state.loading = true;
    },
    [sendReminderMail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [sendReminderMail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },


    [updateClientStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [updateClientStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateClientStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [updateAssessorStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [updateAssessorStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateAssessorStatus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    }
  },
});

export const { resetMessage, resetErrMessage, rowSelect,emptyRow } =
 assessmentResponseSlice.actions;
export default assessmentResponseSlice.reducer;